import { useCallback, useEffect, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import nl2br from 'react-nl2br';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Player } from 'video-react';

import LoadingIndicator from 'components/LoadingIndicator';
import StaticHeader from 'components/StaticHeader';
import { Api } from 'config/api';
import { images } from 'config/images';
import { logout } from 'store/actions/AuthActions';
import { guestGet } from 'utilities/api';
import styles from './PromoInstruction.styles';

const PromoInstruction = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [instruction, setInstruction] = useState(null);
    const [error, setError] = useState(false);
    const classes = styles();

    const fetchInstruction = useCallback(async () => {
        try {
            const endpoint = Api.requests.promo.instructions.index(params.slug);

            const response = await guestGet(endpoint);
            setInstruction(response.data.data);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [params])

    useEffect(() => {
        fetchInstruction();
    }, [fetchInstruction])

    useEffect(() => {
        dispatch(logout());
    });

    return (
        <>
            <StaticHeader promo />

            <main role="main" className="pages no-padding">
                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.login.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.login.right.default } alt="Splash" />
                    </div>
                </figure>

                { loading && (
                    <LoadingIndicator message="Bezig met ophalen van instructie" />
                ) }

                { error && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 mx-auto">
                                <Alert severity="error">De instructie kon niet worden geladen</Alert>
                            </div>
                        </div>
                    </div>
                ) }

                { ! loading && ! error && (
                    <section className="log-in">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mx-auto">
                                    <Box mb={ 2 }>
                                        <Typography variant="h3" component="h1">
                                            { instruction?.title }
                                        </Typography>
                                    </Box>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-xs-12 mx-auto">
                                    { instruction?.video && (
                                        <Player>
                                            <source src={ instruction.video } />
                                        </Player>
                                    )}
                                </div>
                                <div className="col-lg-6 col-xs-12 mx-auto">
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Uitleg</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                { nl2br(instruction?.description) }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={ classes.heading }>Over Go4Hockey</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={ classes.details }>
                                            <Typography>
                                                Ben je op zoek naar meer materiaal en ondersteuning?
                                                Go4Hockey is het platform voor het optimaal begeleiden van je team.
                                            </Typography>

                                            <Box mt={ 2 }>
                                                <Link to="/"  className="btn btn-fill primary">Meer informatie</Link>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                ) }
            </main>
        </>
    );
};

export default PromoInstruction;
