import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';

const ImageDialog = props => {
    const { open, handleClose, image } = props;
    return (
        <Dialog onClose={ handleClose } aria-labelledby="image-dialog" open={ open }>
            <img src={ image } alt="" className="responsive-image" />
        </Dialog>
    );
};

ImageDialog.propTypes = {
    image: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ImageDialog;
