import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import styles from './SidebarItem.styles';

const SidebarItem = ({ name, target, icon, nested }) => {
    const classes = styles();

    return (
        <Link to={ target } className={ classes.link }>
            <ListItem button className={ nested ? classes.nested : null }>
                <ListItemIcon>
                    <Icon>{ icon }</Icon>
                </ListItemIcon>
                <ListItemText primary={ name } />
            </ListItem>
        </Link>
    );
};

SidebarItem.propTypes = {
    name: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    icon: PropTypes.string,
    nested: PropTypes.bool,
};

export default SidebarItem;