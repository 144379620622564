import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { OCCASIONS } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest() {
    const token = yield select(getToken);

    return yield get(token, Api.requests.occasions.index);
}

export function* fetchOneRequest(token, trainingId) {
    return yield get(token, Api.requests.trainings.show(trainingId));
}

export function* fetchFlow() {
    while (true) {
        yield take(OCCASIONS.FETCH.TRIGGER);

        try {
            const response = yield call(fetchAllRequest);
            const occasions = response.data.data;

            yield put(OCCASIONS.FETCH.success(occasions));
        } catch (error) {
            yield put(OCCASIONS.FETCH.failure());
        } finally {
            yield put(OCCASIONS.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(OCCASIONS.SHOW.TRIGGER);

        try {
            const trainingId = request.payload;
            const token = yield select(getToken);
            const response = yield call(fetchOneRequest, token, trainingId);
            const training = response.data.data;

            yield put(OCCASIONS.SHOW.success(training));
        } catch (error) {
            yield put(OCCASIONS.SHOW.failure());
        } finally {
            yield put(OCCASIONS.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
    ]);
}