import { Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import Alert from '@material-ui/lab/Alert';

import { images } from 'config/images';
import PasswordResetForm from 'components/PasswordResetForm';
import StaticHeader from 'components/StaticHeader';
import { AUTH } from 'store/constants';

const PasswordReset = props => {
    const dispatch = useDispatch();
    const error = useSelector(state => state.auth.passwordError);
    const success = useSelector(state => state.auth.passwordSuccess);
    const query = queryString.parse(props.location.search)

    const pageTitle = useIntl().formatMessage({ id: 'passwordReset.title' });
    const passwordLabel = useIntl().formatMessage({ id: 'passwordReset.label.password' });
    const repeatPasswordLabel = useIntl().formatMessage({ id: 'passwordReset.label.passwordConfirmation' });
    const submitButtonText = useIntl().formatMessage({ id: 'passwordReset.button.resetPassword' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const equalFieldMessage = useIntl().formatMessage({ id: 'validation.error.equal' });
    const minimumLengthMessage = useIntl().formatMessage({ id: 'validation.error.minPasswordLength' });
    const errorMessage = useIntl().formatMessage({ id: 'passwordReset.message.error' });
    const successMessage = useIntl().formatMessage({ id: 'passwordReset.message.success' });

    const handleSubmit = values => {
        const payload = {
            ...query,
            ...values,
        }

        dispatch({
            type: AUTH.PASSWORD.RESET,
            payload,
        });
    }

    return (
        <Fragment>
            <StaticHeader />

            <main role="main" className="pages sub">
                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.login.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.login.right.default } alt="Splash" />
                    </div>
                </figure>

                <section className="log-in">
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xm-8 col-xs-8 mx-auto no-gutters">
                                <article className="info">
                                    <h1>{ pageTitle }</h1>

                                    { error && <Alert severity="error">{ errorMessage }</Alert> }
                                    { success && <Alert severity="success">{ successMessage }</Alert> }
                                </article>

                                { ! error && ! success ? (
                                    <PasswordResetForm
                                        title={ pageTitle }
                                        passwordLabel={ passwordLabel }
                                        repeatPasswordLabel={ repeatPasswordLabel }
                                        submitButtonText={ submitButtonText }
                                        minimumLengthMessage={ minimumLengthMessage }
                                        requiredFieldMessage={ requiredFieldMessage }
                                        equalFieldMessage={ equalFieldMessage }
                                        onSubmit={ handleSubmit }
                                    />
                                ) : null }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default PasswordReset;
