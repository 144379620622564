import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ContentContainer from 'components/ContentContainer';
import MapDrawing from 'components/MapDrawing';
import { PROFILE } from 'store/constants';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';

const MapDrawingTool = () => {
    const dispatch = useDispatch();
    const pageTitle = useIntl().formatMessage({ id: 'mapDrawing.title'});
    const premiumFetched = useSelector(state => state.profile.fetched);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const premium = useSelector(state => state.profile.is_premium);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const isPremium = isAdmin ? isAdmin : premium;

    useEffect(() => {
        dispatch(PROFILE.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle }>
            <PremiumWall
                isProfileFetched={ premiumFetched }
                isPremium={ isPremium || hasCorrectPermission }
                premiumImage={ images.premium.drawingtool.default }
            >
                <MapDrawing />
            </PremiumWall>
        </ContentContainer>
    );
};

export default MapDrawingTool;
