import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const SurveysTable = props => {
    const { clubs, data, onCreate, onDelete, onUpdate } = props;
    const history = useHistory();

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Naam',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            editable: 'onAdd',
            field: 'club.data.id',
            render: rowData => rowData?.club?.data?.name || '-',
            title: 'Club',
            lookup: clubs,

        },
        {
            field: 'is_private',
            title: 'Afgeschermd',
            type: 'boolean',

        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/surveys/${rowData.id}/topics`)
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Vragenlijsten"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

SurveysTable.propTypes = {
    clubs: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default SurveysTable;