import { AUTH, CLUB } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    clubId: null,
    teams: [],

    error: false,
    loading: false,

    created: null,
    updated: false,
    deleted: false,
};

const teamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB.TEAM.FETCH.TRIGGER:
        case CLUB.TEAM.CREATE.TRIGGER:
        case CLUB.TEAM.UPDATE.TRIGGER:
        case CLUB.TEAM.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLUB.TEAM.FETCH.SUCCESS:
            return {
                ...state,
                clubId: action.payload.clubId,
                teams: action.payload.teams,
            };

        case CLUB.TEAM.CREATE.SUCCESS:
            return {
                ...state,
                teams: state.teams.concat(action.payload),
                created: action.payload.id,
            };

        case CLUB.TEAM.UPDATE.SUCCESS:
            return {
                ...state,
                teams: findIdAndUpdateObjectInArray([...state.teams], action.payload.id, action.payload),
                updated: true,
            };

        case CLUB.TEAM.DELETE.SUCCESS:
            return {
                ...state,
                teams: deleteObjectWithIdFromArray([...state.teams], action.payload),
                deleted: true,
            };

        case CLUB.TEAM.FETCH.FAILURE:
        case CLUB.TEAM.CREATE.FAILURE:
        case CLUB.TEAM.UPDATE.FAILURE:
        case CLUB.TEAM.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLUB.TEAM.FETCH.FULFILL:
        case CLUB.TEAM.CREATE.FULFILL:
        case CLUB.TEAM.UPDATE.FULFILL:
        case CLUB.TEAM.DELETE.FULFILL:
            return {
                ...state,
                created: null,
                deleted: false,
                error: false,
                loading: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default teamReducer;