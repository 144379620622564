import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { defaultOptions, localization } from 'utilities/materialTable';
import { reverseToReadableDate } from 'utilities/date';
import { sortCaseInsensitive } from 'utilities/sort';

const ClubAccessRequestsTable = props => {
    const { data, onAccept, onDelete } = props;

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a?.user?.data, b?.user?.data, 'name'),
            defaultSort: 'asc',
            field: 'user.data.name',
            title: 'Gebruiker',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a?.team?.data, b?.team?.data, 'name'),
            render: rowData => rowData?.team?.data?.name || '-',
            title: 'Team',
        },
        {
            field: 'date',
            render: rowData => reverseToReadableDate(rowData.date),
            title: 'Datum',
        },
    ];

    const actions = [
        {
            icon: 'thumb_up',
            onClick: (event, rowData) => onAccept(rowData),
            tooltip: 'Accepteren',
        },
    ];

    const editable = {
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            actions={ actions }
            columns={ columns }
            data={ data }
            editable={ editable }
            localization={ localization }
            options={ defaultOptions }
            title="Clubs"
        />
    );
};

ClubAccessRequestsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onAccept: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ClubAccessRequestsTable;