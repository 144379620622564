import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';
import { reverseToReadableDate } from 'utilities/date';

const EvaluationDetails = props => {
    const { evaluation } = props;

    return (
        <>
            <ValueBlock label="Datum" value={ reverseToReadableDate(evaluation.date) } />
            <ValueBlock label="Vragenlijst" value={ evaluation?.survey?.data?.name } />
            <ValueBlock label="Team" value={ evaluation?.team?.data?.name } />
            <ValueBlock label="Spelers uitgenodigd" value={ evaluation?.invite_players ? 'Ja' : 'Nee' } />
            <ValueBlock label="Keepers uitgenodigd" value={ evaluation?.invite_goalkeepers ? 'Ja' : 'Nee' } />
            <ValueBlock label="Afgeschermd" value={ evaluation?.is_private ? 'Ja' : 'Nee' } />
            <ValueBlock label="Laatste reminder" value={ reverseToReadableDate(evaluation?.last_reminder_sent_at) } />
        </>
    );
};

EvaluationDetails.propTypes = {
    evaluation: PropTypes.object.isRequired,
};

export default EvaluationDetails;