import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    container: {
        flex: 1,
        display: 'flex',
    },

    label: {
        fontWeight: 'bold',
        width: ({labelWidth}) => labelWidth,
    },
}));

export default styles;