import { makeStyles } from '@material-ui/core/styles';

const sidebarStyles = makeStyles(() => ({
    logo: {
        width: 208,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 8,
        marginBottom: 32,
    },

    switch: {
        marginTop: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));

const drawerStyles = makeStyles(() => ({
    paper: {
        flexShrink: 0,
        width: 240,
    },
}));

export { sidebarStyles, drawerStyles };