import { AUTH, CLUB } from '../constants';

const INITIAL_STATE = {
    clubPlayerId: null,
    assessments: [],

    error: false,
    loading: false,
};

const clubPlayerAssessmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB.PLAYER.ASSESSMENT.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLUB.PLAYER.ASSESSMENT.FETCH.SUCCESS:
            return {
                ...state,
                clubPlayerId: action.payload.clubPlayerId,
                assessments: action.payload.assessments,
            };

        case CLUB.PLAYER.ASSESSMENT.FETCH.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLUB.PLAYER.ASSESSMENT.FETCH.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default clubPlayerAssessmentReducer;