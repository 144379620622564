import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { ACCOUNTS } from '../constants';
import {
    fetchRequest,
    storeRequest,
    deleteRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        yield take(ACCOUNTS.FETCH.TRIGGER);

        try {
            const endpoint = Api.requests.accounts.index;
            const response = yield call(fetchRequest, endpoint);
            const accounts = response.data.data;

            yield put(ACCOUNTS.FETCH.success(accounts));
        } catch (error) {
            yield put(ACCOUNTS.FETCH.failure());
        } finally {
            yield put(ACCOUNTS.FETCH.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(ACCOUNTS.CREATE.TRIGGER);

        try {
            const endpoint = Api.requests.accounts.store;
            const payload = request.payload;
            const response = yield call(storeRequest, endpoint, payload);

            yield put(ACCOUNTS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(ACCOUNTS.CREATE.failure());
        } finally {
            yield put(ACCOUNTS.CREATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(ACCOUNTS.DELETE.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.accounts.destroy(id);

            yield call(deleteRequest, endpoint);
            yield put(ACCOUNTS.DELETE.success(request.payload));
        } catch (error) {
            yield put(ACCOUNTS.DELETE.failure());
        } finally {
            yield put(ACCOUNTS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        createFlow(),
        deleteFlow(),
    ]);
}