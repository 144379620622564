import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import ClassificationCreate from './ClassificationCreate';
import ClassificationOverview from './ClassificationOverview';
import ClassificationShow from './ClassificationShow';
import ClassificationTeamShow from './ClassificationTeamShow';

const Classifications = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ path }
                component={ ClassificationOverview }
            />

            <Route
                exact
                path={ `${path}/create` }
                component={ ClassificationCreate }
            />

            <Route
                exact
                path={ `${path}/:classificationId` }
                component={ ClassificationShow }
            />

            <Route
                exact
                path={ `${path}/:classificationId/teams/:teamId` }
                component={ ClassificationTeamShow }
            />
        </Switch>
    );
};

export default Classifications;