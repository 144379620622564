import PropTypes from 'prop-types';

import ActionList from 'components/ActionList';

const TeamList = props => {
    const { teams } = props;
    const listItems = teams.map(team => (
        {
            target: `/teams/${team.id}`,
            label: team.name,
        }
    )).sort()

    return (
        <ActionList
            values={ listItems }
        />
    );
};

TeamList.propTypes = {
    teams: PropTypes.array.isRequired,
};

export default TeamList;