import { AUTH, FEEDBACK } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    feedback: [],
    player: {
        loading: false,
        id: null,
        feedback: [],
    },
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const feedbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FEEDBACK.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case FEEDBACK.FETCH.SUCCESS:
            return {
                ...state,
                feedback: action.payload,
                fetched: true,
            };

        case FEEDBACK.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case FEEDBACK.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }
            
        case FEEDBACK.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                created: false,
            }

        case FEEDBACK.CREATE.SUCCESS:
            return {
                ...state,
                feedback: [
                    ...state.feedback,
                    action.payload
                ],
                created: action.payload.id,
            };

        case FEEDBACK.CREATE.FAILURE:
            return {
                ...state,
                error: true,
                loading: false
            }

        case FEEDBACK.CREATE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case FEEDBACK.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                updated: false,
            }

        case FEEDBACK.UPDATE.SUCCESS:
            return {
                ...state,
                feedback: findIdAndUpdateObjectInArray([...state.feedback], action.payload.id, action.payload),
                updated: true,
            };

        case FEEDBACK.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case FEEDBACK.UPDATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case FEEDBACK.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                deleted: false,
            }

        case FEEDBACK.DELETE.SUCCESS:
            return {
                ...state,
                feedback: deleteObjectWithIdFromArray([...state.feedback], action.payload),
                deleted: true,
            };

        case FEEDBACK.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case FEEDBACK.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case FEEDBACK.PLAYER.FETCH.TRIGGER:
            return {
                ...state,
                player: {
                    ...state.player,
                    loading: true,
                    id: null,
                    feedback: [],
                },
            }

        case FEEDBACK.PLAYER.FETCH.SUCCESS:
            return {
                ...state,
                player: {
                    ...state.player,
                    id: action.payload.playerId,
                    feedback: action.payload.feedback,
                },
            };

        case FEEDBACK.PLAYER.FETCH.FAILURE:
            return {
                ...state,
                player: {
                    ...state.player,
                    error: true,
                },
            }

        case FEEDBACK.PLAYER.FETCH.FULFILL:
            return {
                ...state,
                player: {
                    ...state.player,
                    loading: false,
                    error: false,
                },
            }

        case FEEDBACK.CLEAN:
            return {
                ...state,
                error: false,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default feedbackReducer;