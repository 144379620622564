import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ValueBlock from '../ValueBlock';

const PremiumInfo = props => {
    const { accountTypeLabel, accountType, expireDateLabel, expireDate } = props;

    return (
        <Fragment>
            <ValueBlock
                label={ accountTypeLabel }
                value={ accountType }
            />

            { expireDate && (
                <ValueBlock
                    label={ expireDateLabel }
                    value={ expireDate }
                />
            )}
        </Fragment>
    );
};

PremiumInfo.propTypes = {
    accountTypeLabel: PropTypes.string,
    accountType: PropTypes.string.isRequired,
    expireDateLabel: PropTypes.string,
    expireDate: PropTypes.string.isRequired,
};

PremiumInfo.defaultProps = {
    accountTypeLabel: 'Account type',
    expireDateLabel: 'Expire date',
}

export default PremiumInfo;
