import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { EXERCISES } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, patchFile, postFile } from 'utilities/api';
import { deleteObjectWithIdFromArray } from 'utilities/array';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.exercises.index);
}

export function* fetchEducationPlanRequest(token) {
    return yield get(token, Api.requests.exercises.educationPlan);
}

export function* fetchLatestRequest(token) {
    return yield get(token, Api.requests.exercises.latest);
}

export function* fetchOneRequest(id) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.exercises.show(id));
}

export function* createRequest({token, payload}) {
    return yield postFile(token, Api.requests.exercises.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patchFile(token, Api.requests.exercises.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.exercises.delete(id));
}

export function* addLabelRequest({token, exerciseId, labelId, headers = {}}) {
    return yield patch(token, Api.requests.exercises.label.update(exerciseId, labelId), headers);
}

export function* deleteLabelRequest({token, exerciseId, labelId, headers = {}}) {
    return yield deletePost(token, Api.requests.exercises.label.delete(exerciseId, labelId), headers);
}

export function* addTagRequest({token, exerciseId, tagId, headers = {}}) {
    return yield patch(token, Api.requests.exercises.tag.update(exerciseId, tagId), headers);
}

export function* deleteTagRequest({token, exerciseId, tagId, headers = {}}) {
    return yield deletePost(token, Api.requests.exercises.tag.delete(exerciseId, tagId), headers);
}

export function* fetchFlow() {
    while (true) {
        yield take(EXERCISES.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const exercises = response.data.data;

            yield put(EXERCISES.FETCH.success(exercises));
        } catch (error) {
            yield put(EXERCISES.FETCH.failure());
        } finally {
            yield put(EXERCISES.FETCH.fulfill());
        }
    }
}

export function* fetchEducationPlanFlow() {
    while (true) {
        yield take(EXERCISES.EDUCATION_PLAN.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchEducationPlanRequest, token);
            const exercises = response.data.data;

            yield put(EXERCISES.EDUCATION_PLAN.success(exercises));
        } catch (error) {
            yield put(EXERCISES.EDUCATION_PLAN.failure());
        } finally {
            yield put(EXERCISES.EDUCATION_PLAN.fulfill());
        }
    }
}

export function* latestFlow() {
    while (true) {
        yield take(EXERCISES.LATEST.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchLatestRequest, token);
            const exercises = response.data.data;

            yield put(EXERCISES.LATEST.success(exercises));
        } catch (error) {
            yield put(EXERCISES.LATEST.failure());
        } finally {
            yield put(EXERCISES.LATEST.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(EXERCISES.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const response = yield call(fetchOneRequest, id);

            yield put(EXERCISES.SHOW.success(response.data.data));
        } catch (error) {
            yield put(EXERCISES.SHOW.failure());
        } finally {
            yield put(EXERCISES.SHOW.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(EXERCISES.CREATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.title);

            if (request.payload.description) {
                formData.append('description', request.payload.description);
            }

            if (request.payload.explanation) {
                formData.append('explanation', request.payload.explanation);
            }

            if (request.payload.instruction) {
                formData.append('instruction', request.payload.instruction);
            }

            if (request.payload.extension) {
                formData.append('extension', request.payload.extension);
            }

            formData.append('difficulty', request.payload.difficulty);
            formData.append('image', request.payload.image);

            if (request.payload.video) {
                formData.append('video', request.payload.video);
            }

            const response = yield call(createRequest, { token, payload: formData });

            yield put(EXERCISES.CREATE.success(response.data.data));
        } catch (error) {
            yield put(EXERCISES.CREATE.failure());
        } finally {
            yield put(EXERCISES.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(EXERCISES.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.values.title);

            if (request.payload.values.description) {
                formData.append('description', request.payload.values.description);
            }

            if (request.payload.values.explanation) {
                formData.append('explanation', request.payload.values.explanation);
            }

            if (request.payload.values.instruction) {
                formData.append('instruction', request.payload.values.instruction);
            }

            if (request.payload.values.extension) {
                formData.append('extension', request.payload.values.extension);
            }


            formData.append('difficulty', request.payload.values.difficulty);

            if (request.payload.values.image) {
                formData.append('image', request.payload.values.image);
            }

            if (request.payload.values.video) {
                formData.append('video', request.payload.values.video);
            }

            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(EXERCISES.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(EXERCISES.CREATE.failure());
        } finally {
            yield put(EXERCISES.CREATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(EXERCISES.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(EXERCISES.DELETE.success(request.payload));
        } catch (error) {
            yield put(EXERCISES.DELETE.failure());
        } finally {
            yield put(EXERCISES.DELETE.fulfill());
        }
    }
}

export function* addLabelFlow() {
    while (true) {
        const request = yield take(EXERCISES.LABEL.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const exercise = request.payload.exercise;
            const label = request.payload.label;

            yield call(addLabelRequest, {
                token,
                exerciseId: exercise.id,
                labelId: label.id
            });

            exercise.labels = exercise.labels.concat(label.name);

            yield put(EXERCISES.LABEL.UPDATE.success(exercise))
        } catch (error) {
            yield put(EXERCISES.LABEL.UPDATE.failure());
        } finally {
            yield put(EXERCISES.LABEL.UPDATE.fulfill());
        }
    }
}

export function* deleteLabelFlow() {
    while (true) {
        const request = yield take(EXERCISES.LABEL.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);
            const exercise = request.payload.exercise;
            const label = request.payload.label;

            yield call(deleteLabelRequest, {
                token,
                exerciseId: exercise.id,
                labelId: label.id
            });

            exercise.labels = [...exercise.labels].filter(row => row !== label.name);

            yield put(EXERCISES.LABEL.DELETE.success(exercise))
        } catch (error) {
            yield put(EXERCISES.LABEL.DELETE.failure());
        } finally {
            yield put(EXERCISES.LABEL.DELETE.fulfill());
        }
    }
}

export function* addTagFlow() {
    while (true) {
        const request = yield take(EXERCISES.TAG.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const exercise = request.payload.exercise;
            const tag = request.payload.tag;

            yield call(addTagRequest, {
                token,
                exerciseId: exercise.id,
                tagId: tag.id
            });

            exercise.tags.data = exercise.tags.data.concat(tag);

            yield put(EXERCISES.TAG.UPDATE.success(exercise))
        } catch (error) {
            yield put(EXERCISES.TAG.UPDATE.failure());
        } finally {
            yield put(EXERCISES.TAG.UPDATE.fulfill());
        }
    }
}

export function* deleteTagFlow() {
    while (true) {
        const request = yield take(EXERCISES.TAG.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);
            const exercise = request.payload.exercise;
            const tag = request.payload.tag;

            yield call(deleteTagRequest, {
                token,
                exerciseId: exercise.id,
                tagId: tag.id
            });

            exercise.tags.data = deleteObjectWithIdFromArray(exercise.tags.data, tag.id);

            yield put(EXERCISES.TAG.DELETE.success(exercise))
        } catch (error) {
            yield put(EXERCISES.TAG.DELETE.failure());
        } finally {
            yield put(EXERCISES.TAG.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        fetchEducationPlanFlow(),
        latestFlow(),
        showFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        addLabelFlow(),
        deleteLabelFlow(),
        addTagFlow(),
        deleteTagFlow(),
    ]);
}