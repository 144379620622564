import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { VIDEOS } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, patchFile, postFile } from 'utilities/api';
import { deleteObjectWithIdFromArray } from 'utilities/array';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.videos.index);
}

export function* createRequest({token, payload}) {
    return yield postFile(token, Api.requests.videos.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patchFile(token, Api.requests.videos.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.videos.delete(id));
}

export function* addTagRequest({token, videoId, tagId, headers = {}}) {
    return yield patch(token, Api.requests.videos.tag.update(videoId, tagId), headers);
}

export function* deleteTagRequest({token, videoId, tagId, headers = {}}) {
    return yield deletePost(token, Api.requests.videos.tag.delete(videoId, tagId), headers);
}

export function* fetchFlow() {
    while (true) {
        yield take(VIDEOS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const videos = response.data.data;

            yield put(VIDEOS.FETCH.success(videos));
        } catch (error) {
            yield put(VIDEOS.FETCH.failure());
        } finally {
            yield put(VIDEOS.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(VIDEOS.CREATE.TRIGGER);
        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.title);
            formData.append('description', request.payload.description);
            formData.append('video', request.payload.video);

            const response = yield call(createRequest, { token, payload: formData });

            yield put(VIDEOS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(VIDEOS.CREATE.failure());
        } finally {
            yield put(VIDEOS.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(VIDEOS.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.values.title);
            formData.append('description', request.payload.values.description);

            if (request.payload.values.video !== undefined) {
                formData.append('video', request.payload.values.video);
            }

            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(VIDEOS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(VIDEOS.UPDATE.failure());
        } finally {
            yield put(VIDEOS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(VIDEOS.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(VIDEOS.DELETE.success(request.payload));
        } catch (error) {
            yield put(VIDEOS.DELETE.failure());
        } finally {
            yield put(VIDEOS.DELETE.fulfill());
        }
    }
}

export function* addTagFlow() {
    while (true) {
        const request = yield take(VIDEOS.TAG.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const video = request.payload.video;
            const tag = request.payload.tag;

            yield call(addTagRequest, {
                token,
                videoId: video.id,
                tagId: tag.id
            });

            video.tags.data = video.tags.data.concat(tag);

            yield put(VIDEOS.TAG.UPDATE.success(video))
        } catch (error) {
            yield put(VIDEOS.TAG.UPDATE.failure());
        } finally {
            yield put(VIDEOS.TAG.UPDATE.fulfill());
        }
    }
}

export function* deleteTagFlow() {
    while (true) {
        const request = yield take(VIDEOS.TAG.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);
            const video = request.payload.video;
            const tag = request.payload.tag;

            yield call(deleteTagRequest, {
                token,
                videoId: video.id,
                tagId: tag.id
            });

            video.tags.data = deleteObjectWithIdFromArray(video.tags.data, tag.id);

            yield put(VIDEOS.TAG.DELETE.success(video))
        } catch (error) {
            yield put(VIDEOS.TAG.DELETE.failure());
        } finally {
            yield put(VIDEOS.TAG.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        addTagFlow(),
        deleteTagFlow(),
    ]);
}