import PropTypes from 'prop-types';
import { useState } from 'react';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import FormDialog from 'components/FormDialog';
import EventList from 'contents/lists/EventList';
import SelectForm from 'contents/forms/SelectForm';

const PlayerEventsBlock = props => {
    const { events, onSubmit, player } = props;
    const [editMode, setEditMode] = useState(false);
    const eventOptions = events.map(event => {
        return {
            label: event?.name,
            value: event?.id,
        }
    });

    const addPlayerToEvent = values => {
        setEditMode(false);
        onSubmit(values);
    }

    return (
        <>
            <ContentBox>
                <BoxHeading
                    title="Events"
                    icon="edit"
                    onClick={ () => setEditMode(! editMode) }
                />

                <EventList events={ player?.events?.data || [] } />
            </ContentBox>

            <FormDialog
                open={ editMode }
                onClose={ () => setEditMode(false) }
                title="Event toevoegen"
            >
                <SelectForm
                    label="Event"
                    onSubmit={ addPlayerToEvent }
                    options={ eventOptions }
                    submitButtonText="Voeg event toe"
                />
            </FormDialog>

        </>
    );
};

PlayerEventsBlock.propTypes = {
    events: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
}

export default PlayerEventsBlock;