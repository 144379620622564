import { AUTH, CLUB } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from '../../utilities/array';

const INITIAL_STATE = {
    clubs: [],
    fetched: false,
    error: false,
    loading: false,

    created: null,
    updated: false,
    deleted: false,
};

const clubReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB.FETCH.TRIGGER:
        case CLUB.CREATE.TRIGGER:
        case CLUB.UPDATE.TRIGGER:
        case CLUB.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLUB.FETCH.SUCCESS:
            return {
                ...state,
                clubs: action.payload,
                fetched: true,
            };

        case CLUB.CREATE.SUCCESS:
            return {
                ...state,
                clubs: state.clubs.concat(action.payload),
                created: action.payload.id,
            };

        case CLUB.UPDATE.SUCCESS:
            return {
                ...state,
                clubs: findIdAndUpdateObjectInArray([...state.clubs], action.payload.id, action.payload),
                updated: true,
            };

        case CLUB.DELETE.SUCCESS:
            return {
                ...state,
                clubs: deleteObjectWithIdFromArray([...state.clubs], action.payload),
                deleted: true,
            };

        case CLUB.FETCH.FAILURE:
        case CLUB.CREATE.FAILURE:
        case CLUB.UPDATE.FAILURE:
        case CLUB.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLUB.FETCH.FULFILL:
        case CLUB.CREATE.FULFILL:
        case CLUB.UPDATE.FULFILL:
        case CLUB.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                created: null,
                updated: false,
                deleted: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default clubReducer;