import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ValueRow from 'components/ValueRow';
import styles from './ReportHeader.styles';

const ReportHeader = props => {
    const { date, grade, player, survey } = props;
    const classes = styles();

    return (
        <Paper elevation={ 2 } className={ classes.root }>
            <Typography variant="h6" align="left">
                { player }
            </Typography>

            <Box mt={ 2 }>
                <ValueRow
                    label="Onderwerp"
                    value={ survey }
                />

                <ValueRow
                    label="Datum"
                    value={ date }
                />

                { grade && (
                    <ValueRow
                        label="Gemiddelde"
                        value={ grade }
                    />
                ) }
            </Box>
        </Paper>
    );
};

ReportHeader.propTypes = {
    date: PropTypes.string.isRequired,
    grade: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    player: PropTypes.string.isRequired,
    survey: PropTypes.string.isRequired,
};

export default ReportHeader;
