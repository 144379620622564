import { AUTH, TEAM } from '../constants';

const INITIAL_STATE = {
    teamId: null,
    evaluations: [],
    active: null,

    fetched: false,
    error: false,
    loading: false,
};

const evaluationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEAM.EVALUATION.FETCH.TRIGGER:
        case TEAM.EVALUATION.SHOW.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case TEAM.EVALUATION.FETCH.SUCCESS:
            return {
                ...state,
                teamId: action.payload.teamId,
                evaluations: action.payload.evaluations,
                fetched: true,
            };

        case TEAM.EVALUATION.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case TEAM.EVALUATION.FETCH.FAILURE:
        case TEAM.EVALUATION.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case TEAM.EVALUATION.FETCH.FULFILL:
        case TEAM.EVALUATION.SHOW.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default evaluationsReducer;