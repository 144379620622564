import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { reverseToReadableDate } from 'utilities/date';
import LatestItem from './LatestItem';
import styles from './LatestExercises.styles';

const LatestExercises = props => {
    const { title, items } = props;
    const history = useHistory();
    const classes = styles();

    const renderItems = () => {
        return items.map(item => (
            <LatestItem
                key={ `latest-exercise-item-${item.id}`}
                onClick={ () => { history.push(`exercises/${item.id}`) } }
                title={ item.title }
                subtitle={ reverseToReadableDate(item.created_at) }
            />
        ))
    }

    return (
        <div className={ classes.root }>
            <Typography variant="h5" component="h2">{ title }</Typography>

            { renderItems() }
        </div>
    );
};

LatestExercises.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
};

export default LatestExercises;
