import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { TRAININGS } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, post } from 'utilities/api';
import { deleteObjectWithIdFromArray } from 'utilities/array';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.trainings.index);
}

export function* fetchOneRequest(token, trainingId) {
    return yield get(token, Api.requests.trainings.show(trainingId));
}

export function* createRequest({token, payload}) {
    return yield post(token, Api.requests.trainings.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patch(token, Api.requests.trainings.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.trainings.delete(id));
}

export function* addExerciseRequest({token, trainingId, exerciseId, payload, headers = {}}) {
    return yield patch(token, Api.requests.trainings.exercise.update(trainingId, exerciseId), payload, headers);
}

export function* deleteExerciseRequest({token, trainingId, exerciseId, headers = {}}) {
    return yield deletePost(token, Api.requests.trainings.exercise.delete(trainingId, exerciseId), headers);
}

export function* fetchFlow() {
    while (true) {
        yield take(TRAININGS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const trainings = response.data.data;

            yield put(TRAININGS.FETCH.success(trainings));
        } catch (error) {
            yield put(TRAININGS.FETCH.failure());
        } finally {
            yield put(TRAININGS.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(TRAININGS.SHOW.TRIGGER);

        try {
            const trainingId = request.payload;
            const token = yield select(getToken);
            const response = yield call(fetchOneRequest, token, trainingId);
            const training = response.data.data;

            yield put(TRAININGS.SHOW.success(training));
        } catch (error) {
            yield put(TRAININGS.SHOW.failure());
        } finally {
            yield put(TRAININGS.SHOW.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(TRAININGS.CREATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = {
                division_id: request.payload.division_id,
                name: request.payload.name,
            }
            const response = yield call(createRequest, { token, payload: formData });

            yield put(TRAININGS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(TRAININGS.CREATE.failure());
        } finally {
            yield put(TRAININGS.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(TRAININGS.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = {
                division_id: request.payload.values.division_id,
                name: request.payload.values.name,
            }
            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(TRAININGS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(TRAININGS.UPDATE.failure());
        } finally {
            yield put(TRAININGS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(TRAININGS.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(TRAININGS.DELETE.success(request.payload));
        } catch (error) {
            yield put(TRAININGS.DELETE.failure());
        } finally {
            yield put(TRAININGS.DELETE.fulfill());
        }
    }
}

export function* addExerciseFlow() {
    while (true) {
        const request = yield take(TRAININGS.EXERCISE.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const training = request.payload.training;
            const exercise = request.payload.exercise;
            const trainer = request.payload.trainer;
            const payload = trainer !== undefined ? { internal_trainer_id: trainer.id } : {};

            yield call(addExerciseRequest, {
                token,
                trainingId: training.id,
                exerciseId: exercise.id,
                payload,
            });

            training.exercises.data = training.exercises.data.concat(exercise);

            yield put(TRAININGS.SHOW.trigger(training.id));
            yield put(TRAININGS.EXERCISE.UPDATE.success(training))
        } catch (error) {
            yield put(TRAININGS.EXERCISE.UPDATE.failure());
        } finally {
            yield put(TRAININGS.EXERCISE.UPDATE.fulfill());
        }
    }
}

export function* deleteExerciseFlow() {
    while (true) {
        const request = yield take(TRAININGS.EXERCISE.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);
            const training = request.payload.training;
            const exercise = request.payload.exercise;

            yield call(deleteExerciseRequest, {
                token,
                trainingId: training.id,
                exerciseId: exercise.id
            });

            training.exercises.data = deleteObjectWithIdFromArray(training.exercises.data, exercise.id);

            yield put(TRAININGS.SHOW.trigger(training.id));
            yield put(TRAININGS.EXERCISE.DELETE.success(training))
        } catch (error) {
            yield put(TRAININGS.EXERCISE.DELETE.failure());
        } finally {
            yield put(TRAININGS.EXERCISE.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        addExerciseFlow(),
        deleteExerciseFlow(),
    ]);
}