import PropTypes from 'prop-types';

import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const SurveyTopicsTable = props => {
    const { data, onCreate, onDelete, onUpdate, surveyId } = props;
    const history = useHistory();

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            field: 'name',
            title: 'Onderdeel',
        },
        {
            defaultSort: 'asc',
            field: 'order',
            title: 'Volgorde',
            type: 'numeric',
            validate: rowData => rowData.order >= 1 && rowData.order <= 255,
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/surveys/${surveyId}/topics/${rowData.id}/questions`)
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Vragenlijst onderdelen"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

SurveyTopicsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    surveyId: PropTypes.number.isRequired,
};

export default SurveyTopicsTable;