export const reverseToReadableDate = (date = '') => {
    return (date.length >= 10) ? `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}` : '';
};

export const dateToDateString = date => {
    if (date instanceof Date === false) {
        return '';
    }

    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();


    if (dd < 10) {
        dd = `0${dd}`;
    }

    if (mm < 10) {
        mm = `0${mm}`;
    }

    return `${yyyy}-${mm}-${dd}`;
};

export const timestampToDateTimeString = timestamp => {
    const dateObject = new Date(timestamp);

    return dateObject.toLocaleString();

}