import { AUTH, VIDEOS } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    videos: [],
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const videoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VIDEOS.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case VIDEOS.FETCH.SUCCESS:
            return {
                ...state,
                videos: action.payload,
                fetched: true,
            };

        case VIDEOS.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case VIDEOS.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case VIDEOS.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                created: false,
            }

        case VIDEOS.CREATE.SUCCESS:
            return {
                ...state,
                videos: state.videos.concat(action.payload),
                created: action.payload.id,
            };

        case VIDEOS.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case VIDEOS.CREATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case VIDEOS.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
            }

        case VIDEOS.UPDATE.SUCCESS:
            return {
                ...state,
                videos: findIdAndUpdateObjectInArray([...state.videos], action.payload.id, action.payload),
                updated: true,
            };

        case VIDEOS.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case VIDEOS.UPDATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case VIDEOS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                deleted: false,
            }

        case VIDEOS.DELETE.SUCCESS:
            return {
                ...state,
                videos: deleteObjectWithIdFromArray([...state.videos], action.payload),
                deleted: true,
            };

        case VIDEOS.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case VIDEOS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case VIDEOS.TAG.UPDATE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case VIDEOS.TAG.UPDATE.SUCCESS:
            return {
                ...state,
                videos: findIdAndUpdateObjectInArray([...state.videos], action.payload.id, action.payload)
            }

        case VIDEOS.TAG.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case VIDEOS.TAG.DELETE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case VIDEOS.TAG.DELETE.SUCCESS:
            return {
                ...state,
                videos: findIdAndUpdateObjectInArray([...state.videos], action.payload.id, action.payload)
            }

        case VIDEOS.TAG.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case VIDEOS.CLEAN:
            return {
                ...state,
                error: null,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default videoReducer;