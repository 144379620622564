import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import EventOverview from './EventOverview';
import EventOrders from './EventOrders';
import EventShow from './EventShow';

const Events = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ path }
                component={ EventOverview }
            />
            <Route
                exact
                path={ `${path}/:eventId` }
                component={ EventShow }
            />
            <Route
                exact
                path={ `${path}/:eventId/orders` }
                component={ EventOrders }
            />
        </Switch>
    );
};

export default Events;