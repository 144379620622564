import { AUTH, OCCASIONS } from '../constants';

const INITIAL_STATE = {
    occasions: [],
    active: null,
    fetched: false,
    error: false,
    loading: false,
};

const occasionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OCCASIONS.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case OCCASIONS.FETCH.SUCCESS:
            return {
                ...state,
                occasions: action.payload,
                fetched: true,
            };

        case OCCASIONS.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case OCCASIONS.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case OCCASIONS.SHOW.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case OCCASIONS.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case OCCASIONS.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case OCCASIONS.SHOW.FULFILL:
            return {
                ...state,
                error: false,
                loading: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default occasionReducer;