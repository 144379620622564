import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import ExerciseForm from 'components/ExerciseForm';
import styles from './ClubExercisesEdit.styles';
import { CLUB_EXERCISES, PROFILE, TAGS } from 'store/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';

const ClubExercisesEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetchedExercises = useSelector(state => state.clubExercise.fetched);
    const fetchedTags = useSelector(state => state.tag.fetched);
    const loadingExercises = useSelector(state => state.clubExercise.loading);
    const loadingTags = useSelector(state => state.tag.loading);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const profileFetched = useSelector(state => state.profile.fetched);
    const roles = useSelector(state => state.profile.roles);
    const isClubmanager = roles.includes('clubmanager');
    const classes = styles();

    const error = useSelector(state => state.clubExercise.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.clubExercise.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'videos.create.loading' });

    const tags = useSelector(state => state.tag.tags);
    const exercises = useSelector(state => state.clubExercise.exercises);
    const exercise = exercises.filter(object => object.id === parseInt(params.exerciseId))[0];

    const title = exercise ? exercise.title : '';
    const descriptionLabel = useIntl().formatMessage({ id: 'exercises.label.description' });
    const difficultyLabel = useIntl().formatMessage({ id: 'exercises.label.difficulty' });
    const explanationLabel = useIntl().formatMessage({ id: 'exercises.label.explanation' });
    const extensionLabel = useIntl().formatMessage({ id: 'exercises.label.extension' });
    const imageLabel = useIntl().formatMessage({ id: 'exercises.label.image' });
    const instructionLabel = useIntl().formatMessage({ id: 'exercises.label.instruction' });
    const tagsLabel = useIntl().formatMessage({ id: 'exercises.label.tags' });
    const titleLabel = useIntl().formatMessage({ id: 'exercises.label.title' });
    const videoLabel = useIntl().formatMessage({ id: 'exercises.label.video' });
    const submitButtonText = useIntl().formatMessage({ id: 'exercises.button.update' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const getSelectedTags = () => {
        return exercise ? exercise.tags.data : [];
    }

    const getInitialValues = () => {
        if (exercise) {
            return {
                image: undefined,
                video: undefined,
                description: exercise.description ?? '',
                explanation: exercise.explanation ?? '',
                difficulty: exercise.difficulty ?? '',
                extension: exercise.extension ?? '',
                instruction: exercise.instruction ?? '',
                title: exercise.title,
                hidden: exercise.hidden ?? false,
            }
        }

        return {};
    }

    const handleAddTag = tag => {
        dispatch(CLUB_EXERCISES.TAG.UPDATE.trigger({ exercise, tag }));
    }

    const handleDeleteTag = tag => {
        dispatch(CLUB_EXERCISES.TAG.DELETE.trigger({ exercise, tag }));
    }

    const onTagChange = (event, newTag, action, tag) => {
        if (action === 'remove-option') {
            handleDeleteTag(tag.option);
        } else if (action === 'select-option') {
            handleAddTag(tag.option);
        }
    }

    const onSubmit = values => {
        dispatch(CLUB_EXERCISES.UPDATE.trigger({
            values,
            id: params.exerciseId
        }));

        return true;
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    useEffect(() => {
        if (fetchedExercises === false) {
            dispatch(CLUB_EXERCISES.FETCH.trigger());
        }

        if (fetchedTags === false) {
            dispatch(TAGS.FETCH.trigger());
        }

        dispatch(PROFILE.FETCH.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ title } backButton>
            <PremiumWall
                isProfileFetched={ profileFetched }
                isPremium={ isClubmanager || isAdmin }
                premiumImage={ images.premium.exercise.default }
            >
                { fetchedExercises && fetchedTags ? (
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 6 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <ExerciseForm
                                    initialValues={ getInitialValues() }
                                    descriptionLabel={ descriptionLabel }
                                    difficultyLabel={ difficultyLabel }
                                    explanationLabel={ explanationLabel }
                                    extensionLabel={ extensionLabel }
                                    imageLabel={ imageLabel }
                                    instructionLabel={ instructionLabel }
                                    titleLabel={ titleLabel }
                                    videoLabel={ videoLabel }
                                    submitButtonText={ submitButtonText }
                                    showVideoField={ isAdmin }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    invalidFileSizeMessage={ invalidFileSizeMessage }
                                    invalidFileTypeMessage={ invalidFileTypeMessage }
                                    onSubmit={ onSubmit }
                                    editMode
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <Autocomplete
                                    options={ tags }
                                    getOptionLabel={ tag => tag.name }
                                    onChange={ onTagChange }
                                    multiple={ true }
                                    value={ getSelectedTags() }
                                    getOptionSelected={ (a, b) => a.id === b.id }
                                    renderInput={ (params) => <TextField {...params} label={ tagsLabel } variant="outlined" /> }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                ) : null}

                <LoadingBackdrop
                    open={ loadingExercises || loadingTags }
                    message={ fetchedExercises && fetchedTags ? loadingMessage : null }
                />
            </PremiumWall>
        </ContentContainer>
    );
};

export default ClubExercisesEdit;
