import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { CLUB } from '../constants';
import {
    fetchRequest,
    storeRequest,
    updateRequest,
    deleteRequest,
    storeFileRequest,
} from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(CLUB.PLAYER.FETCH.TRIGGER);

        try {
            const clubId = request.payload;
            const endpoint = Api.requests.clubs.players.index(clubId);
            const response = yield call(fetchRequest, endpoint);
            const players = response.data.data;

            yield put(CLUB.PLAYER.FETCH.success({
                clubId,
                players,
            }));
        } catch (error) {
            yield put(CLUB.PLAYER.FETCH.failure());
        } finally {
            yield put(CLUB.PLAYER.FETCH.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(CLUB.PLAYER.CREATE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const values = request.payload.values;
            const endpoint = Api.requests.clubs.players.store(clubId);
            const response = yield call(storeRequest, endpoint, values);

            yield put(CLUB.PLAYER.CREATE.success(response.data.data));
        } catch (error) {
            yield put(CLUB.PLAYER.CREATE.failure());
        } finally {
            yield put(CLUB.PLAYER.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(CLUB.PLAYER.UPDATE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const playerId = request.payload.playerId;
            const values = request.payload.values;
            const endpoint = Api.requests.clubs.players.update(clubId, playerId);
            const response = yield call(updateRequest, endpoint, values);

            yield put(CLUB.PLAYER.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(CLUB.PLAYER.UPDATE.failure());
        } finally {
            yield put(CLUB.PLAYER.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(CLUB.PLAYER.DELETE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const playerId = request.payload.playerId;
            const endpoint = Api.requests.clubs.players.delete(clubId, playerId);

            yield call(deleteRequest, endpoint);
            yield put(CLUB.PLAYER.DELETE.success(playerId));
        } catch (error) {
            yield put(CLUB.PLAYER.DELETE.failure());
        } finally {
            yield put(CLUB.PLAYER.DELETE.fulfill());
        }
    }
}

export function* importFlow() {
    while (true) {
        const request = yield take(CLUB.PLAYER.IMPORT.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const players = request.payload.values.players;
            const endpoint = Api.requests.clubs.players.import(clubId);

            const formData = new FormData();
            formData.append('players', players);

            yield call(storeFileRequest, endpoint, formData);
            yield put(CLUB.PLAYER.FETCH.trigger(clubId));
            yield put(CLUB.PLAYER.IMPORT.success());
        } catch (error) {
            yield put(CLUB.PLAYER.IMPORT.failure());
        } finally {
            yield put(CLUB.PLAYER.IMPORT.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        createFlow(),
        updateFlow(),
        deleteFlow(),
        importFlow(),
    ]);
}