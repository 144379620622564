import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useState } from 'react';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import PlayerForm from 'contents/forms/PlayerForm';
import PlayerDetails from 'contents/details/PlayerDetails';

const PlayerDetailsBlock = props => {
    const { onSubmit, player } = props;
    const [editMode, setEditMode] = useState(false);
    const initialValues = {
        first_name: player?.first_name,
        last_name: player?.last_name,
        club: player?.club,
        team: player?.team,
    };

    const updatePlayer = values => {
        setEditMode(false);
        onSubmit(values);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Speler details"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                { editMode ? (
                    <PlayerForm
                        initialValues={ initialValues }
                        onSubmit={ updatePlayer }
                        submitButtonText="Pas speler aan"
                    />
                ) : (
                    <PlayerDetails player={ player } />
                ) }
            </Box>
        </ContentBox>
    );
};

PlayerDetailsBlock.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
}

export default PlayerDetailsBlock;