import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const ClubUsersTable = props => {
    const { data, onCreate, onDelete, onUpdate } = props;

    const mutatedDate = data.map(row => {
        return {
            ...row,
            director: row.roles.includes('director'),
            clubmanager: row.roles.includes('clubmanager'),
        }
    })

    const columns = [
        {
            editable: 'onAdd',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Gebruiker',
            validate: rowData => !! rowData?.name,
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'email'),
            editable: 'onAdd',
            field: 'email',
            title: 'E-mail',
            validate: rowData => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(rowData?.email),
        },
        {
            editable: 'never',
            field: 'activated',
            render: rowData => rowData?.activated ? 'Ja' : 'Nee',
            title: 'Geactiveerd',
            type: 'boolean',
        },
        {
            field: 'director',
            title: 'Hoofdtrainer',
            render: rowData => rowData.director ? 'Ja' : 'Nee',
            type: 'boolean',
        },
        {
            field: 'clubmanager',
            title: 'Clubmanager',
            render: rowData => rowData.clubmanager ? 'Ja' : 'Nee',
            type: 'boolean',
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Clubs"
            localization={ localization }
            columns={ columns }
            data={ mutatedDate }
            options={ defaultOptions }
            actions={ [] }
            editable={ editable }
        />
    );
};

ClubUsersTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ClubUsersTable;