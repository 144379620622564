import axios from 'axios';

export const post = async (token, url, body = {}) => {
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};

export const patch = async (token, url, body = {}) => {
    const patchBody = {
        ...body,
        _method: 'PATCH',
    };

    return axios.post(url, patchBody, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const postFile = async (token, url, body = {}) => {
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        },
    });
};

export const patchFile = async (token, url, body = {}) => {
    body.append('_method', 'PATCH');

    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        },
    });
};

export const get = async (token, url) => {
    return axios.get(url,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
};

export const getFile = async (token, url) => {
    return axios.get(url,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        }
    );
};

export const downloadFile = async (token, url, fileName) => {
    return getFile(token, url)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}

export const deletePost = async (token, url) => {
    return axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const guestPost = async (url, body = {}) => {
    return axios.post(url, body,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    );
};

export const guestGet = async (url) => {
    return axios.get(url,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
};
