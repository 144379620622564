import PropTypes from 'prop-types';

import ActionList from 'components/ActionList';

const PlayerList = props => {
    const { players } = props;
    const listItems = players.map(player => (
        {
            target: `/players/${player.id}`,
            label: player.full_name,
        }
    )).sort()

    return (
        <ActionList
            values={ listItems }
        />
    );
};

PlayerList.propTypes = {
    players: PropTypes.array.isRequired,
};

export default PlayerList;