import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

import { FEEDBACK } from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import FeedbackForm from 'components/FeedbackForm';
import styles from './FeedbackCreate.styles';

const FeedbackCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const playerId = parseInt(params.playerId);
    const fetched = useSelector(state => state.feedback.fetched);
    const loading = useSelector(state => state.feedback.loading);
    const classes = styles();

    const error = useSelector(state => state.feedback.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.feedback.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'feedback.create.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'feedback.create.title' });
    const feedbackLabel = useIntl().formatMessage({ id: 'feedback.label.feedback' });
    const publishedLabel = useIntl().formatMessage({ id: 'feedback.label.publish' });
    const imageLabel = useIntl().formatMessage({ id: 'feedback.label.image' });
    const submitButtonText = useIntl().formatMessage({ id: 'feedback.button.create' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const onSubmit = values => {
        dispatch(FEEDBACK.CREATE.trigger({
            values,
            playerId,
        }));

        return true;
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/players/${playerId}/feedbacks`)
        }
    }, [created, createdMessage, history, playerId])

    useEffect(() => {
        if (fetched === false) {
            dispatch(FEEDBACK.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: FEEDBACK.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Box mt={ 2 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <FeedbackForm
                                    feedbackLabel={ feedbackLabel }
                                    publishedLabel={ publishedLabel }
                                    imageLabel={ imageLabel }
                                    submitButtonText={ submitButtonText }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    invalidFileSizeMessage={ invalidFileSizeMessage }
                                    invalidFileTypeMessage={ invalidFileTypeMessage }
                                    onSubmit={ onSubmit }
                                />
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            ) : null}

            <LoadingBackdrop
                open={ loading }
                message={ fetched ? loadingMessage : null }
            />
        </ContentContainer>
    );
};

export default FeedbackCreate;
