import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import PlayerShow from './PlayerShow';

const Players = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ `${path}/:playerId` }
                component={ PlayerShow }
            />
        </Switch>
    );
};

export default Players;