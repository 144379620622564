import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { CLUB_EXERCISES } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, patchFile, postFile } from 'utilities/api';
import { deleteObjectWithIdFromArray } from 'utilities/array';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.clubExercises.index);
}

export function* createRequest({token, payload}) {
    return yield postFile(token, Api.requests.exercises.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patchFile(token, Api.requests.exercises.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.exercises.delete(id));
}

export function* addTagRequest({token, exerciseId, tagId, headers = {}}) {
    return yield patch(token, Api.requests.exercises.tag.update(exerciseId, tagId), headers);
}

export function* deleteTagRequest({token, exerciseId, tagId, headers = {}}) {
    return yield deletePost(token, Api.requests.exercises.tag.delete(exerciseId, tagId), headers);
}

export function* fetchFlow() {
    while (true) {
        yield take(CLUB_EXERCISES.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const exercises = response.data.data;

            yield put(CLUB_EXERCISES.FETCH.success(exercises));
        } catch (error) {
            yield put(CLUB_EXERCISES.FETCH.failure());
        } finally {
            yield put(CLUB_EXERCISES.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(CLUB_EXERCISES.CREATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.title);

            if (request.payload.description) {
                formData.append('description', request.payload.description);
            }

            if (request.payload.explanation) {
                formData.append('explanation', request.payload.explanation);
            }

            if (request.payload.instruction) {
                formData.append('instruction', request.payload.instruction);
            }

            if (request.payload.extension) {
                formData.append('extension', request.payload.extension);
            }

            formData.append('difficulty', request.payload.difficulty);
            formData.append('club_only', request.payload.clubOnly);
            formData.append('hidden', request.payload.hidden ? '1' : '0');
            formData.append('image', request.payload.image);

            if (request.payload.video) {
                formData.append('video', request.payload.video);
            }

            const response = yield call(createRequest, { token, payload: formData });

            yield put(CLUB_EXERCISES.CREATE.success(response.data.data));
        } catch (error) {
            yield put(CLUB_EXERCISES.CREATE.failure());
        } finally {
            yield put(CLUB_EXERCISES.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(CLUB_EXERCISES.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.values.title);

            if (request.payload.values.description) {
                formData.append('description', request.payload.values.description);
            }

            if (request.payload.values.explanation) {
                formData.append('explanation', request.payload.values.explanation);
            }

            if (request.payload.values.instruction) {
                formData.append('instruction', request.payload.values.instruction);
            }

            if (request.payload.values.extension) {
                formData.append('extension', request.payload.values.extension);
            }


            formData.append('difficulty', request.payload.values.difficulty);
            formData.append('hidden', request.payload.values.hidden ? '1' : '0');

            if (request.payload.values.image) {
                formData.append('image', request.payload.values.image);
            }

            if (request.payload.values.video) {
                formData.append('video', request.payload.values.video);
            }

            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(CLUB_EXERCISES.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(CLUB_EXERCISES.CREATE.failure());
        } finally {
            yield put(CLUB_EXERCISES.CREATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(CLUB_EXERCISES.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(CLUB_EXERCISES.DELETE.success(request.payload));
        } catch (error) {
            yield put(CLUB_EXERCISES.DELETE.failure());
        } finally {
            yield put(CLUB_EXERCISES.DELETE.fulfill());
        }
    }
}

export function* addTagFlow() {
    while (true) {
        const request = yield take(CLUB_EXERCISES.TAG.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const exercise = request.payload.exercise;
            const tag = request.payload.tag;

            yield call(addTagRequest, {
                token,
                exerciseId: exercise.id,
                tagId: tag.id
            });

            exercise.tags.data = exercise.tags.data.concat(tag);

            yield put(CLUB_EXERCISES.TAG.UPDATE.success(exercise))
        } catch (error) {
            yield put(CLUB_EXERCISES.TAG.UPDATE.failure());
        } finally {
            yield put(CLUB_EXERCISES.TAG.UPDATE.fulfill());
        }
    }
}

export function* deleteTagFlow() {
    while (true) {
        const request = yield take(CLUB_EXERCISES.TAG.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);
            const exercise = request.payload.exercise;
            const tag = request.payload.tag;

            yield call(deleteTagRequest, {
                token,
                exerciseId: exercise.id,
                tagId: tag.id
            });

            exercise.tags.data = deleteObjectWithIdFromArray(exercise.tags.data, tag.id);

            yield put(CLUB_EXERCISES.TAG.DELETE.success(exercise))
        } catch (error) {
            yield put(CLUB_EXERCISES.TAG.DELETE.failure());
        } finally {
            yield put(CLUB_EXERCISES.TAG.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        addTagFlow(),
        deleteTagFlow(),
    ]);
}
