import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { SURVEYS } from '../constants';
import {
    storeRequest,
    updateRequest,
    deleteRequest
} from '../api';

export function* createFlow() {
    while (true) {
        const request = yield take(SURVEYS.QUESTIONS.CREATE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const topicId = request.payload.topicId;
            const payload = request.payload.values;
            const endpoint = Api.requests.surveys.questions.store(topicId);

            yield call(storeRequest, endpoint, payload);
            yield put(SURVEYS.SHOW.trigger(surveyId));
            yield put(SURVEYS.QUESTIONS.CREATE.success());
        } catch (error) {
            yield put(SURVEYS.QUESTIONS.CREATE.failure());
        } finally {
            yield put(SURVEYS.QUESTIONS.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(SURVEYS.QUESTIONS.UPDATE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const topicId = request.payload.topicId;
            const questionId = request.payload.questionId;
            const payload = request.payload.values;
            const endpoint = Api.requests.surveys.questions.update(topicId, questionId);

            yield call(updateRequest, endpoint, payload);
            yield put(SURVEYS.SHOW.trigger(surveyId));
            yield put(SURVEYS.QUESTIONS.UPDATE.success());
        } catch (error) {
            yield put(SURVEYS.QUESTIONS.UPDATE.failure());
        } finally {
            yield put(SURVEYS.QUESTIONS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(SURVEYS.QUESTIONS.DELETE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const topicId = request.payload.topicId;
            const questionId = request.payload.questionId;
            const endpoint = Api.requests.surveys.questions.delete(topicId, questionId);

            yield call(deleteRequest, endpoint);
            yield put(SURVEYS.SHOW.trigger(surveyId));
            yield put(SURVEYS.QUESTIONS.DELETE.success());
        } catch (error) {
            yield put(SURVEYS.QUESTIONS.DELETE.failure());
        } finally {
            yield put(SURVEYS.QUESTIONS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        createFlow(),
        updateFlow(),
        deleteFlow(),
    ]);
}