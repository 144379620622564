import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import styles from './SubscriptionCard.styles';

const SubscriptionCard = props => {
    const { name, price, description, buttonLabel, onClick } = props;
    const classes = styles();

    return (
        <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
            <Paper elevation={ 2 } className={ classes.container }>
                <div className={ classes.header }>
                    <Typography variant="h5" component="h2">
                        { name }
                    </Typography>

                    <Typography variant="h5" component="h2" color={ 'primary' }>
                        { `€ ${price},-` }
                    </Typography>
                </div>

                <Box my={ 2 }>
                    <Typography variant="body2">
                        { description }
                    </Typography>
                </Box>

                <Button variant="contained" color="primary" onClick={ onClick }>
                    { buttonLabel }
                </Button>
            </Paper>
        </Grid>
    );
};

SubscriptionCard.propTypes = {
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default SubscriptionCard;
