import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const ClubPlayersTable = props => {
    const { data, isAdmin, onAddToTeamButtonClick, onCreate, onDelete, onImportButtonClick, onUpdate } = props;
    const history = useHistory();

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'uuid'),
            field: 'uuid',
            title: 'UUID',
            validate: rowData => ! data.some(player => {
                return (
                        player.uuid === rowData.uuid &&
                        player.id !== rowData.id
                    ) ||
                    ! rowData.uuid;
            }),
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Speler',
            validate: rowData => !! rowData?.name,
        },
        {
            field: 'goalkeeper',
            render: rowData => rowData?.goalkeeper ? 'Ja' : 'Nee',
            title: 'Keeper',
            type: 'boolean',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'teams_combined'),
            editable: 'never',
            field: 'teams_combined',
            title: 'Teams',
        },
    ];

    const defaultActions = [
        {
            icon: 'group_add',
            onClick: (event, rowData) => onAddToTeamButtonClick(rowData),
            tooltip: 'Aan team toevoegen',
        },
        {
            icon: 'list_alt',
            onClick: (event, rowData) => history.push(`/club-players/${rowData.id}/assessments`),
            tooltip: 'Evaluaties',
        },
    ];

    const adminActions = [
        {
            icon: 'post_add',
            onClick: onImportButtonClick,
            tooltip: 'Importeer',
            isFreeAction: true,
        },
    ];

    const actions = isAdmin ? [
        ...defaultActions,
        ...adminActions,
    ] : defaultActions;

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Clubs"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

ClubPlayersTable.propTypes = {
    data: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool,
    onAddToTeamButtonClick: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onImportButtonClick: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ClubPlayersTable;