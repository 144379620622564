import { AUTH, CLUB_TRAININGS } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    trainings: [],
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const trainingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB_TRAININGS.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case CLUB_TRAININGS.FETCH.SUCCESS:
            return {
                ...state,
                trainings: action.payload,
                fetched: true,
            };

        case CLUB_TRAININGS.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_TRAININGS.FETCH.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case CLUB_TRAININGS.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                created: false,
            }

        case CLUB_TRAININGS.CREATE.SUCCESS:
            return {
                ...state,
                trainings: state.trainings.concat(action.payload),
                created: action.payload.id,
            };

        case CLUB_TRAININGS.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_TRAININGS.CREATE.FULFILL:
            return {
                ...state,
                loading: false,
                created: false,
                error: false,
            }

        case CLUB_TRAININGS.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
            }

        case CLUB_TRAININGS.UPDATE.SUCCESS:
            return {
                ...state,
                trainings: findIdAndUpdateObjectInArray([...state.trainings], action.payload.id, action.payload),
                updated: true,
            };

        case CLUB_TRAININGS.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_TRAININGS.UPDATE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                updated: false,
            }

        case CLUB_TRAININGS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                deleted: false,
            }

        case CLUB_TRAININGS.DELETE.SUCCESS:
            return {
                ...state,
                trainings: deleteObjectWithIdFromArray([...state.trainings], action.payload),
                deleted: true,
            };

        case CLUB_TRAININGS.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_TRAININGS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                deleted: false,
            }

        case CLUB_TRAININGS.EXERCISE.UPDATE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case CLUB_TRAININGS.EXERCISE.UPDATE.SUCCESS:
            return {
                ...state,
                trainings: findIdAndUpdateObjectInArray([...state.trainings], action.payload.id, action.payload)
            }

        case CLUB_TRAININGS.EXERCISE.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_TRAININGS.EXERCISE.UPDATE.FULFILL:
            return {
                ...state,
                error: false
            }

        case CLUB_TRAININGS.EXERCISE.DELETE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case CLUB_TRAININGS.EXERCISE.DELETE.SUCCESS:
            return {
                ...state,
                trainings: findIdAndUpdateObjectInArray([...state.trainings], action.payload.id, action.payload)
            }

        case CLUB_TRAININGS.EXERCISE.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_TRAININGS.EXERCISE.DELETE.FULFILL:
            return {
                ...state,
                error: false
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default trainingReducer;