import PropTypes from 'prop-types';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import { Field, Formik } from 'formik';
import TextInput from '../TextInput';
import FileInput from '../FileInput';
import Button from '@material-ui/core/Button';
import RatingInput from '../RatingInput';
import CheckboxInput from 'components/CheckboxInput';

const ExerciseForm = props => {
    const {
        descriptionLabel,
        difficultyLabel,
        explanationLabel,
        extensionLabel,
        imageLabel,
        instructionLabel,
        titleLabel,
        videoLabel,
        submitButtonText,
        showVideoField,
        requiredFieldMessage,
        invalidFileSizeMessage,
        invalidFileTypeMessage,
        initialValues,
        editMode,
        onSubmit
    } = props;
    const IMAGE_FILE_SIZE = 2 * 1024 * 1024;
    const VIDEO_FILE_SIZE = 10 * 1024 * 1024;
    const IMAGE_SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];
    const VIDEO_SUPPORTED_FORMATS = ['video/mp4'];

    const validationSchema = editMode ? {
        title: yup.string().max(255).required(requiredFieldMessage),
        description: yup.string().nullable(),
        explanation: yup.string().nullable(),
        instruction: yup.string().nullable(),
        extension: yup.string().nullable(),
        difficulty: yup.number().required(requiredFieldMessage),
        image: yup.mixed().notRequired()
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= IMAGE_FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return IMAGE_SUPPORTED_FORMATS.includes(value.type)
                }
            ),
        video: yup.mixed().notRequired()
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= VIDEO_FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return VIDEO_SUPPORTED_FORMATS.includes(value.type)
                }
            ),
    } : {
        title: yup.string().max(255).required(requiredFieldMessage),
        description: yup.string(),
        explanation: yup.string(),
        instruction: yup.string(),
        extension: yup.string(),
        difficulty: yup.number().required(requiredFieldMessage),
        image: yup.mixed().required(requiredFieldMessage)
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => value && value.size <= IMAGE_FILE_SIZE
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => value && IMAGE_SUPPORTED_FORMATS.includes(value.type)
            ),
        video: yup.mixed().notRequired()
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= VIDEO_FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return VIDEO_SUPPORTED_FORMATS.includes(value.type)
                }
            ),
    }
    const validationSchemaObject = yup.object().shape(validationSchema);

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name={ 'title' }
                            label={ titleLabel }
                            component={ TextInput }
                            errorMessage={ errors['title'] }
                            touched={ touched['title'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'description' }
                            label={ descriptionLabel }
                            component={ TextInput }
                            errorMessage={ errors['description'] }
                            touched={ touched['description'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'explanation' }
                            label={ explanationLabel }
                            component={ TextInput }
                            errorMessage={ errors['explanation'] }
                            touched={ touched['explanation'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            lines={ 12 }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'instruction' }
                            label={ instructionLabel }
                            component={ TextInput }
                            errorMessage={ errors['instruction'] }
                            touched={ touched['instruction'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            lines={ 12 }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'extension' }
                            label={ extensionLabel }
                            component={ TextInput }
                            errorMessage={ errors['extension'] }
                            touched={ touched['extension'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            lines={ 12 }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name={ 'difficulty' }
                            label={ difficultyLabel }
                            component={ RatingInput }
                            errorMessage={ errors['difficulty'] }
                            touched={ touched['difficulty'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name="hidden"
                            label="Verberg oefening"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['hidden'] }
                            touched={ touched['hidden'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name={ 'image' }
                            component={ FileInput }
                            title={ imageLabel }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors["image"] ? errors["image"] : undefined }
                            touched={ touched["image"] }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    { showVideoField && (
                        <Box mt={ 2 }>
                            <Field
                                name={ 'video' }
                                component={ FileInput }
                                title={ videoLabel }
                                setFieldValue={ setFieldValue }
                                errorMessage={ errors["video"] ? errors["video"] : undefined }
                                touched={ touched["video"] }
                                onBlur={ handleBlur }
                            />
                        </Box>
                    )}

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};


ExerciseForm.propTypes = {
    descriptionLabel: PropTypes.string,
    difficultyLabel: PropTypes.string,
    explanationLabel: PropTypes.string,
    extensionLabel: PropTypes.string,
    imageLabel: PropTypes.string,
    instructionLabel: PropTypes.string,
    titleLabel: PropTypes.string,
    videoLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    showVideoField: PropTypes.bool,
    requiredFieldMessage: PropTypes.string,
    invalidFileSizeMessage: PropTypes.string,
    invalidFileTypeMessage: PropTypes.string,
    editMode: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

ExerciseForm.defaultProps = {
    descriptionLabel: 'description',
    difficultyLabel: 'difficulty',
    explanationLabel: 'explanation',
    extensionLabel: 'extension',
    imageLabel: 'map',
    instructionLabel: 'instruction',
    titleLabel: 'title',
    videoLabel: 'video',
    submitButtonText: 'submit',
    showVideoField: false,
    requiredFieldMessage: 'required',
    invalidFileSizeMessage: 'invalid file size',
    invalidFileTypeMessage: 'invalid file type',
    initialValues: {
        description: '',
        difficulty: 0,
        explanation: '',
        extension: '',
        hidden: false,
        image: undefined,
        instruction: '',
        title: '',
        video: undefined
    }
}

export default ExerciseForm;
