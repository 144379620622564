import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        marginBottom: theme.spacing(2),
    },

    active: {
        border: `1px solid ${ theme.palette.primary.light }`,
    },

    iconButton: {
        backgroundColor: '#fff'
    }
}));

export default styles;