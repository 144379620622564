import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { FEEDBACK } from 'store/constants';
import { reverseToReadableDate } from 'utilities/date';

const PlayersFeedback = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const playerId = parseInt(params.playerId);
    const feedback = useSelector(state => state.feedback.player.feedback);
    const feedbackPlayerId = useSelector(state => state.feedback.player.profile?.id);
    const loading = useSelector(state => state.feedback.player.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'feedback.title' });
    const error = useSelector(state => state.feedback.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.feedback.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });
    const unpublishedLabel = useIntl().formatMessage({ id: 'feedback.label.unpublished' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'feedback.label.trainer' }),
            field: 'trainer.data.full_name',
            customSort: (a, b) => sortCaseInsensitive(a.trainer.data, b.trainer.data, 'full_name'),
        },
        {
            title: useIntl().formatMessage({ id: 'feedback.label.published' }),
            field: 'published_at',
            render: rowData => rowData.published_at ? reverseToReadableDate(rowData.published_at) : unpublishedLabel,
            customSort: (a, b) => sortCaseInsensitive(a, b, 'published_at'),
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push(`/players/${playerId}/feedback/create`)
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/players/${playerId}/feedback/${rowData.id}`)
        },
    ];

    useEffect(() => {
        if (playerId !== feedbackPlayerId) {
            dispatch(FEEDBACK.PLAYER.FETCH.trigger(playerId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 6 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ feedback }
                            options={ defaultOptions }
                            actions={ actions }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default PlayersFeedback;
