import { useEffect } from 'react';

import _ from 'lodash';
import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { OCCASIONS, PROFILE } from 'store/constants';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';

const Occasions = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.occasion.fetched);
    const occasions = useSelector(state => state.occasion.occasions);
    const loading = useSelector(state => state.occasion.loading);
    const locale = useSelector(state => state.persist.language);
    const premiumFetched = useSelector(state => state.profile.fetched);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const isPremium = useSelector(state => state.profile.is_premium);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);
    const tableOptions = {
        ...defaultOptions,
        filtering: true,
        exportButton: false,
    }

    const pageTitle = useIntl().formatMessage({ id: 'occasions.title' });
    const error = useSelector(state => state.occasion.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const lookupDivisions = _.chain(occasions)
        .map(occasion => occasion.training.data.division.data)
        .uniqBy('id')
        .keyBy('name')
        .mapValues('name')
        .value();
    const lookupOccasion = _.chain(occasions)
        .uniqBy('name')
        .keyBy('name')
        .mapValues('name')
        .value();

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'occasions.label.occasion' }),
            field: 'name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            lookup: lookupOccasion,
        },
        {
            title: useIntl().formatMessage({ id: 'occasions.label.training' }),
            field: 'training.data.name',
            defaultSort: 'asc',
            filtering: false,
        },
        {
            title: useIntl().formatMessage({ id: 'occasions.label.division' }),
            field: 'training.data.division.data.name',
            defaultSort: 'asc',
            lookup: lookupDivisions,
        },
        {
            title: useIntl().formatMessage({ id: 'occasions.label.numberOfExercises' }),
            render: rowData => rowData.number_of_exercises,
            customSort: (a, b) => a.number_of_exercises < b.number_of_exercises ? -1 : 1,
            filtering: false,
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`occasions/${rowData.training.data.id}`)
        },
    ];

    useEffect(() => {
        if (fetched === false) {
            dispatch(OCCASIONS.FETCH.trigger());
        }

        dispatch(PROFILE.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            <PremiumWall
                isProfileFetched={ premiumFetched }
                isPremium={ isAdmin || isPremium || hasCorrectPermission }
                premiumImage={ images.premium.training.default }
            >
                { loading ? <LoadingIndicator /> : (
                    <Grid container spacing={ 3 }>
                        <Grid item xs={ 12 }>
                            <MaterialTable
                                title={ pageTitle }
                                localization={ localization }
                                columns={ columns }
                                data={ occasions }
                                options={ tableOptions }
                                actions={ actions }
                            />
                        </Grid>
                    </Grid>
                ) }
            </PremiumWall>
        </ContentContainer>
    );
};

export default Occasions;
