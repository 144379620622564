module.exports = {
    '404.heading': 'Sorry...',
    '404.subheading': 'Deze pagina bestaat niet',
    '404.title': '404',

    'accounts.button.create': 'Voeg gebruiker toe',
    'accounts.create.loading': 'Gebruiker wordt toegevoegd',
    'accounts.create.title': 'Accounts: gebruiker toevoegen',
    'accounts.title': 'Accounts',
    'accounts.label.clubmanager': 'Clubmanager',
    'accounts.label.email': 'E-mail',
    'accounts.label.name': 'Naam',

    'app.name': 'Go4Hockey online',

    'assessments.categories.button.create': 'Maak rapport onderdeel aan',
    'assessments.categories.button.update': 'Pas rapport onderdeel aan',
    'assessments.categories.create.loading': 'Rapport onderdeel wordt aangemaakt',
    'assessments.categories.create.title': 'Rapport onderdeel aanmaken',
    'assessments.categories.title': 'Rapport onderdelen',
    'assessments.categories.update.loading': 'Rapport onderdeel wordt aangepast',
    'assessments.categories.update.title': 'Rapport onderdeel aanpassen',
    'assessments.create.categoryButton': 'Start rapport',
    'assessments.create.categoryHeading': 'Onderdelen van het rapport:',
    'assessments.create.loading': 'Rapport wordt aangemaakt',
    'assessments.create.submitButton': 'Sla rapport op',
    'assessments.create.title': 'Rapport aanmaken',
    'assessments.create.topicHeading': 'Kies een rapport onderwerp:',
    'assessments.criteria.button.create': 'Maak criterium aan',
    'assessments.criteria.button.update': 'Pas criterium aan',
    'assessments.criteria.create.loading': 'Criterium wordt aangemaakt',
    'assessments.criteria.create.title': 'Criterium aanmaken',
    'assessments.criteria.title': 'Rapport criteria',
    'assessments.criteria.update.loading': 'Criterium wordt aangepast',
    'assessments.criteria.update.title': 'Criterium aanpassen',
    'assessments.levels.update.title': 'Criterium aanpassen',
    'assessments.label.category': 'Onderdeel',
    'assessments.label.criterion': 'Criterium',
    'assessments.label.date': 'Datum',
    'assessments.label.explanation': 'Keuze',
    'assessments.label.level': 'Niveau',
    'assessments.label.name': 'Speler',
    'assessments.label.order': 'Volgorde',
    'assessments.label.respondent': 'Respondent',
    'assessments.label.team': 'Team',
    'assessments.label.timestamp': 'Tijdstip',
    'assessments.label.topic': 'Onderwerp',
    'assessments.label.trainer': 'Trainer',
    'assessments.levels.button.create': 'Maak criterium antwoord aan',
    'assessments.levels.button.update': 'Pas criterium antwoord aan',
    'assessments.levels.create.loading': 'Criterium antwoord wordt aangemaakt',
    'assessments.levels.create.title': 'Criterium antwoord aanmaken',
    'assessments.levels.title': 'Rapport criterium antwoorden',
    'assessments.levels.update.loading': 'Criterium antwoord wordt aangepast',
    'assessments.requests.create.button': 'Verstuur verzoek',
    'assessments.requests.create.loading': 'Rapport verzoek wordt aangemaakt',
    'assessments.requests.create.title': 'Rapport verzoek aanmaken',
    'assessments.requests.create.teams.heading': 'Selecteer een team:',
    'assessments.requests.create.topics.heading': 'Selecteer een onderwerp:',
    'assessments.requests.create.respondents.heading': 'Selecteer de respondenten:',
    'assessments.requests.title': 'Rapport verzoeken',
    'assessments.requests.show.title': 'Rapport verzoek: ',
    'assessments.show.title': 'Rapport',
    'assessments.title': 'Rapporten',
    'assessments.topics.button.create': 'Onderwerp aanmaken',
    'assessments.topics.button.update': 'Onderwerp aanpassen',
    'assessments.topics.create.loading': 'Onderwerp wordt aangemaakt',
    'assessments.topics.create.title': 'Rapport onderwerp aanmaken',
    'assessments.topics.update.loading': 'Onderwerp wordt aangepast',
    'assessments.topics.update.title': 'Rapport onderwerp aanpassen',
    'assessments.topics.title': 'Rapport onderwerpen',
    'assessments.update.loading': 'Rapport wordt aangepast',
    'assessments.update.submitButton': 'Pas rapport aan',
    'assessments.update.title': 'Rapport aanpassen',

    'clubs.button.add-clubmanager': 'Voeg clubmanager toe',
    'clubs.button.add-user': 'Voeg gebruiker toe',
    'clubs.button.create': 'Voeg club toe',
    'clubs.button.update': 'Pas club aan',
    'clubs.create.loading': 'Club wordt aangemaakt',
    'clubs.create.title': 'Club toevoegen',
    'clubs.title': 'Clubs',
    'clubs.label.accounts': 'Gebruikers',
    'clubs.label.address': 'Adres',
    'clubs.label.city': 'Plaats',
    'clubs.label.clubmanager': 'Clubmanager',
    'clubs.label.clubmanager.email': 'E-mail',
    'clubs.label.clubmanager.name': 'Naam',
    'clubs.label.country': 'Land',
    'clubs.label.name': 'Club',
    'clubs.label.postal': 'Postcode',
    'clubs.label.users': 'Club accounts',
    'clubs.update.loading': 'Club wordt aangepast',

    'dashboard.title': 'Dashboard',

    'dialog.exercise.description': 'Omschrijving',
    'dialog.exercise.explanation': 'Uitleg',
    'dialog.exercise.map': 'Kaart',
    'dialog.exercise.noVideo': 'Er is geen video beschikbaar',
    'dialog.exercise.title': 'Oefening',
    'dialog.exercise.video': 'Video',
    'dialog.button.add': 'Toevoegen',
    'dialog.button.close': 'Sluiten',
    'dialog.button.update': 'Aanpassen',

    'events.button.addDate': 'Voeg datum toe',
    'events.button.save': 'Maak event aan',
    'events.button.update': 'Pas event aan',
    'events.create.loading': 'Event wordt aangemaakt',
    'events.create.title': 'Event aanmaken',
    'events.label.club': 'Club',
    'events.label.date': 'Datum',
    'events.label.dates': 'Datums',
    'events.label.description': 'Omschrijving',
    'events.label.explanation': 'Uitleg',
    'events.label.image': 'Afbeelding',
    'events.label.name': 'Event',
    'events.label.places': 'Plaatsen',
    'events.label.players': 'Spelers',
    'events.label.price': 'Prijs',
    'events.label.trainers': 'Trainers',
    'events.label.trainings': 'Trainingen',
    'events.label.type': 'Type',
    'events.orders.label.club': 'Club',
    'events.orders.label.diet': 'Dieet',
    'events.orders.label.group_with': 'In de groep met',
    'events.orders.label.name': 'Naam',
    'events.orders.label.parent_email': 'E-mail ouder',
    'events.orders.label.shirt_size': 'Shirtmaat',
    'events.orders.label.team': 'Team',
    'events.orders.title': 'Event aanmeldingen',
    'events.title': 'Event',
    'events.update.loading': 'Uploaden en aanpassen van het event',
    'events.updated.title': 'Event aanpassen',

    'exercises.create.loading': 'Uploaden en aanmaken van oefening',
    'exercises.create.title': 'Oefening toevoegen',
    'exercises.button.update': 'Pas oefening aan',
    'exercises.button.save': 'Voeg oefening toe',
    'exercises.title': 'Oefeningen',
    'exercises.label.comments': 'Opmerkingen',
    'exercises.label.description': 'Omschrijving',
    'exercises.label.difficulty': 'Moeilijkheidsgraad',
    'exercises.label.explanation': 'Uitleg',
    'exercises.label.extension': 'Uitbouw',
    'exercises.label.image': 'Kaart',
    'exercises.label.instruction': 'Instructie',
    'exercises.label.pdf': 'Download PDF',
    'exercises.label.trainer': 'Trainer',
    'exercises.label.trainings': 'Trainingen',
    'exercises.label.tags': 'Tags',
    'exercises.label.title': 'Oefening',
    'exercises.label.video': 'Video',
    'exercises.latest': 'Nieuwe oefeningen',
    'exercises.update.loading': 'Uploading en aanpassen van oefening',

    'exerciseTags.count': 'Aantal oefeningen',
    'exerciseTags.tag': 'Type',
    'exerciseTags.title': 'Oefeningen collectie',

    'feedback.button.create': 'Maak feedback aan',
    'feedback.button.update': 'Pas feedback aan',
    'feedback.create.loading': 'Uploaden en aanmaken van feedback',
    'feedback.create.title': 'Feedback toevoegen',
    'feedback.label.feedback': 'Feedback',
    'feedback.label.image': 'Foto',
    'feedback.label.player': 'Speler',
    'feedback.label.publish': 'Publiceren',
    'feedback.label.published': 'Publicatiedatum',
    'feedback.label.trainer': 'Trainer',
    'feedback.label.unpublished': 'Ongepubliceerd',
    'feedback.title': 'Feedback',
    'feedback.update.loading': 'Uploaden en aanpassen feedback',
    'feedback.update.title': 'Feedback aanpassen',

    'general.back': 'Terug naar overzicht',

    'highlight.delete.button_text': 'Verwijderen',
    'highlight.delete.success_message': 'Hoogtepunt is verwijderd',
    'highlight.label.highlight': 'Hoogtepunt',
    'highlight.label.highlights': 'Hoogtepunten',
    'highlight.label.minute': 'Minuut',
    'highlight.store.button_text': 'Voeg hoogtepunt toe',
    'highlight.store.heading': 'Hoogtepunt toevoegen',
    'highlight.store.loading': 'Bezig met opslaan van hoogtepunt',
    'highlight.store.success_message': 'Hoogtepunt is toegevoegd',
    'highlight.title': 'Hoogtepunten',

    'language.dutch': 'Nederlands',
    'language.english': 'Engels',

    'lineup.label.player': 'Speler',
    'lineup.label.position': 'Positie',
    'lineup.label.substitute': 'Wissel',
    'lineup.store.loading': 'Bezig met opslaan van de opstelling',
    'lineup.store.submit_button_text': 'Sla opstelling op',
    'lineup.store.success_message': 'De opstelling is opgeslagen',
    'lineup.title': 'Opstelling',

    'login.error': 'Ongeldige inloggegevens',
    'login.label.loginButton': 'Inloggen',
    'login.label.password': 'Wachtwoord',
    'login.label.passwordRecovery': 'Wachtwoord vergeten?',
    'login.label.register': 'Maak een account aan',
    'login.label.username': 'E-mailadres',
    'login.title': 'Inloggen',

    'mapDrawing.downloadButton': 'Download kaart',
    'mapDrawing.explanation': '* Dubbelklikken om een element te verwijderen',
    'mapDrawing.fieldPanel.indoor': 'Zaalhockey',
    'mapDrawing.fieldPanel.outdoor': 'Veldhockey',
    'mapDrawing.imagePanel.icon.playerBallBlack': 'Speler met bal (zwart)',
    'mapDrawing.imagePanel.icon.playerBallBlue': 'Speler met bal (blauw)',
    'mapDrawing.imagePanel.icon.playerBallOrange': 'Speler met bal (oranje)',
    'mapDrawing.imagePanel.icon.playerBlack': 'Speler zonder bal (zwart)',
    'mapDrawing.imagePanel.icon.playerBlue': 'Speler zonder bal (blauw)',
    'mapDrawing.imagePanel.icon.playerOrange': 'Speler zonder bal (oranje)',
    'mapDrawing.imagePanel.icon.coach': 'Coach',
    'mapDrawing.imagePanel.icon.coneBlue': 'Pion blauw',
    'mapDrawing.imagePanel.icon.coneOrange': 'Pion oranje',
    'mapDrawing.imagePanel.icon.coneRed': 'Pion rood',
    'mapDrawing.imagePanel.icon.coneWhite': 'Pion wit',
    'mapDrawing.imagePanel.icon.keeper': 'Keeper',
    'mapDrawing.imagePanel.icon.stick': 'Schieten',
    'mapDrawing.imagePanel.icon.goal': 'Goal',
    'mapDrawing.imagePanel.icon.obstacle': 'Obstakel',
    'mapDrawing.imagePanel.icon.ball': 'Bal',
    'mapDrawing.resetButton': 'Reset',
    'mapDrawing.textPanel.text.button': 'Tekst toevoegen',
    'mapDrawing.textPanel.text.placeholder': 'Tekst...',
    'mapDrawing.title': 'Tekentool',
    'mapDrawing.toolbar.item.arrow-1': 'Pass',
    'mapDrawing.toolbar.item.arrow-2': 'Lopen met bal',
    'mapDrawing.toolbar.item.arrow-3': 'Lopen zonder bal',
    'mapDrawing.toolbar.item.circle': 'Cirkel',
    'mapDrawing.toolbar.item.draw': 'Tekenen',
    'mapDrawing.toolbar.item.move': 'Bewerken',

    'matches.button.create': 'Maak wedstrijd aan',
    'matches.button.update': 'Pas wedstrijd aan',
    'matches.create.loading': 'Bezig met aanmaken van de wedstrijd',
    'matches.create.title': 'Wedstrijd aanmaken',
    'matches.label.date': 'Datum',
    'matches.label.name': 'Wedstrijd',
    'matches.title': 'Wedstrijden',
    'matches.update.loading': 'Bezig met aanmpassen van de wedstrijd',
    'matches.update.title': 'Wedstrijd aanpassen',

    'news.button.update': 'Pas nieuwsbericht aan',
    'news.button.save': 'Maak nieuwsbericht aan',
    'news.create.loading': 'Uploaden en aanmaken van nieuwsbericht',
    'news.create.title': 'Nieuwsbericht aanmaken',
    'news.label.date': 'Datum',
    'news.label.image': 'Afbeelding',
    'news.label.message': 'Bericht',
    'news.label.title': 'Titel',
    'news.update.loading': 'Uploaden en aanpassen van nieuwsbericht',
    'news.update.title': 'Nieuwsbericht aanpassen',
    'news.title': 'Nieuws',

    'occasions.label.name': 'Naam',
    'occasions.label.division': 'Niveau',
    'occasions.label.occasion': 'Gelegenheid',
    'occasions.label.numberOfExercises': 'Aantal oefeningen',
    'occasions.label.training': 'Training',
    'occasions.title': 'Trainingen',

    'orders.label.amount': 'Bedrag',
    'orders.label.birthday': 'Verjaardag',
    'orders.label.club': 'Club',
    'orders.label.diet': 'Dieet',
    'orders.label.email': 'E-mail',
    'orders.label.event': 'Event',
    'orders.label.full_name': 'Naam',
    'orders.label.group_with': 'In de groep met',
    'orders.label.mollie_payment_id': 'Mollie payment ID',
    'orders.label.parent_email': 'E-mail ouder',
    'orders.label.parent_phone': 'Telefoon ouder',
    'orders.label.parent_name': 'Naam ouder',
    'orders.label.remarks': 'Opmerkingen',
    'orders.label.shirt_size': 'Shirtmaat',
    'orders.label.status': 'Status',
    'orders.label.team': 'Team',
    'orders.label.timestamp': 'Tijdstip',
    'orders.show.title': 'Event inschrijving bekijken',
    'orders.title': 'Event inschrijvingen',

    'passwordRecovery.button.sendMail': 'Mail instructies',
    'passwordRecovery.intro': 'Vul je e-mailadres in en ontvang instructies om het wachtwoord opnieuw in te stellen.',
    'passwordRecovery.label.email' : 'E-mail',
    'passwordRecovery.mailSend.error': 'Dit e-mailadres is onbekend',
    'passwordRecovery.label.login' : 'Ga naar inloggen',
    'passwordRecovery.mailSend.success': 'Een e-mail met instructies is naar je verstuurd',
    'passwordRecovery.title': 'Wachtwoord vergeten?',

    'passwordReset.button.resetPassword' : 'Stel wachtwoord in',
    'passwordReset.label.password' : 'Wachtwoord',
    'passwordReset.label.passwordConfirmation' : 'Herhaal wachtwoord',
    'passwordReset.message.success': 'Het wachtwoord is aangepast',
    'passwordReset.message.error': 'De resetlink is verlopen',
    'passwordReset.title': 'Wachtwoord instellen',

    'periods.button.update': 'Pas tijdvak aan',
    'periods.button.create': 'Voeg tijdvak toe',
    'periods.create.title': 'Tijdsvak toevoegen',
    'periods.label.endDate': 'Einddatum',
    'periods.label.internal': 'Intern',
    'periods.label.numberOfTrainings': 'Aantal trainingen',
    'periods.label.startDate': 'Startdatum',
    'periods.label.trainings': 'Trainingen',
    'periods.show.title': 'Tijdsvak bekijken',
    'periods.title': 'Tijdvakken',
    'periods.update.title': 'Tijdsvak aanpassen',
    'periods.update.loading': 'Tijdvak wordt aangepast',

    'photos.create.loading': 'Bezig met uploaden van de foto',
    'photos.create.submit_button_text': 'Voeg foto toe',
    'photos.heading.add': 'Foto toevoegen',
    'photos.heading.photos': 'Foto\'s',
    'photos.label.delete': 'Verwijderen',
    'photos.label.photo': 'Foto',
    'photos.title': 'Wedstrijdfoto\'s',

    'players.button.create': 'Voeg speler toe',
    'players.button.import': 'Importeer spelers',
    'players.button.update': 'Pas speler aan',
    'players.create.loading': 'Speler wordt toegevoegd',
    'players.create.title': 'Speler toevoegen',
    'players.header.add': 'Speler toevoegen',
    'players.header.import': 'Spelers importeren',
    'players.heading.about': 'Over',
    'players.heading.events': 'Events',
    'players.heading.linked_accounts': 'Gedeelte toegang',
    'players.heading.playercard': 'Playercard',
    'players.heading.privacy': 'Privacy',
    'players.heading.teams': 'Teams',
    'players.label.club': 'Club',
    'players.label.email': 'E-mailadres',
    'players.label.first_name': 'Voornaam',
    'players.label.last_name': 'Achternaam',
    'players.label.name': 'Naam',
    'players.label.privacy.evaluation': 'Evaluaties toegestaan',
    'players.label.privacy.photo': 'Foto\'s toegestaan',
    'players.label.privacy.video': 'Video\'s toegestaan',
    'players.label.stats.stat_1': 'SVDB (spelen van de bal)',
    'players.label.stats.stat_2': 'SPEL (spelinzicht)',
    'players.label.stats.stat_3': 'LMDB (lopen met de bal)',
    'players.label.stats.stat_4': 'INZE (inzet)',
    'players.label.stats.stat_5': 'AVDB (aannemen van de bal)',
    'players.label.stats.stat_6': 'PLEZ (plezier)',
    'players.label.team': 'Team',
    'players.label.xls': 'Excel file',
    'players.message.pending': 'Geen account',
    'players.show.title': 'Speler bekijken',
    'players.stats.button.update': 'Pas player stats aan',
    'players.stats.update.loading': 'Bezig met aanpassen van player stats',
    'players.stats.update.title': 'Players stats aanpassen',
    'players.title': 'Spelers',
    'players.update.loading': 'Speler wordt aangepast',
    'players.update.title': 'Speler aanpassen',

    'premium.create.loading': 'Bezig met opslaan van premium abonnement',
    'premium.create.search_button_text': 'Zoek speler op',
    'premium.create.submit_button_text': 'Voeg premium toe',
    'premium.create.user_not_found': 'E-mail onbekend: speler niet gevonden',
    'premium.create.title': 'Premium toevoegen',
    'premium.label.club': 'Club',
    'premium.label.name': 'Naam',
    'premium.label.email': 'E-mail',
    'premium.label.end_date': 'Einddatum',
    'premium.label.start_date': 'Startdatum',
    'premium.label.status': 'Status',
    'premium.label.timestamp': 'Tijdstip',
    'premium.promo.button_text': 'Premium worden?',
    'premium.promo.description': 'Go4Hockey online is ontwikkeld voor trainers en/of coaches op ieder niveau. Door premium te worden ontvang je wekelijks trainingen en kan je zelf trainingen en trainingskaarten maken. Go4Hockey verzorgt professionele trainingen voor elk niveau, van mini’s tot volwassenen. Daarnaast kunnen coaches de opstelling en tactiek voor elke wedstrijd invoeren. Wacht niet en upgrade je account naar premium.',
    'premium.promo.title': 'Alleen voor premium-leden beschikbaar',
    'premium.title': 'Premium',
    'premium.update.submit_button_text': 'Pas premium aan',
    'premium.update.title': 'Premium aanpassen',

    'rating.store.loading': 'Bezig met opslaan van beoordelingen',
    'rating.store.submit_button_text': 'Sla beoordelingen op',
    'rating.store.success_message': 'De beoordelingen zijn opgeslagen',
    'rating.title': 'Beoordelingen',

    'register.button.submit': 'Registreer',
    'register.error.existingEmail': 'Dit e-mailadres heeft al een account',
    'register.error.error': 'Er is een fout opgetreden',
    'register.label.email': 'E-mailadres',
    'register.label.firstName': 'Voornaam',
    'register.label.infix': 'Tussenvoegsel',
    'register.label.lastName': 'Achternaam',
    'register.label.login': 'Heb je al een account? Ga naar inloggen.',
    'register.label.password': 'Wachtwoord',
    'register.label.passwordConfirmation': 'Herhaal wachtwoord',
    'register.label.privacyPolicy': 'Ik ga akkoord met de privacy policy',
    'register.label.termsAndConditions': 'Ik ga akkoord met de algemene voorwaarden',
    'register.success': 'Het account is aangemaakt. Controleer je mail om het account te activeren.',
    'register.title': 'Registreren',

    'report.label.report': 'Wedstrijdverslag',
    'report.store.loading': 'Bezig met opslaan van wedstrijdverslag',
    'report.store.submit_button_text': 'Sla wedstrijdverslag op',
    'report.store.success_message': 'Wedstrijdverslag is opgeslagen',
    'report.title': 'Wedstrijdverslag',

    'sidebar.assessments': 'Rapporten',
    'sidebar.assessment-requests': 'Rapport verzoeken',
    'sidebar.club': 'Club',
    'sidebar.clubs': 'Clubs',
    'sidebar.dashboard': 'Dashboard',
    'sidebar.education': 'Lesmateriaal',
    'sidebar.education-plan': 'Leerlijn',
    'sidebar.events': 'Events',
    'sidebar.exercises': 'Oefeningen',
    'sidebar.exercise-tags': 'Leerlijn oefeningen',
    'sidebar.feedback': 'Feedback',
    'sidebar.internal': 'Intern',
    'sidebar.logout': 'Uitloggen',
    'sidebar.map-drawing-tool': 'Tekentool',
    'sidebar.matches': 'Wedstrijden',
    'sidebar.news': 'Nieuws',
    'sidebar.orders': 'Event inschrijvingen',
    'sidebar.periods': 'Tijdvakken',
    'sidebar.personal': 'Persoonlijk',
    'sidebar.players': 'Spelers',
    'sidebar.premium': 'Premium',
    'sidebar.subscription': 'Premium',
    'sidebar.surveys': 'Vragenlijsten',
    'sidebar.survey-requests': 'Vragenlijst verzoeken',
    'sidebar.teams': 'Teams',
    'sidebar.trainers': 'Trainers',
    'sidebar.trainings': 'Trainingen',
    'sidebar.user-exercises': 'Mijn oefeningen',
    'sidebar.users': 'Gebruikers',
    'sidebar.videos': 'Instructievideo\'s',

    'string.yes': 'Ja',
    'string.no': 'Nee',

    'subscription.label.accountHeading': 'Account informatie',
    'subscription.label.accountType': 'Type account',
    'subscription.label.expireDate': 'Verloopdatum',
    'subscription.label.club': 'Club',
    'subscription.label.free': 'Gratis',
    'subscription.label.premium': 'Premium',
    'subscription.month.description': 'Wil je een maand Go4Hockey Online proberen met je team? Dan is dit je kans. Het abonnement stopt automatisch na een maand.',
    'subscription.month.name': 'Maand',
    'subscription.payment.success': 'Betaling verwerkt',
    'subscription.payment.pending': 'Betaling wordt verwerkt',
    'subscription.payment.failed': 'Betaling geannuleerd of verlopen',
    'subscription.orderButton.label': 'Bestel nu',
    'subscription.title': 'Premium',
    'subscription.year.description': 'Wil jij een seizoen lang je team optimaal begeleiden? Dan is de beste keuze om een jaar abonnement te nemen! Het grote voordeel is een jaar lang zonder zorgen Go4Hockey Online gebruiken. Na een jaar stop het abonnement automatisch. ',
    'subscription.year.name': 'Jaar',

    'surveys.answers.button.create': 'Voeg antwoord toe',
    'surveys.answers.button.update': 'Pas antwoord aan',
    'surveys.answers.create.loading': 'Antwoord wordt toegevoegd',
    'surveys.answers.create.title': 'Vragenlijst: antwoord aan vraag toevoegen',
    'surveys.answers.title': 'Vragenlijst: antwoorden op vraag',
    'surveys.answers.update.loading': 'Antwoord wordt aangepast',
    'surveys.answers.update.title': 'Vragenlijst: antwoord aanpassen',
    'surveys.label.answer': 'Antwoord',
    'surveys.label.closed_invites': 'Afgeronde verzoeken',
    'surveys.label.completed': 'Afgerond',
    'surveys.label.is_private': 'Afgeschermd',
    'surveys.label.multipleChoice': 'Multiple choice',
    'surveys.label.number_of_invites': 'Aantal verzoeken',
    'surveys.label.open_invites': 'Open verzoeken',
    'surveys.label.options': 'Opties',
    'surveys.label.order': 'Volgorde',
    'surveys.label.player': 'Speler',
    'surveys.label.question': 'Vraag',
    'surveys.label.respondent': 'Respondent',
    'surveys.label.respondents': 'Respondenten',
    'surveys.label.timestamp': 'Tijdstip',
    'surveys.label.team': 'Team',
    'surveys.label.topic': 'Onderwerp',
    'surveys.questions.button.create': 'Voeg vraag toe',
    'surveys.questions.button.update': 'Pas vraag aan',
    'surveys.questions.create.loading': 'Vraag wordt toegevoegd',
    'surveys.questions.create.title': 'Vragenlijst: vraag toevoegen',
    'surveys.questions.title': 'Vragenlijst: vragen',
    'surveys.questions.update.loading': 'Vraag wordt aangepast',
    'surveys.questions.update.title': 'Vragenlijst: vraag aanpassen',
    'surveys.requests.create.button': 'Verstuur verzoek',
    'surveys.requests.create.loading': 'Vragenlijst verzoek wordt aangemaakt',
    'surveys.requests.create.options.heading': 'Selecteer opties:',
    'surveys.requests.create.respondents.heading': 'Selecteer de respondenten:',
    'surveys.requests.create.teams.heading': 'Selecteer een team:',
    'surveys.requests.create.topics.heading': 'Selecteer een onderwerp:',
    'surveys.requests.create.title': 'Vragenlijst verzoek aanmaken',
    'surveys.requests.label.next-button': 'Volgende',
    'surveys.requests.title': 'Vragenlijst verzoeken',
    'surveys.requests.show.title': 'Vragenlijst verzoek: ',
    'surveys.topics.button.create': 'Maak vragenlijst aan',
    'surveys.topics.button.update': 'Pas vragenlijst aan',
    'surveys.topics.create.loading': 'Vragenlijst wordt aangemaakt',
    'surveys.topics.create.title': 'Vragenlijst aanmaken',
    'surveys.topics.title': 'Vragenlijsten',
    'surveys.topics.update.loading': 'Vragenlijst wordt aangepast',
    'surveys.topics.update.title': 'Vragenlijst aanpassen',

    'surveys.show.title': 'Vragenlijst',
    'surveys.label.date': 'Datum',
    'surveys.title': 'Speler vragenlijsten',

    'table.actions.assessment': 'Rapporten',
    'table.actions.feedback': 'Feedback',
    'table.actions.highlights': 'Hoogtepunten',
    'table.actions.lineup': 'Opstelling',
    'table.actions.photos': 'Foto\'s',
    'table.actions.ratings': 'Beoordelingen',
    'table.actions.report': 'Verslag',
    'table.actions.survey': 'Vragenlijsten',
    'table.actions.tactic': 'Tactiek',
    'table.actions.view': 'Bekijken',

    'tactic.store.success_message': 'De wedstrijdtactiek is opgeslagen',
    'tactic.title': 'Wedstrijdtactiek',
    'tactic.label.tactic': 'Wedstrijdtactiek',
    'tactic.label.image': 'Afbeelding',
    'tactic.store.submit_button_text': 'Sla wedstrijdtactiek op',
    'tactic.store.loading': 'Bezig met opslaan van wedstrijdtactiek',

    'teams.button.admin.create': 'Voeg admin toe',
    'teams.button.create': 'Maak team aan',
    'teams.button.update': 'Pas team aan',
    'teams.create.loading': 'Team wordt aangemaakt',
    'teams.create.title': 'Team aanmaken',
    'teams.label.admins': 'Admins',
    'teams.label.clubAccess': 'Club toegang',
    'teams.label.email': 'E-mailadres',
    'teams.label.name': 'Team',
    'teams.label.numberOfAdmins': 'Aantal admins',
    'teams.label.numberOfPlayers': 'Aantal spelers',
    'teams.label.players': 'Spelers',
    'teams.title': 'Teams',
    'teams.update.loading': 'Team wordt aangepast',

    'toast.error.emailNotFound': 'E-mailadres is onbekend',
    'toast.error.main': 'Er is een fout opgetreden...',
    'toast.success.created': 'Met succes aangemaakt',
    'toast.success.delete': 'Met succes verwijderd',
    'toast.success.updated': 'Met succes aangepast',

    'trainers.button.create': 'Voeg trainer toe',
    'trainers.button.update': 'Pas trainer aan',
    'trainers.create.loading': 'Trainer wordt aangemaakt',
    'trainers.create.title': 'Trainer aanmaken',
    'trainers.label.email': 'E-mail',
    'trainers.label.name': 'Naam',
    'trainers.label.rate': 'Tarief',
    'trainers.title': 'Trainers',
    'trainers.update.loading': 'Trainer wordt aangepast',
    'trainers.update.title': 'Trainer aanpassen',

    'trainings.button.addExercise': 'Voeg oefening toe',
    'trainings.button.create': 'Maak training aan',
    'trainings.button.update': 'Pas training aan',
    'trainings.create.loading': 'Training wordt aangemaakt',
    'trainings.create.title': 'Voeg training toe',
    'trainings.label.division': 'Niveau',
    'trainings.label.exercise': 'Oefening',
    'trainings.label.exercises': 'Oefeningen',
    'trainings.label.name': 'Naam',
    'trainings.label.numberOfExercises': 'Aantal oefeningen',
    'trainings.label.trainer': 'Trainer',
    'trainings.title': 'Trainingen',
    'trainings.update.loading': 'Training wordt aangepast',
    'trainings.update.title': 'Pas training aan',

    'validation.error.email': 'Ongeldig e-mailadres',
    'validation.error.equal' : 'Velden zijn niet gelijk',
    'validation.error.fileSize': 'Bestand is te groot',
    'validation.error.fileType': 'Ongeldig bestandsformaat',
    'validation.error.minPasswordLength': 'Minimaal 8 karakters',
    'validation.error.maxString': 'Maximaal 255 karakters',
    'validation.error.number': 'Ongeldig getal',
    'validation.error.numberMin': 'Waarde te laag',
    'validation.error.numberMax': 'Waarde te hoog',
    'validation.error.required': 'Verplicht veld',

    'verify.label.login': 'Ga naar inloggen',
    'verify.message.alreadyVerified': 'Het account was al geactiveerd. Je kunt nu inloggen.',
    'verify.message.error': 'De verificatielink is verlopen',
    'verify.message.verifying': 'Bezig met activeren van account. Even geduld.',
    'verify.message.success': 'Het account is geactiveerd. Je kunt nu inloggen.',
    'verify.title': 'Account verifiëren',

    'videos.create.loading': 'Uploaden en aanmaken van instructievideo',
    'videos.create.title': 'Instructievideo toevoegen',
    'videos.button.update': 'Pas instructievideo aan',
    'videos.button.save': 'Voeg instructievideo toe',
    'videos.label.description': 'Omschrijving',
    'videos.label.tags': 'Tags',
    'videos.label.title': 'Naam',
    'videos.label.video': 'Video',
    'videos.title': 'Instructievideo\'s',
    'videos.update.loading': 'Uploading en aanpassen van instructievideo',

    'viewMode.label': 'Weergave',
    'viewMode.default': 'Algemeen',
    'viewMode.trainer': 'Trainer',
    'viewMode.coach': 'Coach',
    'viewMode.club': 'Club',
};
