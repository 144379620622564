import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { isDate, parse } from 'date-fns';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import CheckboxGroupInput from 'components/CheckboxGroupInput';
import DatePickerInput from 'components/DatePickerInput';

const ClassificationForm = props => {
    const {
        onSubmit,
        teams,
    } = props;
    const initialValues = {
        closing_date: '',
        teams: teams.map(team => `${team.value}`),
    };

    const parseDateString = (value, originalValue) => {
        return isDate(originalValue)
            ? originalValue
            : parse(originalValue, "yyyy-MM-dd", new Date());
    }

    const validationSchemaObject = yup.object().shape({
        closing_date: yup
            .date()
            .transform(parseDateString)
            .min(new Date(), 'Datum moet in de toekomst liggen')
            .required('Verplicht veld'),
        teams: yup
            .array()
            .min(1, 'Minimaal 1 team verplicht')
            .required('Verplicht veld')
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                dirty,
                errors,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
                setFieldValue,
                touched,
              }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name="closing_date"
                            label="Sluitingsdatum"
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['closing_date'] }
                            touched={ touched['closing_date'] }
                            onBlur={ handleBlur['closing_date'] }
                            minDate={ new Date() }
                        />
                    </Box>

                    <Box mt={ 4 }>
                        <Field
                            component={ CheckboxGroupInput }
                            errorMessage={ errors['teams'] }
                            heading="Teams"
                            name="teams"
                            options={ teams }
                            setFieldValue={ setFieldValue }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            disabled={ isSubmitting || ! isValid || ! dirty }
                            type="submit"
                            variant="contained"
                        >
                            Verstuur verzoeken
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

ClassificationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    teams: PropTypes.array.isRequired,
};

export default ClassificationForm;
