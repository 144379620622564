import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import styles from './ChipCollection.styles';

const ChipCollection = props => {
    const { items, onDelete } = props;
    const classes = styles();

    const renderChips = () => {
        let increment = 0;

        return items.map(item => {
            increment++;

            return <Chip key={ `chip-${increment}` } label={ item.label } onDelete={ () => onDelete(item.id) } />
        })
    }

    return (
        <div className={ classes.root }>
            { renderChips() }
        </div>
    );
};

ChipCollection.propTypes = {
    items: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ChipCollection;
