import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { IconButton } from '@material-ui/core';

import styles from './LogoutButton.styles';

const LogoutButton = () => {
    const classes = styles();

    return (
        <IconButton
            onClick={ () => { window.location.href = '/logout' } }
            className={ classes.icon }
        >
            <ExitToAppIcon />
        </IconButton>
    );
};

export default LogoutButton;
