import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { USER } from 'store/constants';
import ContentBox from 'components/ContentBox';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchForm from 'contents/forms/SearchForm';
import UserSearchResultTable from 'contents/tables/UserSearchResultTable';

const UserSearch = () => {
    const dispatch = useDispatch();
    const results = useSelector(state => state.user.search.results);
    const query = useSelector(state => state.user.search.query);
    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);

    const submitQuery = values => {
        dispatch(USER.SEARCH.trigger(values));
    }

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    return (
        <ContentContainer title="Gebruiker zoeken">
            <Grid container spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <ContentBox>
                        <SearchForm onSubmit={ submitQuery } />
                    </ContentBox>
                </Grid>

                { query && (
                    <Grid item xs={ 12 }>
                        { loading ? (
                            <ContentBox>
                                <LoadingIndicator  />
                            </ContentBox>
                        ) : (
                            <UserSearchResultTable
                                data={ results }
                                query={ query }
                            />
                        ) }
                    </Grid>
                )}
            </Grid>
        </ContentContainer>
    );
};

export default UserSearch;