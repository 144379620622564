import PropTypes from 'prop-types';

import MaterialTable from 'material-table';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const ClassificationTeamTable = props => {
    const { data, team } = props;

    const iterationNumbers = {
        first: '1',
        second: '2',
        unknown: '-',
    }

    const adviceValues = {
        demote: 'Onder',
        promote: 'Boven',
        sustain: 'Gemiddeld',
        unknown: '-',
    }

    const renderData = data.map(row => ({
        player: row.player,
        iteration: iterationNumbers[row.iteration] ?? iterationNumbers.unknown,
        advise: adviceValues[row.advise] ?? adviceValues.unknown,
        comment: row.comment ?? '',
    }));

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'player'),
            defaultSort: 'asc',
            field: 'player',
            title: 'Speler',
        },
        {
            field: 'iteration',
            title: 'Jaargang',
        },
        {
            field: 'advise',
            title: 'Niveau (advies)',
        },
        {
            field: 'comment',
            title: 'Opmerking',
        },
    ];

    return (
        <MaterialTable
            title={ `Teambeoordeling ${team}` }
            localization={ localization }
            columns={ columns }
            data={ renderData }
            options={ defaultOptions }
        />
    );
};

ClassificationTeamTable.propTypes = {
    data: PropTypes.array.isRequired,
    team: PropTypes.string.isRequired,
};

export default ClassificationTeamTable;