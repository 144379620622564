import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    panels: {
        display: 'flex',
        flexDirection: 'column',
        width: 294,
    },

    fieldPanel: {
        marginTop: theme.spacing(2),
    },

    textPanel: {
        marginBottom: theme.spacing(2),
    },

    imagePanel: {
        height: 410,
        overflowX: 'hidden',
        overflowY: 'scroll',
    },

    buttonPanel: {
        marginTop: 'auto',
    },

    resetButton: {
        backgroundColor: theme.palette.grey[300],
    },

    downloadButton: {
        marginTop: theme.spacing(2),
        height: 50,
        color: '#fff',
    },
}));

export default styles;