import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { PROFILE } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;
export const getUserId = (state) => state.persist.userId;

export function* fetchRequest() {
    const token = yield select(getToken);
    const userId = yield select(getUserId);

    return yield get(token, Api.requests.users.show(userId));
}

export function* fetchFlow() {
    while (true) {
        yield take(PROFILE.FETCH.TRIGGER);

        try {
            const userId = yield select(getUserId);

            if (userId) {
                const response = yield call(fetchRequest);
                const profile = response.data.data;

                yield put(PROFILE.FETCH.success(profile));
            }
        } catch (error) {
            yield put(PROFILE.FETCH.failure());
        } finally {
            yield put(PROFILE.FETCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
    ]);
}