import { Fragment } from 'react';

import { Link } from 'react-router-dom';

import { images } from 'config/images';
import StaticHeader from 'components/StaticHeader';
import StaticFooter from 'components/StaticFooter/StaticFooter';
import Box from '@material-ui/core/Box';

const Home = () => {
    return (
        <Fragment>
            <StaticHeader />

            <main role="main" className="home">
                <section className="banner gap-both">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 order-2 order-md-1 align-self-center">
                                <div className="content-button">
                                    <div className="content">
                                        <h1 className="onepager-h1">Ontwikkel samen de jeugdopleiding!</h1>

                                        <p>
                                            Voor elke club is de jeugdopleiding belangrijk. Goede trainingen zorgen voor
                                            betere resultaten en meer (spel)plezier. Go4Hockey begeleidt en ondersteunt
                                            de trainers en coaches van uw club. Ons platform biedt de volgende voordelen:
                                        </p>

                                        <div className="columns">
                                            <ul>
                                                <li>Een leerlijn voor het hele seizoen</li>
                                                <li>Complete trainingen per niveau</li>
                                                <li>Monitoring van spelers middels een spelervolgsysteem</li>
                                                <li>Data voor het maken van teamindelingen</li>
                                                <li>Voldoet aan de AVG wetgeving</li>
                                            </ul>
                                        </div>

                                        <Box mt={ 2 }>
                                            <p>
                                                Maak dit seizoen <strong>gratis</strong> gebruik van ons platform en apps!
                                            </p>
                                        </Box>

                                        <Link to={ '/inquiry' } className="btn btn-fill primary">Vraag nu een try-out aan</Link>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-xl-6 offset-xl-1 col-lg-6  col-md-6 col-sm-10 order-1 order-md-2 align-self-center">
                                <div className="video">
                                    <iframe
                                        title="explainer"
                                        className="responsive-iframe"
                                        width="585"
                                        height="329"
                                        src="https://www.youtube.com/embed/dBTqjwcy3hA"
                                        allowFullScreen>
                                    </iframe>
                                    <div className="splash top-left">
                                        <img className="img-fluid" src={ images.splash.hero.left.default } alt="Splash" />
                                    </div>
                                    <div className="splash bot-right">
                                        <img className="img-fluid" src={ images.splash.hero.right.default } alt="Splash" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="partners gap-both">
                    <div className="container-fluid">
                        <div className="row no-gutters">
                            <div className="col-12 d-flex justify-content-center">
                                <h4>Onze clubs</h4>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="column">
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.rotterdam.default } alt="Rotterdam" />
                                    </figure>
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.craeyenhout.default } alt="Craeyenhout" />
                                    </figure>
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.hudito.default } alt="Hudito" />
                                    </figure>
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.kieviten.default } alt="Kieviten" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="column">
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.nhc.default } alt="NHC" />
                                    </figure>
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.victoria.default } alt="Victoria" />
                                    </figure>
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.hcw.default } alt="HCW" />
                                    </figure>
                                    <figure>
                                        <img className="img-fluid" src={ images.clublogo.feyenoord.default } alt="Feyenoord" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="splash bot-left">
                        <img className="img-fluid" src={ images.splash.trainer.left.default } alt="Splash" />
                    </div>
                </section>

                <section className="trainer gap-both">
                    <div className="container-fluid content-padding">
                        <div className="row">
                            <div className="col-xxl-4 col-lg-4 col-md-5 col-sm-8  align-self-center">
                                <div className="img-box">
                                    <img src={ images.homepage.trainer.default } className="img-fluid" alt="Go4Hockey trainer" />
                                </div>
                            </div>

                            <div className="col-xxl-7 col-lg-7 offset-lg-1 col-md-7 align-self-center">
                                <div className="content">
                                    <h2>Trainer</h2>
                                    <p>Als trainer krijg je de volgende mogelijkheden:</p>
                                    <div className="columns">
                                        <ul>
                                            <li>Tekentool</li>
                                            <li>Eigen oefeningen maken</li>
                                            <li>Periodisering</li>
                                            <li>Oefeningen Collectie (500+)</li>
                                            <li>Complete trainingen op niveau (leerlijn)</li>
                                        </ul>
                                        <ul>
                                            <li>Videofeedback sturen</li>
                                            <li>Instructie video’s (techniek)</li>
                                            <li>Team beheren</li>
                                            <li>Clubomgeving (oefeningen en trainingen delen)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="coach gap-both dark">
                    <div className="splash bot-right">
                        <img className="img-fluid" src={ images.splash.trainer.right.default } alt="Splash" />
                    </div>
                    <div className="container-fluid content-padding">
                        <div className="row">
                            <div className="col-xxl-7 col-lg-7 col-md-12 align-self-center">
                                <div className="content">
                                    <h2>Coach</h2>
                                    <p>
                                        Als coach krijg je de volgende mogelijkheden:
                                    </p>
                                    <div className="columns">
                                        <ul>
                                            <li>Opstelling maken</li>
                                            <li>Tactiek sturen</li>
                                            <li>Hoogtepunten toevoegen</li>
                                            <li>Wedstrijdverslagen</li>
                                        </ul>
                                        <ul>
                                            <li>Wedstrijdbeoordeling geven per speler</li>
                                            <li>Foto’s toevoegen</li>
                                            <li>Coachbegeleiding (instructie video’s gedrag & tactiek)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 offset-xxl-1 col-lg-5 col-md-8 col-sm-10 mx-auto">
                                <div className="img-wrapper">
                                    <figure className="img-box top">
                                        <img src={ images.homepage.coach.top.default } className="img-fluid" alt="Coach" />
                                    </figure>
                                    <figure className="img-box bot">
                                        <img src={ images.homepage.coach.bottom.default } className="img-fluid" alt="Coach" />
                                    </figure>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="speler gap-both">
                    <div className="container-fluid content-padding">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10 align-self-center">
                                <figure className="img-box">
                                    <img src={ images.homepage.player.default } className="img-fluid" alt="Spelers bij een Go4Hockey kamp" />

                                        <div className="splash bot-left">
                                            <img className="img-fluid" src={ images.splash.player.left.default } alt="Splash" />
                                        </div>
                                </figure>
                            </div>

                            <div className="col-xxl-7 offset-xxl-1 col-xl-7 offset-xl-1 col-lg-7 col-md-12 col-sm-12">
                                <div className="content">
                                    <h2>Club</h2>
                                    <p>Wil je jouw spelers volgen en aan het eind van het seizoen teamindelingen maken? Laat
                                        trainers, coaches en zelfs spelers evaluaties invullen. Als club
                                        krijg je de volgende mogelijkheden:</p>
                                    <div className="columns">
                                        <ul>
                                            <li>Spelervolgsysteem</li>
                                            <li>Eigen evaluaties maken (zowel beoordeling als Persoonlijk Ontwikkelingsplan)</li>
                                            <li>Mogelijkheid om standaard rapporten te gebruiken</li>
                                            <li>Wekelijkse welbevinden van je spelers monitoren</li>
                                            <li>De clubmanagers kunnen trainers, coaches, spelers en teams beheren</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="club gap-both dark">
                    <div className="splash bot-right">
                        <img className="img-fluid" src={ images.splash.player.right.default } alt="Splash" />
                    </div>
                    <div className="container-fluid content-padding">
                        <div className="row">
                            <div className="col-xxl-7 col-lg-7 col-md-7 order-md-1 order-2 align-self-center">
                                <div className="content">
                                    <h2>Clubondersteuning</h2>
                                    <p>We kunnen samen de jeugdopleiding optimaliseren en het platform/apps optimaal leren
                                        benutten. Wij bieden de volgende mogelijkheden:
                                    </p>
                                    <div className="columns">
                                        <ul>
                                            <li>Invoeren van trainer, coaches en spelers</li>
                                            <li>Ondersteuning bij de opleiding</li>
                                            <li>Hulp bij het opzetten van evaluaties en leerlijnen</li>
                                        </ul>
                                        <ul>
                                            <li>Workshops (3x 1 uur)</li>
                                            <li>Systeembegeleiding</li>
                                            <li>Basisopleiding trainer/coach</li>
                                            <li>Zaalhockey</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 offset-xxl-1 col-lg-4 offset-lg-1 col-md-5 col-sm-8 order-md-2 order-1  align-self-center">
                                <div className="img-box">
                                    <img src={ images.homepage.club.default } className="img-fluid" alt="Clubondersteuning" />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="testimonial content-padding gap-both">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 align-self-start brand">
                                <div className="img-fluid">
                                    <img src={ images.clublogo.rotterdam.default } alt="Hockey Club Rotterdam" />
                                </div>
                            </div>

                            <div className="col-lg-10 col-md-10 col-sm-12 align-self-center">
                                <div className="content">
                                    <i className="icon icon-quote" />
                                    <h3>Een zeer prettige tool om je training voor te bereiden. Ik gebruik de complete
                                        trainingen maar
                                        pak
                                        soms
                                        ook een oefening uit de database app! </h3>
                                    <div className="author">
                                        <span>Trainer, HC Rotterdam</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="apps">
                    <div className="splash top-left">
                        <img src={ images.splash.testimonial.left.default } className="img-fluid" alt="Splash" />
                    </div>

                    <div className="divider top">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path d="M1200 120L0 0 0 0 1200 0 1200 120z" className="shape-fill" />
                        </svg>
                    </div>

                    <div className="container-fluid gap-both content-padding">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xm-10 col-xs-12">
                                <div className="card">
                                    <img src={ images.homepage.solutions.trainerApp.default } className="card-img-top" alt="Go4Hockey Team app" />
                                        <div className="card-body">
                                            <h4 className="card-title">Go4Hockey Team App</h4>
                                            <p className="card-text">Een handige app voor een optimale begeleiding! </p>

                                            <article className="app-list">
                                                <a href="https://play.google.com/store/apps/details?id=nl.go4hockey.trainercoach"
                                                   target="_blank" rel="noopener noreferrer">
                                                    <img src={ images.homepage.stores.googlePlay.default } className="img-fluid" alt="Google Play" />
                                                </a>
                                                <a href="https://apps.apple.com/nl/app/go4hockey-team/id1516669677" target="_blank"
                                                   rel="noopener noreferrer">
                                                    <img src={ images.homepage.stores.appStore.default } className="img-fluid" alt="Apple AppStore" />
                                                </a>
                                            </article>
                                        </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xm-10 col-xs-12">
                                <div className="card">
                                    <img src={ images.homepage.solutions.playerApp.default } className="card-img-top" alt="Go4Hockey Speler app" />
                                        <div className="card-body">
                                            <h4 className="card-title">Go4Hockey Speler app</h4>
                                            <p className="card-text">Sta in verbinding met je team en bekijk jouw ontwikkeling.
                                            </p>

                                            <article className="app-list">
                                                <a href="https://play.google.com/store/apps/details?id=nl.go4hockey.speler"
                                                   target="_blank" rel="noopener noreferrer">
                                                    <img src={ images.homepage.stores.googlePlay.default } className="img-fluid" alt="Google Play" />
                                                </a>
                                                <a href="https://apps.apple.com/us/app/id1516669704" target="_blank"
                                                   rel="noopener noreferrer">
                                                    <img src={ images.homepage.stores.appStore.default } className="img-fluid" alt="Apple AppStore" />
                                                </a>
                                            </article>
                                        </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xm-10 col-xs-12">
                                <div className="card">
                                    <img src={ images.homepage.solutions.platform.default } className="card-img-top" alt="Go4Hockey Web" />
                                        <div className="card-body">
                                            <h4 className="card-title">Go4Hockey Online</h4>
                                            <p className="card-text">Een eigen omgeving voor de clubmanager en hoofdtrainer.</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divider bot">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path d="M1200 120L0 0 0 0 1200 0 1200 120z" className="shape-fill" />
                        </svg>
                    </div>
                </section>

                <section className="spellers-apps gap-both content-padding">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-4 col-lg-5 col-md-6 align-self-center">
                                <div className="content">
                                    <h2>Speler</h2>
                                    <p>
                                        Ontwikkel je met de Speler app! Je kan videofeedback ontvangen, evaluaties bekijken
                                        en je eigen team volgen. Als speler krijg je de volgende mogelijkheden:</p>
                                    <div className="columns">
                                        <ul>
                                            <li>Opstellingen</li>
                                            <li>Tactiek</li>
                                            <li>Hoogtepunten</li>
                                        </ul>
                                        <ul>
                                            <li>Evaluaties</li>
                                            <li>Wedstrijdverslagen</li>
                                            <li>Foto's</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-xxl-5 offset-xxl-1 col-xl-7 offset-xl-1 col-lg-6 offset-lg-1 col-md-6 col-sm-8 col-10 align-self-center">
                                <div className="img-box">
                                    <img src={ images.homepage.playersApp.default } className="img-fluid" alt="Spelers-app" />
                                        <div className="splash bot-right">
                                            <img src={ images.splash.playersApp.right.default } className="img-fluid" alt="Splash" />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <StaticFooter />
            </main>
        </Fragment>
    );
};

export default Home;
