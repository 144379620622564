import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import PremiumWall from 'components/PremiumWall';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { CLUB_TRAININGS, PROFILE } from 'store/constants';
import { images } from 'config/images';

const ClubTrainings = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.clubTraining.fetched);
    const trainings = useSelector(state => state.clubTraining.trainings);
    const loading = useSelector(state => state.clubTraining.loading);
    const locale = useSelector(state => state.persist.language);
    const isPremium = useSelector(state => state.profile.is_premium);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const profileFetched = useSelector(state => state.profile.fetched);
    const roles = useSelector(state => state.profile.roles);
    const isClubmanager = roles.includes('clubmanager');
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'trainings.title' });
    const error = useSelector(state => state.clubTraining.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.clubTraining.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'trainings.label.name' }),
            field: 'name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'trainings.label.division' }),
            field: 'division.data.name',
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'trainings.label.numberOfExercises' }),
            render: rowData => rowData.exercises.data.length,
            customSort: (a, b) => a.exercises.data.length < b.exercises.data.length ? -1 : 1,
        },
        {
            title: 'Verborgen',
            field: 'hidden',
            defaultSort: 'asc',
            type: 'boolean',
        },
    ];

    const defaultActions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/club/trainings/${rowData.id}`)
        },
    ];

    const clubmanagerActions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('/club/trainings/create')
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`/club/trainings/${rowData.id}/edit`)
        },
    ];

    const actions = isClubmanager ? [...clubmanagerActions, ...defaultActions] : defaultActions;

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(CLUB_TRAININGS.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(CLUB_TRAININGS.FETCH.trigger());
        }

        if (profileFetched === false) {
            dispatch(PROFILE.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            <PremiumWall
                isProfileFetched={ profileFetched }
                isPremium={ isPremium || hasCorrectPermission || isAdmin }
                premiumImage={ images.premium.exercise.default }
            >
                { loading ? <LoadingIndicator /> : (
                    <Grid container spacing={ 3 }>
                        <Grid item xs={ 12 }>
                            <MaterialTable
                                title={ pageTitle }
                                localization={ localization }
                                columns={ columns }
                                data={ trainings }
                                options={ defaultOptions }
                                actions={ actions }
                                editable={ isClubmanager ? { onRowDelete } : {} }
                            />
                        </Grid>
                    </Grid>
                ) }
            </PremiumWall>
        </ContentContainer>
    );
};

export default ClubTrainings;
