import { AUTH, CLUB } from '../constants';
import { deleteObjectWithIdFromArray, findIdAndUpdateObjectInArray } from 'utilities/array';

const INITIAL_STATE = {
    clubId: null,
    users: [],

    error: false,
    loading: false,

    created: null,
    updated: false,
    deleted: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB.USER.FETCH.TRIGGER:
        case CLUB.USER.CREATE.TRIGGER:
        case CLUB.USER.UPDATE.TRIGGER:
        case CLUB.USER.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLUB.USER.FETCH.SUCCESS:
            return {
                ...state,
                clubId: action.payload.clubId,
                users: action.payload.users,
            };

        case CLUB.USER.CREATE.SUCCESS:
            return {
                ...state,
                users: state.users.concat(action.payload),
                created: action.payload.id,
            };

        case CLUB.USER.UPDATE.SUCCESS:
            return {
                ...state,
                users: findIdAndUpdateObjectInArray([...state.users], action.payload.id, action.payload),
                updated: true,
            };

        case CLUB.USER.DELETE.SUCCESS:
            return {
                ...state,
                users: deleteObjectWithIdFromArray([...state.users], action.payload),
                deleted: true,
            };

        case CLUB.USER.FETCH.FAILURE:
        case CLUB.USER.CREATE.FAILURE:
        case CLUB.USER.UPDATE.FAILURE:
        case CLUB.USER.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLUB.USER.FETCH.FULFILL:
        case CLUB.USER.CREATE.FULFILL:
        case CLUB.USER.UPDATE.FULFILL:
        case CLUB.USER.DELETE.FULFILL:
            return {
                ...state,
                created: null,
                deleted: false,
                error: false,
                loading: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default userReducer;