import { useEffect } from 'react';

import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Dutch from 'languages/nl';
import English from 'languages/en-US';
import {
    setMessages as setMessagesAction,
    setLanguage as setLanguageAction,
} from 'store/actions/LanguageActions';

const LanguageWrapper = props => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.persist.language);
    const messages = useSelector(state => state.language.messages);

    const setMessages = messages => {
        dispatch(setMessagesAction(messages));
    };

    const setLanguage = newLocale => {
        dispatch(setLanguageAction(newLocale));

        if (newLocale === 'en-US') {
            setMessages(English);
        } else {
            setMessages(Dutch);
        }
    };

    useEffect(() => {
        if (locale) {
            setLanguage(locale);
        }
    });

    return (
        <IntlProvider locale={ locale ?? 'nl' } messages={ messages }>
            { props.children }
        </IntlProvider>
    );
};

export default LanguageWrapper;

