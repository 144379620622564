import { LANGUAGE } from '../constants';

export const setMessages = messages => ({
    type: LANGUAGE.MESSAGES.SET,
    messages,
});

export const setLanguage = language => {
    return {
        type: LANGUAGE.SET,
        language,
    }
};