import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    content: {
        marginTop: theme.spacing(2),
        marginBottom: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: 'auto',
    },
}));

export default styles;