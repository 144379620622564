import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClassificationTeamTable from 'contents/tables/ClassificationTeamTable';
import { CLASSIFICATIONS } from 'store/constants';

const ClassificationTeamShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const classificationId = parseInt(params.classificationId);
    const teamId = parseInt(params.teamId);
    const classification = useSelector(state => state.classificationTeam.active);
    const error = useSelector(state => state.classificationTeam.error);
    const { players, reviews, team } = classification;
    const fetched =
        classification?.id === classificationId &&
        classification?.teamId === teamId;

    const tableData = players.filter(player => !! player.club_player_id)
        .map(player => {
            const matchedReview = reviews.find(review => review.club_player_id === player.club_player_id);

            return {
                advise: matchedReview?.advise,
                comment: matchedReview?.comment,
                iteration: matchedReview?.iteration,
                player: player.name,
            };
        });

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (! fetched) {
            dispatch(CLASSIFICATIONS.TEAMS.SHOW.trigger({
                classificationId,
                teamId
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ `Teambeoordeling ${team}` } backButton={ true }>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                        <ClassificationTeamTable
                            data={ tableData }
                            team={ team }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClassificationTeamShow;