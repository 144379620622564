import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { CLASSIFICATIONS } from '../constants';
import { fetchRequest } from '../api';

export function* showFlow() {
    while (true) {
        const request = yield take(CLASSIFICATIONS.TEAMS.SHOW.TRIGGER);

        try {
            const classificationId = request.payload.classificationId;
            const teamId = request.payload.teamId;

            const classificationEndpoint = Api.requests.classifications.teams.show(classificationId, teamId);
            const teamsEndpoint = Api.requests.teams.show(teamId);

            const [classificationResponse, teamsResponse] = yield all([
                yield call(fetchRequest, classificationEndpoint),
                yield call(fetchRequest, teamsEndpoint),
            ])

            const closingDate = classificationResponse.data.data.closing_date;
            const reviews = classificationResponse.data.data.reviews.data;
            const players = teamsResponse.data.data.players.data;
            const team = teamsResponse.data.data.name;

            yield put(CLASSIFICATIONS.TEAMS.SHOW.success({
                id: classificationId,
                teamId,
                closingDate,
                players,
                reviews,
                team,
            }));
        } catch (error) {
            yield put(CLASSIFICATIONS.TEAMS.SHOW.failure());
        } finally {
            yield put(CLASSIFICATIONS.TEAMS.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        showFlow(),
    ]);
}