import { AUTH, EXERCISES } from '../constants';
import { deleteObjectWithIdFromArray, findIdAndUpdateObjectInArray } from 'utilities/array';

const INITIAL_STATE = {
    exercises: [],
    educationPlanExercises: [],
    latest: [],
    active: null,
    fetched: false,
    educationPlanFetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const exerciseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXERCISES.LATEST.TRIGGER:
        case EXERCISES.FETCH.TRIGGER:
        case EXERCISES.EDUCATION_PLAN.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case EXERCISES.FETCH.SUCCESS:
            return {
                ...state,
                exercises: action.payload,
                fetched: true,
            };

        case EXERCISES.EDUCATION_PLAN.SUCCESS:
            return {
                ...state,
                educationPlanExercises: action.payload,
                educationPlanFetched: true,
            };

        case EXERCISES.LATEST.SUCCESS:
            return {
                ...state,
                latest: action.payload,
                fetched: true,
            };

        case EXERCISES.LATEST.FAILURE:
        case EXERCISES.FETCH.FAILURE:
        case EXERCISES.EDUCATION_PLAN.FAILURE:
            return {
                ...state,
                error: true
            }

        case EXERCISES.LATEST.FULFILL:
        case EXERCISES.FETCH.FULFILL:
        case EXERCISES.EDUCATION_PLAN.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case EXERCISES.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                created: false,
            }

        case EXERCISES.CREATE.SUCCESS:
            return {
                ...state,
                exercises: state.exercises.concat(action.payload),
                created: action.payload.id,
            };

        case EXERCISES.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case EXERCISES.CREATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case EXERCISES.SHOW.TRIGGER:
            return {
                ...state,
                error: false,
            }

        case EXERCISES.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case EXERCISES.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case EXERCISES.SHOW.FULFILL:
            return {
                ...state,
                error: false,
            }

        case EXERCISES.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
            }

        case EXERCISES.UPDATE.SUCCESS:
            return {
                ...state,
                exercises: findIdAndUpdateObjectInArray([...state.exercises], action.payload.id, action.payload),
                updated: true,
            };

        case EXERCISES.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case EXERCISES.UPDATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case EXERCISES.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                deleted: false,
            }

        case EXERCISES.DELETE.SUCCESS:
            return {
                ...state,
                exercises: deleteObjectWithIdFromArray([...state.exercises], action.payload),
                deleted: true,
            };

        case EXERCISES.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case EXERCISES.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case EXERCISES.LABEL.UPDATE.TRIGGER:
        case EXERCISES.TAG.UPDATE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case EXERCISES.LABEL.UPDATE.SUCCESS:
        case EXERCISES.TAG.UPDATE.SUCCESS:
            return {
                ...state,
                exercises: findIdAndUpdateObjectInArray([...state.exercises], action.payload.id, action.payload)
            }

        case EXERCISES.LABEL.UPDATE.FAILURE:
        case EXERCISES.TAG.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case EXERCISES.LABEL.DELETE.TRIGGER:
        case EXERCISES.TAG.DELETE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case EXERCISES.LABEL.DELETE.SUCCESS:
        case EXERCISES.TAG.DELETE.SUCCESS:
            return {
                ...state,
                exercises: findIdAndUpdateObjectInArray([...state.exercises], action.payload.id, action.payload)
            }

        case EXERCISES.LABEL.DELETE.FAILURE:
        case EXERCISES.TAG.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case EXERCISES.CLEAN:
            return {
                ...state,
                error: null,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default exerciseReducer;