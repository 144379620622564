import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './TrainersShow.styles';
import ValueBlock from 'components/ValueBlock';
import { INTERNAL_TRAINERS } from 'store/constants';
import { toast } from 'react-toastify';

const TrainersShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetched = useSelector(state => state.internalTrainer.fetched);
    const loading = useSelector(state => state.internalTrainer.loading);
    const classes = styles();

    const trainers = useSelector(state => state.internalTrainer.internalTrainers);
    const trainer = trainers.filter(object => object.id === parseInt(params.trainerId))[0];
    const name = trainer ? trainer.user.data.full_name : '';
    const nameLabel = useIntl().formatMessage({ id: 'trainers.label.name' });
    const rate = trainer ? trainer.rate : 0;
    const rateLabel = useIntl().formatMessage({ id: 'trainers.label.rate' });
    const email = trainer ? trainer.user.data.email : 0;
    const emailLabel = useIntl().formatMessage({ id: 'trainers.label.email' });
    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(INTERNAL_TRAINERS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: INTERNAL_TRAINERS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ name } backButton={ true }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ nameLabel } value={ name } />
                            <ValueBlock label={ emailLabel } value={ email } />
                            <ValueBlock label={ rateLabel } value={ rate } />
                        </Paper>
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default TrainersShow;
