import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';

import styles from './ExerciseChips.styles';

const ExerciseChips = props => {
    const { exercises, onDelete } = props;
    const classes = styles();

    const renderChips = () => {
        let increment = 0;

        return exercises.map(exercise => {
            increment++;

            const label = (
                <div>
                    { exercise.title }
                    { exercise.internal_trainer ? <strong> - { exercise.internal_trainer }</strong> : null }
                </div>
            );

            return <Chip key={ `exercise-chip-${increment}` } label={ label } onDelete={ () => onDelete(exercise) } color="primary" />
        })
    }

    return (
        <div className={ classes.root }>
            { renderChips() }
        </div>
    );
};

ExerciseChips.propTypes = {
    exercises: PropTypes.array.isRequired,
    onDelete: PropTypes.func,
};

export default ExerciseChips;
