import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { images } from 'config/images';
import { logout } from 'store/actions/AuthActions';

const AppsCatchAll = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const data = {
        hcr: {
            name: 'HC Rotterdam',
            playerAppAndroid: 'https://play.google.com/store/apps/details?id=com.hcrotterdam.speler',
            playerAppIos: 'https://apps.apple.com/nl/app/hcr-speler/id1581592991',
            teamAppAndroid: 'https://play.google.com/store/apps/details?id=com.hcrotterdam.trainercoach',
            teamAppIos: 'https://apps.apple.com/nl/app/hcr-team/id1581598018',
        },
        hudito: {
            name: 'Hudito',
            playerAppAndroid: 'https://play.google.com/store/apps/details?id=nl.hudito.speler',
            playerAppIos: 'https://apps.apple.com/nl/app/hudito-speler/id1581593015',
            teamAppAndroid: 'https://play.google.com/store/apps/details?id=nl.hudito.trainercoach',
            teamAppIos: 'https://apps.apple.com/nl/app/hudito-team/id1581600327',
        },
        go4hockey: {
            name: 'Go4Hockey',
            playerAppAndroid: 'https://play.google.com/store/apps/details?id=nl.go4hockey.speler',
            playerAppIos: 'https://apps.apple.com/nl/app/go4hockey-speler/id1516669704',
            teamAppAndroid: 'https://play.google.com/store/apps/details?id=nl.go4hockey.trainercoach',
            teamAppIos: 'https://apps.apple.com/nl/app/go4hockey-team/id1516669677',
        },
    }

    let club = params.club || 'go4hockey';

    if (! data.hasOwnProperty(club)) {
        club = 'go4hockey';
    }

    const clubData = data[club];

    useEffect(() => {
        dispatch(logout());
    });

    return (
        <section className="apps">
            <div className="container-fluid gap-both content-padding">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xm-10 col-xs-12">
                        <div className="card">
                            <img src={ images.homepage.solutions.playerApp.default } className="card-img-top" alt="Go4Hockey Speler app" />
                            <div className="card-body">
                                <h4 className="card-title">{ clubData.name } Speler app</h4>
                                <p className="card-text">Sta in verbinding met je team en bekijk jouw ontwikkeling.
                                </p>

                                <article className="app-list">
                                    <a href={ clubData.playerAppAndroid } target="_blank" rel="noopener noreferrer">
                                        <img src={ images.homepage.stores.googlePlay.default } className="img-fluid" alt="Google Play" />
                                    </a>
                                    <a href={ clubData.playerAppIos } target="_blank" rel="noopener noreferrer">
                                        <img src={ images.homepage.stores.appStore.default } className="img-fluid" alt="Apple AppStore" />
                                    </a>
                                </article>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xm-10 col-xs-12">
                        <div className="card">
                            <img src={ images.homepage.solutions.trainerApp.default } className="card-img-top" alt="Go4Hockey Team app" />
                            <div className="card-body">
                                <h4 className="card-title">{ clubData.name } Team app</h4>
                                <p className="card-text">Een app voor trainers en coaches om teams te begeleiden.</p>

                                <article className="app-list">
                                    <a href={ clubData.teamAppAndroid } target="_blank" rel="noopener noreferrer">
                                        <img src={ images.homepage.stores.googlePlay.default } className="img-fluid" alt="Google Play" />
                                    </a>
                                    <a href={ clubData.teamAppIos } target="_blank" rel="noopener noreferrer">
                                        <img src={ images.homepage.stores.appStore.default } className="img-fluid" alt="Apple AppStore" />
                                    </a>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppsCatchAll;