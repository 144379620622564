import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
    CLUB,
    EVENT,
    EVENT_TYPES,
} from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import EventsTable from 'contents/tables/EventsTable';

const EventOverview = () => {
    const dispatch = useDispatch();
    const events = useSelector(state => state.event.events);
    const eventTypes = useSelector(state => state.eventType.types);
    const clubs = useSelector(state => state.club.clubs);
    const fetched = useSelector(state => state.event.fetched);
    const created = useSelector(state => state.event.created);
    const error = useSelector(state => state.event.error);
    const deleted = useSelector(state => state.event.deleted);

    const onCreate = data => {
        return new Promise((resolve) => {
            dispatch(EVENT.CREATE.trigger(data));

            resolve();
        })
    }

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(EVENT.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Het event is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (deleted) {
            toast.success('Het event is verwijderd');
        }
    }, [deleted]);

    useEffect(() => {
        dispatch(EVENT.FETCH.trigger());
        dispatch(EVENT_TYPES.FETCH.trigger());
        dispatch(CLUB.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title="Events">
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <EventsTable
                            data={ events }
                            clubs={ clubs }
                            eventTypes={ eventTypes }
                            onCreate={ onCreate }
                            onDelete={ onRowDelete }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default EventOverview;
