import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

const TextInput = ({ field, ...props }) => {
    const { label, lines, type, errorMessage, touched, disabled, variant, className } = props;
    const { name, value, onChange, onBlur } = field;

    return (
        <TextField
            fullWidth
            name={ name }
            error={ touched && errorMessage ? true : false }
            label={ label }
            helperText={ touched && errorMessage ? errorMessage : undefined }
            onChange={ onChange }
            onBlur={ onBlur }
            value={ value }
            multiline={ lines > 0 }
            rowsMax={ lines }
            type={ type ?? 'text' }
            disabled={ disabled }
            variant={ variant }
            className={ className }
        />
    );
};

TextInput.propTypes = {
    field: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    touched: PropTypes.bool,
    lines: PropTypes.number,
};

export default TextInput;
