import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import NewsForm from 'components/NewsForm';
import styles from './NewsEdit.styles';
import { NEWS } from 'store/constants';

const NewsEdit = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const params = useParams();
    const fetched = useSelector(state => state.news.fetched);
    const loading = useSelector(state => state.news.loading);

    const error = useSelector(state => state.news.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.news.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'news.update.loading' });

    const news = useSelector(state => state.news.news);
    const newsItem = news.filter(object => object.id === parseInt(params.newsId))[0];

    const pageTitle = useIntl().formatMessage({ id: 'news.update.title' });
    const titleLabel = useIntl().formatMessage({ id: 'news.label.title' });
    const messageLabel = useIntl().formatMessage({ id: 'news.label.message' });
    const imageLabel = useIntl().formatMessage({ id: 'news.label.image' });
    const submitButtonText = useIntl().formatMessage({ id: 'news.button.update' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const getInitialValues = () => {
        if (newsItem) {
            return {
                title: newsItem.title,
                message: newsItem.message,
            }
        }

        return {};
    }

    const onSubmit = values => {
        dispatch(NEWS.UPDATE.trigger({
            id: params.newsId,
            values
        }));

        return true
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(NEWS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: NEWS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <NewsForm
                                initialValues={ getInitialValues() }
                                titleLabel={ titleLabel }
                                messageLabel={ messageLabel }
                                imageLabel={ imageLabel }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidFileSizeMessage={ invalidFileSizeMessage }
                                invalidFileTypeMessage={ invalidFileTypeMessage}
                                onSubmit={ onSubmit }
                                editMode
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ loading } message={ fetched ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default NewsEdit;
