import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import styles from './ImageButton.styles';

const ImageButton = props => {
    const { alt, onDragStart, src } = props;
    const classes = styles();

    return (
        <Paper elevation={ 2 } className={ classes.root } onClick={ onDragStart }>
            <img src={ src } alt={ alt } className={ classes.icon } onDragStart={ onDragStart } />
        </Paper>
    );
};

ImageButton.propTypes = {
    alt: PropTypes.string,
    onDragStart: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
};

export default ImageButton;