import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    block: {
        padding: theme.spacing(2),
    },

    link: {
        textDecoration: 'none',
        color: '#333333',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

export default styles;