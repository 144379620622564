import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { MATCHES } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, post, postFile } from 'utilities/api';
import { deleteObjectWithIdFromArray } from 'utilities/array';

export const getToken = (state) => state.persist.accessToken;
export const getMatch = (state) => state.match.active;

export function* fetchAllRequest(teamId) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.matches.index(teamId));
}

export function* fetchOneRequest(teamId, matchId) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.matches.show(teamId, matchId));
}

export function* createRequest(teamId, payload) {
    const token = yield select(getToken);

    return yield post(token, Api.requests.matches.store(teamId), payload);
}

export function* editRequest(teamId, matchId, payload, headers = {}) {
    const token = yield select(getToken);

    return yield patch(token, Api.requests.matches.update(teamId, matchId), payload, headers);
}

export function* deleteRequest(teamId, matchId) {
    const token = yield select(getToken);

    return yield deletePost(token, Api.requests.matches.delete(teamId, matchId));
}

export function* saveLineupRequest(payload) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield post(token, Api.requests.lineup.store(match.id), payload);
}

export function* saveTacticRequest(payload) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield postFile(token, Api.requests.tactic.store(match.id), payload);
}

export function* saveHighlightRequest(payload) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield post(token, Api.requests.highlights.store(match.id), payload);
}

export function* deleteHighlightRequest(highlightId) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield deletePost(token, Api.requests.highlights.delete(match.id, highlightId));
}

export function* saveReportRequest(payload) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield post(token, Api.requests.report.store(match.id), payload);
}

export function* saveRatingsRequest(payload) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield post(token, Api.requests.ratings.store(match.id), payload);
}

export function* savePhotoRequest(payload) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield postFile(token, Api.requests.photos.store(match.id), payload);
}

export function* deletePhotoRequest(photoId) {
    const token = yield select(getToken);
    const match = yield select(getMatch);

    return yield deletePost(token, Api.requests.photos.delete(match.id, photoId));
}

export function* fetchFlow() {
    while (true) {
        const request = yield take(MATCHES.FETCH.TRIGGER);

        try {
            const response = yield call(fetchAllRequest, request.payload.teamId);
            const match = response.data.data;

            yield put(MATCHES.FETCH.success(match));
        } catch (error) {
            yield put(MATCHES.FETCH.failure());
        } finally {
            yield put(MATCHES.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(MATCHES.SHOW.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const matchId = request.payload.matchId;
            const response = yield call(fetchOneRequest, teamId, matchId);

            yield put(MATCHES.SHOW.success(response.data.data));
        } catch (error) {
            yield put(MATCHES.SHOW.failure());
        } finally {
            yield put(MATCHES.SHOW.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(MATCHES.CREATE.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const formData = request.payload.values;
            const response = yield call(createRequest, teamId, formData);

            yield put(MATCHES.CREATE.success(response.data.data));
        } catch (error) {
            yield put(MATCHES.CREATE.failure());
        } finally {
            yield put(MATCHES.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(MATCHES.UPDATE.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const matchId = request.payload.matchId;
            const formData = request.payload.values;
            const response = yield call(editRequest, teamId, matchId, formData);

            yield put(MATCHES.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(MATCHES.UPDATE.failure());
        } finally {
            yield put(MATCHES.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(MATCHES.DELETE.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const matchId = request.payload.matchId;

            yield call(deleteRequest, teamId, matchId);

            yield put(MATCHES.DELETE.success(matchId));
        } catch (error) {
            yield put(MATCHES.DELETE.failure());
        } finally {
            yield put(MATCHES.DELETE.fulfill());
        }
    }
}

export function* storeLineup() {
    while (true) {
        const request = yield take(MATCHES.LINEUP.CREATE.TRIGGER);

        try {
            const response = yield call(saveLineupRequest, request.payload);
            const lineup = response.data.data;

            const match = {
                ...yield select(getMatch)
            };
            match.lineup.data = lineup;

            yield put(MATCHES.LINEUP.CREATE.success(match));
        } catch (error) {
            yield put(MATCHES.LINEUP.CREATE.failure());
        } finally {
            yield put(MATCHES.LINEUP.CREATE.fulfill());
        }
    }
}

export function* storeTactic() {
    while (true) {
        const request = yield take(MATCHES.TACTIC.CREATE.TRIGGER);

        try {
            const formData = new FormData();
            formData.append('tactic', request.payload.tactic);

            if (request.payload.image) {
                formData.append('image', request.payload.image);
            }

            const response = yield call(saveTacticRequest, formData);
            const tactic = response.data.data;

            let match = {
                ...yield select(getMatch),
                tactic: {
                    data: tactic
                }
            };

            yield put(MATCHES.TACTIC.CREATE.success(match));
        } catch (error) {
            yield put(MATCHES.TACTIC.CREATE.failure());
        } finally {
            yield put(MATCHES.TACTIC.CREATE.fulfill());
        }
    }
}

export function* storeHighlight() {
    while (true) {
        const request = yield take(MATCHES.HIGHLIGHT.CREATE.TRIGGER);

        try {
            const response = yield call(saveHighlightRequest, request.payload);
            const highlight = response.data.data;

            const match = {
                ...yield select(getMatch)
            };
            match.highlights.data = match.highlights.data.concat(highlight);

            yield put(MATCHES.HIGHLIGHT.CREATE.success(match));
        } catch (error) {
            yield put(MATCHES.HIGHLIGHT.CREATE.failure());
        } finally {
            yield put(MATCHES.HIGHLIGHT.CREATE.fulfill());
        }
    }
}

export function* deleteHighlight() {
    while (true) {
        const request = yield take(MATCHES.HIGHLIGHT.DELETE.TRIGGER);

        try {
            yield call(deleteHighlightRequest, request.payload);

            const match = {
                ...yield select(getMatch)
            };
            match.highlights.data = deleteObjectWithIdFromArray(match.highlights.data, request.payload);

            yield put(MATCHES.HIGHLIGHT.DELETE.success(match));
        } catch (error) {
            yield put(MATCHES.HIGHLIGHT.DELETE.failure());
        } finally {
            yield put(MATCHES.HIGHLIGHT.DELETE.fulfill());
        }
    }
}

export function* storeReport() {
    while (true) {
        const request = yield take(MATCHES.REPORT.CREATE.TRIGGER);

        try {
            const response = yield call(saveReportRequest, request.payload);
            const report = response.data.data;

            let match = {
                ...yield select(getMatch),
                report: {
                    data: report
                }
            };

            yield put(MATCHES.REPORT.CREATE.success(match));
        } catch (error) {
            yield put(MATCHES.REPORT.CREATE.failure());
        } finally {
            yield put(MATCHES.REPORT.CREATE.fulfill());
        }
    }
}

export function* storeRatings() {
    while (true) {
        const request = yield take(MATCHES.RATING.CREATE.TRIGGER);

        try {
            const response = yield call(saveRatingsRequest, request.payload);
            const ratings = response.data.data;

            const match = {
                ...yield select(getMatch)
            };
            match.ratings.data = ratings;

            yield put(MATCHES.RATING.CREATE.success(match));
        } catch (error) {
            yield put(MATCHES.RATING.CREATE.failure());
        } finally {
            yield put(MATCHES.RATING.CREATE.fulfill());
        }
    }
}

export function* storePhoto() {
    while (true) {
        const request = yield take(MATCHES.PHOTO.CREATE.TRIGGER);

        try {
            const formData = new FormData();
            formData.append('image', request.payload);

            const response = yield call(savePhotoRequest, formData);
            const photo = response.data.data;

            const match = {
                ...yield select(getMatch)
            };
            match.photos.data = match.photos.data.concat(photo);

            yield put(MATCHES.PHOTO.CREATE.success(match));
        } catch (error) {
            yield put(MATCHES.PHOTO.CREATE.failure());
        } finally {
            yield put(MATCHES.PHOTO.CREATE.fulfill());
        }
    }
}

export function* deletePhoto() {
    while (true) {
        const request = yield take(MATCHES.PHOTO.DELETE.TRIGGER);

        try {
            yield call(deletePhotoRequest, request.payload);

            const match = {
                ...yield select(getMatch)
            };
            match.photos.data = deleteObjectWithIdFromArray(match.photos.data, request.payload);

            yield put(MATCHES.PHOTO.DELETE.success(match));
        } catch (error) {
            yield put(MATCHES.PHOTO.DELETE.failure());
        } finally {
            yield put(MATCHES.PHOTO.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        storeLineup(),
        storeTactic(),
        storeHighlight(),
        deleteHighlight(),
        storeReport(),
        storeRatings(),
        storePhoto(),
        deletePhoto(),
    ]);
}