import Paper from '@material-ui/core/Paper';

import styles from './ContentBox.styles';

const ContentBox = props => {
    const classes = styles();
    const { children } = props;

    return (
        <Paper elevation={ 2 } className={ classes.container }>
            { children }
        </Paper>
    );
};

export default ContentBox;