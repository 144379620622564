import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const TeamPlayersTable = props => {
    const { data, onDelete } = props;
    const history = useHistory();

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Naam',
        },
        {
            field: 'club_player_id',
            title: 'Gekoppeld door club',
            type: 'boolean',
        },
    ];

    const actions = [
        rowData => ({
            disabled: ! rowData.club_player_id,
            icon: 'list_alt',
            onClick: (event, rowData) => history.push(`/club-players/${rowData.club_player_id}/assessments`),
            tooltip: 'Evaluaties',
        }),
    ];

    const editable = {
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Spelers"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

TeamPlayersTable.propTypes = {
    data: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default TeamPlayersTable;