import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import styles from './LoginForm.styles';
import TextInput from '../TextInput';

const LoginForm = props => {
    const {
        usernameLabel,
        passwordLabel,
        passwordRecoveryLabel,
        emailFieldMessage,
        requiredFieldMessage,
        submitButtonText,
        passwordRecoveryTarget,
        onSubmit,
    } = props;

    const classes = styles();

    const initialValues = {
        username: '',
        password: '',
    };

    const validationSchemaObject = yup.object().shape({
        username: yup.string().email(emailFieldMessage).required(requiredFieldMessage),
        password: yup.string(),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'username' }
                            label={ usernameLabel }
                            component={ TextInput }
                            errorMessage={ errors['username'] }
                            touched={ touched['username'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            variant="outlined"
                            className={ classes.input }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Box mb={ 1 } className={ classes.forgotPassword }>
                            <Link to={ passwordRecoveryTarget }>{ passwordRecoveryLabel }</Link>
                        </Box>

                        <Field
                            name={ 'password' }
                            type={ 'password' }
                            label={ passwordLabel }
                            component={ TextInput }
                            errorMessage={ errors['password'] }
                            touched={ touched['password'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            variant="outlined"
                            className={ classes.input }
                        />
                    </Box>

                    <Box mt={ 3 }>
                        <Button
                            type="submit"
                            size="large"
                            disabled={ isSubmitting }
                            color={ 'primary' }
                            variant={ 'contained' }
                            className={ classes.button }
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

LoginForm.propTypes = {
    usernameLabel: PropTypes.string,
    passwordLabel: PropTypes.string,
    passwordRecoveryLabel: PropTypes.string,
    emailFieldMessage: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    submitButtonText: PropTypes.string,
    passwordRecoveryTarget: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    passwordRecoveryLabel: 'Forgot password?',
    emailFieldMessage: 'Invalid e-mail',
    requiredFieldMessage: 'Required',
    passwordRecoveryTarget: '/',
    submitButtonText: 'Submit',
};

export default LoginForm;


