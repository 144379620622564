import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClassificationDetailBlock from 'contents/blocks/ClassificationDetailBlock';
import ClassificationTeamsBlock from 'contents/blocks/ClassificationTeamsBlock';
import { CLASSIFICATIONS } from 'store/constants';

const ClassificationShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const classificationId = parseInt(params.classificationId);
    const classification = useSelector(state => state.classification.active);
    const error = useSelector(state => state.classification.error);
    const teams = classification?.teams?.data || [];
    const fetched = classification?.id === classificationId;

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (! fetched) {
            dispatch(CLASSIFICATIONS.SHOW.trigger(classificationId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title="Teambeoordeling bekijken" backButton={ true }>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <ClassificationDetailBlock classification={ classification } />
                    </Grid>

                    <Grid item xs={ 6 }>
                        <ClassificationTeamsBlock
                            classificationId={ classificationId }
                            teams={ teams }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClassificationShow;