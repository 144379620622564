import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const SelectInput = ({ field, ...props }) => {
    const { label, errorMessage, touched, options } = props;
    const { name, value, onChange, onBlur } = field;
    const renderOptions = options.map( option => <MenuItem key={ option.value } value={ option.value }>{ option.label }</MenuItem> );

    return (
        <FormControl fullWidth>
            <InputLabel id={ `select-input-${name}`}>{ label }</InputLabel>

            <Select
                value={ value ?? '' }
                error={ !! (touched && errorMessage) }
                onChange={ onChange(name) }
                onBlur={ onBlur(name) }
            >
                { renderOptions }
            </Select>

            { touched && errorMessage ? (
                <FormHelperText error={ true }>{ errorMessage  }</FormHelperText>
            ) : undefined }
        </FormControl>
    );
};

SelectInput.propTypes = {
    field: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    touched: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

SelectInput.defaultProps = {
    options: [],
}

export default SelectInput;
