import FullScreenBackground from 'components/FullScreenBackground';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';

import { images } from 'config/images';
import styles from './NotFoundUnauthenticated.styles';

const NotFoundUnauthenticated = () => {
    const classes = styles();
    const heading = useIntl().formatMessage({ id: '404.heading' });
    const subheading = useIntl().formatMessage({ id: '404.subheading' });

    return (
        <FullScreenBackground image={ images.background.login.default }>
            <Box className={ classes.content }>
                <Typography variant="h3" component="h1" color="primary" gutterBottom>
                    { heading }
                </Typography>
                <Typography variant="h5" component="h2" color="primary" gutterBottom>
                    { subheading }
                </Typography>
            </Box>
        </FullScreenBackground>
    );
};

export default NotFoundUnauthenticated;
