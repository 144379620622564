import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { USER } from '../constants';
import {
    fetchRequest,
    storeRequest,
    updateRequest,
} from '../api';

export function* showFlow() {
    while (true) {
        const request = yield take(USER.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.users.show(id);
            const response = yield call(fetchRequest, endpoint);

            yield put(USER.SHOW.success(response.data.data));
        } catch (error) {
            yield put(USER.SHOW.failure());
        } finally {
            yield put(USER.SHOW.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(USER.UPDATE.TRIGGER);

        try {
            const userId = request.payload.userId;
            const endpoint = Api.requests.users.update(userId);
            const payload = request.payload.values;
            const response = yield call(updateRequest, endpoint, payload);

            yield put(USER.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(USER.UPDATE.failure());
        } finally {
            yield put(USER.UPDATE.fulfill());
        }
    }
}

export function* searchFlow() {
    while (true) {
        const request = yield take(USER.SEARCH.TRIGGER);

        try {
            const payload = request.payload;
            const endpoint = Api.requests.users.search;
            const response = yield call(storeRequest, endpoint, payload);

            yield put(USER.SEARCH.success(response.data.data));
        } catch (error) {
            yield put(USER.SEARCH.failure());
        } finally {
            yield put(USER.SEARCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        showFlow(),
        updateFlow(),
        searchFlow(),
    ]);
}