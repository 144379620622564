import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PasswordResetFormStyles from './PasswordResetForm.styles';
import TextInput from '../TextInput';
import PropTypes from 'prop-types';

const PasswordResetForm = props => {
    const {
        equalFieldMessage,
        onSubmit,
        passwordLabel,
        repeatPasswordLabel,
        minimumLengthMessage,
        requiredFieldMessage,
        submitButtonText,
    } = props;
    const classes = PasswordResetFormStyles();

    const initialValues = {
        password: '',
        password_confirmation: '',
    };

    const validationSchemaObject = yup.object().shape({
        password: yup.string().required(requiredFieldMessage)
            .test('len', minimumLengthMessage, val => {
                if (val) {
                    return val.length >= 8;
                }

                return false;
            }),
        password_confirmation: yup.string().required(requiredFieldMessage)
            .oneOf([yup.ref('password'), null], equalFieldMessage)
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form className={classes.root} noValidate autoComplete="off" onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'password' }
                            type={ 'password' }
                            label={ passwordLabel }
                            component={ TextInput }
                            errorMessage={ errors['password'] }
                            touched={ touched['password'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            variant="outlined"
                            className={ classes.input }
                        />
                    </Box>

                    <Box mt={ 3 }>
                        <Field
                            name={ 'password_confirmation' }
                            type={ 'password' }
                            label={ repeatPasswordLabel }
                            component={ TextInput }
                            errorMessage={ errors['password_confirmation'] }
                            touched={ touched['password_confirmation'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            variant="outlined"
                            className={ classes.input }
                        />
                    </Box>

                    <Box mt={ 3 }>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            className={ classes.button }
                            disabled={ isSubmitting }
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

PasswordResetForm.propTypes = {
    passwordLabel: PropTypes.string,
    passwordConfirmationLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    minimumLengthMessage: PropTypes.string,
    equalFieldMessage: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
};

PasswordResetForm.defaultProps = {
    passwordLabel: 'Password',
    passwordConfirmationLabel: 'Repeat password',
    submitButtonText: 'Submit',
    minimumLengthMessage: 'Minimum characters not reached',
    equalFieldMessage: 'Fields should be equal',
    requiredFieldMessage: 'Required',
};

export default PasswordResetForm;
