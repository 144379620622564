import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const ClubsTable = props => {
    const { data, onCreate, onDelete, onUpdate } = props;
    const history = useHistory();

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Club',
            validate: rowData => !! rowData?.name,
        },
        {
            editable: 'never',
            field: 'key',
            title: 'Kenmerk',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'address'),
            field: 'address',
            title: 'Adres',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'postal'),
            field: 'postal',
            title: 'Postcode',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'city'),
            field: 'city',
            title: 'Plaats',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'country'),
            field: 'country',
            title: 'Land',
        },
    ];

    const actions = [
        {
            icon: 'people',
            tooltip: 'Gebruikers',
            onClick: (event, rowData) => history.push(`/clubs/${rowData.id}/users`)
        },
        {
            icon: 'lock_open',
            tooltip: 'Toegangsverzoeken',
            onClick: (event, rowData) => history.push(`/clubs/${rowData.id}/access-requests`)
        },
        {
            icon: 'supervised_user_circle',
            tooltip: 'Teams',
            onClick: (event, rowData) => history.push(`/clubs/${rowData.id}/teams`)
        },
        {
            icon: 'people_outline',
            tooltip: 'Spelers',
            onClick: (event, rowData) => history.push(`/clubs/${rowData.id}/players`)
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Clubs"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

ClubsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ClubsTable;