import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { FEEDBACK } from 'store/constants';
import { reverseToReadableDate } from 'utilities/date';

const Feedback = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.feedback.fetched);
    const feedback = useSelector(state => state.feedback.feedback);
    const loading = useSelector(state => state.feedback.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'feedback.title' });
    const error = useSelector(state => state.feedback.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.feedback.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });
    const unpublishedLabel = useIntl().formatMessage({ id: 'feedback.label.unpublished' });


    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'feedback.label.player' }),
            field: 'player.data.full_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'player.data.full_name'),
        },
        {
            title: useIntl().formatMessage({ id: 'feedback.label.published' }),
            field: 'published_at',
            render: rowData => rowData.published_at ? reverseToReadableDate(rowData.published_at) : unpublishedLabel,
            customSort: (a, b) => sortCaseInsensitive(a, b, 'published_at'),
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/players/${rowData.player.data.id}/feedback/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`feedback/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = data => {
        return new Promise((resolve, reject) => {
            const playerId = data.player.data.id;
            const feedbackId = data.id;

            dispatch(FEEDBACK.DELETE.trigger({
                playerId,
                feedbackId,
            }));

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(FEEDBACK.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: FEEDBACK.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 9 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ feedback }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default Feedback;
