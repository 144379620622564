import { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import EventDatesBlock from 'contents/blocks/EventDatesBlock';
import EventDetailsBlock from 'contents/blocks/EventDetailsBlock';
import EventPlayersBlock from 'contents/blocks/EventPlayersBlock';
import EventTrainersBlock from 'contents/blocks/EventTrainersBlock';
import EventTrainingsBlock from 'contents/blocks/EventTrainingsBlock';

import {
    CLUB,
    EVENT,
    EVENT_TYPES,
    INTERNAL_TRAINERS,
    TRAININGS
} from 'store/constants';
import styles from './EventShow.styles';

const EventShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const classes = styles();

    const eventId = parseInt(params.eventId);
    const event = useSelector(state => state.event.active);
    const eventTypes = useSelector(state => state.eventType.types);
    const clubs = useSelector(state => state.club.clubs);
    const trainers = useSelector(state => state.internalTrainer.internalTrainers);
    const trainings = useSelector(state => state.training.trainings);
    const fetchedEvent = event?.id === eventId;
    const fetchedEventTypes = useSelector(state => state.eventType.fetched);
    const fetchedClubs = useSelector(state => state.club.fetched);
    const fetchedTrainers = useSelector(state => state.internalTrainer.fetched);
    const fetchedTrainings = useSelector(state => state.training.fetched);
    const dates = event?.dates?.data || [];
    const players = event?.players?.data || [];
    const activeTrainers = event?.trainers?.data || [];
    const activeTrainings = event?.trainings?.data || [];
    const error = useSelector(state => state.event.error);

    const updateEvent = values => {
        dispatch(EVENT.UPDATE.trigger({
            id: eventId,
            values,
        }));

        return true
    };

    const addDate = values => {
        dispatch(EVENT.DATE.UPDATE.trigger({
            eventId,
            values,
        }));

        return true;
    }

    const removeDate = dateId => {
        dispatch(EVENT.DATE.DELETE.trigger({
            dateId,
            eventId,
        }));

        return true;
    }

    const addTrainer = values => {
        dispatch(EVENT.TRAINER.UPDATE.trigger({
            eventId,
            trainerId: values.selected_id,
        }));

        return true;
    }

    const removeTrainer = trainerId => {
        dispatch(EVENT.TRAINER.DELETE.trigger({
            trainerId,
            eventId,
        }));

        return true;
    }

    const addTraining = values => {
        dispatch(EVENT.TRAINING.UPDATE.trigger({
            eventId,
            trainingId: values.selected_id,
        }));

        return true;
    }

    const removeTraining = trainingId => {
        dispatch(EVENT.TRAINING.DELETE.trigger({
            trainingId,
            eventId,
        }));

        return true;
    }

    const removePlayer = playerId => {
        dispatch(EVENT.PLAYER.DELETE.trigger({
            playerId,
            eventId,
        }));

        return true;
    }

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (fetchedEvent === false) {
            dispatch(EVENT.SHOW.trigger(eventId));
        }

        if (fetchedEventTypes === false) {
            dispatch(EVENT_TYPES.FETCH.trigger());
        }

        if (fetchedClubs === false) {
            dispatch(CLUB.FETCH.trigger());
        }

        if (fetchedTrainers === false) {
            dispatch(INTERNAL_TRAINERS.FETCH.trigger());
        }

        if (fetchedTrainings === false) {
            dispatch(TRAININGS.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    return (
        <ContentContainer title={ event?.name || 'Event bekijken' } backButton>
            { fetchedEvent ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <EventDetailsBlock
                            clubs={ clubs }
                            event={ event }
                            eventTypes={ eventTypes }
                            onSubmit={ updateEvent }
                        />

                        <Box mt={ 2 }>
                            <EventDatesBlock
                                onAdd={ addDate }
                                onDelete={ removeDate }
                                dates={ dates }
                            />
                        </Box>

                        <Box mt={ 2 }>
                            <EventTrainersBlock
                                onAdd={ addTrainer }
                                onDelete={ removeTrainer }
                                trainers={ trainers }
                                activeTrainers={ activeTrainers }
                            />
                        </Box>

                        <Box mt={ 2 }>
                            <EventTrainingsBlock
                                onAdd={ addTraining }
                                onDelete={ removeTraining }
                                trainings={ trainings }
                                activeTrainings={ activeTrainings }
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <img src={ event?.image || '' } alt={ event?.name } className="responsive-image" />
                        </Paper>

                        <Box mt={ 2 }>
                            <EventPlayersBlock
                                onDelete={ removePlayer }
                                players={ players }
                            />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default EventShow;
