import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/TextInput';

const PlayerForm = props => {
    const {
        initialValues,
        onSubmit,
        submitButtonText,
    } = props;

    const validationSchemaObject = yup.object().shape({
        first_name: yup
            .string()
            .required('Ongeldige voornaam'),
        last_name: yup
            .string()
            .required('Ongeldige achternaam'),
        club: yup
            .string()
            .optional(),
        team: yup
            .string()
            .optional(),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
              }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['first_name'] }
                            label="Voornaam"
                            name="first_name"
                            onChange={ handleChange }
                            onBlur={ handleBlur['first_name'] }
                            touched={ touched['first_name'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['last_name'] }
                            label="Achternaam"
                            name="last_name"
                            onChange={ handleChange }
                            onBlur={ handleBlur['last_name'] }
                            touched={ touched['last_name'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['club'] }
                            label="Club"
                            name="club"
                            onChange={ handleChange }
                            onBlur={ handleBlur['club'] }
                            touched={ touched['club'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['team'] }
                            label="Team"
                            name="team"
                            onChange={ handleChange }
                            onBlur={ handleBlur['team'] }
                            touched={ touched['team'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

PlayerForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
};

PlayerForm.defaultProps = {
    initialValues: {
        first_name: '',
        last_name: '',
        club: '',
        team: '',
    },
    submitButtonText: 'Maak speler aan',
}

export default PlayerForm;
