import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import UserSearch from './UserSearch';
import UserShow from './UserShow';

const Users = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Redirect
                exact
                from={ path }
                to={ `${path}/search` }
            />

            <Route
                exact
                path={ `${path}/search` }
                component={ UserSearch }
            />
            <Route
                exact
                path={ `${path}/:userId` }
                component={ UserShow }
            />
        </Switch>
    );
};

export default Users;