import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}));

export default styles;