import { Dutch, English } from 'languages/material-table'

export const getMaterialTableTranslation = locale => {
    if (locale === 'en-US') {
        return English;
    }

    return Dutch;
};

export const defaultOptions = {
    actionsColumnIndex: -1,
    addRowPosition: 'first',
    emptyRowsWhenPaging: false,
    pageSize: 50,
    pageSizeOptions: [10, 25, 50, 100],
    sorting: true,
    exportButton: true,
    showTitle: false,
};

export const localization = {
    body: {
        emptyDataSourceMessage: 'Er zijn geen resultaten gevonden',
        addTooltip: 'Toevoegen',
        deleteTooltip: 'Verwijderen',
        editTooltip: 'Aanpassen',
        filterRow: {
            filterTooltip: 'Filteren',
        },
        editRow: {
            deleteText: 'Weet je zeker dat je deze rij wilt verwijderen?',
            cancelTooltip: 'Annuleren',
            saveTooltip: 'Opslaan',
        },
    },
    grouping: {
        placeholder: 'Sleep tabelkoppen ...',
    },
    header: {
        actions: 'Acties',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} van {count}',
        labelRowsSelect: 'rijen',
        labelRowsPerPage: 'Rijen per pagina:',
        firstAriaLabel: 'Eerste pagina',
        firstTooltip: 'Eerste pagina',
        previousAriaLabel: 'Vorige pagina',
        previousTooltip: 'Vorige pagina',
        nextAriaLabel: 'Volgende pagina',
        nextTooltip: 'Volgende pagina',
        lastAriaLabel: 'Laatste pagina',
        lastTooltip: 'Laatste pagina',
    },
    toolbar: {
        addRemoveColumns: 'Rijen toevoegen of verwijderen',
        nRowsSelected: '{0} rij(en) geselecteerd',
        showColumnsTitle: 'Toon kolommen',
        showColumnsAriaLabel: 'Toon kolommen',
        exportTitle: 'Exporteer',
        exportAriaLabel: 'Exporteer',
        exportName: 'Exporteer als CSV',
        searchTooltip: 'Zoeken',
        searchPlaceholder: 'Zoeken',
    },
};
