import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';

const PlayerDetails = props => {
    const { player } = props;

    return (
        <>
            <ValueBlock label="Voornaam"  value={ player?.first_name } />
            <ValueBlock label="Achternaam" value={ player?.last_name } />
            <ValueBlock label="Team" value={ player?.team } />
            <ValueBlock label="Club" value={ player?.club } />
        </>
    );
};

PlayerDetails.propTypes = {
    player: PropTypes.object.isRequired,
};

export default PlayerDetails;