import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import ClubPlayerAssessments from './ClubPlayerAssessments';

const ClubPLayers = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ `${path}/:clubPlayerId/assessments` }
                component={ ClubPlayerAssessments }
            />
        </Switch>
    );
};

export default ClubPLayers;