import PropTypes from 'prop-types';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';

import styles from './PaginationArrows.styles';

const PaginationArrows = props => {
    const classes = styles();
    const { children, next, previous } = props;

    return (
        <div className={ classes.container }>
            <IconButton
                disabled={ ! previous }
                color="primary"
                component="span"
                onClick={ previous }
            >
                <ArrowBack />
            </IconButton>

            { children }

            <IconButton
                disabled={ ! next }
                color="primary"
                component="span"
                onClick={ next }
            >
                <ArrowForward />
            </IconButton>
        </div>
    );
};

PaginationArrows.propTypes = {
    next: PropTypes.func,
    previous: PropTypes.func,
};

export default PaginationArrows;