import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import styles from './ValueRow.styles';

const ValueRow = props => {
    const { label, labelWidth, value } = props;
    const classes = styles({ labelWidth } );

    return (
        <Box className={ classes.container }>
            <Typography variant="body1" gutterBottom className={ classes.label }>
                { label }:
            </Typography>

            <Typography variant="body1" gutterBottom>
                { value }
            </Typography>
        </Box>
    );
};

ValueRow.propTypes = {
    label: PropTypes.string,
    labelWidth: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

ValueRow.defaultProps = {
    labelWidth: 100,
}

export default ValueRow;
