import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PROFILE } from 'store/constants';
import { post } from 'utilities/api';
import { Api } from 'config/api';
import { reverseToReadableDate } from 'utilities/date';
import PremiumInfo from 'components/PremiumInfo/PremiumInfo';
import ContentContainer from 'components/ContentContainer';
import SubscriptionCard from 'components/SubscriptionCard';
import styles from './Subscription.styles';

const Subscription = props => {
    const dispatch = useDispatch();
    const pageTitle = useIntl().formatMessage({ id: 'subscription.title' });
    const token = useSelector(state => state.persist.accessToken);
    const permissions = useSelector(state => state.profile.permissions);
    const isClubAccount = permissions.includes('workout');
    const isPremium = useSelector(state => state.profile.is_premium);
    const premiumExpireDate = useSelector(state => state.profile.premium?.end_date);
    const fetched = useSelector(state => state.profile.fetched);
    const error = useSelector(state => state.profile.error);
    const query = queryString.parse(props.location.search)
    const classes = styles();

    const paymentState = query.status;
    const paymentSuccessful = useIntl().formatMessage({ id: 'subscription.payment.success' });
    const paymentPending = useIntl().formatMessage({ id: 'subscription.payment.pending' });
    const paymentFailed = useIntl().formatMessage({ id: 'subscription.payment.failed' });

    const expireDate = premiumExpireDate ? reverseToReadableDate(premiumExpireDate) : '';

    const premiumLabel = useIntl().formatMessage({ id: 'subscription.label.premium' });
    const freeLabel = useIntl().formatMessage({ id: 'subscription.label.free' });
    const clubLabel = useIntl().formatMessage({ id: 'subscription.label.club' });
    const accountType = isPremium ? premiumLabel : freeLabel;
    const accountHeading = useIntl().formatMessage({ id: 'subscription.label.accountHeading' });
    const accountTypeLabel = useIntl().formatMessage({ id: 'subscription.label.accountType' });
    const expireDateLabel = useIntl().formatMessage({ id: 'subscription.label.expireDate' });

    const monthName = useIntl().formatMessage({ id: 'subscription.month.name' });
    const monthPrice = 5;
    const monthDescription = useIntl().formatMessage({ id: 'subscription.month.description' });
    const yearName = useIntl().formatMessage({ id: 'subscription.year.name' });
    const yearPrice = 50;
    const yearDescription = useIntl().formatMessage({ id: 'subscription.year.description' });
    const orderButtonLabel = useIntl().formatMessage({ id: 'subscription.orderButton.label' });
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    const goToPayment = async period => {
        const apiUrl = Api.requests.subscriptions.store;

        post(token, `${apiUrl}`, { period })
            .then(response => {
                if (response.status === 200) {
                    const checkoutUrl = response.data.data.checkout_url;

                    window.location.replace(checkoutUrl);

                    return ;
                }

                toast.error(errorMessage);
            })
            .catch(error => {
                toast.error(errorMessage);
            });
    }

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (paymentState === 'paid') {
            toast.success(paymentSuccessful);
        } else if (paymentState === 'open') {
            toast.info(paymentPending)
        } else if (paymentState) {
            toast.error(paymentFailed);
        }
    }, [paymentState, paymentSuccessful, paymentPending, paymentFailed]);

    useEffect(() => {
        dispatch(PROFILE.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle }>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 8 } lg={ 6 }>
                    <Paper elevation={ 2 } className={ classes.block }>
                        <Typography variant="h5" component="h2" gutterBottom>
                            { accountHeading }
                        </Typography>

                        { fetched ? (
                            <PremiumInfo
                                expireDateLabel={ expireDateLabel }
                                expireDate={ expireDate }
                                accountTypeLabel={ accountTypeLabel }
                                accountType={ isClubAccount ? clubLabel : accountType }
                            />
                        ) : (
                            <CircularProgress />
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Box mt={ 2 }>
                <Grid container spacing={ 2 }>
                    <SubscriptionCard
                        name={ monthName }
                        price={ monthPrice }
                        description={ monthDescription }
                        buttonLabel={ orderButtonLabel }
                        onClick={ () => goToPayment('month') }
                    />

                    <SubscriptionCard
                        name={ yearName }
                        price={ yearPrice }
                        description={ yearDescription }
                        buttonLabel={ orderButtonLabel }
                        onClick={ () => goToPayment('year') }
                    />
                </Grid>
            </Box>
        </ContentContainer>
    );
};

export default Subscription;
