import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { CLUB, PREMIUM } from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import LoadingIndicator from 'components/LoadingIndicator';
import PremiumForm from 'components/PremiumForm';
import ValueBlock from 'components/ValueBlock';
import styles from './PremiumEdit.styles';

const PremiumEdit = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const params = useParams();
    const loading = useSelector(state => state.premium.loading);
    const clubs = useSelector(state => state.club.clubs);
    const clubsFetched = useSelector(state => state.club.fetched);
    const premiumId = parseInt(params.premiumId);
    const premium = useSelector(state => state.premium.active);
    const premiumLoaded = premium?.id === premiumId;

    const initialValues = premiumLoaded ? {
        start_date: premium.start_date,
        end_date: premium.end_date,
        club_id: premium.club_id,
    } : {}

    const error = useSelector(state => state.premium.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.premium.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'premium.create.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'premium.update.title' });
    const nameLabel = useIntl().formatMessage({ id: 'premium.label.name' });
    const emailLabel = useIntl().formatMessage({ id: 'premium.label.email' });
    const startDateLabel = useIntl().formatMessage({ id: 'premium.label.start_date' });
    const endDateLabel = useIntl().formatMessage({ id: 'premium.label.end_date' });
    const clubLabel = useIntl().formatMessage({ id: 'premium.label.club' });
    const submitButtonText = useIntl().formatMessage({ id: 'premium.update.submit_button_text' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });

    const handleSubmit = values => {
        dispatch(PREMIUM.UPDATE.trigger({
            values,
            id: premiumId,
        }));

        return true;
    }

    useEffect(() => {
        if (clubsFetched === false) {
            dispatch(CLUB.FETCH.trigger());
        }

        dispatch(PREMIUM.SHOW.trigger(premiumId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    return (
        <ContentContainer title={ pageTitle } backButton>
            { premiumLoaded && clubsFetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 4 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock
                                label={ nameLabel }
                                value={ premium.user.data.full_name }
                            />

                            <ValueBlock
                                label={ emailLabel }
                                value={ premium.user.data.email }
                            />
                        </Paper>

                        <Box mt={ 2 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <PremiumForm
                                    startDateLabel={ startDateLabel }
                                    endDateLabel={ endDateLabel }
                                    clubLabel={ clubLabel }
                                    submitButtonText={ submitButtonText }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    clubs={ clubs }
                                    onSubmit={ handleSubmit }
                                    initialValues={ initialValues }
                                />
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            )}


            <LoadingBackdrop open={ loading } message={ loadingMessage } />
        </ContentContainer>
    );
};

export default PremiumEdit;
