import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    hidden: {
        display: 'none' ,
    },

    avatar: {
        width: 20,
        height: 20,
    },

    infoBox: {
        paddingLeft: theme.spacing(2),
    }
}));

export default styles;