import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import ActionList from 'components/ActionList';
import BoxHeading from 'components/BoxHeading';
import ContentBox from 'components/ContentBox';

const ClassificationTeamsBlock = props => {
    const { classificationId, teams } = props;
    const teamList = teams.map(team => {
        return {
            target: `/classifications/${classificationId}/teams/${team.id}`,
            label: team.name
        }
    });

    return (
        <ContentBox>
            <BoxHeading
                title="Teams"
            />

            <Box mt={ 2 }>
                <ActionList
                    values={ teamList }
                />
            </Box>
        </ContentBox>
    );
};

ClassificationTeamsBlock.propTypes = {
    classificationId: PropTypes.number.isRequired,
    teams: PropTypes.array.isRequired,
}

export default ClassificationTeamsBlock;