import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { EVALUATIONS } from '../constants';
import {
    fetchRequest,
    storeRequest,
    deleteRequest, updateRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        yield take(EVALUATIONS.FETCH.TRIGGER);

        try {
            const endpoint = Api.requests.evaluations.index;
            const response = yield call(fetchRequest, endpoint);
            const evaluations = response.data.data;

            yield put(EVALUATIONS.FETCH.success(evaluations));
        } catch (error) {
            yield put(EVALUATIONS.FETCH.failure());
        } finally {
            yield put(EVALUATIONS.FETCH.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(EVALUATIONS.CREATE.TRIGGER);

        try {
            const endpoint = Api.requests.evaluations.store;
            const payload = request.payload;
            yield call(storeRequest, endpoint, payload);

            yield put(EVALUATIONS.FETCH.trigger());
            yield put(EVALUATIONS.CREATE.success());
        } catch (error) {
            yield put(EVALUATIONS.CREATE.failure());
        } finally {
            yield put(EVALUATIONS.CREATE.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(EVALUATIONS.SHOW.TRIGGER);

        try {
            const evaluationId = request.payload;
            const endpoint = Api.requests.evaluations.show(evaluationId);
            const response = yield call(fetchRequest, endpoint);

            yield put(EVALUATIONS.SHOW.success(response.data.data));
        } catch (error) {
            yield put(EVALUATIONS.SHOW.failure());
        } finally {
            yield put(EVALUATIONS.SHOW.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(EVALUATIONS.DELETE.TRIGGER);

        try {
            const evaluationId = request.payload;
            const endpoint = Api.requests.evaluations.delete(evaluationId);

            yield call(deleteRequest, endpoint);
            yield put(EVALUATIONS.DELETE.success(request.payload));
        } catch (error) {
            yield put(EVALUATIONS.DELETE.failure());
        } finally {
            yield put(EVALUATIONS.DELETE.fulfill());
        }
    }
}

export function* clearFlow() {
    while (true) {
        const request = yield take(EVALUATIONS.CLEAR.TRIGGER);

        try {
            const evaluationIds = request.payload;
            const endpoint = Api.requests.evaluations.clear;

            yield call(updateRequest, endpoint, { ids: evaluationIds });
            yield put(EVALUATIONS.CLEAR.success());
        } catch (error) {
            yield put(EVALUATIONS.CLEAR.failure());
        } finally {
            yield put(EVALUATIONS.CLEAR.fulfill());
        }
    }
}

export function* remindFlow() {
    while (true) {
        const request = yield take(EVALUATIONS.REMIND.TRIGGER);

        try {
            const evaluationIds = request.payload;
            const endpoint = Api.requests.evaluations.remind;

            yield call(updateRequest, endpoint, { ids: evaluationIds });
            yield put(EVALUATIONS.REMIND.success());
        } catch (error) {
            yield put(EVALUATIONS.REMIND.failure());
        } finally {
            yield put(EVALUATIONS.REMIND.fulfill());
        }
    }
}

export function* syncFlow() {
    while (true) {
        const request = yield take(EVALUATIONS.SYNC.TRIGGER);

        try {
            const evaluationIds = request.payload;
            const endpoint = Api.requests.evaluations.sync;

            yield call(updateRequest, endpoint, { ids: evaluationIds });
            yield put(EVALUATIONS.SYNC.success());
        } catch (error) {
            yield put(EVALUATIONS.SYNC.failure());
        } finally {
            yield put(EVALUATIONS.SYNC.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        createFlow(),
        showFlow(),
        deleteFlow(),
        clearFlow(),
        remindFlow(),
        syncFlow(),
    ]);
}
