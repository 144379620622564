import { AUTH, EVENT_TYPES } from '../constants';

const INITIAL_STATE = {
    types: [],
    fetched: false,
    error: false,
    loading: false,
};

const eventTypeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVENT_TYPES.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case EVENT_TYPES.FETCH.SUCCESS:
            return {
                ...state,
                types: action.payload,
                fetched: true,
            };

        case EVENT_TYPES.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case EVENT_TYPES.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case EVENT_TYPES.CLEAN:
            return {
                ...state,
                error: null,
                loading: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default eventTypeReducer;