import PropTypes from 'prop-types';

import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';

import DatePickerInput from 'components/DatePickerInput';
import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { dateStringIsInFuture } from 'utilities/validation';

const ClassificationsTable = props => {
    const { data, onDelete, onUpdate } = props;
    const history = useHistory();

    const columns = [
        {
            defaultSort: 'desc',
            editComponent: props => (
                <DatePickerInput
                    field={ {
                        name: 'closing_date',
                        value: props.value,
                        onBlur: () => false,
                    } }
                    minDate={ new Date() }
                    setFieldValue={ (field, value) => props.onChange(value) }
                />
            ),
            field: 'closing_date',
            render: rowData => reverseToReadableDate(rowData.closing_date),
            title: 'Sluitingsdatum',
            type: 'date',
            validate: rowData => dateStringIsInFuture(rowData.closing_date),
        },
        {
            editable: 'never',
            field: 'team_count',
            title: 'Aantal teams',
            type: 'numeric',
        },
        {
            editable: 'never',
            field: 'review_count',
            title: 'Aantal beoordelingen',
            type: 'numeric',
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/classifications/${rowData.id}`),
        },
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: () => history.push('/classifications/create'),
        },
    ];

    const editable = {
        onRowDelete: onDelete,
        onRowUpdate: onUpdate,
    };

    return (
        <MaterialTable
            title="Evaluaties"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

ClassificationsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ClassificationsTable;