import { useEffect } from 'react';

import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import SurveysTable from 'contents/tables/SurveysTable';
import { CLUB, SURVEYS } from 'store/constants';
import { removeEmptyKeys } from 'utilities/object';

const SurveyOverview = () => {
    const dispatch = useDispatch();
    const surveys = useSelector(state => state.survey.surveys);
    const clubs = useSelector(state => state.club.clubs);
    const surveysFetched = useSelector(state => state.survey.fetched);
    const clubsFetched = useSelector(state => state.club.fetched);
    const error = useSelector(state => state.survey.error);
    const created = useSelector(state => state.survey.created);
    const updated = useSelector(state => state.survey.updated);
    const deleted = useSelector(state => state.survey.deleted);
    const fetched = surveysFetched && clubsFetched;
    const clubsLookup = _.chain(clubs)
        .uniqBy('id')
        .keyBy('id')
        .mapValues('name')
        .value();

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = removeEmptyKeys(data);
            values.club_id = values?.club?.data?.id || null;
            delete values.club;

            dispatch(SURVEYS.CREATE.trigger(values))

            resolve();
        })
    }

    const onUpdate = values => {
        return new Promise((resolve) => {
            const surveyId = values.id;
            delete values.id;
            delete values.original;

            dispatch(SURVEYS.UPDATE.trigger({
                surveyId,
                values,
            }))

            resolve();
        })
    }

    const onDelete = data => {
        return new Promise((resolve, reject) => {
            dispatch(SURVEYS.DELETE.trigger(data.id));

            resolve();
        });
    };

    useEffect(() => {
        dispatch(SURVEYS.FETCH.trigger());

        if (! clubsFetched) {
            dispatch(CLUB.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Vragenlijst is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (updated) {
            toast.success('Vragenlijst is aangepast');
        }
    }, [updated]);

    useEffect(() => {
        if (deleted) {
            toast.success('Vragenlijst is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Vragenlijsten">
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 9 }>
                        <SurveysTable
                            data={ surveys }
                            clubs={ clubsLookup }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                            onUpdate={ onUpdate }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default SurveyOverview;
