import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';

const NotFound = () => {
    const pageTitle = useIntl().formatMessage({ id: '404.title' });
    const heading = useIntl().formatMessage({ id: '404.heading' });
    const subheading = useIntl().formatMessage({ id: '404.subheading' });

    return (
        <ContentContainer title={ pageTitle }>
            <Grid container spacing={3}>
                <Grid item xs={ 12 }>
                    <Typography variant="h3" component="h1" gutterBottom>
                        { heading }
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        { subheading }
                    </Typography>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

export default NotFound;
