import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { Drawer } from '@material-ui/core';
import _ from 'lodash';

import { images } from 'config/images';
import { navigation } from 'config/navigation';
import SidebarItem from './SidebarItem';
import { sidebarStyles, drawerStyles } from './Sidebar.styles';
import SidebarDropdownItem from './SidebarDropdownItem';

const Sidebar = props => {
    const { children } = props;
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const viewMode = useSelector(state => state.persist.viewMode);
    const roles = useSelector(state => state.profile.roles);
    const classes = sidebarStyles();
    const drawerClasses = drawerStyles();

    const renderSidebarItems = () => {
        const navigationItems = isAdmin ? navigation.admin : navigation[viewMode ?? 'default'];
        const keyPrefix = isAdmin ? 'nav_admin_' : 'nav_user_';

        return navigationItems.map((item, index) => {
            if (item.role && ! roles.includes(item.role)) {
                return false;
            }

            const hasChildren = _.has(item, 'children');

            if (hasChildren) {
                return (
                    <SidebarDropdownItem
                        key={ `${keyPrefix}${index}` }
                        name={ item.name }
                        icon={ item.icon }
                        children={ item.children }
                    />
                )
            }

            return (
                <SidebarItem
                    key={ `${keyPrefix}${index}` }
                    name={ item.name }
                    target={ item.target }
                    icon={ item.icon }
                />
            )
        });
    };

    return (
        <Drawer
            classes={ drawerClasses }
            variant="permanent"
            anchor="left"
            open
        >
            <img src={ images.logo.landscape208.default } alt="Logo" className={ classes.logo } />

            <List>
                { renderSidebarItems() }

                { children }
            </List>
        </Drawer>
    );
};

export default Sidebar;
