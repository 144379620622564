import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { INTERNAL_TRAINERS } from 'store/constants';

const Trainers = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.internalTrainer.fetched);
    const trainers = useSelector(state => state.internalTrainer.internalTrainers);
    const loading = useSelector(state => state.internalTrainer.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'trainers.title' });
    const error = useSelector(state => state.internalTrainer.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.internalTrainer.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'trainers.label.name' }),
            field: 'user.data.full_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'user.data.full_name'),
            defaultSort: 'asc',
        },
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'trainers.label.rate' }),
            field: 'rate',
            render: rowData => `€ ${rowData.rate},-`,
        },
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'trainers.label.email' }),
            field: 'user.data.email',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'user.data.email'),
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`trainers/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`trainers/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(INTERNAL_TRAINERS.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(INTERNAL_TRAINERS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: INTERNAL_TRAINERS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ trainers }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default Trainers;
