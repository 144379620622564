import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    title: {
        marginBottom: 0,
        paddingBottom: 0,
        width: 300,
    },
}));

export default styles;