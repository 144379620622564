import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    outerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        cursor: 'pointer',
    }
}));

export default styles;