import { all, call, put, take } from 'redux-saga/effects';

import { EVENT } from '../constants';
import {
    deleteRequest,
    fetchRequest,
    storeRequest,
    updateRequest,
    updateFileRequest,
} from '../api';
import { Api } from 'config/api';

export function* fetchFlow() {
    while (true) {
        yield take(EVENT.FETCH.TRIGGER);

        try {
            const endpoint = Api.requests.events.index;
            const response = yield call(fetchRequest, endpoint);
            const events = response.data.data;

            yield put(EVENT.FETCH.success(events));
        } catch (error) {
            yield put(EVENT.FETCH.failure());
        } finally {
            yield put(EVENT.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(EVENT.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.events.show(id);
            const response = yield call(fetchRequest, endpoint);

            yield put(EVENT.SHOW.success(response.data.data));
        } catch (error) {
            yield put(EVENT.SHOW.failure());
        } finally {
            yield put(EVENT.SHOW.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(EVENT.CREATE.TRIGGER);

        try {
            const endpoint = Api.requests.events.store;
            const response = yield call(storeRequest, endpoint, request.payload);

            yield put(EVENT.CREATE.success(response.data.data));
        } catch (error) {
            yield put(EVENT.CREATE.failure());
        } finally {
            yield put(EVENT.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(EVENT.UPDATE.TRIGGER);

        try {
            const id = request.payload.id;
            const endpoint = Api.requests.events.update(id);
            const formData = new FormData();
            formData.append('club_id', request.payload.values.club_id);
            formData.append('event_type_id', request.payload.values.event_type_id);
            formData.append('name', request.payload.values.name);
            formData.append('description', request.payload.values.description);
            formData.append('explanation', request.payload.values.explanation);
            formData.append('price', request.payload.values.price);
            formData.append('places', request.payload.values.places);

            if (request.payload.values.image !== undefined) {
                formData.append('image', request.payload.values.image);
            }

            const response = yield call(updateFileRequest, endpoint, formData);

            yield put(EVENT.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(EVENT.UPDATE.failure());
        } finally {
            yield put(EVENT.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(EVENT.DELETE.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.events.delete(id);

            yield call(deleteRequest, endpoint);
            yield put(EVENT.DELETE.success(request.payload));
        } catch (error) {
            yield put(EVENT.DELETE.failure());
        } finally {
            yield put(EVENT.DELETE.fulfill());
        }
    }
}

export function* addDateFlow() {
    while (true) {
        const request = yield take(EVENT.DATE.UPDATE.TRIGGER);

        try {
            const eventId = request.payload.eventId;
            const payload = request.payload.values;
            const endpoint = Api.requests.events.dates.store(eventId);
            const response = yield call(storeRequest, endpoint, payload);

            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.DATE.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(EVENT.DATE.UPDATE.failure());
        } finally {
            yield put(EVENT.DATE.UPDATE.fulfill());
        }
    }
}

export function* deleteDateFlow() {
    while (true) {
        const request = yield take(EVENT.DATE.DELETE.TRIGGER);

        try {
            const dateId = request.payload.dateId;
            const eventId = request.payload.eventId;
            const endpoint = Api.requests.events.dates.delete(eventId, dateId);

            yield call(deleteRequest, endpoint);
            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.DATE.DELETE.success(request.payload));
        } catch (error) {
            yield put(EVENT.DATE.DELETE.failure());
        } finally {
            yield put(EVENT.DATE.DELETE.fulfill());
        }
    }
}

export function* addTrainerFlow() {
    while (true) {
        const request = yield take(EVENT.TRAINER.UPDATE.TRIGGER);

        try {
            const eventId = request.payload.eventId;
            const trainerId = request.payload.trainerId;
            const endpoint = Api.requests.events.trainers.update(eventId, trainerId);
            const response = yield call(updateRequest, endpoint);

            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.TRAINER.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(EVENT.TRAINER.UPDATE.failure());
        } finally {
            yield put(EVENT.TRAINER.UPDATE.fulfill());
        }
    }
}

export function* deleteTrainerFlow() {
    while (true) {
        const request = yield take(EVENT.TRAINER.DELETE.TRIGGER);

        try {
            const trainerId = request.payload.trainerId;
            const eventId = request.payload.eventId;
            const endpoint = Api.requests.events.trainers.delete(eventId, trainerId);

            yield call(deleteRequest, endpoint);
            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.TRAINER.DELETE.success(request.payload));
        } catch (error) {
            yield put(EVENT.TRAINER.DELETE.failure());
        } finally {
            yield put(EVENT.TRAINER.DELETE.fulfill());
        }
    }
}

export function* addTrainingFlow() {
    while (true) {
        const request = yield take(EVENT.TRAINING.UPDATE.TRIGGER);

        try {
            const eventId = request.payload.eventId;
            const trainingId = request.payload.trainingId;
            const endpoint = Api.requests.events.trainings.update(eventId, trainingId);
            const response = yield call(updateRequest, endpoint);

            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.TRAINING.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(EVENT.TRAINING.UPDATE.failure());
        } finally {
            yield put(EVENT.TRAINING.UPDATE.fulfill());
        }
    }
}

export function* deleteTrainingFlow() {
    while (true) {
        const request = yield take(EVENT.TRAINING.DELETE.TRIGGER);

        try {
            const trainingId = request.payload.trainingId;
            const eventId = request.payload.eventId;
            const endpoint = Api.requests.events.trainings.delete(eventId, trainingId);

            yield call(deleteRequest, endpoint);
            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.TRAINING.DELETE.success(request.payload));
        } catch (error) {
            yield put(EVENT.TRAINING.DELETE.failure());
        } finally {
            yield put(EVENT.TRAINING.DELETE.fulfill());
        }
    }
}

export function* deletePlayerFlow() {
    while (true) {
        const request = yield take(EVENT.PLAYER.DELETE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const eventId = request.payload.eventId;
            const endpoint = Api.requests.events.players.delete(eventId, playerId);

            yield call(deleteRequest, endpoint);
            yield put(EVENT.SHOW.trigger(eventId));
            yield put(EVENT.PLAYER.DELETE.success(request.payload));
        } catch (error) {
            yield put(EVENT.PLAYER.DELETE.failure());
        } finally {
            yield put(EVENT.PLAYER.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        createFlow(),
        showFlow(),
        updateFlow(),
        deleteFlow(),
        addDateFlow(),
        deleteDateFlow(),
        addTrainerFlow(),
        deleteTrainerFlow(),
        addTrainingFlow(),
        deleteTrainingFlow(),
        deletePlayerFlow(),
    ]);
}
