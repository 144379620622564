import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { TEAM } from '../constants';
import {
    fetchRequest,
    storeRequest,
    deleteRequest,
} from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(TEAM.ADMIN.FETCH.TRIGGER);

        try {
            const teamId = request.payload;
            const endpoint = Api.requests.teams.show(teamId);
            const response = yield call(fetchRequest, endpoint);
            const admins = response.data.data.admins.data;

            yield put(TEAM.ADMIN.FETCH.success({
                admins,
                teamId,
            }));
        } catch (error) {
            yield put(TEAM.ADMIN.FETCH.failure());
        } finally {
            yield put(TEAM.ADMIN.FETCH.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(TEAM.ADMIN.CREATE.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const endpoint = Api.requests.teams.admins.store(teamId);
            const payload = request.payload.values;
            const response = yield call(storeRequest, endpoint, payload);

            yield put(TEAM.ADMIN.CREATE.success(response.data.data));
        } catch (error) {
            yield put(TEAM.ADMIN.CREATE.failure());
        } finally {
            yield put(TEAM.ADMIN.CREATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(TEAM.ADMIN.DELETE.TRIGGER);

        try {
            const adminId = request.payload.adminId;
            const teamId = request.payload.teamId;
            const endpoint = Api.requests.teams.admins.delete(teamId, adminId);

            yield call(deleteRequest, endpoint);
            yield put(TEAM.ADMIN.DELETE.success(adminId));
        } catch (error) {
            yield put(TEAM.ADMIN.DELETE.failure());
        } finally {
            yield put(TEAM.ADMIN.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        createFlow(),
        deleteFlow(),
    ]);
}