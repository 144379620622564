import { AUTH, PLAYER, PLAYERCARD } from '../constants';

const INITIAL_STATE = {
    active: null,
    error: false,
    loading: false,
};

const playercardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLAYERCARD.SHOW.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case PLAYERCARD.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case PLAYERCARD.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case PLAYERCARD.SHOW.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case PLAYER.STATS.UPDATE.SUCCESS:
            return {
                ...state,
                active: null,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default playercardReducer;