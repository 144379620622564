import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import TeamAdmins from './TeamAdmins';
import TeamEvaluations from './TeamEvaluations';
import TeamEvaluationShow from './TeamEvaluationShow';
import TeamPlayers from './TeamPlayers';

const Users = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ `${path}/:teamId/admins` }
                component={ TeamAdmins }
            />

            <Route
                exact
                path={ `${path}/:teamId/evaluations` }
                component={ TeamEvaluations }
            />

            <Route
                exact
                path={ `${path}/:teamId/evaluations/:evaluationId` }
                component={ TeamEvaluationShow }
            />

            <Route
                exact
                path={ `${path}/:teamId/players` }
                component={ TeamPlayers }
            />
        </Switch>
    );
};

export default Users;