import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { images } from 'config/images';
import ImageItem from '../ImageItem/ImageItem';

const ImagePanel = props => {
    const { setDraggable, setRef } = props;

    const icons = [
        { src: images.tool.icon.ball.default, tooltip: 'Bal' },
        { src: images.tool.icon.balls.default, tooltip: 'Ballen' },
        { src: images.tool.icon.ballGun.default, tooltip: 'Ballenkanon' },
        { src: images.tool.icon.bounce.default, tooltip: 'Bounce' },
        { src: images.tool.icon.yellowFlaps.default, tooltip: 'Gele flappen' },
        { src: images.tool.icon.coneBlue.default, tooltip: 'Pion: Blauw' },
        { src: images.tool.icon.coneGrey.default, tooltip: 'Pion: Grijs' },
        { src: images.tool.icon.coneOrange.default, tooltip: 'Pion: Oranje' },
        { src: images.tool.icon.coneRed.default, tooltip: 'Pion: Rood' },
        { src: images.tool.icon.coneLowBlue.default, tooltip: 'Lage pion: Blauw' },
        { src: images.tool.icon.coneLowGrey.default, tooltip: 'Lage pion: Grijs' },
        { src: images.tool.icon.coneLowOrange.default, tooltip: 'Lage pion: Oranje' },
        { src: images.tool.icon.coneLowRed.default, tooltip: 'Lage pion: Rood' },
        { src: images.tool.icon.goalBottom.default, tooltip: 'Doel: Onderkant' },
        { src: images.tool.icon.goalLarge.default, tooltip: 'Doel: Groot' },
        { src: images.tool.icon.goalSmall.default, tooltip: 'Doel: Klein' },
        { src: images.tool.icon.goalUpper.default, tooltip: 'Doel: Bovenkant' },
        { src: images.tool.icon.hordeCones.default, tooltip: 'Horde: Pionnen' },
        { src: images.tool.icon.hordeHigh.default, tooltip: 'Horde: Hoog' },
        { src: images.tool.icon.hordeLow.default, tooltip: 'Horde: Laag' },
        { src: images.tool.icon.crossRed.default, tooltip: 'Rood kruis' },
        { src: images.tool.icon.circleBlue.default, tooltip: 'Circel: blauw' },
        { src: images.tool.icon.hoop.default, tooltip: 'Hoepel' },
        { src: images.tool.icon.ladder.default, tooltip: 'Ladder' },
        { src: images.tool.icon.tire.default, tooltip: 'Band' },
        { src: images.tool.icon.trainer.default, tooltip: 'Trainer' },
        { src: images.tool.icon.keeper.default, tooltip: 'Keeper' },
        { src: images.tool.icon.shirtBlack.default, tooltip: 'Speler: Zwart' },
        { src: images.tool.icon.shirtBlack01.default, tooltip: 'Speler 1: Zwart' },
        { src: images.tool.icon.shirtBlack02.default, tooltip: 'Speler 2: Zwart' },
        { src: images.tool.icon.shirtBlack03.default, tooltip: 'Speler 3: Zwart' },
        { src: images.tool.icon.shirtBlack04.default, tooltip: 'Speler 4: Zwart' },
        { src: images.tool.icon.shirtBlack05.default, tooltip: 'Speler 5: Zwart' },
        { src: images.tool.icon.shirtBlack06.default, tooltip: 'Speler 6: Zwart' },
        { src: images.tool.icon.shirtBlack07.default, tooltip: 'Speler 7: Zwart' },
        { src: images.tool.icon.shirtBlack08.default, tooltip: 'Speler 8: Zwart' },
        { src: images.tool.icon.shirtBlack09.default, tooltip: 'Speler 9: Zwart' },
        { src: images.tool.icon.shirtBlack10.default, tooltip: 'Speler 10: Zwart' },
        { src: images.tool.icon.shirtBlack11.default, tooltip: 'Speler 11: Zwart' },
        { src: images.tool.icon.shirtBlack12.default, tooltip: 'Speler 12: Zwart' },
        { src: images.tool.icon.shirtBlack13.default, tooltip: 'Speler 13: Zwart' },
        { src: images.tool.icon.shirtBlack14.default, tooltip: 'Speler 14: Zwart' },
        { src: images.tool.icon.shirtBlack15.default, tooltip: 'Speler 15: Zwart' },
        { src: images.tool.icon.shirtBlack16.default, tooltip: 'Speler 15: Zwart' },
        { src: images.tool.icon.shirtBlue.default, tooltip: 'Speler: Blauw' },
        { src: images.tool.icon.shirtBlue01.default, tooltip: 'Speler 1: Blauw' },
        { src: images.tool.icon.shirtBlue02.default, tooltip: 'Speler 2: Blauw' },
        { src: images.tool.icon.shirtBlue03.default, tooltip: 'Speler 3: Blauw' },
        { src: images.tool.icon.shirtBlue04.default, tooltip: 'Speler 4: Blauw' },
        { src: images.tool.icon.shirtBlue05.default, tooltip: 'Speler 5: Blauw' },
        { src: images.tool.icon.shirtBlue06.default, tooltip: 'Speler 6: Blauw' },
        { src: images.tool.icon.shirtBlue07.default, tooltip: 'Speler 7: Blauw' },
        { src: images.tool.icon.shirtBlue08.default, tooltip: 'Speler 8: Blauw' },
        { src: images.tool.icon.shirtBlue09.default, tooltip: 'Speler 9: Blauw' },
        { src: images.tool.icon.shirtBlue10.default, tooltip: 'Speler 10: Blauw' },
        { src: images.tool.icon.shirtBlue11.default, tooltip: 'Speler 11: Blauw' },
        { src: images.tool.icon.shirtBlue12.default, tooltip: 'Speler 12: Blauw' },
        { src: images.tool.icon.shirtBlue13.default, tooltip: 'Speler 13: Blauw' },
        { src: images.tool.icon.shirtBlue14.default, tooltip: 'Speler 14: Blauw' },
        { src: images.tool.icon.shirtBlue15.default, tooltip: 'Speler 15: Blauw' },
        { src: images.tool.icon.shirtBlue16.default, tooltip: 'Speler 16: Blauw' },
        { src: images.tool.icon.shirtOrange.default, tooltip: 'Speler: Oranje' },
        { src: images.tool.icon.shirtOrange01.default, tooltip: 'Speler 1: Oranje' },
        { src: images.tool.icon.shirtOrange02.default, tooltip: 'Speler 2: Oranje' },
        { src: images.tool.icon.shirtOrange03.default, tooltip: 'Speler 3: Oranje' },
        { src: images.tool.icon.shirtOrange04.default, tooltip: 'Speler 4: Oranje' },
        { src: images.tool.icon.shirtOrange05.default, tooltip: 'Speler 5: Oranje' },
        { src: images.tool.icon.shirtOrange06.default, tooltip: 'Speler 6: Oranje' },
        { src: images.tool.icon.shirtOrange07.default, tooltip: 'Speler 7: Oranje' },
        { src: images.tool.icon.shirtOrange08.default, tooltip: 'Speler 8: Oranje' },
        { src: images.tool.icon.shirtOrange09.default, tooltip: 'Speler 9: Oranje' },
        { src: images.tool.icon.shirtOrange10.default, tooltip: 'Speler 10: Oranje' },
        { src: images.tool.icon.shirtOrange11.default, tooltip: 'Speler 11: Oranje' },
        { src: images.tool.icon.shirtOrange12.default, tooltip: 'Speler 12: Oranje' },
        { src: images.tool.icon.shirtOrange13.default, tooltip: 'Speler 13: Oranje' },
        { src: images.tool.icon.shirtOrange14.default, tooltip: 'Speler 14: Oranje' },
        { src: images.tool.icon.shirtOrange15.default, tooltip: 'Speler 15: Oranje' },
        { src: images.tool.icon.shirtOrange16.default, tooltip: 'Speler 16: Oranje' },
    ];

    const handleDragStart = e => {
        setDraggable(false);
        setRef(e.target.src);
    };

    const renderIcons = () => {
        let i = 0;

        return icons.map(icon => {
            i++;
            const identifier = `icon-${i}`;

            return <ImageItem
                key={ identifier }
                src={ icon.src }
                tooltip={ icon.tooltip }
                onDragStart={ handleDragStart }
            />
        });
    };

    return (
        <Grid container spacing={ 1 }>
            { renderIcons() }
        </Grid>
    );
};

ImagePanel.propTypes = {
    setDraggable: PropTypes.func.isRequired,
    setRef: PropTypes.func.isRequired,
};

export default ImagePanel;
