import { useRef, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

const UrlImage = props => {
    const { draggable, height, image, index, onDoubleClick, onSelect, onUpdate, rotation, shapeProps, selected, width, x, y } = props;
    const shapeRef = useRef();
    const transformRef = useRef();
    const [loadedImage] = useImage(image);

    const handleDoubleClick = () => {
        onDoubleClick(index);
    };

    const handleOnClick = () => {
        onSelect(index);
    };

    useEffect(() => {
        if (selected) {
            transformRef.current.setNode(shapeRef.current);
            transformRef.current.getLayer().batchDraw();
        }
    }, [selected]);

    return (
        <Fragment>
            <Image
                { ...shapeProps }
                draggable={ draggable }
                onClick={ handleOnClick }
                height={ height }
                image={ loadedImage }
                onDragEnd={ (e) => {
                    onUpdate(index, {
                        x: e.target.x() + e.target.width() / 2,
                        y: e.target.y() + e.target.height() / 2,
                    });
                } }
                onDblClick={ handleDoubleClick }
                onTransformEnd={e => {
                    const newWidth = e.target.width() * e.target.scaleX();
                    const newHeight = e.target.height() * e.target.scaleY();

                    onUpdate(index, {
                        height: newHeight,
                        rotation: e.target.rotation(),
                        width: newWidth,
                        x: e.target.x() + newWidth / 2,
                        y: e.target.y() + newHeight / 2,
                    });
                } }
                ref={ shapeRef }
                rotation={ rotation }
                width={ width }
                x={ x }
                y={ y }
            />

            { selected && (
                <Transformer
                    ref={ transformRef }
                    boundBoxFunc={ (oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }

                        return newBox;
                    } }
                />
            ) }
        </Fragment>
    );
};

UrlImage.propTypes = {
    draggable: PropTypes.bool,
    height: PropTypes.number,
    image: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    rotation: PropTypes.number,
    selected: PropTypes.bool,
    shapeProps: PropTypes.any,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
};

UrlImage.defaultProps = {
    draggable: false,
    rotation: 0,
    x: 0,
    y: 0,
};

export default UrlImage;