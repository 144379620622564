import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    block: {
        padding: theme.spacing(2),
    },

    smallPaddingBottom: {
        paddingBottom: theme.spacing(1),
    }
}));

export default styles;