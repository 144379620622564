import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { PREMIUM } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, post } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest() {
    const token = yield select(getToken);

    return yield get(token, Api.requests.premium.index);
}

export function* fetchOneRequest(id) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.premium.show(id));
}

export function* createRequest({payload}) {
    const token = yield select(getToken);
    return yield post(token, Api.requests.premium.store, payload);
}

export function* editRequest({ id, payload, headers = {}}) {
    const token = yield select(getToken);
    return yield patch(token, Api.requests.premium.update(id), payload, headers);
}

export function* deleteRequest(id) {
    const token = yield select(getToken);

    return yield deletePost(token, Api.requests.premium.delete(id));
}

export function* fetchFlow() {
    while (true) {
        yield take(PREMIUM.FETCH.TRIGGER);

        try {
            const response = yield call(fetchAllRequest);
            const premium = response.data.data;

            yield put(PREMIUM.FETCH.success(premium));
        } catch (error) {
            yield put(PREMIUM.FETCH.failure());
        } finally {
            yield put(PREMIUM.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(PREMIUM.CREATE.TRIGGER);

        try {
            let formData = {
                user_id: request.payload.user_id,
                start_date: request.payload.start_date,
                end_date: request.payload.end_date,
                status: 'paid',
            }

            if (request.payload.club_id) {
                formData.club_id = request.payload.club_id;
            }

            const response = yield call(createRequest, { payload: formData });

            yield put(PREMIUM.CREATE.success(response.data.data));
        } catch (error) {
            yield put(PREMIUM.CREATE.failure());
        } finally {
            yield put(PREMIUM.CREATE.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(PREMIUM.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const response = yield call(fetchOneRequest, id);

            yield put(PREMIUM.SHOW.success(response.data.data));
        } catch (error) {
            yield put(PREMIUM.SHOW.failure());
        } finally {
            yield put(PREMIUM.SHOW.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(PREMIUM.UPDATE.TRIGGER);

        try {
            const formData = {
                start_date: request.payload.values.start_date,
                end_date: request.payload.values.end_date,
                club_id: request.payload.values.club_id,
            }

            const response = yield call(editRequest, {
                id: request.payload.id,
                payload: formData,
            });

            yield put(PREMIUM.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(PREMIUM.UPDATE.failure());
        } finally {
            yield put(PREMIUM.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(PREMIUM.DELETE.TRIGGER);

        try {
            yield call(deleteRequest, request.payload);
            yield put(PREMIUM.DELETE.success(request.payload));
        } catch (error) {
            yield put(PREMIUM.DELETE.failure());
        } finally {
            yield put(PREMIUM.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        showFlow(),
        editFlow(),
        deleteFlow(),
    ]);
}