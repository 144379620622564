import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import styles from './BoxHeading.styles';

const BoxTitle = props => {
    const {  icon, onClick, title } = props;
    const classes = styles();

    return (
        <Typography variant="h5" component="h2">
            { title }

            { icon && (
                <IconButton
                    color="primary"
                    component="span"
                    onClick={ onClick }
                    className={ classes.button }
                    size="small"
                >
                    <Icon>{ icon }</Icon>
                </IconButton>
            )}
        </Typography>
    );
};

BoxTitle.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
};

export default BoxTitle;