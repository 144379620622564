import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { sortCaseInsensitive } from 'utilities/sort';
import { OCCASIONS } from 'store/constants';
import {
    defaultOptions,
    getMaterialTableTranslation
} from 'utilities/materialTable';

const OccasionsShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const occasionId = parseInt(params.occasionId);
    const active = useSelector(state => state.occasion.active);

    const training = active?.id === occasionId ? active : null;
    const exercises = training?.exercises?.data ? training.exercises.data : [];
    const loading = useSelector(state => state.occasion.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);
    const tableOptions = {
        ...defaultOptions,
        exportButton: false,
    }

    const pageTitle = useIntl().formatMessage({ id: 'occasions.title' });
    const error = useSelector(state => state.occasion.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    const columns = [ 
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'exercises.label.title' }),
            field: 'title',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'title'),
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.description' }),
            field: 'description',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'description'),
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.difficulty' }),
            field: 'difficulty',
            render: rowData => <Rating name="disabled" value={ parseInt(rowData.difficulty) } disabled />
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/occasions/${occasionId}/exercises/${rowData.id}`)
        },
    ];

    useEffect(() => {
        dispatch(OCCASIONS.SHOW.trigger(occasionId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={3}>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ exercises }
                            options={ tableOptions }
                            actions={ actions }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default OccasionsShow;
