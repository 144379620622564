import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { PERIODS } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, post } from 'utilities/api';
import { deleteObjectWithIdFromArray } from 'utilities/array';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.periods.index);
}

export function* createRequest({token, payload}) {
    return yield post(token, Api.requests.periods.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patch(token, Api.requests.periods.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.periods.delete(id));
}

export function* addTrainingRequest({token, periodId, trainingId, payload, headers = {}}) {
    return yield patch(token, Api.requests.periods.training.update(periodId, trainingId), payload, headers);
}

export function* deleteTrainingRequest({token, periodId, trainingId, headers = {}}) {
    return yield deletePost(token, Api.requests.periods.training.delete(periodId, trainingId), headers);
}

export function* fetchFlow() {
    while (true) {
        yield take(PERIODS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const periods = response.data.data;

            yield put(PERIODS.FETCH.success(periods));
        } catch (error) {
            yield put(PERIODS.FETCH.failure());
        } finally {
            yield put(PERIODS.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(PERIODS.CREATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = {
                start_date: request.payload.start_date,
                end_date: request.payload.end_date,
                internal: request.payload.internal,
            }
            const response = yield call(createRequest, { token, payload: formData });

            yield put(PERIODS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(PERIODS.CREATE.failure());
        } finally {
            yield put(PERIODS.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(PERIODS.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = {
                start_date: request.payload.values.start_date,
                end_date: request.payload.values.end_date,
                internal: request.payload.values.internal,
            }
            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(PERIODS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(PERIODS.UPDATE.failure());
        } finally {
            yield put(PERIODS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(PERIODS.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(PERIODS.DELETE.success(request.payload));
        } catch (error) {
            yield put(PERIODS.DELETE.failure());
        } finally {
            yield put(PERIODS.DELETE.fulfill());
        }
    }
}

export function* addTrainingFlow() {
    while (true) {
        const request = yield take(PERIODS.TRAINING.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const period = request.payload.period;
            const training = request.payload.training;

            yield call(addTrainingRequest, {
                token,
                periodId: period.id,
                trainingId: training.id
            });

            period.trainings.data = period.trainings.data.concat(training);

            yield put(PERIODS.TRAINING.UPDATE.success(period))
        } catch (error) {
            yield put(PERIODS.TRAINING.UPDATE.failure());
        } finally {
            yield put(PERIODS.TRAINING.UPDATE.fulfill());
        }
    }
}

export function* deleteTrainingFlow() {
    while (true) {
        const request = yield take(PERIODS.TRAINING.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);
            const period = request.payload.period;
            const training = request.payload.training;

            yield call(deleteTrainingRequest, {
                token,
                periodId: period.id,
                trainingId: training.id
            });

            period.trainings.data = deleteObjectWithIdFromArray(period.trainings.data, training.id);

            yield put(PERIODS.TRAINING.DELETE.success(period))
        } catch (error) {
            yield put(PERIODS.TRAINING.DELETE.failure());
        } finally {
            yield put(PERIODS.TRAINING.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        addTrainingFlow(),
        deleteTrainingFlow(),
    ]);
}