import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: '#333333',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },

    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default styles;