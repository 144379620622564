import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClubPlayerAssessmentsTable from 'contents/tables/ClubPlayerAssessmentsTable';
import { CLUB } from 'store/constants';

const ClubPlayerAssessments = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const clubPlayerId = parseInt(params.clubPlayerId);
    const assessments = useSelector(state => state.clubPlayerAssessment.assessments);
    const reducerClubPlayerId = useSelector(state => state.clubPlayerAssessment.clubPlayerId);
    const error = useSelector(state => state.clubPlayerAssessment.error);
    const fetched = clubPlayerId === reducerClubPlayerId;

    useEffect(() => {
        if (! fetched) {
            dispatch(CLUB.PLAYER.ASSESSMENT.FETCH.trigger(clubPlayerId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetched, clubPlayerId]);

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    return (
        <ContentContainer title="Speler: evaluaties" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <ClubPlayerAssessmentsTable
                            data={ assessments }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClubPlayerAssessments;