import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { ACCOUNTS } from 'store/constants';

    const Accounts = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.account.fetched);
    const accounts = useSelector(state => state.account.accounts);
    const loading = useSelector(state => state.account.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'accounts.title' });
    const error = useSelector(state => state.account.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.account.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const yesLabel = useIntl().formatMessage({ id: 'string.yes' });
    const noLabel = useIntl().formatMessage({ id: 'string.no' });

    const columns = [
        {
            title: useIntl().formatMessage({ id: 'accounts.label.email' }),
            field: 'email',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'email'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'accounts.label.name' }),
            field: 'full_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'full_name'),
        },
        {
            width: 150,
            title: useIntl().formatMessage({ id: 'accounts.label.clubmanager' }),
            render: row => row.roles.includes('clubmanager') ? yesLabel : noLabel,
            sorting: false,
        },
    ];

        const actions = [
            {
                icon: 'add_box',
                tooltip: localization.body.addTooltip,
                isFreeAction: true,
                onClick: (event) => history.push('accounts/create')
            },
        ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(ACCOUNTS.DELETE.trigger(data.id));

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(ACCOUNTS.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 9 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ accounts }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default Accounts;
