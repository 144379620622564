import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './NewsShow.styles';
import ValueBlock from 'components/ValueBlock';
import { NEWS } from 'store/constants';
import { toast } from 'react-toastify';

const NewsShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetched = useSelector(state => state.news.fetched);
    const loading = useSelector(state => state.news.loading);
    const classes = styles();

    const news = useSelector(state => state.news.news);
    const newsItem = news.filter(object => object.id === parseInt(params.newsId))[0];
    const title = news.length > 0 ? newsItem.title : '';
    const titleLabel = useIntl().formatMessage({ id: 'news.label.title' });
    const message = news.length > 0 ? newsItem.message : '';
    const messageLabel = useIntl().formatMessage({ id: 'news.label.message' });
    const image = news.length > 0 ? newsItem.image : '';
    const imageLabel = useIntl().formatMessage({ id: 'news.label.image' });
    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(NEWS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: NEWS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ title } backButton={ true }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ titleLabel } value={ title } />
                            <ValueBlock label={ messageLabel } value={ message } />
                        </Paper>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <img src={ image } alt={ imageLabel } className="responsive-image" />
                        </Paper>
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default NewsShow;
