import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './LoadingBackdrop.styles';

const LoadingBackdrop = props => {
    const classes = styles();
    const { message, open, handleClose } = props;

    return (
        <Backdrop className={ classes.root } open={ open } onClick={ handleClose }>
            <CircularProgress color="inherit" />

            <Box mt={ 2 }>
                <Typography variant="body1" className={ classes.message }>
                    { message }
                </Typography>
            </Box>

        </Backdrop>
    );
};

LoadingBackdrop.propTypes = {
    message: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.bool,
};

export default LoadingBackdrop;
