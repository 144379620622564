import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import EvaluationForm from 'contents/forms/EvaluationForm';
import LoadingBackdrop from 'components/LoadingBackdrop';
import LoadingIndicator from 'components/LoadingIndicator';
import { CLUB, EVALUATIONS, SURVEYS } from 'store/constants';

const EvaluationCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userClubId = useSelector(state => state.profile.club_id);
    const teamsClubId = useSelector(state => state.clubTeam.clubId);
    const teams = useSelector(state => state.clubTeam.teams);
    const surveys = useSelector(state => state.survey.surveys);
    const loading = useSelector(state => state.evaluation.loading);
    const created = useSelector(state => state.evaluation.created);
    const error = useSelector(state => state.evaluation.error);
    const fetched = userClubId && userClubId === teamsClubId;
    const teamOptions = teams.map(team => ({
        label: team.name,
        value: team.id,
    }));
    const surveyOptions = surveys.map(survey => ({
        label: survey.name,
        value: survey.id,
    }))

    const onSubmit = values => {
        dispatch(EVALUATIONS.CREATE.trigger(values));
    };

    useEffect(() => {
        dispatch(SURVEYS.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            userClubId &&
            userClubId !== teamsClubId
        ) {
            dispatch(CLUB.TEAM.FETCH.trigger(userClubId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userClubId])

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Evaluatieverzoeken zijn verstuurd');

            history.push('/evaluations')
        }
    }, [created, history])

    return (
        <ContentContainer title="Evaluatie aanmaken" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <EvaluationForm
                            surveys={ surveyOptions }
                            teams={ teamOptions }
                            onSubmit={ onSubmit }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }

            <LoadingBackdrop open={ loading } message="Bezig met versturen van evaluatieverzoeken" />
        </ContentContainer>
    );
};

export default EvaluationCreate;
