import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import TeamAdminsTable from 'contents/tables/TeamAdminsTable';
import { removeEmptyKeys } from 'utilities/object';
import { TEAM } from 'store/constants';

const TeamAdmins = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const teamId = parseInt(params.teamId);
    const admins = useSelector(state => state.teamAdmin.admins);
    const reducerTeamId = useSelector(state => state.teamAdmin.teamId);
    const error = useSelector(state => state.teamAdmin.error);
    const unknownUser = useSelector(state => state.teamAdmin.unknownUser);
    const created = useSelector(state => state.teamAdmin.created);
    const deleted = useSelector(state => state.teamAdmin.deleted);
    const fetched = teamId === reducerTeamId;

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = removeEmptyKeys(data);

            dispatch(TEAM.ADMIN.CREATE.trigger({
                teamId,
                values,
            }))

            resolve();
        })
    }

    const onDelete = data => {
        return new Promise((resolve) => {
            dispatch(TEAM.ADMIN.DELETE.trigger({
                teamId,
                adminId: data.id,
            }));

            resolve();
        })
    };

    useEffect(() => {
        if (! fetched) {
            dispatch(TEAM.ADMIN.FETCH.trigger(teamId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetched, teamId]);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (unknownUser === true) {
            toast.error('Gebruiker is onbekend');
        }
    }, [unknownUser]);

    useEffect(() => {
        if (created) {
            toast.success('Admin is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (deleted) {
            toast.success('Admin is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Team: admins" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <TeamAdminsTable
                            data={ admins }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default TeamAdmins;