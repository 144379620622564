import PropTypes from 'prop-types';

import { reverseToReadableDate } from 'utilities/date';
import ValueBlock from 'components/ValueBlock';

const UserDetails = props => {
    const { user } = props;

    const activationDate = user?.email_verified_at ?
        reverseToReadableDate(user.email_verified_at) : '';

    return (
        <>
            <ValueBlock
                label="Voornaam"
                value={ user?.first_name }
            />
            <ValueBlock
                label="Achternaam"
                value={ user?.last_name }
            />
            <ValueBlock
                label="E-mailadres"
                value={ user?.email }
            />
            <ValueBlock
                label="Activatiedatum"
                value={ activationDate }
            />
        </>
    );
};

UserDetails.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserDetails;