import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const TeamEvaluationsTable = props => {
    const { data, teamId } = props;
    const history = useHistory();

    const columns = [
        {
            defaultSort: 'desc',
            field: 'date',
            render: rowData => reverseToReadableDate(rowData.date),
            title: 'Datum',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a.survey.data, b.survey.data, 'name'),
            field: 'survey.data.name',
            title: 'Vragenlijst',
        },
        {
            field: 'is_private',
            title: 'Afgeschermd',
            type: 'boolean',
        },
        {
            field: 'invite_players',
            title: 'Spelers uitgenodigd',
            type: 'boolean',
        },
        {
            field: 'invite_goalkeepers',
            title: 'Keepers uitgenodigd',
            type: 'boolean',
        },
        {
            field: 'invites_open',
            title: 'Open uitnodigingen',
            type: 'numeric',
        },
        {
            field: 'number_of_assessments',
            title: 'Reacties',
            type: 'numeric',
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/teams/${teamId}/evaluations/${rowData.id}`)
        },
    ];

    return (
        <MaterialTable
            title="Clubs"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
        />
    );
};

TeamEvaluationsTable.propTypes = {
    data: PropTypes.array.isRequired,
    teamId: PropTypes.number.isRequired,
};

export default TeamEvaluationsTable;