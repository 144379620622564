import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { useIntl } from 'react-intl';

const OrdersTable = props => {
    const { data } = props;
    const history = useHistory();

    const columns = [
        {
            title: 'Naam',
            field: 'player.data.full_name',
            // customSort: (a, b) => sortCaseInsensitive(a?.player?.data, b?.player?.data, 'full_name'),
            defaultSort: 'asc',
        },
        {
            title: 'E-mail ouder',
            field: 'parent_email',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'parent_email'),
        },
        {
            title: 'Club',
            field: 'club',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'club'),
        },
        {
            title: 'Team',
            field: 'team',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'team'),
        },
        {
            title: 'Dieet',
            field: 'diet',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'diet'),
        },
        {
            title: 'Shirtmaat',
            field: 'shirt_size',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'shirt_size'),
        },
        {
            title: 'In de groep met',
            field: 'group_with',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'group_with'),
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/orders/${rowData.id}`)
        },
    ];

    return (
        <MaterialTable
            title="Events"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
        />
    );
};

OrdersTable.propTypes = {
    data: PropTypes.array.isRequired,
};

export default OrdersTable;