import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { EXERCISE_TAGS } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest() {
    const token = yield select(getToken);

    return yield get(token, Api.requests.exerciseTags.index);
}

export function* fetchOneRequest(id) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.exerciseTags.show(id));
}

export function* fetchFlow() {
    while (true) {
        yield take(EXERCISE_TAGS.FETCH.TRIGGER);

        try {
            const response = yield call(fetchAllRequest);
            const exerciseTags = response.data.data;

            yield put(EXERCISE_TAGS.FETCH.success(exerciseTags));
        } catch (error) {
            yield put(EXERCISE_TAGS.FETCH.failure());
        } finally {
            yield put(EXERCISE_TAGS.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(EXERCISE_TAGS.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const response = yield call(fetchOneRequest, id);

            yield put(EXERCISE_TAGS.SHOW.success(response.data.data));
        } catch (error) {
            yield put(EXERCISE_TAGS.SHOW.failure());
        } finally {
            yield put(EXERCISE_TAGS.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
    ]);
}