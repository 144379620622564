import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TextInput from '../TextInput';
import CheckboxInput from '../CheckboxInput';
import styles from './RegistrationForm.styles';

const RegistrationForm = props => {
    const {
        emailLabel,
        firstNameLabel,
        infixLabel,
        lastNameLabel,
        passwordLabel,
        passwordConfirmationLabel,
        privacyPolicyLabel,
        termsAndConditionsLabel,
        submitButtonText,
        onSubmit,
        minimumLengthMessage,
        emailFieldMessage,
        equalFieldMessage,
        requiredFieldMessage,
    } = props;

    const classes = styles();

    const initialValues = {
        first_name: '',
        infix: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        terms: false,
        privacy: false,
    };

    const validationSchemaObject = yup.object().shape({
        first_name: yup.string().required(requiredFieldMessage),
        infix: yup.string(),
        last_name: yup.string().required(requiredFieldMessage),
        email: yup.string().email(emailFieldMessage).required(requiredFieldMessage),
        password: yup.string()
            .test('len', minimumLengthMessage, val => {
                if (val) {
                    return val.length >= 8;
                }

                return false;
            }),
        password_confirmation: yup.string().required(requiredFieldMessage)
            .oneOf([yup.ref('password'), null], equalFieldMessage),
        terms: yup.bool().oneOf([true], requiredFieldMessage),
        privacy: yup.bool().oneOf([true], requiredFieldMessage),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <form onSubmit={ handleSubmit }>
                    <Grid container spacing={ 4 }>
                        <Grid item xs={ 6 } md={ 8 }>
                            <Field
                                name={ 'first_name' }
                                label={ firstNameLabel }
                                component={ TextInput }
                                errorMessage={ errors['first_name'] }
                                touched={ touched['first_name'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 } md={ 4 }>
                            <Field
                                name={ 'infix' }
                                label={ infixLabel }
                                component={ TextInput }
                                errorMessage={ errors['infix'] }
                                touched={ touched['infix'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Field
                                name={ 'last_name' }
                                label={ lastNameLabel }
                                component={ TextInput }
                                errorMessage={ errors['last_name'] }
                                touched={ touched['last_name'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Field
                                name={ 'email' }
                                label={ emailLabel }
                                component={ TextInput }
                                errorMessage={ errors['email'] }
                                touched={ touched['email'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Field
                                name={ 'password' }
                                label={ passwordLabel }
                                component={ TextInput }
                                errorMessage={ errors['password'] }
                                touched={ touched['password'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                type={ 'password' }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Field
                                name={ 'password_confirmation' }
                                label={ passwordConfirmationLabel }
                                component={ TextInput }
                                errorMessage={ errors['password_confirmation'] }
                                touched={ touched['password_confirmation'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                type={ 'password' }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>
                    </Grid>

                    <Box mt={ 2 }>
                        <Grid container spacing={ 0 }>
                            <Grid item xs={ 12 }>
                                <Field
                                    name={ 'terms' }
                                    label={ termsAndConditionsLabel }
                                    component={ CheckboxInput }
                                    setFieldValue={ setFieldValue }
                                    errorMessage={ errors['terms'] }
                                    touched={ touched['terms'] }
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Grid>

                            <Grid item xs={ 12 }>
                                <Field
                                    name={ 'privacy' }
                                    label={ privacyPolicyLabel }
                                    component={ CheckboxInput }
                                    setFieldValue={ setFieldValue }
                                    errorMessage={ errors['privacy'] }
                                    touched={ touched['privacy'] }
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            type="submit"
                            size="large"
                            disabled={ isSubmitting }
                            color={ 'primary' }
                            variant={ 'contained' }
                            className={ classes.button }
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

RegistrationForm.propTypes = {
    emailLabel: PropTypes.string,
    firstNameLabel: PropTypes.string,
    infixLabel: PropTypes.string,
    lastNameLabel: PropTypes.string,
    passwordLabel: PropTypes.string,
    passwordConfirmationLabel: PropTypes.string,
    privacyPolicyLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    termsAndConditionsLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    submitButtonText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    minimumLengthMessage: PropTypes.string,
    emailFieldMessage: PropTypes.string,
    equalFieldMessage: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
};

RegistrationForm.defaultProps = {
    emailLabel: 'E-mail',
    firstNameLabel: 'First name',
    infixLabel: 'Infix',
    lastNameLabel: 'Last name',
    passwordLabel: 'Password',
    passwordConfirmationLabel: 'Repeat password',
    privacyPolicyLabel: 'Privacy policy',
    termsAndConditionsLabel: 'Terms and conditions',
    submitButtonText: 'Submit',
    minimumLengthMessage: 'Minimum characters not reached',
    emailFieldMessage: 'Invalid e-mail',
    equalFieldMessage: 'Fields should be equal',
    requiredFieldMessage: 'Required',
};

export default RegistrationForm;
