import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    active: {
        color: theme.palette.primary.main,
    },

    list: {
        margin: 0,
        padding: 0,
    },

    listItem: {
        margin: 0,
        marginLeft: theme.spacing(2.5),
        padding: 0,
        fontSize: 12,
    },
}));

export default styles;