import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { NEWS } from 'store/constants';
import { reverseToReadableDate } from 'utilities/date';

const News = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.news.fetched);
    const news = useSelector(state => state.news.news);
    const loading = useSelector(state => state.news.loading);
    const locale = useSelector(state => state.persist.language);
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'news.title' });
    const error = useSelector(state => state.news.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.news.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'news.label.title' }),
            field: 'title',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'title'),
        },
        {
            title: useIntl().formatMessage({ id: 'news.label.message' }),
            field: 'message',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'message'),
        },
        {
            title: useIntl().formatMessage({ id: 'news.label.date' }),
            render: rowData => reverseToReadableDate(rowData.date),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'date'),
            defaultSort: 'desc',
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('news/create')
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`news/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`news/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(NEWS.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(NEWS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: NEWS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ news }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default News;
