import { makeStyles } from '@material-ui/core/styles';

const size = 30;

const styles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: theme.spacing(1),
        cursor: 'move',
        backgroundColor: '#4A9945',
        width: size + theme.spacing(2),
        height: size + theme.spacing(2),
    },

    icon: {
        position: 'relative',
        width: size,
        height: size,
    },
}));

export default styles;