import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightMedium,
    },

    list: {
        marginLeft: theme.spacing(2),
    },

    details: {
        flexDirection: 'column',
    },
}));

export default styles;