module.exports = {
    '404.heading': 'Sorry...',
    '404.subheading': 'We couldn\'t find that page',
    '404.title': '404',

    'accounts.button.create': 'Add user',
    'accounts.create.loading': 'Adding user',
    'accounts.create.title': 'Accounts: add user',
    'accounts.title': 'Accounts',
    'accounts.label.clubmanager': 'Clubmanager',
    'accounts.label.email': 'E-mail',
    'accounts.label.name': 'Naam',

    'app.name': 'Go4Hockey online',

    'assessments.categories.button.create': 'Add assessment category',
    'assessments.categories.button.update': 'Edit assessment category',
    'assessments.categories.create.loading': 'Adding assessment category',
    'assessments.categories.create.title': 'Add assessment category',
    'assessments.categories.title': 'Assessment categories',
    'assessments.categories.update.loading': 'Updating assessment category',
    'assessments.categories.update.title': 'Edit assessment category',
    'assessments.create.categoryButton': 'Start assessment',
    'assessments.create.categoryHeading': 'Assessment categories:',
    'assessments.create.loading': 'Saving assessment',
    'assessments.create.submitButton': 'Save assessment',
    'assessments.create.title': 'Add assessment',
    'assessments.create.topicHeading': 'Choose your assessment topic:',
    'assessments.criteria.button.create': 'Add criterion',
    'assessments.criteria.button.update': 'Edit criterion',
    'assessments.criteria.create.loading': 'Adding criterion',
    'assessments.criteria.create.title': 'Add criterion',
    'assessments.criteria.title': 'Assessment criteria',
    'assessments.criteria.update.loading': 'Updating criterion',
    'assessments.criteria.update.title': 'Edit criterion',
    'assessments.label.category': 'Category',
    'assessments.label.criterion': 'Criterion',
    'assessments.label.date': 'Date',
    'assessments.label.explanation': 'Choice',
    'assessments.label.level': 'Level',
    'assessments.label.name': 'Players',
    'assessments.label.order': 'Order',
    'assessments.label.respondent': 'Respondent',
    'assessments.label.team': 'Team',
    'assessments.label.timestamp': 'Timestamp',
    'assessments.label.topic': 'Topic',
    'assessments.label.trainer': 'Trainer',
    'assessments.levels.button.create': 'Add criterion answer',
    'assessments.levels.button.update': 'Edit criterion answer',
    'assessments.levels.create.loading': 'Adding criterion answer',
    'assessments.levels.create.title': 'Add criterion answer',
    'assessments.levels.title': 'Assessment criterion answers',
    'assessments.levels.update.loading': 'Updating criterion answer',
    'assessments.levels.update.title': 'Edit criterion answer',
    'assessments.requests.create.button': 'Send assessment request',
    'assessments.requests.create.loading': 'Sending assessment request',
    'assessments.requests.create.title': 'Create assessment request',
    'assessments.requests.create.teams.heading': 'Select team:',
    'assessments.requests.create.topics.heading': 'Select topic:',
    'assessments.requests.create.respondents.heading': 'Select respondents:',
    'assessments.requests.title': 'Assessment requests',
    'assessments.requests.show.title': 'Assessment request: ',
    'assessments.show.title': 'Assessment',
    'assessments.title': 'Assessments',
    'assessments.topics.button.create': 'Save assessment topic',
    'assessments.topics.button.update': 'Edit assessment topic',
    'assessments.topics.create.loading': 'Saving assessment topic',
    'assessments.topics.create.title': 'Add assessment topic',
    'assessments.topics.update.loading': 'Updating assessment topic',
    'assessments.topics.update.title': 'Update assessment topic',
    'assessments.topics.title': 'Assessment topics',
    'assessments.update.loading': 'Updating assessment',
    'assessments.update.submitButton': 'Update assessment',
    'assessments.update.title': 'Edit assessment',

    'clubs.button.add-clubmanager': 'Add clubmanager',
    'clubs.button.add-user': 'Add user',
    'clubs.button.create': 'Add club',
    'clubs.button.update': 'Update club',
    'clubs.create.loading': 'Creating club',
    'clubs.create.title': 'Add club',
    'clubs.title': 'Clubs',
    'clubs.label.accounts': 'Accounts',
    'clubs.label.address': 'Address',
    'clubs.label.city': 'City',
    'clubs.label.clubmanager': 'Clubmanager',
    'clubs.label.clubmanager.email': 'E-mail',
    'clubs.label.clubmanager.name': 'Name',
    'clubs.label.country': 'Country',
    'clubs.label.name': 'Club',
    'clubs.label.postal': 'Postal',
    'clubs.label.users': 'Club accounts',
    'clubs.update.loading': 'Updating club',

    'dashboard.title': 'Dashboard',

    'dialog.exercise.description': 'Description',
    'dialog.exercise.explanation': 'Explanation',
    'dialog.exercise.map': 'Map',
    'dialog.exercise.noVideo': 'This video is not available',
    'dialog.exercise.title': 'Title',
    'dialog.exercise.video': 'Video',
    'dialog.button.add': 'Add',
    'dialog.button.close': 'Close',
    'dialog.button.update': 'Update',

    'events.button.addDate': 'Add date',
    'events.button.save': 'Add event',
    'events.button.update': 'Update event',
    'events.create.loading': 'Creating event',
    'events.create.title': 'Add event',
    'events.label.club': 'Club',
    'events.label.date': 'Date',
    'events.label.dates': 'Dates',
    'events.label.description': 'Description',
    'events.label.explanation': 'Explanation',
    'events.label.image': 'Image',
    'events.label.name': 'Event',
    'events.label.places': 'Places',
    'events.label.players': 'Players',
    'events.label.price': 'Price',
    'events.label.trainers': 'Trainers',
    'events.label.trainings': 'Trainings',
    'events.label.type': 'Type',
    'events.orders.label.club': 'Club',
    'events.orders.label.diet': 'Diet',
    'events.orders.label.group_with': 'Group with',
    'events.orders.label.name': 'Name',
    'events.orders.label.parent_email': 'E-mail parent',
    'events.orders.label.shirt_size': 'Shirt size',
    'events.orders.label.team': 'Team',
    'events.orders.title': 'Event orders',
    'events.title': 'Events',
    'events.update.loading': 'Uploading and updating event',
    'events.updated.title': 'Edit event',

    'exercises.create.loading': 'Uploading and creating exercise',
    'exercises.create.title': 'Add exercise',
    'exercises.button.update': 'Update exercise',
    'exercises.button.save': 'Add exercise',
    'exercises.title': 'Exercises',
    'exercises.label.comments': 'Comments',
    'exercises.label.description': 'Description',
    'exercises.label.difficulty': 'Difficulty',
    'exercises.label.explanation': 'Explanation',
    'exercises.label.extension': 'Extension',
    'exercises.label.image': 'Map',
    'exercises.label.instruction': 'Instruction',
    'exercises.label.pdf': 'Download PDF',
    'exercises.label.trainer': 'Trainer',
    'exercises.label.trainings': 'Trainings',
    'exercises.label.tags': 'Tags',
    'exercises.label.title': 'Exercise',
    'exercises.label.video': 'Video',
    'exercises.latest': 'Latest exercises',
    'exercises.update.loading': 'Uploading and updating exercise',

    'exerciseTags.count': 'Number of exercises',
    'exerciseTags.tag': 'Type',
    'exerciseTags.title': 'Exercise collection',

    'feedback.button.create': 'Add feedback',
    'feedback.button.update': 'Edit feedback',
    'feedback.create.loading': 'Uploading and creating feedback',
    'feedback.create.title': 'Add feedback',
    'feedback.label.feedback': 'Feedback',
    'feedback.label.image': 'Photo',
    'feedback.label.player': 'Players',
    'feedback.label.publish': 'Publish',
    'feedback.label.published': 'Published at',
    'feedback.label.trainer': 'Trainer',
    'feedback.label.unpublished': 'Unpublished',
    'feedback.title': 'Feedback',
    'feedback.update.loading': 'Uploading and updating feedback',
    'feedback.update.title': 'Edit feedback',

    'general.back': 'Back to overview',

    'highlight.delete.button_text': 'Highlight',
    'highlight.delete.success_message': 'Highlight removed',
    'highlight.label.highlight': 'Highlight',
    'highlight.label.highlights': 'Highlights',
    'highlight.label.minute': 'Minute',
    'highlight.store.button_text': 'Add highlight',
    'highlight.store.heading': 'Add highlight',
    'highlight.store.loading': 'Adding highlight',
    'highlight.store.success_message': 'Highlight added',
    'highlight.title': 'Highlights',

    'language.dutch': 'Dutch',
    'language.english': 'English',

    'lineup.label.player': 'Players',
    'lineup.label.position': 'Position',
    'lineup.label.substitute': 'Substitute',
    'lineup.store.loading': 'Saving lineup',
    'lineup.store.submit_button_text': 'Save lineup',
    'lineup.store.success_message': 'Lineup is saved',
    'lineup.title': 'Lineup',

    'login.error': 'Incorrect credentials',
    'login.intro': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit per mauris sagittis ultrices morbi consectetur.',
    'login.label.loginButton': 'Login',
    'login.label.password': 'Password',
    'login.label.passwordRecovery': 'Forgot password?',
    'login.label.register': 'Sign up for an account',
    'login.label.username': 'E-mailaddress',
    'login.title': 'Login',

    'mapDrawing.downloadButton': 'Download map',
    'mapDrawing.explanation': '* Doubleclick to remove an item',
    'mapDrawing.fieldPanel.indoor': 'Indoor',
    'mapDrawing.fieldPanel.outdoor': 'Outdoor',
    'mapDrawing.imagePanel.icon.playerBallBlack': 'Players with ball (black)',
    'mapDrawing.imagePanel.icon.playerBallBlue': 'Players with ball (blue)',
    'mapDrawing.imagePanel.icon.playerBallOrange': 'Players with ball (orange)',
    'mapDrawing.imagePanel.icon.playerBlack': 'Players without ball (black)',
    'mapDrawing.imagePanel.icon.playerBlue': 'Players without ball (blue)',
    'mapDrawing.imagePanel.icon.playerOrange': 'Players without ball (orange)',
    'mapDrawing.imagePanel.icon.coach': 'Coach',
    'mapDrawing.imagePanel.icon.coneBlue': 'Cone blue',
    'mapDrawing.imagePanel.icon.coneOrange': 'Cone orange',
    'mapDrawing.imagePanel.icon.coneRed': 'Cone red',
    'mapDrawing.imagePanel.icon.coneWhite': 'Cone white',
    'mapDrawing.imagePanel.icon.keeper': 'Keeper',
    'mapDrawing.imagePanel.icon.stick': 'Shoot',
    'mapDrawing.imagePanel.icon.goal': 'Goal',
    'mapDrawing.imagePanel.icon.obstacle': 'Obstacle',
    'mapDrawing.imagePanel.icon.ball': 'Ball',
    'mapDrawing.resetButton': 'Reset',
    'mapDrawing.textPanel.text.button': 'Add text to map',
    'mapDrawing.textPanel.text.placeholder': 'Text...',
    'mapDrawing.title': 'Drawing tool',
    'mapDrawing.toolbar.item.arrow-1': 'Pass',
    'mapDrawing.toolbar.item.arrow-2': 'Move with ball',
    'mapDrawing.toolbar.item.arrow-3': 'Move without ball',
    'mapDrawing.toolbar.item.circle': 'Circle',
    'mapDrawing.toolbar.item.draw': 'Draw',
    'mapDrawing.toolbar.item.move': 'Edit',

    'matches.button.create': 'Add match',
    'matches.button.update': 'Update match',
    'matches.create.loading': 'Adding match',
    'matches.create.title': 'Add match',
    'matches.label.date': 'Date',
    'matches.label.name': 'Match',
    'matches.title': 'Matches',
    'matches.update.loading': 'Updating match',
    'matches.update.title': 'Edit match',

    'news.button.save': 'Create news item',
    'news.create.loading': 'Uploading and creating news item',
    'news.button.update': 'Update news item',
    'news.create.title': 'Create news item',
    'news.label.date': 'Date',
    'news.label.image': 'Image',
    'news.label.message': 'Message',
    'news.label.title': 'Title',
    'news.update.loading': 'Uploading and updating news item',
    'news.update.title': 'Edit news item',
    'news.title': 'News',

    'occasions.label.name': 'Name',
    'occasions.label.division': 'Division',
    'occasions.label.occasion': 'Occasion',
    'occasions.label.numberOfExercises': 'Number of exercises',
    'occasions.label.training': 'Training',
    'occasions.title': 'Trainings',

    'orders.label.amount': 'Amount paid',
    'orders.label.birthday': 'Birthday',
    'orders.label.club': 'Club',
    'orders.label.diet': 'Diet',
    'orders.label.email': 'E-mail',
    'orders.label.event': 'Event',
    'orders.label.full_name': 'Full name',
    'orders.label.group_with': 'Group with',
    'orders.label.mollie_payment_id': 'Mollie payment ID',
    'orders.label.parent_email': 'E-mail parent',
    'orders.label.parent_name': 'Name parent',
    'orders.label.parent_phone': 'Phone parent',
    'orders.label.remarks': 'Remarks',
    'orders.label.shirt_size': 'Shirt size',
    'orders.label.status': 'Status',
    'orders.label.team': 'Team',
    'orders.label.timestamp': 'Timestamp',
    'orders.show.title': 'View event order',
    'orders.title': 'Event orders',

    'passwordRecovery.button.sendMail': 'Send recovery link',
    'passwordRecovery.intro': 'Enter your email to reset password',
    'passwordRecovery.label.email' : 'E-mailaddress',
    'passwordRecovery.mailSend.success': 'We\ve sent you and e-mail with further instructions.',
    'passwordRecovery.mailSend.error': 'E-mailaddress is unknown',
    'passwordRecovery.title': 'Forgot password?',

    'passwordReset.button.resetPassword' : 'Set new password',
    'passwordReset.label.password' : 'Password',
    'passwordReset.label.passwordConfirmation' : 'Repeat password',
    'passwordReset.message.success': 'Your password is updated',
    'passwordReset.message.error': 'Resetlink expired',
    'passwordReset.title': 'Choose new password',

    'periods.button.update': 'Update period',
    'periods.create.title': 'Add period',
    'periods.label.endDate': 'End date',
    'periods.label.internal': 'Internal',
    'periods.label.numberOfTrainings': 'Number of trainings',
    'periods.label.startDate': 'Start date',
    'periods.label.trainings': 'Trainings',
    'periods.show.title': 'View period',
    'periods.title': 'Periods',
    'periods.update.loading': 'Updating period',
    'periods.update.title': 'Edit period',

    'photos.create.loading': 'Uploading photo',
    'photos.create.submit_button_text': 'Add photo',
    'photos.heading.add': 'Add photo',
    'photos.heading.photos': 'Photos',
    'photos.label.delete': 'Delete',
    'photos.label.photo': 'Photo',
    'photos.title': 'Match photos',

    'players.button.create': 'Add player',
    'players.button.import': 'Import players',
    'players.button.update': 'Edit player',
    'players.create.loading': 'Adding player',
    'players.create.title': 'Add player',
    'players.header.add': 'Add player',
    'players.header.import': 'Import players',
    'players.heading.about': 'About',
    'players.heading.events': 'Events',
    'players.heading.linked_accounts': 'Shared access',
    'players.heading.playercard': 'Playercard',
    'players.heading.privacy': 'Privacy',
    'players.heading.teams': 'Teams',
    'players.label.club': 'Club',
    'players.label.email': 'E-mailaddress',
    'players.label.first_name': 'First name',
    'players.label.last_name': 'Last name',
    'players.label.name': 'Name',
    'players.label.privacy.evaluation': 'Evaluations allowed',
    'players.label.privacy.photo': 'Photos allowed',
    'players.label.privacy.video': 'Videos allowed',
    'players.label.stats.stat_1': 'SVDB',
    'players.label.stats.stat_2': 'SPEL',
    'players.label.stats.stat_3': 'LMDB',
    'players.label.stats.stat_4': 'INZE',
    'players.label.stats.stat_5': 'AVDB',
    'players.label.stats.stat_6': 'PLEZ',
    'players.label.team': 'Team',
    'players.label.xls': 'Excel file',
    'players.message.pending': 'No user account',
    'players.show.title': 'Players details',
    'players.stats.button.update': 'Update player stats',
    'players.stats.update.loading': 'Updating player stats',
    'players.stats.update.title': 'Update player stats',
    'players.title': 'Players',
    'players.update.loading': 'Updating player',
    'players.update.title': 'Edit player',

    'premium.create.loading': 'Attaching premium state',
    'premium.create.search_button_text': 'UserSearch user',
    'premium.create.submit_button_text': 'Add premium',
    'premium.create.title': 'Add premium',
    'premium.create.user_not_found': 'E-mail unknown: user not found',
    'premium.label.club': 'Club',
    'premium.label.name': 'Name',
    'premium.label.email': 'E-mail',
    'premium.label.end_date': 'End date',
    'premium.label.start_date': 'Start Date',
    'premium.label.status': 'Status',
    'premium.label.timestamp': 'Timestamp',
    'premium.title': 'Premium',
    'premium.update.submit_button_text': 'Update premium',
    'premium.update.title': 'Edit premium',

    'rating.store.loading': 'Saving ratings',
    'rating.store.submit_button_text': 'Save ratings',
    'rating.store.success_message': 'Ratings are saved successfully',
    'rating.title': 'Match ratings',

    'register.button.submit': 'Register',
    'register.error.existingEmail': 'This e-mailaddress already has an account',
    'register.error.error': 'An error occured. Please try again.',
    'register.label.email': 'E-mailaddress',
    'register.label.firstName': 'First name',
    'register.label.infix': 'infix',
    'register.label.lastName': 'Last name',
    'register.label.login': 'Already have an account? Sign in',
    'register.label.password': 'Password',
    'register.label.passwordConfirmation': 'Repeat password',
    'register.label.privacyPolicy': 'I agree to the privacy policy',
    'register.label.termsAndConditions': 'I agree to the terms and conditions',
    'register.success': 'Account created successfully. Please check your mail to activate your account.',
    'register.title': 'Register',

    'report.label.report': 'Match report',
    'report.store.loading': 'Saving match report',
    'report.store.submit_button_text': 'Save match report',
    'report.store.success_message': 'Match report saved',
    'report.title': 'Match report',

    'sidebar.assessments': 'Assessments',
    'sidebar.assessment-requests': 'Assessment requests',
    'sidebar.club': 'Club',
    'sidebar.clubs': 'Clubs',
    'sidebar.dashboard': 'Dashboard',
    'sidebar.education': 'Education',
    'sidebar.education-plan': 'Education plan',
    'sidebar.events': 'Events',
    'sidebar.exercises': 'Exercises',
    'sidebar.exercise-tags': 'Education plan',
    'sidebar.feedback': 'Feedback',
    'sidebar.internal': 'Internal',
    'sidebar.logout': 'Logout',
    'sidebar.map-drawing-tool': 'Drawing tool',
    'sidebar.matches': 'Matches',
    'sidebar.news': 'News',
    'sidebar.orders': 'Event orders',
    'sidebar.periods': 'Periods',
    'sidebar.personal': 'Personal',
    'sidebar.players': 'Players',
    'sidebar.premium': 'Premium',
    'sidebar.subscription': 'Premium',
    'sidebar.surveys': 'Surveys',
    'sidebar.survey-requests': 'Survey requests',
    'sidebar.teams': 'Teams',
    'sidebar.trainers': 'Trainers',
    'sidebar.trainings': 'Trainings',
    'sidebar.user-exercises': 'My exercises',
    'sidebar.users': 'Users',
    'sidebar.videos': 'Instructional videos',

    'string.yes': 'Yes',
    'string.no': 'No',

    'subscription.label.accountHeading': 'Current account',
    'subscription.label.accountType': 'Account type',
    'subscription.label.expireDate': 'Exoire date',
    'subscription.label.club': 'Club',
    'subscription.label.free': 'Free',
    'subscription.label.premium': 'Premium',
    'subscription.month.description': 'Do you want to try out Go4Hockey Online for a month? Here is your opportunity. This plan will end automatically after a month.',
    'subscription.month.name': 'Month',
    'subscription.name': 'Month',
    'subscription.orderButton.label': 'Order now',
    'subscription.payment.success': 'Payment successfully finished',
    'subscription.payment.pending': 'Payment waiting for approval',
    'subscription.payment.failed': 'Payment cancelled or failed',
    'premium.promo.button_text': 'Become premium',
    'premium.promo.description': 'Go4Hockey online helps all trainers and coaches, regardless experience. Become premium member and get weekly exercises. As premium member you can create your own exercises and even maps as well. Premium coaches have the ability to add match tactics and lineups. Don’t hesitate, become premium.',
    'premium.promo.title': 'Only available for premium members',
    'subscription.title': 'Premium',
    'subscription.year.description': 'Do you want to get the maximum performance out of your team for a year? Join us for a year! You can use our complete solution for a year without having to worry about a renewal. This plan will end automatically after a year.',
    'subscription.year.name': 'Year',

    'surveys.answers.button.create': 'Add answer',
    'surveys.answers.button.update': 'Update answer',
    'surveys.answers.create.loading': 'Adding answer',
    'surveys.answers.create.title': 'Survey: add answer to question',
    'surveys.answers.title': 'Survey: question answers',
    'surveys.answers.update.loading': 'Updating answer',
    'surveys.answers.update.title': 'Survey: edit answer',
    'surveys.label.answer': 'Answer',
    'surveys.label.closed_invites': 'Finished requests',
    'surveys.label.completed': 'Completed',
    'surveys.label.is_private': 'Private',
    'surveys.label.multipleChoice': 'Multiple choice',
    'surveys.label.number_of_invites': 'Total requests',
    'surveys.label.open_invites': 'Open requests',
    'surveys.label.options': 'Options',
    'surveys.label.order': 'Order',
    'surveys.label.player': 'Players',
    'surveys.label.question': 'Question',
    'surveys.label.respondent': 'Respondent',
    'surveys.label.respondents': 'Respondents',
    'surveys.label.timestamp': 'Timestamp',
    'surveys.label.team': 'Team',
    'surveys.label.topic': 'Subject',
    'surveys.questions.button.create': 'Add question',
    'surveys.questions.button.update': 'Edit question',
    'surveys.questions.create.loading': 'Adding question',
    'surveys.questions.create.title': 'Survey: add question',
    'surveys.questions.title': 'Survey: questions',
    'surveys.questions.update.loading': 'Updating question',
    'surveys.questions.update.title': 'Survey: edit question',
    'surveys.requests.create.button': 'Send survey requests',
    'surveys.requests.create.loading': 'Sending survey requests',
    'surveys.requests.create.options.heading': 'Select options:',
    'surveys.requests.create.respondents.heading': 'Select respondents:',
    'surveys.requests.create.teams.heading': 'Select team:',
    'surveys.requests.create.title': 'Create survey request',
    'surveys.requests.create.topics.heading': 'Select topic:',
    'surveys.requests.label.next-button': 'Next',
    'surveys.requests.title': 'Survey requests',
    'surveys.requests.show.title': 'Survey request: ',
    'surveys.topics.button.create': 'Add survey',
    'surveys.topics.button.update': 'Update survey',
    'surveys.topics.create.loading': 'Adding survey',
    'surveys.topics.create.title': 'Add survey',
    'surveys.topics.title': 'Surveys',
    'surveys.topics.update.loading': 'Updating survey',
    'surveys.topics.update.title': 'Update survey',

    'surveys.show.title': 'Survey',
    'surveys.label.date': 'Date',
    'surveys.title': 'Players surveys',

    'table.actions.assessment': 'Assessments',
    'table.actions.feedback': 'Feedback',
    'table.actions.highlights': 'Highlights',
    'table.actions.lineup': 'Lineup',
    'table.actions.photos': 'Photos',
    'table.actions.ratings': 'Rating',
    'table.actions.report': 'Report',
    'table.actions.survey': 'Surveys',
    'table.actions.tactic': 'Tactic',
    'table.actions.view': 'View',

    'tactic.store.success_message': 'Match tactics are saved',
    'tactic.title': 'Match tactics',
    'tactic.label.tactic': 'Match tactics',
    'tactic.label.image': 'Image',
    'tactic.store.submit_button_text': 'Save match tactic',
    'tactic.store.loading': 'Saving match tactic',

    'teams.button.admin.create': 'Add admin',
    'teams.button.create': 'Add team',
    'teams.button.update': 'Update team',
    'teams.create.loading': 'Adding team',
    'teams.create.title': 'Add team',
    'teams.label.admins': 'Admins',
    'teams.label.clubAccess': 'Club access',
    'teams.label.email': 'E-mailaddress',
    'teams.label.name': 'Team',
    'teams.label.numberOfAdmins': 'Number of admins',
    'teams.label.numberOfPlayers': 'Number of players',
    'teams.label.players': 'Players',
    'teams.title': 'Teams',
    'teams.update.loading': 'Updating team',

    'toast.error.emailNotFound': 'E-mailaddress not found',
    'toast.error.main': 'Whoops, an error occurred...',
    'toast.success.created': 'Successfully created',
    'toast.success.delete': 'Deleted successfully',
    'toast.success.updated': 'Successfully updated',

    'trainers.button.create': 'Add trainer',
    'trainers.button.update': 'Update trainer',
    'trainers.create.loading': 'Adding trainer',
    'trainers.create.title': 'Add trainer',
    'trainers.label.email': 'E-mailaddress',
    'trainers.label.name': 'Name',
    'trainers.label.rate': 'Rate',
    'trainers.title': 'Trainers',
    'trainers.update.loading': 'Updating trainer',
    'trainers.update.title': 'Edit trainer',

    'trainings.button.addExercise': 'Add exercise',
    'trainings.button.create': 'Add training',
    'trainings.button.update': 'Update training',
    'trainings.create.title': 'Add training',
    'trainings.create.loading': 'Creating training',
    'trainings.label.division': 'Division',
    'trainings.label.exercise': 'Exercise',
    'trainings.label.exercises': 'Exercises',
    'trainings.label.name': 'Name',
    'trainings.label.numberOfExercises': 'Number of exercises',
    'trainings.label.trainer': 'Trainer',
    'trainings.title': 'Trainings',
    'trainings.update.loading': 'Updating training',
    'trainings.update.title': 'Edit training',

    'validation.error.email': 'Invalid e-mailaddress',
    'validation.error.equal' : 'Fields are not equal',
    'validation.error.fileSize': 'File too large',
    'validation.error.fileType': 'Invalid file type',
    'validation.error.minPasswordLength': 'Minimum of 8 characters',
    'validation.error.maxString': 'Maximaal 255 karakters',
    'validation.error.number': 'Invalid number',
    'validation.error.numberMin': 'Number too low',
    'validation.error.numberMax': 'Number too high',
    'validation.error.required': 'Required field',

    'verify.label.login': 'Go to login',
    'verify.message.alreadyVerified': 'This account was already activated',
    'verify.message.error': 'This verificationlink has expired',
    'verify.message.verifying': 'Just a moment, verifying account...',
    'verify.message.success': 'Your account has been activated. You can now login.',
    'verify.title': 'Verigy account',

    'videos.create.loading': 'Uploading and creating instructional video',
    'videos.create.title': 'Add instructional video',
    'videos.button.update': 'Update instructional video',
    'videos.button.save': 'Add instructional video',
    'videos.label.description': 'Description',
    'videos.label.tags': 'Tags',
    'videos.label.title': 'Name',
    'videos.label.video': 'Video',
    'videos.title': 'Instructional videos',
    'videos.update.loading': 'Uploading and updating instructional video',

    'viewMode.label': 'View mode',
    'viewMode.default': 'Default',
    'viewMode.trainer': 'Trainer',
    'viewMode.coach': 'Coach',
    'viewMode.club': 'Club',
};
