import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';

const OrderParentDetails = props => {
    const { order } = props;

    return (
        <>
            <ValueBlock label="Naam" value={ order?.parent_name } />
            <ValueBlock label="Telefoonnr." value={ order?.parent_phone } />
            <ValueBlock label="E-mail" value={ order?.parent_email } />
            <ValueBlock label="Opmerkingen" value={ order?.remarks } />
        </>
    );
};

OrderParentDetails.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderParentDetails;