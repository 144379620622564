import { AUTH, CLASSIFICATIONS } from '../constants';

const INITIAL_STATE = {
    active: {
        id: null,
        teamId: null,
        closingDate: null,
        players: [],
        reviews: [],
        team: '',
    },
    
    error: false,
    loading: false,
};

const teamsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLASSIFICATIONS.TEAMS.SHOW.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLASSIFICATIONS.TEAMS.SHOW.SUCCESS:
            return {
                ...state,
                active: {
                    id: action.payload.id,
                    teamId: action.payload.teamId,
                    closingDate: action.payload.closingDate,
                    players: action.payload.players,
                    reviews: action.payload.reviews,
                    team: action.payload.team,
                },
            };

        case CLASSIFICATIONS.TEAMS.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLASSIFICATIONS.TEAMS.SHOW.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }
            
        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default teamsReducer;