import { all, call, put, take } from 'redux-saga/effects';

import { Api } from '../../config/api';
import { PLAYER } from '../constants';
import {
    fetchRequest,
    storeRequest,
    updateRequest,
} from '../api';

export function* showFlow() {
    while (true) {
        const request = yield take(PLAYER.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.players.show(id);
            const response = yield call(fetchRequest, endpoint);

            yield put(PLAYER.SHOW.success(response.data.data));
        } catch (error) {
            yield put(PLAYER.SHOW.failure());
        } finally {
            yield put(PLAYER.SHOW.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(PLAYER.CREATE.TRIGGER);

        try {
            const userId = request.payload.userId;
            const payload = request.payload.values;
            const endpoint = Api.requests.players.store(userId);
            const response = yield call(storeRequest, endpoint, payload);

            yield put(PLAYER.CREATE.success(response.data.data));
        } catch (error) {
            yield put(PLAYER.CREATE.failure());
        } finally {
            yield put(PLAYER.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(PLAYER.UPDATE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const endpoint = Api.requests.players.update(playerId);
            const payload = request.payload.values;
            const response = yield call(updateRequest, endpoint, payload);

            yield put(PLAYER.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(PLAYER.UPDATE.failure());
        } finally {
            yield put(PLAYER.UPDATE.fulfill());
        }
    }
}

export function* updateSettingsFlow() {
    while (true) {
        const request = yield take(PLAYER.SETTINGS.UPDATE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const payload = request.payload.values;
            const endpoint = Api.requests.players.update(playerId);
            const response = yield call(updateRequest, endpoint, payload);

            yield put(PLAYER.SETTINGS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(PLAYER.SETTINGS.UPDATE.failure());
        } finally {
            yield put(PLAYER.SETTINGS.UPDATE.fulfill());
        }
    }
}

export function* updateStatsFlow() {
    while (true) {
        const request = yield take(PLAYER.STATS.UPDATE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const payload = request.payload.values;
            const endpoint = Api.requests.players.stats.store(playerId);
            const response = yield call(storeRequest, endpoint, payload);

            yield put(PLAYER.STATS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(PLAYER.STATS.UPDATE.failure());
        } finally {
            yield put(PLAYER.STATS.UPDATE.fulfill());
        }
    }
}

export function* addToEventFlow() {
    while (true) {
        const request = yield take(PLAYER.EVENT.UPDATE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const eventId = request.payload.eventId;
            const endpoint = Api.requests.events.players.update(eventId, playerId);
            const response = yield call(updateRequest, endpoint);

            yield put(PLAYER.SHOW.trigger(playerId));
            yield put(PLAYER.EVENT.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(PLAYER.EVENT.UPDATE.failure());
        } finally {
            yield put(PLAYER.EVENT.UPDATE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        showFlow(),
        createFlow(),
        updateFlow(),
        updateSettingsFlow(),
        updateStatsFlow(),
        addToEventFlow(),
    ]);
}