import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Player } from 'video-react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';

import { EXERCISES } from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ValueBlock from 'components/ValueBlock';
import RatingBlock from 'components/RatingBlock';
import styles from './ExercisesShow.styles';
import Button from '@material-ui/core/Button';

const ExercisesShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const exerciseId = parseInt(params.exerciseId);
    const active = useSelector(state => state.exercise.active);
    const activeLoaded = active ? active.id === exerciseId : false;
    const loading = useSelector(state => state.exercise.loading);
    const classes = styles();

    const exercise = activeLoaded ? active : null;

    const description = exercise ? exercise.description : '';
    const descriptionLabel = useIntl().formatMessage({ id: 'exercises.label.description' });
    const difficulty = exercise ? exercise.difficulty : '';
    const difficultyLabel = useIntl().formatMessage({ id: 'exercises.label.difficulty' });
    const explanation = exercise ? exercise.explanation : '';
    const explanationLabel = useIntl().formatMessage({ id: 'exercises.label.explanation' });
    const extension = exercise ? exercise.extension : '';
    const extensionLabel = useIntl().formatMessage({ id: 'exercises.label.extension' });
    const image = exercise ? exercise.image : '';
    const imageLabel = useIntl().formatMessage({ id: 'exercises.label.image' });
    const instruction = exercise ? exercise.instruction : '';
    const instructionLabel = useIntl().formatMessage({ id: 'exercises.label.instruction' });
    const tags = exercise ? exercise.tags.data.map(tag => tag.name).join(', ') : '';
    const tagsLabel = useIntl().formatMessage({ id: 'exercises.label.tags' });
    const title = exercise ? exercise.title : '';
    const titleLabel = useIntl().formatMessage({ id: 'exercises.label.title' });
    const video = exercise ? exercise.video : '';
    const pdf = exercise ? exercise.pdf : '';
    const pdfLabel = useIntl().formatMessage({ id: 'exercises.label.pdf' });
    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        dispatch(EXERCISES.SHOW.trigger(exerciseId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ title } backButton={ true }>
            { loading || ! activeLoaded ? <LoadingIndicator /> : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ titleLabel } value={ title } />
                            <ValueBlock label={ descriptionLabel } value={ description } />
                            <ValueBlock label={ explanationLabel } value={ explanation } />
                            <ValueBlock label={ instructionLabel } value={ instruction } />
                            <ValueBlock label={ extensionLabel } value={ extension } />
                            <RatingBlock label={ difficultyLabel } value={ parseInt(difficulty) } />
                            <ValueBlock label={ tagsLabel } value={ tags } />
                        </Paper>

                        <Box mt={ 2 }>
                            <Button type="button" color={ 'primary' } variant={ 'contained' } onClick={ () => window.open(pdf) }>
                                { pdfLabel }
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={ 6 }>
                        { image.length ? (
                            <Paper elevation={ 2 } className={ classes.block }>
                                <img src={ image } alt={ imageLabel } className="responsive-image" />
                            </Paper>
                        ) : null }

                        { video.length ? (
                            <Box mt={ 2 }>
                                <Paper elevation={ 2 } className={ classes.block }>
                                    <Player>
                                        <source src={ video } />
                                    </Player>
                                </Paper>
                            </Box>
                        ) : null }
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default ExercisesShow;
