import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './TrainingsShow.styles';
import ValueBlock from 'components/ValueBlock';
import ValueList from 'components/ValueList';
import { TRAININGS } from 'store/constants';

const TrainingsShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const classes = styles();
    const trainingId = parseInt(params.trainingId);
    const training = useSelector(state => state.training.active);
    const fetched = trainingId === training?.id;
    const name = training ? training.name : '';
    const nameLabel = useIntl().formatMessage({ id: 'trainings.label.name' });
    const division = training ? training.division.data.name : '';
    const divisionLabel = useIntl().formatMessage({ id: 'trainings.label.division' });
    const exercises = training ? training.exercises.data.map(exercise => {
        const name = (
            <span>
                { exercise.title }
                { exercise.internal_trainer ? <strong> - { exercise.internal_trainer }</strong> : null }
            </span>
        );

        return name;
    }) : [];
    const exercisesLabel = useIntl().formatMessage({ id: 'trainings.label.exercises' });
    const numberOfExercisesLabel = useIntl().formatMessage({ id: 'trainings.label.numberOfExercises' });
    const error = useSelector(state => state.training.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(TRAININGS.SHOW.trigger(trainingId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ name } backButton={ true }>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ nameLabel } value={ name } />
                            <ValueBlock label={ divisionLabel } value={ division } />
                            <ValueBlock label={ numberOfExercisesLabel } value={ exercises.length } />

                            <ValueList
                                label={ exercisesLabel }
                                values={ exercises }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default TrainingsShow;
