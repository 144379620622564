import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClassificationForm from 'contents/forms/ClassificationForm';
import { CLASSIFICATIONS, CLUB } from 'store/constants';

const ClassificationCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userClubId = useSelector(state => state.profile.club_id);
    const teamsClubId = useSelector(state => state.clubTeam.clubId);
    const teams = useSelector(state => state.clubTeam.teams);
    const created = useSelector(state => state.classification.created);
    const error = useSelector(state => state.classification.error);
    const fetched = userClubId && userClubId === teamsClubId;
    const teamOptions = teams.map(team => ({
        label: team.name,
        value: team.id,
    }));

    const onSubmit = values => {
        dispatch(CLASSIFICATIONS.CREATE.trigger(values));
    };

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (
            userClubId &&
            userClubId !== teamsClubId
        ) {
            dispatch(CLUB.TEAM.FETCH.trigger(userClubId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userClubId])

    useEffect(() => {
        if (created) {
            toast.success('Verzoeken teambeoordeling zijn verstuurd');

            history.push(`/classifications`)
        }
    }, [created, history])

    return (
        <ContentContainer title="Teambeoordeling aanmaken" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <ClassificationForm
                            teams={ teamOptions }
                            onSubmit={ onSubmit }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClassificationCreate;