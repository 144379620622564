import { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import EvaluationAssessmentsBlock from 'contents/blocks/EvaluationAssessmentsBlock';
import EvaluationDetailBlock from 'contents/blocks/EvaluationDetailBlock';
import EvaluationOpenInvitesBlock from 'contents/blocks/EvaluationOpenInvitesBlock';
import { EVALUATIONS } from 'store/constants';

const EvaluationShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const evaluationId = parseInt(params.evaluationId);
    const evaluation = useSelector(state => state.evaluation.active);
    const error = useSelector(state => state.evaluation.error);
    const fetched = evaluation?.id === evaluationId;

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (! fetched) {
            dispatch(EVALUATIONS.SHOW.trigger(evaluationId));
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title="Evaluatie bekijken" backButton={ true }>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <EvaluationDetailBlock evaluation={ evaluation } />
                    </Grid>

                    <Grid item xs={ 6 }>
                        <EvaluationAssessmentsBlock
                            evaluation={ evaluation }
                        />

                        <Box mt={ 2 }>
                            <EvaluationOpenInvitesBlock
                                invites={ evaluation?.invites?.data ?? [] }
                            />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default EvaluationShow;