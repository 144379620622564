import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { VIDEOS } from 'store/constants';

const Videos = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.video.fetched);
    const videos = useSelector(state => state.video.videos);
    const loading = useSelector(state => state.video.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'videos.title' });
    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.video.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'videos.label.title' }),
            field: 'title',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'title'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'videos.label.description' }),
            field: 'description',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'description'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'videos.label.tags' }),
            render: rowData => {
                const tags = rowData.tags.data.map(function (tag) {
                    return tag.name;
                });

                return tags.join(', ');
            },
            sorting: false,
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('videos/create')
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`videos/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`videos/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(VIDEOS.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(VIDEOS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: VIDEOS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ videos }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default Videos;
