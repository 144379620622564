import { select } from 'redux-saga/effects';

import { deletePost, get, patch, patchFile, post, postFile } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchRequest(endpoint) {
    const token = yield select(getToken);

    return yield get(token, endpoint);
}

export function* storeRequest(endpoint, payload) {
    const token = yield select(getToken);

    return yield post(token, endpoint, payload);
}

export function* storeFileRequest(endpoint, payload) {
    const token = yield select(getToken);

    return yield postFile(token, endpoint, payload);
}

export function* updateRequest(endpoint, payload) {
    const token = yield select(getToken);

    return yield patch(token, endpoint, payload);
}

export function* updateFileRequest(endpoint, payload) {
    const token = yield select(getToken);

    return yield patchFile(token, endpoint, payload);
}

export function* deleteRequest(endpoint) {
    const token = yield select(getToken);

    return yield deletePost(token, endpoint);
}
