import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CLUB } from 'store/constants';
import { removeEmptyKeys } from 'utilities/object';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClubTeamsTable from 'contents/tables/ClubTeamsTable';

const ClubTeams = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const clubId = parseInt(params.clubId);
    const teams = useSelector(state => state.clubTeam.teams);
    const reducerClubId = useSelector(state => state.clubTeam.clubId);
    const error = useSelector(state => state.clubTeam.error);
    const created = useSelector(state => state.clubTeam.created);
    const updated = useSelector(state => state.clubTeam.updated);
    const deleted = useSelector(state => state.clubTeam.deleted);
    const fetched = clubId === reducerClubId;

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = removeEmptyKeys(data);

            dispatch(CLUB.TEAM.CREATE.trigger({
                clubId,
                values,
            }))

            resolve();
        })
    }

    const onUpdate = data => {
        return new Promise((resolve) => {
            const teamId = data.id;
            const values = {
                name: data.name,
            }

            dispatch(CLUB.TEAM.UPDATE.trigger({
                clubId,
                teamId,
                values,
            }))

            resolve();
        })
    };

    const onDelete = data => {
        return new Promise((resolve) => {
            dispatch(CLUB.TEAM.DELETE.trigger({
                clubId,
                teamId: data.id,
            }));

            resolve();
        })
    };

    useEffect(() => {
        if (! fetched) {
            dispatch(CLUB.TEAM.FETCH.trigger(clubId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Team is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (updated) {
            toast.success('Team is aangepast');
        }
    }, [updated]);

    useEffect(() => {
        if (deleted) {
            toast.success('Team is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Club: teams" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <ClubTeamsTable
                            data={ teams }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                            onUpdate={ onUpdate }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClubTeams;