import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import styles from './Toolbar.styles';
import { theming } from 'config/theming';
import ToolbarItem from '../ToolbarItem';

const Toolbar = props => {
    const { actionType, setActionType } = props;
    const classes = styles();
    const elements = [
        { actionType: 'element', color: 'black', icon: 'open_with', tooltip: useIntl().formatMessage({ id: 'mapDrawing.toolbar.item.move'}) },
        { actionType: 'circle', color: 'black', icon: 'radio_button_unchecked', tooltip: useIntl().formatMessage({ id: 'mapDrawing.toolbar.item.circle'}) },
        { actionType: 'draw', color: 'black', icon: 'gesture', tooltip: useIntl().formatMessage({ id: 'mapDrawing.toolbar.item.draw'}) },
        { actionType: 'arrow', color: 'black', icon: 'arrow_right_alt', tooltip: useIntl().formatMessage({ id: 'mapDrawing.toolbar.item.arrow-1'}) },
        { actionType: 'arrow-dashed', color: theming.palette.secondary.main, icon: 'arrow_right_alt', tooltip: useIntl().formatMessage({ id: 'mapDrawing.toolbar.item.arrow-2'}) },
        { actionType: 'arrow-broken', color: theming.palette.primary.main, icon: 'arrow_right_alt', tooltip: useIntl().formatMessage({ id: 'mapDrawing.toolbar.item.arrow-3'}) },
    ];

    const renderItems = () => {
        return elements.map(element => {
            return <ToolbarItem
                key={ element.actionType }
                color={ element.color }
                className={ classes.item }
                active={ actionType === element.actionType }
                icon={ element.icon }
                onClick={ () => { setActionType(element.actionType) } }
                tooltip={ element.tooltip }
            />
        });
    };

    return (
        <div className={ classes.root }>
            { renderItems() }
        </div>
    );
};

Toolbar.propTypes = {
    actionType: PropTypes.string.isRequired,
    setActionType: PropTypes.func.isRequired,
};

export default Toolbar;