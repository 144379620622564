import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import SurveyStructure from 'components/SurveyStructure';
import SurveyTopicsTable from 'contents/tables/SurveyTopicsTable';
import { SURVEYS } from 'store/constants';
import { removeEmptyKeys } from 'utilities/object';

const SurveyTopics = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const surveyId = parseInt(params.surveyId);
    const survey = useSelector(state => state.survey.active);
    const error = useSelector(state => state.surveyTopic.error);
    const created = useSelector(state => state.surveyTopic.created);
    const updated = useSelector(state => state.surveyTopic.updated);
    const deleted = useSelector(state => state.surveyTopic.deleted);
    const fetched = survey?.id === surveyId;
    const topics = survey?.structure?.data || [];

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = removeEmptyKeys(data);

            dispatch(SURVEYS.TOPICS.CREATE.trigger({
                surveyId,
                values,
            }))

            resolve();
        })
    }

    const onUpdate = values => {
        return new Promise((resolve) => {
            const topicId = values.id;
            delete values.id;

            dispatch(SURVEYS.TOPICS.UPDATE.trigger({
                surveyId,
                topicId,
                values,
            }))

            resolve();
        })
    }

    const onDelete = data => {
        return new Promise((resolve, reject) => {
            dispatch(SURVEYS.TOPICS.DELETE.trigger({
                surveyId,
                topicId: data.id,
            }));

            resolve();
        });
    };

    useEffect(() => {
        if (! fetched) {
            dispatch(SURVEYS.SHOW.trigger(surveyId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyId, fetched]);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Onderdeel is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (updated) {
            toast.success('Onderdeel is aangepast');
        }
    }, [updated]);

    useEffect(() => {
        if (deleted) {
            toast.success('Onderdeel is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Vragenlijst onderdelen" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 8 }>
                        <SurveyTopicsTable
                            data={ topics }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                            onUpdate={ onUpdate }
                            surveyId={ surveyId }
                        />
                    </Grid>

                    <Grid item xs={ 4 }>
                        <SurveyStructure
                            structure={ topics }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default SurveyTopics;
