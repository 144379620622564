import { Fragment, Component } from 'react';

import { Route, Switch } from 'react-router-dom';

import Faq from 'routes/Faq';
import Home from 'routes/Home';
import Inquiry from 'routes/Inquiry';
import Login from 'routes/Login';
import NotFoundUnauthenticated from 'routes/NotFoundUnauthenticated';
import PasswordReset from 'routes/PasswordReset';
import PasswordRecovery from 'routes/PasswordRecovery';
import Register from 'routes/Register';
import Verify from 'routes/Verify';

import Apps from 'routes/Apps';
import Promo from 'routes/Promo';

class Auth extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path={ '/' } component={ Home } />
                    <Route exact path={ '/faq' } component={ Faq } />
                    <Route exact path={ '/inquiry' } component={ Inquiry } />
                    <Route exact path={ '/login' } component={ Login } />
                    <Route path={ '/password/reset' } component={ PasswordReset } />
                    <Route exact path={ '/password-recovery' } component={ PasswordRecovery } />
                    <Route exact path={ '/register' } component={ Register } />
                    <Route exact path={ '/verify/:userId' } component={ Verify } />

                    <Route path="/promo">
                        <Promo />
                    </Route>

                    <Route path="/apps">
                        <Apps />
                    </Route>

                    <Route component={ NotFoundUnauthenticated } />
                </Switch>
            </Fragment>
        );
    }
}

export default Auth;
