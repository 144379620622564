import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import TrainerForm from 'components/TrainerForm';
import styles from './TrainersCreate.styles';
import { INTERNAL_TRAINERS } from 'store/constants';

const TrainersCreate = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const history = useHistory();

    const fetchedInternalTrainers = useSelector(state => state.internalTrainer.fetched);
    const fetchedUsers = useSelector(state => state.user.fetched);
    const loadingInternalTrainers = useSelector(state => state.internalTrainer.loading);
    const loadingUsers = useSelector(state => state.user.loading);
    const isLoading = loadingInternalTrainers || loadingUsers;
    const isFetched = fetchedInternalTrainers && fetchedUsers;
    const trainers = useSelector(state => state.internalTrainer.internalTrainers);
    const users = useSelector(state => state.user.users);
    const availableUsers = users.filter(user => {
        const index = trainers.findIndex(trainer => {
            return trainer.user.data.id === user.id;
        });

        return index < 0;
    }).map(user => {
        return {
            label: user.email,
            value: user.id,
        }
    });

    const error = useSelector(state => state.internalTrainer.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.internalTrainer.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'trainers.create.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'trainers.create.title' });
    const emailLabel = useIntl().formatMessage({ id: 'trainers.label.email' });
    const rateLabel = useIntl().formatMessage({ id: 'trainers.label.rate' });
    const submitButtonText = useIntl().formatMessage({ id: 'trainers.button.create' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidNumberMessage = useIntl().formatMessage({ id: 'validation.error.number' });

    const onSubmit = values => {
        dispatch(INTERNAL_TRAINERS.CREATE.trigger(values));

        return true
    };

    useEffect(() => {
        if (fetchedUsers === false) {
            // dispatch(USER.FETCH.trigger());
        }

        if (fetchedInternalTrainers === false) {
            dispatch(INTERNAL_TRAINERS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: INTERNAL_TRAINERS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/trainers/${created}/edit`)
        }
    }, [created, createdMessage, history])

    return (
        <ContentContainer title={ pageTitle } backButton>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <Paper elevation={ 2 } className={ classes.block }>
                        { ! created ? (
                            <TrainerForm
                                users={ availableUsers }
                                emailLabel={ emailLabel }
                                rateLabel={ rateLabel }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidNumberMessage={ invalidNumberMessage }
                                onSubmit={ onSubmit }
                            />
                        ) : null }
                    </Paper>
                </Grid>
            </Grid>

            <LoadingBackdrop open={ isLoading } message={ isFetched ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default TrainersCreate;
