import { AUTH } from '../constants';

export const loginSuccessful = payload => ({
    type: AUTH.LOGIN_SUCCESS,
    payload
});

export const accountUnverified = () => ({ type: AUTH.UNVERIFIED });

export const loginFailed = () => ({ type: AUTH.LOGIN_FAIL });

export const logout = () => ({ type: AUTH.LOGOUT });