import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import styles from './SearchBox.styles';

const SearchBox = props => {
    const { fieldLabel, buttonLabel, onSubmit, submitDisabled } = props;
    const [fieldValue, setFieldValue] = useState('');
    const classes = styles();

    const handleSubmit = () => {
        onSubmit(fieldValue);
    }

    return (
        <Fragment>
            <TextField
                name={ 'field' }
                onChange={ event => setFieldValue(event.target.value) }
                label={ fieldLabel }
                type={ 'text' }
                fullWidth
            />

            <Box mt={ 2 }>
                <Button
                    onClick={ handleSubmit }
                    size={ 'large' }
                    disabled={ submitDisabled || fieldValue.length === 0 }
                    color={ 'primary' }
                    variant={ 'contained' }
                    className={ classes.button }
                    fullWidth
                >
                    { buttonLabel }
                </Button>
            </Box>
        </Fragment>
    );
};

SearchBox.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitDisabled: PropTypes.bool,
    fieldLabel: PropTypes.string,
    buttonLabel: PropTypes.string,
};

SearchBox.defaultProps = {
    submitDisabled: false,
    buttonLabel: 'submit',
};

export default SearchBox;
