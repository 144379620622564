import { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ORDERS } from 'store/constants';
import BoxHeading from 'components/BoxHeading';
import ContentBox from 'components/ContentBox';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import OrderDetails from 'contents/details/OrderDetails';
import OrderParentDetails from 'contents/details/OrderParentDetails';
import OrderPlayerDetails from 'contents/details/OrderPlayerDetails';

const OrderShow = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const orderId = parseInt(params.orderId)
    const order = useSelector(state => state.order.active);
    const error = useSelector(state => state.order.error);
    const orderLoaded = order?.id === orderId;

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden');
        }
    }, [error]);

    useEffect(() => {
        dispatch(ORDERS.SHOW.trigger(orderId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title="Aanmelding"  backButton>
            { orderLoaded ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <ContentBox>
                            <BoxHeading title="Aanmelding" />

                            <Box mt={ 2 }>
                                <OrderDetails order={ order } />
                            </Box>
                        </ContentBox>

                        <Box mt={ 2 }>
                            <ContentBox>
                                <BoxHeading title="Speler" />

                                <Box mt={ 2 }>
                                    <OrderPlayerDetails order={ order } />
                                </Box>
                            </ContentBox>
                        </Box>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <ContentBox>
                            <BoxHeading title="Ouder" />

                            <Box mt={ 2 }>
                                <OrderParentDetails order={ order } />
                            </Box>
                        </ContentBox>
                    </Grid>
                </Grid>
            ) : <LoadingIndicator /> }
        </ContentContainer>
    );
};

export default OrderShow;
