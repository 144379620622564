import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ORDERS } from 'store/constants';
import { sortCaseInsensitive } from 'utilities/sort';
import { timestampToDateTimeString } from 'utilities/date';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import LoadingIndicator from 'components/LoadingIndicator';
import ContentContainer from 'components/ContentContainer';

const OrderOverview = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.order.fetched);
    const orders = useSelector(state => state.order.orders);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'orders.title' });
    const error = useSelector(state => state.order.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    const columns = [
        {
            title: useIntl().formatMessage({ id: 'orders.label.timestamp' }),
            render: rowData => timestampToDateTimeString(rowData.timestamp),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'timestamp'),
        },
        {
            title: useIntl().formatMessage({ id: 'orders.label.full_name' }),
            field: 'user.data.full_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'user.data.full_name'),
        },
        {
            title: useIntl().formatMessage({ id: 'orders.label.event' }),
            field: 'event.data.name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'event.data.name'),
        },
        {
            title: useIntl().formatMessage({ id: 'orders.label.parent_email' }),
            field: 'parent_email',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'parent_email'),
        },
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'orders.label.mollie_payment_id' }),
            field: 'mollie_payment_id',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'title'),
        },
        {
            title: useIntl().formatMessage({ id: 'orders.label.status' }),
            field: 'status',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'status'),
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`orders/${rowData.id}`)
        },
    ];

    useEffect(() => {
        dispatch(ORDERS.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { fetched ? (
                <Grid container spacing={3}>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ orders }
                            options={ defaultOptions }
                            actions={ actions }
                        />
                    </Grid>
                </Grid>
            ) : <LoadingIndicator /> }
        </ContentContainer>
    );
};

export default OrderOverview;
