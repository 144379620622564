import PropTypes from 'prop-types';

import PremiumPromo from '../PremiumPromo';
import LoadingIndicator from '../LoadingIndicator';

const PremiumWall = props => {
    const { isProfileFetched, isPremium, premiumImage, children } = props;

    if (isProfileFetched === false) {
        return <LoadingIndicator />
    }

    return isPremium ? children : <PremiumPromo image={ premiumImage } />
};

PremiumWall.propTypes = {
    isProfileFetched: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    premiumImage: PropTypes.string.isRequired,
};

export default PremiumWall;
