import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/TextInput';

const SearchForm = props => {
    const {
        initialValues,
        onSubmit,
        submitButtonText,
    } = props;

    const validationSchemaObject = yup.object().shape({
        query: yup
            .string()
            .min(3, 'Zoekopdracht te kort (min. 3 tekens)')
            .required('Geen zoekopdracht ingevuld'),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['query'] }
                            label={ 'Zoekopdracht' }
                            name={ 'query' }
                            onChange={ handleChange }
                            onBlur={ handleBlur['query'] }
                            touched={ touched['query'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

SearchForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
};

SearchForm.defaultProps = {
    initialValues: {
        query: '',
    },
    submitButtonText: 'Zoek',
}

export default SearchForm;
