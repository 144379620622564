import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import TrainingForm from 'components/TrainingForm';
import ExerciseChips from 'components/ExerciseChips';
import AddExerciseToTrainingForm from 'components/AddExerciseToTrainingForm';
import PremiumWall from 'components/PremiumWall';
import styles from './ClubTrainingsEdit.styles';
import {
    CLUB_EXERCISES,
    CLUB_TRAININGS,
    DIVISIONS,
    EXERCISES,
    PROFILE,
} from 'store/constants';
import { images } from 'config/images';

const ClubTrainingsEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetchedTrainings = useSelector(state => state.clubTraining.fetched);
    const fetchedDivisions = useSelector(state => state.division.fetched);
    const fetchedEducationPlanExercises = useSelector(state => state.exercise.educationPlanFetched);
    const fetchedClubExercises = useSelector(state => state.clubExercise.fetched);
    const fetchedInternalTrainers = useSelector(state => state.internalTrainer.fetched);
    const isFetching = ! (fetchedTrainings && fetchedDivisions && fetchedEducationPlanExercises && fetchedClubExercises && fetchedInternalTrainers);
    const loadingTrainings = useSelector(state => state.clubTraining.loading);
    const loadingDivisions = useSelector(state => state.division.loading);
    const loadingExercises = useSelector(state => state.clubExercise.loading);
    const profileFetched = useSelector(state => state.profile.fetched);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const roles = useSelector(state => state.profile.roles);
    const isClubmanager = roles.includes('clubmanager');
    const isLoading = loadingTrainings || loadingDivisions || loadingExercises;
    const classes = styles();

    const pageTitle = useIntl().formatMessage({ id: 'trainings.update.title' });
    const error = useSelector(state => state.clubTraining.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.clubTraining.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'trainings.update.loading' });

    const divisions = useSelector(state => state.division.divisions);
    const trainings = useSelector(state => state.clubTraining.trainings);
    const training = trainings.filter(object => object.id === parseInt(params.trainingId))[0];
    const trainingExercises = training ? training.exercises.data : [];
    const clubExercises = useSelector(state => state.clubExercise.exercises);
    const educationPlanExercises = useSelector(state => state.exercise.educationPlanExercises);
    const exercises = [...clubExercises, ...educationPlanExercises];
    const availableExercises = exercises.filter(exercise => {
        const index = trainingExercises.findIndex(trainingExercise => {
            return trainingExercise.id === exercise.id;
        });

        return index < 0;
    });

    const nameLabel = useIntl().formatMessage({ id: 'trainings.label.name' });
    const divisionLabel = useIntl().formatMessage({ id: 'trainings.label.division' });
    const exercisesLabel = useIntl().formatMessage({ id: 'trainings.label.exercises' });
    const exerciseLabel = useIntl().formatMessage({ id: 'trainings.label.exercise' });
    const trainerLabel = useIntl().formatMessage({ id: 'trainings.label.trainer' });
    const submitButtonText = useIntl().formatMessage({ id: 'trainings.button.update' });
    const addExerciseButtonText = useIntl().formatMessage({ id: 'trainings.button.addExercise' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });

    const getInitialValues = () => {
        if (training) {
            return {
                name: training.name,
                division_id: training.division.data.id,
                hidden: training.hidden,
            }
        }

        return {};
    }

    const handleAddExercise = (values) => {
        const exercise = exercises.find(exercise => exercise.id === values.exercise_id);

        dispatch(CLUB_TRAININGS.EXERCISE.UPDATE.trigger({ training, exercise }));

        return true;
    }

    const handleDeleteExercise = exercise => {
        dispatch(CLUB_TRAININGS.EXERCISE.DELETE.trigger({ training, exercise }));
    }

    const onSubmit = values => {
        dispatch(CLUB_TRAININGS.UPDATE.trigger({
            id: params.trainingId,
            values
        }));

        return true
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    useEffect(() => {
        if (fetchedTrainings === false) {
            dispatch(CLUB_TRAININGS.FETCH.trigger());
        }

        if (fetchedDivisions === false) {
            dispatch(DIVISIONS.FETCH.trigger());
        }

        if (fetchedEducationPlanExercises === false) {
            dispatch(EXERCISES.EDUCATION_PLAN.trigger());
        }

        if (fetchedClubExercises === false) {
            dispatch(CLUB_EXERCISES.FETCH.trigger());
        }

        if (profileFetched === false) {
            dispatch(PROFILE.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: DIVISIONS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            <PremiumWall
                isProfileFetched={ profileFetched }
                isPremium={ isClubmanager || isAdmin }
                premiumImage={ images.premium.exercise.default }
            >
                { fetchedTrainings && fetchedDivisions ? (
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 6 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <TrainingForm
                                    initialValues={ getInitialValues() }
                                    nameLabel={ nameLabel }
                                    divisionLabel={ divisionLabel }
                                    divisions={ divisions }
                                    submitButtonText={ submitButtonText }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    onSubmit={ onSubmit }
                                    editMode
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <AddExerciseToTrainingForm
                                    trainerLabel={ trainerLabel }
                                    exerciseLabel={ exerciseLabel }
                                    submitButtonText={ addExerciseButtonText }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    exercises={ availableExercises }
                                    selectedExercises={ trainingExercises }
                                    onSubmit={ handleAddExercise }
                                />
                            </Paper>

                            <Box mt={ 2 }>
                                <Paper elevation={ 2 } className={ classes.block }>
                                    <Box mb={ 1.5 }>
                                        <Typography variant="h6" component="h1" gutterBottom>
                                            { exercisesLabel }
                                        </Typography>
                                    </Box>

                                    <ExerciseChips
                                        exercises={ trainingExercises }
                                        onDelete={ handleDeleteExercise }
                                    />
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                ) : null }

            </PremiumWall>
                <LoadingBackdrop open={ isLoading } message={ ! isFetching ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default ClubTrainingsEdit;
