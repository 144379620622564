import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import FileInput from 'components/FileInput';

const ClubPlayerImportForm = props => {
    const {
        onSubmit
    } = props;
    const initialValues = {
        players: null,
    };
    const FILE_SIZE = 40 * 1024;
    const SUPPORTED_FORMATS = ['text/csv'];

    const validationSchema = {
        players: yup
            .mixed()
            .required('Verplicht veld')
            .test(
                'fileSize',
                'Bestand is te groot',
                value => value && value.size <= FILE_SIZE
            )
            .test(
                'fileFormat',
                'Ongeldig bestandsformaat',
                value => value && SUPPORTED_FORMATS.includes(value.type)
            ),
    };
    const validationSchemaObject = yup.object().shape(validationSchema);

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>

                    <Box>
                        <Field
                            name="players"
                            component={ FileInput }
                            title="CSV-file"
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['players'] ? errors['players'] : undefined }
                            touched={ touched['players'] }
                            onBlur={ handleBlur['players'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            disabled={ isSubmitting }
                            type="submit"
                            variant="contained"
                        >
                            Importeer spelers
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

ClubPlayerImportForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default ClubPlayerImportForm;
