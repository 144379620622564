import { AUTH, CLUB } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    clubId: null,
    players: [],

    error: false,
    errorImport: false,
    loading: false,
    isImporting: false,

    created: null,
    updated: false,
    deleted: false,
    imported: false,
};

const playerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB.PLAYER.FETCH.TRIGGER:
        case CLUB.PLAYER.CREATE.TRIGGER:
        case CLUB.PLAYER.UPDATE.TRIGGER:
        case CLUB.PLAYER.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLUB.PLAYER.IMPORT.TRIGGER:
            return {
                ...state,
                isImporting: true,
            }

        case CLUB.PLAYER.FETCH.SUCCESS:
            return {
                ...state,
                clubId: action.payload.clubId,
                players: action.payload.players,
            };

        case CLUB.PLAYER.CREATE.SUCCESS:
            return {
                ...state,
                players: state.players.concat(action.payload),
                created: action.payload.id,
            };

        case CLUB.PLAYER.UPDATE.SUCCESS:
            return {
                ...state,
                players: findIdAndUpdateObjectInArray([...state.players], action.payload.id, action.payload),
                updated: true,
            };

        case CLUB.PLAYER.DELETE.SUCCESS:
            return {
                ...state,
                players: deleteObjectWithIdFromArray([...state.players], action.payload),
                deleted: true,
            };

        case CLUB.PLAYER.IMPORT.SUCCESS:
            return {
                ...state,
                imported: true,
            };

        case CLUB.PLAYER.FETCH.FAILURE:
        case CLUB.PLAYER.CREATE.FAILURE:
        case CLUB.PLAYER.UPDATE.FAILURE:
        case CLUB.PLAYER.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLUB.PLAYER.IMPORT.FAILURE:
            return {
                ...state,
                errorImport: true,
            }

        case CLUB.PLAYER.FETCH.FULFILL:
        case CLUB.PLAYER.CREATE.FULFILL:
        case CLUB.PLAYER.UPDATE.FULFILL:
        case CLUB.PLAYER.DELETE.FULFILL:
        case CLUB.PLAYER.IMPORT.FULFILL:
            return {
                ...state,
                created: null,
                deleted: false,
                error: false,
                errorImport: false,
                imported: false,
                loading: false,
                updated: false,
                isImporting: false,
            }

        case CLUB.TEAM.PLAYER.UPDATE.SUCCESS:
            return {
                ...state,
                players: findIdAndUpdateObjectInArray([...state.players], action.payload.id, action.payload),
            };

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default playerReducer;