import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';

const ActionList = props => {
    const { values } = props;
    const history = useHistory();

    const goToTarget = target => history.push(target);

    const renderList = () => {
        let increment = 0;

        return values.map(value => {
            const { target, label } = value;
            increment++;

            return (
                <ListItem
                    button
                    component="span"
                    onClick={ () => goToTarget(target) }
                    key={ increment }
                >
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>

                    <ListItemText primary={ label } />
                </ListItem>
            )
        })
    }

    return (
        <List component="nav">
            { renderList() }
        </List>
    );
};

ActionList.propTypes = {
    values: PropTypes.array,
};

export default ActionList;