import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmModal = props => {
    const {
        confirmButtonText,
        content,
        isOpen,
        onClose,
        onConfirm,
        title
    } = props;

    return (
        <Dialog
            open={ isOpen }
            onClose={ onClose }
        >
            <DialogTitle>
                { title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { content }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose }>
                    Annuleren
                </Button>
                <Button onClick={ onConfirm } color="primary" autoFocus>
                    { confirmButtonText }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmModal.propTypes = {
    confirmButtonText: PropTypes.string,
    content: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
}

ConfirmModal.defaultProps = {
    confirmButtonText: 'Bevestigen',
    isOpen: false,
}

export default ConfirmModal;
