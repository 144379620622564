import PropTypes from 'prop-types';

import MaterialTable from 'material-table';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const SurveyAnswersTable = props => {
    const { data, onCreate, onDelete, onUpdate } = props;

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'answer'),
            field: 'answer',
            title: 'Antwoord',
        },
        {
            field: 'grade',
            title: 'Cijfer',
            type: 'numeric',
            validate: rowData => rowData.order >= 0 && rowData.order <= 255,
        },
        {
            defaultSort: 'asc',
            field: 'order',
            title: 'Volgorde',
            type: 'numeric',
            validate: rowData => rowData.order >= 1 && rowData.order <= 255,
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Vragenlijst antwoorden"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            editable={ editable }
        />
    );
};

SurveyAnswersTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default SurveyAnswersTable;