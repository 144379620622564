import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';

import SidebarItem from '../SidebarItem';

const SidebarDropdownItem = props => {
    const { activePath, children, icon, name } = props;
    const locationPath = window.location.pathname;
    const clubId = useSelector(state => state.profile.club_id);
    const activeRoute = locationPath.startsWith(activePath);
    const [open, setOpen] = useState(activeRoute);

    const handleToggle = () => {
        setOpen(! open);
    };

    const renderSidebarItems = () => {
        return children.map((item, index) => (
            <SidebarItem
                key={ `sidebar-dropdown-item-${index}` }
                name={ item.name }
                target={ item.target.replace(':clubId', clubId) }
                icon={ item.icon }
                nested
            />
        ));
    }

    return (
        <Fragment>
            <ListItem button onClick={ handleToggle }>
                <ListItemIcon>
                    <Icon>{ icon }</Icon>
                </ListItemIcon>
                <ListItemText primary={ name } />
                { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>

            <Collapse in={ open } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { renderSidebarItems() }
                </List>
            </Collapse>
        </Fragment>
    );
};

SidebarDropdownItem.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    activePath: PropTypes.string,
    children: PropTypes.array.isRequired,
};

export default SidebarDropdownItem;