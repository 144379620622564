import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { TEAM } from '../constants';
import {
    fetchRequest,
    deleteRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(TEAM.PLAYER.FETCH.TRIGGER);

        try {
            const teamId = request.payload;
            const endpoint = Api.requests.teams.players.index(teamId);
            const response = yield call(fetchRequest, endpoint);
            const players = response.data.data;

            yield put(TEAM.PLAYER.FETCH.success({
                players,
                teamId,
            }));
        } catch (error) {
            yield put(TEAM.PLAYER.FETCH.failure());
        } finally {
            yield put(TEAM.PLAYER.FETCH.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(TEAM.PLAYER.DELETE.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const playerId = request.payload.playerId;
            const endpoint = Api.requests.teams.players.delete(teamId, playerId);

            yield call(deleteRequest, endpoint);
            yield put(TEAM.PLAYER.DELETE.success(playerId));
        } catch (error) {
            yield put(TEAM.PLAYER.DELETE.failure());
        } finally {
            yield put(TEAM.PLAYER.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        deleteFlow(),
    ]);
}