import { AUTH, USER } from '../constants';

const INITIAL_STATE = {
    users: [],
    search: {
        query: '',
        results: [],
    },
    active: null,

    loading: false,
    error: false,
    updated: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER.UPDATE.TRIGGER:
        case USER.SHOW.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case USER.SEARCH.TRIGGER:
            return {
                ...state,
                loading: true,
                search: {
                    query: action.payload.query,
                    results: [],
                }
            }

        case USER.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case USER.UPDATE.SUCCESS:
            return {
                ...state,
                active: action.payload,
                updated: true,
            };

        case USER.SEARCH.SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: action.payload,
                },
            };

        case USER.SHOW.FAILURE:
        case USER.UPDATE.FAILURE:
        case USER.SEARCH.FAILURE:
            return {
                ...state,
                error: true,
            }

        case USER.SHOW.FULFILL:
        case USER.UPDATE.FULFILL:
        case USER.SEARCH.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default userReducer;