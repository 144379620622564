import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import AssessmentShow from './AssessmentShow';

const Assessments = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ `${path}/:assessmentId` }
                component={ AssessmentShow }
            />
        </Switch>
    );
};

export default Assessments;