import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    label: {
        fontWeight: 'bold',
        marginBottom: 0,
    },

    highlighted: {
        color: theme.palette.primary.main,
    }
}));

export default styles;
