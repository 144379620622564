import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
    content: {
        marginTop: 0,
        paddingTop: 0,
        marginLeft: 240,
    },
}));

export default styles;
