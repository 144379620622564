import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';

const PlayerSettingsDetails = props => {
    const { player } = props;

    return (
        <>
            <ValueBlock
                label="Video's toegestaan"
                value={ player?.settings?.data?.allow_evaluation ? 'Ja' : 'Nee' }
            />
            <ValueBlock
                label="Foto's toegestaan" 
                value={ player?.settings?.data?.allow_photo ? 'Ja' : 'Nee' }
            />
            <ValueBlock
                label="Evaluaties toegestaan" 
                value={ player?.settings?.data?.allow_video ? 'Ja' : 'Nee' }
            />
        </>
    );
};

PlayerSettingsDetails.propTypes = {
    player: PropTypes.object.isRequired,
};

export default PlayerSettingsDetails;