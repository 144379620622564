import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { dateToDateString } from 'utilities/date';

const DatePickerInput = ({ field, ...props }) => {
    const { label, errorMessage, touched, setFieldValue, minDate, maxDate } = props;
    const { name, value, onBlur } = field;
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFieldValue(name, dateToDateString(date));
    };

    useEffect(() => {
        if (value) {
            const date = new Date(value);

            setSelectedDate(date);
        }
    }, [value]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                label={ label }
                format="dd-MM-yyyy"
                value={ selectedDate }
                onChange={ handleDateChange }
                error={ touched && errorMessage ? true : false }
                helperText={ touched && errorMessage ? errorMessage : undefined }
                onBlur={ onBlur }
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                minDate={ minDate ? minDate : undefined }
                maxDate={ maxDate ? maxDate : undefined }
            />
        </MuiPickersUtilsProvider>
    );
};

DatePickerInput.propTypes = {
    field: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    touched: PropTypes.bool,
};

export default DatePickerInput;
