import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { CLUB } from '../constants';
import {
    fetchRequest,
    storeRequest,
    deleteRequest, updateRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(CLUB.USER.FETCH.TRIGGER);

        try {
            const clubId = request.payload;
            const endpoint = Api.requests.clubs.users.index(clubId);
            const response = yield call(fetchRequest, endpoint);
            const users = response.data.data;

            yield put(CLUB.USER.FETCH.success({
                clubId,
                users,
            }));
        } catch (error) {
            yield put(CLUB.USER.FETCH.failure());
        } finally {
            yield put(CLUB.USER.FETCH.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(CLUB.USER.CREATE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const values = request.payload.values;
            const endpoint = Api.requests.clubs.users.store(clubId);
            const response = yield call(storeRequest, endpoint, values);

            yield put(CLUB.USER.CREATE.success(response.data.data));
        } catch (error) {
            yield put(CLUB.USER.CREATE.failure());
        } finally {
            yield put(CLUB.USER.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(CLUB.USER.UPDATE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const userId = request.payload.userId;
            const values = request.payload.values;
            const endpoint = Api.requests.clubs.users.update(clubId, userId);
            const response = yield call(updateRequest, endpoint, values);

            yield put(CLUB.USER.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(CLUB.USER.UPDATE.failure());
        } finally {
            yield put(CLUB.USER.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(CLUB.USER.DELETE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const userId = request.payload.userId;
            const endpoint = Api.requests.clubs.users.delete(clubId, userId);

            yield call(deleteRequest, endpoint);
            yield put(CLUB.USER.DELETE.success(userId));
        } catch (error) {
            yield put(CLUB.USER.DELETE.failure());
        } finally {
            yield put(CLUB.USER.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        createFlow(),
        updateFlow(),
        deleteFlow(),
    ]);
}