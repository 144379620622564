import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/TextInput';
import DatePickerInput from 'components/DatePickerInput';

const UserForm = props => {
    const {
        initialValues,
        onSubmit,
    } = props;

    const validationSchemaObject = yup.object().shape({
        first_name: yup
            .string()
            .required('Ongeldige voornaam'),
        last_name: yup
            .string()
            .required('Ongeldige achternaam'),
        email_verified_at: yup
            .date()
            .typeError('Ongeldige datum')
            .nullable(),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['first_name'] }
                            label="Voornaam"
                            name="first_name"
                            onChange={ handleChange }
                            onBlur={ handleBlur['first_name'] }
                            touched={ touched['first_name'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            component={ TextInput }
                            errorMessage={ errors['last_name'] }
                            label="Achternaam"
                            name="last_name"
                            onChange={ handleChange }
                            onBlur={ handleBlur['first_name'] }
                            touched={ touched['last_name'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name="email_verified_at"
                            label="Activatiedatum"
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['email_verified_at'] }
                            touched={ touched['email_verified_at'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['first_name'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            Pas gebruiker aan
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

UserForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default UserForm;
