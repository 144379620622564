import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import SelectInput from '../SelectInput';
import DatePickerInput from '../DatePickerInput';

const PremiumForm = props => {
    const {
        startDateLabel,
        endDateLabel,
        clubLabel,
        submitButtonText,
        requiredFieldMessage,
        clubs,
        onSubmit,
        initialValues,
    } = props;

    const emptyClubOption = { value: null, label: '-' }
    const clubList = clubs.map(club => {
        return { value: club.id, label: club.name }
    });
    const clubOptions = [
        emptyClubOption,
        ...clubList,
    ]

    const validationSchemaObject = yup.object().shape({
        start_date: yup.string().max(255).required(requiredFieldMessage),
        end_date: yup.string().max(255).required(requiredFieldMessage),
        club_id: yup.mixed(),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
            }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'start_date' }
                            label={ startDateLabel }
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['start_date'] }
                            touched={ touched['start_date'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'end_date' }
                            label={ endDateLabel }
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['end_date'] }
                            touched={ touched['end_date'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'club_id' }
                            label={ clubLabel }
                            component={ SelectInput }
                            errorMessage={ errors['club_id'] }
                            touched={ touched['club_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            options={ clubOptions }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

PremiumForm.propTypes = {
    startDateLabel: PropTypes.string,
    endDateLabel: PropTypes.string,
    clubLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    clubs: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

PremiumForm.defaultProps = {
    startDateLabel: 'start date',
    endDateLabel: 'end date',
    clubLabel: 'club',
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    clubs: [],
    initialValues: {
        start_date: '',
        end_date: '',
        club_id: null,
    }
}



export default PremiumForm;
