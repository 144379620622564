import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import styles from './ValueBlock.styles';

const ValueBlock = props => {
    const { highlighted, label, mb, value } = props;
    const classes = styles();

    return (
        <Box mb={ mb }>
            <Typography variant="body1" gutterBottom className={ `${classes.label} ${highlighted ? classes.highlighted : null}` }>
                { label }
            </Typography>

            <Typography variant="body1" gutterBottom className={ highlighted ? classes.highlighted : null }>
                { value }
            </Typography>
        </Box>
    );
};

ValueBlock.propTypes = {
    highlighted: PropTypes.bool,
    label: PropTypes.string,
    mb: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

ValueBlock.defaultProps = {
    mb: 2,
}

export default ValueBlock;
