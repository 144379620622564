import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ValueBlock from 'components/ValueBlock';
import { getAverage } from 'utilities/calculations';
import styles from './AssessmentQuestion.styles';

const AssessmentQuestion = props => {
    const { heading, answers } = props;
    const classes = styles();

    const grades = answers
        .filter(answer => answer.grade)
        .map(answer => answer.grade);
    const average = getAverage(grades, 1);

    const renderAnswers = () => {
        return (
            answers.map(answer => (
                <ValueBlock
                    key={  `survey-answer-${answer.id}-${answer.respondent_id}` }
                    label={ answer.respondent_name }
                    mb={ 0 }
                    value={ answer.answer }
                />
            ))
        )
    }

    return (
        <>
            <Box className={ classes.questionContainer }>
                <Typography variant="h6">
                    { heading }
                </Typography>

                { average > 0 && (
                    <Typography
                        align="right"
                        variant="h5"
                    >
                        { average }
                    </Typography>
                ) }
            </Box>

            <Box mt={ 1 }>
                { renderAnswers() }
            </Box>
        </>
    );
};

AssessmentQuestion.propTypes = {
    heading: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
};

export default AssessmentQuestion;