import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './ClubTrainingsShow.styles';
import ValueBlock from 'components/ValueBlock';
import ValueList from 'components/ValueList';
import PremiumWall from 'components/PremiumWall';
import { CLUB_TRAININGS, PROFILE } from 'store/constants';
import { images } from 'config/images';

const ClubTrainingsShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetched = useSelector(state => state.clubTraining.fetched);
    const loading = useSelector(state => state.clubTraining.loading);
    const premiumFetched = useSelector(state => state.profile.fetched);
    const isPremium = useSelector(state => state.profile.is_premium);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const classes = styles();

    const trainings = useSelector(state => state.clubTraining.trainings);
    const training = trainings.filter(object => object.id === parseInt(params.trainingId))[0];
    const name = training ? training.name : '';
    const nameLabel = useIntl().formatMessage({ id: 'trainings.label.name' });
    const division = training ? training.division.data.name : '';
    const divisionLabel = useIntl().formatMessage({ id: 'trainings.label.division' });
    const exercises = training ? training.exercises.data.map(exercise => {
        const name = (
            <span>
                { exercise.title }
                { exercise.internal_trainer ? <strong> - { exercise.internal_trainer }</strong> : null }
            </span>
        );

        return name;
    }) : [];
    const exercisesLabel = useIntl().formatMessage({ id: 'trainings.label.exercises' });
    const numberOfExercisesLabel = useIntl().formatMessage({ id: 'trainings.label.numberOfExercises' });
    const error = useSelector(state => state.clubTraining.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(CLUB_TRAININGS.FETCH.trigger());
        }

        if (premiumFetched === false) {
            dispatch(PROFILE.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ name } backButton={ true }>
            <PremiumWall
                isProfileFetched={ premiumFetched }
                isPremium={ isPremium || hasCorrectPermission || isAdmin }
                premiumImage={ images.premium.exercise.default }
            >
                { loading ? <LoadingIndicator /> : (
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 6 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <ValueBlock label={ nameLabel } value={ name } />
                                <ValueBlock label={ divisionLabel } value={ division } />
                                <ValueBlock label={ numberOfExercisesLabel } value={ exercises.length } />
                                <ValueBlock label="Verborgen"  value={ training?.hidden ? 'Ja' : 'Nee' } />

                                <ValueList
                                    label={ exercisesLabel }
                                    values={ exercises }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                ) }
            </PremiumWall>
        </ContentContainer>
    );
};

export default ClubTrainingsShow;
