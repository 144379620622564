import { useState } from 'react';

import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ChipCollection from 'components/ChipCollection';
import ContentBox from 'components/ContentBox';
import SelectForm from 'contents/forms/SelectForm';

const EventTrainersBlock = props => {
    const { activeTrainers, onAdd, onDelete, trainers } = props;
    const [editMode, setEditMode] = useState(false);
    const chips = activeTrainers.map(trainer => {
        return {
            id: trainer.id,
            label: trainer.name,
        }
    });
    const trainerOptions = trainers.map(trainer => {
        return {
            label: trainer?.user?.data?.full_name,
            value: trainer?.id,
        }
    });

    const addTrainer = values => {
        setEditMode(false);
        onAdd(values);
    }

    const deleteTrainer = trainerId => {
        onDelete(trainerId);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Trainers"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                { editMode ? (
                    <SelectForm
                        label="Trainer"
                        onSubmit={ addTrainer }
                        options={ trainerOptions }
                        submitButtonText="Voeg trainer toe"
                    />
                ) : (
                    <ChipCollection
                        items={ chips }
                        onDelete={ deleteTrainer }
                    />
                ) }
            </Box>
        </ContentBox>
    );
};

EventTrainersBlock.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    trainers: PropTypes.array.isRequired,
    activeTrainers: PropTypes.array.isRequired,
}

export default EventTrainersBlock;