import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';

import ManualButton from '../ManualButton';
import LogoutButton from './LogoutButton';
import styles from './Header.styles';
import { useSelector } from 'react-redux';

const Header = props => {
    const { backButton, title } = props;
    const history = useHistory();
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const classes = styles();

    return (
        <div className={ classes.root }>
            <AppBar position="static">
                <Toolbar>
                    { backButton ? (
                        <IconButton
                            className={ classes.backButton }
                            onClick={ () => history.goBack() }
                        >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    ) : null }

                    <Typography variant="h6" align="left" className={ classes.title }>
                        { title }
                    </Typography>

                    { ! isAdmin && (
                        <ManualButton />
                    ) }

                    <LogoutButton />
                </Toolbar>
            </AppBar>
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    backButton: PropTypes.bool,
};

export default Header;