import { AUTH, PROFILE } from '../constants';

const INITIAL_STATE = {
    fetched: false,
    id: null,
    club_id: null,
    firstName: '',
    infix: '',
    lastName: '',
    email: '',
    is_premium: false,
    premium: {},
    roles: [],
    permissions: [],
    error: false,
    loading: false,
};

const profileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROFILE.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case PROFILE.FETCH.SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                club_id: action.payload.club_id,
                firstName: action.payload.first_name,
                infix: action.payload.infix,
                lastName: action.payload.last_name,
                email: action.payload.email,
                is_premium: action.payload.is_premium,
                premium: action.payload?.premium?.data,
                roles: action.payload.roles,
                permissions: action.payload.permissions,
                fetched: true,
            };

        case PROFILE.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case PROFILE.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default profileReducer;