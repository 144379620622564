import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import ClubAccessRequests from './ClubAccessRequests';
import ClubOverview from './ClubOverview';
import ClubPlayers from './ClubPlayers';
import ClubTeams from './ClubTeams';
import ClubUsers from './ClubUsers';

const Clubs = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ path }
                component={ ClubOverview }
            />
            <Route
                exact
                path={ `${path}/:clubId/access-requests` }
                component={ ClubAccessRequests }
            />
            <Route
                exact
                path={ `${path}/:clubId/players` }
                component={ ClubPlayers }
            />
            <Route
                exact
                path={ `${path}/:clubId/teams` }
                component={ ClubTeams }
            />
            <Route
                exact
                path={ `${path}/:clubId/users` }
                component={ ClubUsers }
            />
        </Switch>
    );
};

export default Clubs;