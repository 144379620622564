import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const TeamAdminsTable = props => {
    const { data, onCreate, onDelete } = props;

    const columns = [
        {
            editable: 'never',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Naam',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'email'),
            editable: 'onAdd',
            field: 'email',
            title: 'E-mail',
            validate: rowData => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(rowData?.email),
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Clubs"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ [] }
            editable={ editable }
        />
    );
};

TeamAdminsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default TeamAdminsTable;