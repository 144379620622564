import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { TRAININGS } from 'store/constants';

const Trainings = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.training.fetched);
    const trainings = useSelector(state => state.training.trainings);
    const loading = useSelector(state => state.training.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'trainings.title' });
    const error = useSelector(state => state.training.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.training.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            title: useIntl().formatMessage({ id: 'trainings.label.name' }),
            field: 'name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'trainings.label.division' }),
            field: 'division.data.name',
        },
        {
            title: useIntl().formatMessage({ id: 'trainings.label.numberOfExercises' }),
            field: 'exercise_count',
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('trainings/create')
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`trainings/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`trainings/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(TRAININGS.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(TRAININGS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: TRAININGS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ trainings }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default Trainings;
