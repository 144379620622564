export const navigation = {
    admin: [
        { name: 'Gebruikers', target: '/users', icon: 'people' },
        { name: 'Clubs', target: '/clubs', icon: 'store-mall-directory' },
        {
            name: 'Intern', icon: 'move_to_inbox', children:
                [
                    { name: 'Events', target: '/events', icon: 'event' },
                    { name: 'Trainers', target: '/trainers', icon: 'record_voice_over' },
                    { name: 'Nieuws', target: '/news', icon: 'menu_book' },
                ]
        },
        {
            name: 'Oefenstof', icon: 'school', children:
                [
                    { name: 'Tekentool', target: '/map-drawing-tool', icon: 'gesture' },
                    { name: 'Oefeningen', target: '/exercises', icon: 'directions_run' },
                    { name: 'Trainingen', target: '/trainings', icon: 'transfer_within_a_station' },
                    { name: 'Tijdvakken', target: '/periods', icon: 'access_time' },
                    { name: 'Instructievideo\'s', target: '/videos', icon: 'video_library' },
                ]
        },
        { name: 'Vragenlijsten', target: '/surveys', icon: 'list_alt' },
    ],
    default: [
        { name: 'Tekentool', target: '/map-drawing-tool', icon: 'gesture' },
        {
            name: 'Clubmanager', icon: 'supervisor_account', role: 'clubmanager', children: [
                { name: 'Gebruikers', target: '/clubs/:clubId/users', icon: 'people', role: 'clubmanager' },
                { name: 'Toegangsverzoeken', target: '/clubs/:clubId/access-requests', icon: 'lock_open', role: 'clubmanager' },
                { name: 'Teams', target: '/clubs/:clubId/teams', icon: 'supervised_user_circle' },
                { name: 'Spelers', target: '/clubs/:clubId/players', icon: 'people_outline' },
                { name: 'Evaluaties', target: '/evaluations', icon: 'list_alt', role: 'clubmanager' },
                { name: 'Teambeoordelingen', target: '/classifications', icon: 'thumbs_up_down', role: 'clubmanager' },
        ] },
        {
            name: 'Hoofdtrainer', icon: 'supervisor_account', role: 'director', children:
                [
                    { name: 'Club trainingen', target: '/club/trainings', icon: 'transfer_within_a_station' },
                    { name: 'Club oefeningen', target: '/club/exercises', icon: 'directions_run' },
                ]
        },
        {
            name: 'Oefenstof', icon: 'school', children:
                [
                    { name: 'Leerlijn oefeningen', target: '/exercise-tags', icon: 'transfer_within_a_station' },
                    { name: 'Eigen oefeningen', target: '/exercises', icon: 'directions_run' },
                ]
        },
    ],
};
