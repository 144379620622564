import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TextInput from '../TextInput';

const UserCompactForm = props => {
    const {
        nameLabel,
        emailLabel,
        submitButtonText,
        requiredFieldMessage,
        emailFieldMessage,
        onSubmit,
    } = props;

    const validationSchemaObject = yup.object().shape({
        name: yup.string().required(requiredFieldMessage),
        email: yup.string().email(emailFieldMessage).required(requiredFieldMessage),
    });

    const initialValues = {
        name: '',
        email: '',
    }

    const handleSubmitAndResetForm = async (values, {resetForm}) => {
        onSubmit(values);
        resetForm(initialValues);
    }

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ handleSubmitAndResetForm }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
            }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'name' }
                            label={ nameLabel }
                            component={ TextInput }
                            errorMessage={ errors['name'] }
                            touched={ touched['name'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name={ 'email' }
                            label={ emailLabel }
                            component={ TextInput }
                            errorMessage={ errors['email'] }
                            touched={ touched['email'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            type={ 'email' }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

UserCompactForm.propTypes = {
    nameLabel: PropTypes.string,
    emailLabel: PropTypes.string,
    adminSubmitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    emailFieldMessage: PropTypes.string,
    handleAddAdmin: PropTypes.func,
};

UserCompactForm.defaultProps = {
    nameLabel: 'name',
    emailLabel: 'email',
    adminSubmitButtonText: 'submit',
    requiredFieldMessage: 'required',
    emailFieldMessage: 'invalid email',
}

export default UserCompactForm;
