import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import SurveyStructure from 'components/SurveyStructure';
import SurveyAnswersTable from 'contents/tables/SurveyAnswersTable';
import { SURVEYS } from 'store/constants';
import { removeEmptyKeys } from 'utilities/object';

const SurveyAnswers = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const surveyId = parseInt(params.surveyId);
    const questionId = parseInt(params.questionId);
    const survey = useSelector(state => state.survey.active);
    const error = useSelector(state => state.surveyAnswer.error);
    const created = useSelector(state => state.surveyAnswer.created);
    const updated = useSelector(state => state.surveyAnswer.updated);
    const deleted = useSelector(state => state.surveyAnswer.deleted);
    const fetched = survey?.id === surveyId;
    const structure = survey?.structure?.data || [];
    const [answers, setAnswers] = useState([]);

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = removeEmptyKeys(data);

            dispatch(SURVEYS.ANSWERS.CREATE.trigger({
                surveyId,
                questionId,
                values,
            }))

            resolve();
        })
    }

    const onUpdate = values => {
        return new Promise((resolve) => {
            const answerId = values.id;
            delete values.id;

            dispatch(SURVEYS.ANSWERS.UPDATE.trigger({
                surveyId,
                questionId,
                answerId,
                values,
            }))

            resolve();
        })
    }

    const onDelete = data => {
        return new Promise((resolve, reject) => {
            dispatch(SURVEYS.ANSWERS.DELETE.trigger({
                surveyId,
                questionId,
                answerId: data.id,
            }));

            resolve();
        });
    };

    useEffect(() => {
        if (! fetched) {
            dispatch(SURVEYS.SHOW.trigger(surveyId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyId, fetched]);

    useEffect(() => {
        const topics = survey?.structure?.data || [];
        const answers = topics
            .map(topic => topic?.questions?.data)
            .reduce((accumulator, item) => [...accumulator, ...item], [])
            .find(question => question.id === questionId)
            ?.answers?.data || [];

        setAnswers(answers);
    }, [survey, questionId]);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Antwoord is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (updated) {
            toast.success('Antwoord is aangepast');
        }
    }, [updated]);

    useEffect(() => {
        if (deleted) {
            toast.success('Antwoord is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Vragenlijst antwoorden" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 8 }>
                        <SurveyAnswersTable
                            data={ answers }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                            onUpdate={ onUpdate }
                        />
                    </Grid>

                    <Grid item xs={ 4 }>
                        <SurveyStructure
                            structure={ structure }
                            questionId={ questionId }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default SurveyAnswers;
