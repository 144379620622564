import PropTypes from 'prop-types';

import { Circle as KonvaCircle } from "react-konva";

const Circle = props => {
    const { draggable, endX, endY, index, onDoubleClick, onUpdate, startX, startY} = props;
    const distanceX = (startX > endX) ? startX - endX : endX - startX;
    const distanceY = (startY > endY) ? startY - endY : endY - startY;
    const radius = distanceX > distanceY ? distanceX : distanceY;

    const handleDoubleClick = () => onDoubleClick(index);

    return (
        <KonvaCircle
            draggable={ draggable }
            onDragEnd={ (e) => {
                onUpdate(index, {
                    endX: e.target.x() + e.target.radius(),
                    endY: e.target.y() + e.target.radius(),
                    startX: e.target.x(),
                    startY: e.target.y(),
                });
            } }
            onDblClick={ handleDoubleClick }
            radius={ radius }
            stroke="black"
            strokeWidth={ 3 }
            x={ startX }
            y={ startY }
        />
    )
};

Circle.propTypes = {
    draggable: PropTypes.bool,
    endX: PropTypes.number.isRequired,
    endY: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    startX: PropTypes.number.isRequired,
    startY: PropTypes.number.isRequired,
};

export default Circle;