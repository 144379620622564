import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import EvaluationCreate from './EvaluationCreate';
import EvaluationOverview from './EvaluationOverview';
import EvaluationShow from './EvaluationShow';

const Evaluations = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ path }
                component={ EvaluationOverview }
            />

            <Route
                exact
                path={ `${path}/create` }
                component={ EvaluationCreate }
            />

            <Route
                exact
                path={ `${path}/:evaluationId` }
                component={ EvaluationShow }
            />
        </Switch>
    );
};

export default Evaluations;