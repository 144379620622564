import { Link } from 'react-router-dom';

import { images } from 'config/images';

const StaticFooter = () => {
    return (
        <footer>
            <article className="copy-r">
                <Link to={ '/' } className="logo">
                    <img src={ images.logo.dark.default } className="img-fluid" alt="Go4Hockey logo" />
                </Link>
                <span>© 2021 Go4Hockey, Alle rechten voorbehouden</span>
            </article>

            <article className="footer-nav">
                <Link to={ '/faq' }>FAQ</Link>
                <a href="/pdf/terms-and-conditions.pdf" target="_blank" rel="noopener noreferrer">Algemene voorwaarden</a>
                <a href="/pdf/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </article>
        </footer>
    );
};

export default StaticFooter;
