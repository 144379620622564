export const theming = {
    palette: {
        primary: {
            main: '#F07D00',
        },

        secondary: {
            main: '#1E66F0',
        },
    }
};