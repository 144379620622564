import { Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Accounts from 'routes/Accounts';
import AccountsCreate from 'routes/AccountsCreate';
import Clubs from 'routes/Clubs';
import ClubExercises from 'routes/ClubExercises';
import ClubExercisesEdit from 'routes/ClubExercisesEdit';
import ClubExercisesCreate from 'routes/ClubExercisesCreate';
import ClubPlayers from 'routes/ClubPlayers';
import ClubTrainings from 'routes/ClubTrainings';
import ClubTrainingsEdit from 'routes/ClubTrainingsEdit';
import ClubTrainingsCreate from 'routes/ClubTrainingsCreate';
import ClubTrainingsShow from 'routes/ClubTrainingsShow';
import Evaluations from 'routes/Evaluations';
import Events from 'routes/Events';
import Exercises from 'routes/Exercises';
import ExercisesCreate from 'routes/ExercisesCreate';
import ExercisesEdit from 'routes/ExercisesEdit';
import ExercisesShow from 'routes/ExercisesShow';
import ExerciseTags from 'routes/ExerciseTags';
import ExerciseTagsShow from 'routes/ExerciseTagsShow';
import Feedback from 'routes/Feedback';
import FeedbackCreate from 'routes/FeedbackCreate';
import FeedbackEdit from 'routes/FeedbackEdit';
import FeedbackShow from 'routes/FeedbackShow';
import Logout from 'routes/Logout';
import MapDrawingTool from 'routes/MapDrawingTool';
import News from 'routes/News';
import NewsCreate from 'routes/NewsCreate';
import NewsEdit from 'routes/NewsEdit';
import NewsShow from 'routes/NewsShow';
import NotFound from 'routes/NotFound';
import Occasions from 'routes/Occasions';
import OccasionsShow from 'routes/OccasionsShow';
import OccasionsExercise from 'routes/OccasionsExercise';
import Orders from 'routes/Orders';
import Periods from 'routes/Periods';
import PeriodsCreate from 'routes/PeriodsCreate';
import PeriodsEdit from 'routes/PeriodsEdit';
import PeriodsShow from 'routes/PeriodsShow';
import Premium from 'routes/Premium';
import PremiumCreate from 'routes/PremiumCreate';
import PremiumEdit from 'routes/PremiumEdit';
import PlayersFeedback from 'routes/PlayersFeedback';
import PlayersSurveys from 'routes/PlayersSurveys';
import PlayerShow from 'routes/Players/PlayerShow'; // Revamped
import Sidebar from 'components/Sidebar';
import Subscription from 'routes/Subscription';
import Surveys from 'routes/Surveys';
import Trainers from 'routes/Trainers';
import TrainersCreate from 'routes/TrainersCreate';
import TrainersEdit from 'routes/TrainersEdit';
import TrainersShow from 'routes/TrainersShow';
import Trainings from 'routes/Trainings';
import TrainingsCreate from 'routes/TrainingsCreate';
import TrainingsEdit from 'routes/TrainingsEdit';
import TrainingsShow from 'routes/TrainingsShow';
import Verify from 'routes/Verify';
import Videos from 'routes/Videos';
import VideosCreate from 'routes/VideosCreate';
import VideosEdit from 'routes/VideosEdit';
import VideosShow from 'routes/VideosShow';

import Apps from 'routes/Apps';
import Assessments from 'routes/Assessments';
import Classifications from 'routes/Classifications';
import Players from 'routes/Players';
import Promo from 'routes/Promo';
import Teams from 'routes/Teams';
import Users from 'routes/Users';

import { PROFILE } from 'store/constants';
import styles from './Main.styles';

const Main = () => { 
    const classes = styles();
    const dispatch = useDispatch();
    const userId = useSelector(state => state.persist.userId);

    useEffect(() => {
        if (userId) {
            dispatch(PROFILE.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <Fragment>
            <Sidebar />

            <main className={ classes.content }>
                <Switch>
                    <Redirect exact from="/" to={ '/map-drawing-tool' } />
                    <Redirect exact from="/login" to={ '/map-drawing-tool' } />

                    <Route exact path={ '/accounts' } component={ Accounts } />
                    <Route exact path={ '/accounts/create' } component={ AccountsCreate } />
                    <Route exact path={ '/club/exercises' } component={ ClubExercises } />
                    <Route exact path={ '/club/exercises/create' } component={ ClubExercisesCreate } />
                    <Route exact path={ '/club/exercises/:exerciseId/edit' } component={ ClubExercisesEdit } />
                    <Route exact path={ '/club/trainings' } component={ ClubTrainings } />
                    <Route exact path={ '/club/trainings/create' } component={ ClubTrainingsCreate } />
                    <Route exact path={ '/club/trainings/:trainingId' } component={ ClubTrainingsShow } />
                    <Route exact path={ '/club/trainings/:trainingId/edit' } component={ ClubTrainingsEdit } />
                    <Route exact path={ '/exercises' } component={ Exercises } />
                    <Route exact path={ '/exercises/create' } component={ ExercisesCreate } />
                    <Route exact path={ '/exercises/:exerciseId' } component={ ExercisesShow } />
                    <Route exact path={ '/exercises/:exerciseId/edit' } component={ ExercisesEdit } />
                    <Route exact path={ '/exercise-tags' } component={ ExerciseTags } />
                    <Route exact path={ '/exercise-tags/:exerciseTagId' } component={ ExerciseTagsShow } />
                    <Route exact path={ '/feedback' } component={ Feedback } />
                    <Route exact path={ '/feedback/:feedbackId/edit' } component={ FeedbackEdit } />
                    <Route exact path={ '/logout' } component={ Logout } />
                    <Route exact path={ '/map-drawing-tool' } component={ MapDrawingTool } />
                    <Route exact path={ '/news' } component={ News } />
                    <Route exact path={ '/news/create' } component={ NewsCreate } />
                    <Route exact path={ '/news/:newsId' } component={ NewsShow } />
                    <Route exact path={ '/news/:newsId/edit' } component={ NewsEdit } />
                    <Route exact path={ '/occasions' } component={ Occasions } />
                    <Route exact path={ '/occasions/:occasionId' } component={ OccasionsShow } />
                    <Route exact path={ '/occasions/:occasionId/exercises/:exerciseId' } component={ OccasionsExercise } />
                    <Route exact path={ '/periods' } component={ Periods } />
                    <Route exact path={ '/periods/create' } component={ PeriodsCreate } />
                    <Route exact path={ '/periods/:periodId' } component={ PeriodsShow } />
                    <Route exact path={ '/periods/:periodId/edit' } component={ PeriodsEdit } />
                    <Route exact path={ '/players/:playerId/feedback/create' } component={ FeedbackCreate } />
                    <Route exact path={ '/players/:playerId/feedback' } component={ PlayersFeedback } />
                    <Route exact path={ '/players/:playerId/feedback/:feedbackId' } component={ FeedbackShow } />
                    <Route exact path={ '/players/:playerId/surveys' } component={ PlayersSurveys } />
                    <Route exact path={ '/players/:playerId' } component={ PlayerShow } />
                    <Route exact path={ '/premium' } component={ Premium } />
                    <Route exact path={ '/premium/create' } component={ PremiumCreate } />
                    <Route exact path={ '/premium/:premiumId/edit' } component={ PremiumEdit } />
                    <Route exact path={ '/subscription' } component={ Subscription } />
                    <Route exact path={ '/trainers' } component={ Trainers } />
                    <Route exact path={ '/trainers/create' } component={ TrainersCreate } />
                    <Route exact path={ '/trainers/:trainerId' } component={ TrainersShow } />
                    <Route exact path={ '/trainers/:trainerId/edit' } component={ TrainersEdit } />
                    <Route exact path={ '/trainings' } component={ Trainings } />
                    <Route exact path={ '/trainings/create' } component={ TrainingsCreate } />
                    <Route exact path={ '/trainings/:trainingId' } component={ TrainingsShow } />
                    <Route exact path={ '/trainings/:trainingId/edit' } component={ TrainingsEdit } />
                    <Route exact path={ '/verify/:userId' } component={ Verify } />
                    <Route exact path={ '/videos' } component={ Videos } />
                    <Route exact path={ '/videos/create' } component={ VideosCreate } />
                    <Route exact path={ '/videos/:videoId' } component={ VideosShow } />
                    <Route exact path={ '/videos/:videoId/edit' } component={ VideosEdit } />


                    <Route path="/apps">
                        <Apps />
                    </Route>

                    <Route path="/assessments">
                        <Assessments />
                    </Route>

                    <Route path="/classifications">
                        <Classifications />
                    </Route>

                    <Route path="/clubs">
                        <Clubs />
                    </Route>

                    <Route path="/club-players">
                        <ClubPlayers />
                    </Route>

                    <Route path="/evaluations">
                        <Evaluations />
                    </Route>

                    <Route path="/events">
                        <Events />
                    </Route>

                    <Route path="/orders">
                        <Orders />
                    </Route>

                    <Route path="/players">
                        <Players />
                    </Route>

                    <Route path="/promo">
                        <Promo />
                    </Route>

                    <Route path="/surveys">
                        <Surveys />
                    </Route>

                    <Route path="/teams">
                        <Teams />
                    </Route>

                    <Route path="/users">
                        <Users />
                    </Route>

                    <Route component={ NotFound } />
                </Switch>
            </main>
        </Fragment>
    );
};

export default Main;
