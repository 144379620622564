import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ValueRow from 'components/ValueRow';

const InvitationList = props => {
    const { invitations } = props;

    const renderList = () => {
        let increment = 0;

        return invitations.map(invitation => {
            increment++;

            return (
                <ListItem
                    divider={ invitations.length !== increment }
                    key={ `invitation-list-item-${increment}`}
                >
                    <Box>
                        <ValueRow
                            label="Respondent"
                            value={ invitation.respondent }
                        />

                        <ValueRow
                            label="Speler"
                            value={ invitation.assessee }
                        />
                    </Box>
                </ListItem>
            );
        })
    }

    return (
        <List>
            { renderList() }
        </List>
    );
};

InvitationList.propTypes = {
    invitations: PropTypes.array.isRequired,
};

export default InvitationList;
