import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { ORDERS } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest() {
    const token = yield select(getToken);

    return yield get(token, Api.requests.orders.index);
}

export function* fetchOneRequest(orderId) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.orders.show(orderId));
}

export function* fetchFlow() {
    while (true) {
        yield take(ORDERS.FETCH.TRIGGER);

        try {
            const response = yield call(fetchAllRequest);
            const orders = response.data.data;

            yield put(ORDERS.FETCH.success(orders));
        } catch (error) {
            yield put(ORDERS.FETCH.failure());
        } finally {
            yield put(ORDERS.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(ORDERS.SHOW.TRIGGER);

        try {
            const orderId = request.payload;
            const response = yield call(fetchOneRequest, orderId);
            const order = response.data.data;

            yield put(ORDERS.SHOW.success(order));
        } catch (error) {
            yield put(ORDERS.SHOW.failure());
        } finally {
            yield put(ORDERS.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
    ]);
}