import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useState } from 'react';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import ChipCollection from '../../../components/ChipCollection';
import EventDateForm from '../../forms/EventDateForm';

const EventDatesBlock = props => {
    const { onAdd, onDelete, dates } = props;
    const [editMode, setEditMode] = useState(false);
    const chips = dates.map(date => {
        return {
            id: date.id,
            label: date.date,
        }
    });

    const addDate = values => {
        setEditMode(false);
        onAdd(values);
    }

    const deleteDate = dateId => {
        onDelete(dateId);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Data"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                { editMode ? (
                    <EventDateForm onSubmit={ addDate } />
                ) : (
                    <ChipCollection
                        items={ chips }
                        onDelete={ deleteDate }
                    />
                ) }
            </Box>
        </ContentBox>
    );
};

EventDatesBlock.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    dates: PropTypes.array.isRequired,
}

export default EventDatesBlock;