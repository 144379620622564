import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AssessmentTopic from 'components/AssessmentTopic';
import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import LoadingIndicator from 'components/LoadingIndicator';
import ReportHeader from 'components/ReportHeader';
import { ASSESSMENTS } from 'store/constants';
import { downloadFile } from 'utilities/api';
import { getAverage } from 'utilities/calculations';
import { reverseToReadableDate } from 'utilities/date';

const AssessmentShow = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const assessmentId = parseInt(params.assessmentId);
    const assessment = useSelector(state => state.assessment.active);
    const error = useSelector(state => state.assessment.error);
    const accessToken = useSelector(state => state.persist.accessToken);
    const fetched = assessment?.id === assessmentId;
    const [isLoading, setLoading] = useState(false);

    const structure = assessment?.survey?.data?.structure?.data || [];
    const answers = assessment?.answers?.data || [];
    const grades = answers
        .filter(answer => answer.grade)
        .map(answer => answer.grade);
    const average = getAverage(grades, 1);

    const downloadEvaluation = async () => {
        const server = process.env.REACT_APP_API_SERVER;
        const downloadUrl = `${server}/api/assessments/${assessmentId}/pdf`;
        const fileName = `Evaluatie ${assessment?.player_name}.pdf`;

        setLoading(true);

        await downloadFile(accessToken, downloadUrl, fileName);

        setLoading(false);
    }

    const renderTopics = () => {
        return structure.map(topic => {
            const questions = topic?.questions?.data || [];
            const hasAnswers = questions.some(question => {
                return answers.some(answer => answer.survey_question_id === question.id)
            });

            if (! hasAnswers) {
                return null;
            }

            return (
                <Box
                    key={ `topic-${topic.id}` }
                    mt={ 2 }
                >
                    <AssessmentTopic
                        answers={ answers }
                        questions={ topic.questions.data }
                        topic={ topic.name }
                    />
                </Box>
            )
        })
    }

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(ASSESSMENTS.SHOW.trigger(assessmentId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title="Evaluatie" backButton>
            { fetched ? (
                <>
                    <ReportHeader
                        date={ reverseToReadableDate(assessment.date) }
                        grade={ average }
                        player={ assessment.player_name }
                        survey={ assessment?.survey?.data?.name || 'Onbekend' }
                    />

                    { renderTopics() }

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            onClick={ downloadEvaluation }
                            variant="contained"
                        >
                            Download evaluatie
                        </Button>
                    </Box>
                </>
            ) : (
                <LoadingIndicator />
            ) }

            <LoadingBackdrop
                open={ isLoading }
                message="Bezig met ophalen van PDF"
            />
        </ContentContainer>
    );
};

export default AssessmentShow;