import { Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Api } from 'config/api';
import { images } from 'config/images';
import { guestPost } from 'utilities/api';
import ForgotPasswordForm from '../ForgotPasswordForm';
import StaticHeader from 'components/StaticHeader';

const PasswordRecovery = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const pageTitle = useIntl().formatMessage({ id: 'passwordRecovery.title' });
    const pageIntro = useIntl().formatMessage({ id: 'passwordRecovery.intro' });
    const loginLabel = useIntl().formatMessage({ id: 'passwordRecovery.label.login' });
    const emailLabel = useIntl().formatMessage({ id: 'passwordRecovery.label.email' });
    const emailFieldMessage = useIntl().formatMessage({ id: 'validation.error.email' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const submitButtonText = useIntl().formatMessage({ id: 'passwordRecovery.button.sendMail' });
    const errorMessage = useIntl().formatMessage({ id: 'passwordRecovery.mailSend.error' });
    const mailSentMessage = useIntl().formatMessage({ id: 'passwordRecovery.mailSend.success' });

    const onSubmit = async values => {
        setError(null);
        setSuccess(null);

        try {
            await guestPost(Api.requests.requestPasswordReset, values);

            setSuccess(mailSentMessage);
        } catch (error) {
            setError(errorMessage);

            return true;
        }

    }

    return (
        <Fragment>
            <StaticHeader />

            <main role="main" className="pages sub">
                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.login.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.login.right.default } alt="Splash" />
                    </div>
                </figure>

                <section className="log-in">
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xm-8 col-xs-8 mx-auto no-gutters">
                                <article className="info">
                                    <h1>{ pageTitle }</h1>
                                    <p>{ pageIntro }</p>

                                    { success && <Alert severity="success">{ success }</Alert> }
                                    { error && <Alert severity="error">{ error }</Alert> }
                                </article>

                                { ! success && ! error ? (
                                    <div>
                                        { error ? <Alert severity="error">{ error }</Alert> : null }

                                        <ForgotPasswordForm
                                            emailLabel={ emailLabel }
                                            emailFieldMessage={ emailFieldMessage }
                                            requiredFieldMessage={ requiredFieldMessage }
                                            submitButtonText={ submitButtonText }
                                            onSubmit={ onSubmit }
                                        />
                                    </div>
                                ) : null }

                                <Box mt={ 3 }>
                                    <Link to={ '/login' }>{ loginLabel }</Link>
                                </Box>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default PasswordRecovery;
