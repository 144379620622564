import { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import styles from './TextPanel.styles';

const TextPanel = props => {
    const { addText } = props;
    const [text, setText] = useState('');
    const classes = styles();

    const handleAddText = () => {
        addText(text);

        setText('');
    };

    return (
        <Paper elevation={ 2 } className={ classes.root }>
            <TextField
                className={ classes.input }
                fullWidth
                id="addTextField"
                label={ useIntl().formatMessage({ id: 'mapDrawing.textPanel.text.placeholder'}) }
                onChange={ e => setText(e.target.value) }
                value={ text }
                variant="outlined"
            />

            <Button
                className={ classes.button }
                color="primary"
                fullWidth
                onClick={ handleAddText }
                variant="contained"
                size={ 'large' }
            >
                { useIntl().formatMessage({ id: 'mapDrawing.textPanel.text.button'}) }
            </Button>
        </Paper>
    );
};

TextPanel.propTypes = {
    addText: PropTypes.func.isRequired,
};

export default TextPanel;