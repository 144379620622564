import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import BoxHeading from 'components/BoxHeading';
import ContentBox from 'components/ContentBox';
import InvitationList from 'components/InvitationList';

const EvaluationOpenInvitesBlock = props => {
    const { invites } = props;

    return (
        <ContentBox>
            <BoxHeading
                title="Open uitnodigingen"
            />

            <Box mt={ 2 }>
                { invites.length ? (
                    <InvitationList
                        invitations={ invites }
                    />
                ) : (
                    <Typography variant="body1">
                        Er zijn geen open uitnodigingen.
                    </Typography>
                ) }
            </Box>
        </ContentBox>
    );
};

EvaluationOpenInvitesBlock.propTypes = {
    invites: PropTypes.array.isRequired,
}

export default EvaluationOpenInvitesBlock;