import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { ASSESSMENTS } from '../constants';
import { fetchRequest } from '../api';

export function* showFlow() {
    while (true) {
        const request = yield take(ASSESSMENTS.SHOW.TRIGGER);

        try {
            const assessmentId = request.payload;
            const endpoint = Api.requests.assessments.show(assessmentId);
            const response = yield call(fetchRequest, endpoint);

            yield put(ASSESSMENTS.SHOW.success(response.data.data));
        } catch (error) {
            yield put(ASSESSMENTS.SHOW.failure());
        } finally {
            yield put(ASSESSMENTS.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        showFlow(),
    ]);
}