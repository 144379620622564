import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useState } from 'react';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import EventForm from 'contents/forms/EventForm';
import EventDetails from 'contents/details/EventDetails';

const EventDetailsBlock = props => {
    const { clubs, event, eventTypes, onSubmit } = props;
    const [editMode, setEditMode] = useState(false);
    const initialValues = {
        name: event?.name,
        event_type_id: event?.event_type_id,
        club_id: event?.club_id,
        places: event?.places,
        price: event?.price,
        description: event?.description,
        explanation: event?.explanation,
    };

    const updateEvent = values => {
        setEditMode(false);
        onSubmit(values);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Event details"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                { editMode ? (
                    <EventForm
                        clubs={ clubs }
                        editMode
                        eventTypes={ eventTypes }
                        initialValues={ initialValues }
                        onSubmit={ updateEvent }
                        submitButtonText="Pas event aan"
                    />
                ) : (
                    <EventDetails event={ event } />
                ) }
            </Box>
        </ContentBox>
    );
};

EventDetailsBlock.propTypes = {
    event: PropTypes.object.isRequired,
    clubs: PropTypes.array.isRequired,
    eventTypes: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default EventDetailsBlock;