import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';

const EventDetails = props => {
    const { event } = props;

    return (
        <>
            <ValueBlock label="Event" value={ event?.name } />
            <ValueBlock label="Type" value={ event?.type } />
            <ValueBlock label="Club" value={ event?.club } />
            <ValueBlock label="Prijs" value={ event?.price } />
            <ValueBlock label="Beschikbare plaatsen" value={ event?.places } />
            <ValueBlock label="Omschrijving" value={ event?.description } />
            <ValueBlock label="Uitleg" value={ event?.explanation } />
        </>
    );
};

EventDetails.propTypes = {
    event: PropTypes.object.isRequired,
};

export default EventDetails;