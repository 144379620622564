import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import PromoExercise from './PromoExercise';
import PromoInstruction from './PromoInstruction';

const Promo = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ `${path}/oefening/:slug` }
                component={ PromoExercise }
            />

            <Route
                exact
                path={ `${path}/instructie/:slug` }
                component={ PromoInstruction }
            />

            <Redirect from={ path } to={ '/' } />
        </Switch>
    );
};

export default Promo;