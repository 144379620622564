import { AUTH, CLUB_EXERCISES } from '../constants';
import { deleteObjectWithIdFromArray, findIdAndUpdateObjectInArray } from 'utilities/array';

const INITIAL_STATE = {
    exercises: [],
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const exerciseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB_EXERCISES.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case CLUB_EXERCISES.FETCH.SUCCESS:
            return {
                ...state,
                exercises: action.payload,
                fetched: true,
            };

        case CLUB_EXERCISES.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_EXERCISES.FETCH.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case CLUB_EXERCISES.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                created: false,
            }

        case CLUB_EXERCISES.CREATE.SUCCESS:
            return {
                ...state,
                exercises: state.exercises.concat(action.payload),
                created: action.payload.id,
            };

        case CLUB_EXERCISES.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_EXERCISES.CREATE.FULFILL:
            return {
                ...state,
                loading: false,
                created: false,
                error: false,
            }


        case CLUB_EXERCISES.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
            }

        case CLUB_EXERCISES.UPDATE.SUCCESS:
            return {
                ...state,
                exercises: findIdAndUpdateObjectInArray([...state.exercises], action.payload.id, action.payload),
                updated: true,
            };

        case CLUB_EXERCISES.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_EXERCISES.UPDATE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case CLUB_EXERCISES.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                deleted: false,
            }

        case CLUB_EXERCISES.DELETE.SUCCESS:
            return {
                ...state,
                exercises: deleteObjectWithIdFromArray([...state.exercises], action.payload),
                deleted: true,
            };

        case CLUB_EXERCISES.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_EXERCISES.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                deleted: false,
                error: false,
            }

        case CLUB_EXERCISES.TAG.UPDATE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case CLUB_EXERCISES.TAG.UPDATE.SUCCESS:
            return {
                ...state,
                exercises: findIdAndUpdateObjectInArray([...state.exercises], action.payload.id, action.payload)
            }

        case CLUB_EXERCISES.TAG.UPDATE.FAILURE:
        case CLUB_EXERCISES.TAG.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case CLUB_EXERCISES.TAG.UPDATE.FULFILL:
        case CLUB_EXERCISES.TAG.DELETE.FULFILL:
            return {
                ...state,
                error: false
            }

        case CLUB_EXERCISES.TAG.DELETE.TRIGGER:
            return {
                ...state,
                error: null,
            }

        case CLUB_EXERCISES.TAG.DELETE.SUCCESS:
            return {
                ...state,
                exercises: findIdAndUpdateObjectInArray([...state.exercises], action.payload.id, action.payload)
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default exerciseReducer;