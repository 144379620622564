import PropTypes from 'prop-types';

import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';

import styles from './RatingInput.styles';

const RatingInput = ({ field, ...props }) => {
    const { label, errorMessage, touched } = props;
    const { name, value, onChange, onBlur } = field;
    const classes = styles();

    return (
        <div className={ classes.root }>
            <Typography variant="body1">
                { label }
            </Typography>

            <div className={ classes.ratingContainer }>
                <Rating
                    name={ name }
                    value={ parseInt(value) }
                    onChange={ onChange }
                    onBlur={ onBlur }
                />
            </div>

            { touched && errorMessage ? (
                <Typography variant="caption" color="error" align={ 'left' }>
                    { errorMessage }
                </Typography>
            ) : null}
        </div>
    );
};

RatingInput.propTypes = {
    field: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    touched: PropTypes.bool,
};

export default RatingInput;
