import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import TrainingForm from 'components/TrainingForm';
import ExerciseChips from 'components/ExerciseChips';
import AddExerciseToTrainingForm from 'components/AddExerciseToTrainingForm';
import styles from './TrainingsEdit.styles';
import {
    TRAININGS,
    DIVISIONS,
    EXERCISES,
    INTERNAL_TRAINERS
} from 'store/constants';

const TrainingsEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const trainingId = parseInt(params.trainingId);
    const training = useSelector(state => state.training.active);
    const fetchedTraining = trainingId === training?.id;
    const fetchedDivisions = useSelector(state => state.division.fetched);
    const fetchedExercises = useSelector(state => state.exercise.fetched);
    const fetchedInternalTrainers = useSelector(state => state.internalTrainer.fetched);
    const isFetching = ! (fetchedTraining && fetchedDivisions && fetchedExercises && fetchedInternalTrainers);
    const loadingDivisions = useSelector(state => state.division.loading);
    const loadingExercises = useSelector(state => state.exercise.loading);
    const loadingInternalTrainers = useSelector(state => state.internalTrainer.loading);
    const isLoading = ! fetchedTraining || loadingDivisions || loadingExercises || loadingInternalTrainers;
    const classes = styles();

    const pageTitle = useIntl().formatMessage({ id: 'trainings.update.title' });
    const error = useSelector(state => state.training.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.training.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'trainings.update.loading' });

    const divisions = useSelector(state => state.division.divisions);
    const internalTrainers = useSelector(state => state.internalTrainer.internalTrainers);
    const trainingExercises = training ? training.exercises.data : [];
    const exercises = useSelector(state => state.exercise.exercises);
    const availableExercises = exercises.filter(exercise => {
        const index = trainingExercises.findIndex(trainingExercise => {
            return trainingExercise.id === exercise.id;
        });

        return index < 0;
    });

    const nameLabel = useIntl().formatMessage({ id: 'trainings.label.name' });
    const divisionLabel = useIntl().formatMessage({ id: 'trainings.label.division' });
    const exercisesLabel = useIntl().formatMessage({ id: 'trainings.label.exercises' });
    const exerciseLabel = useIntl().formatMessage({ id: 'trainings.label.exercise' });
    const trainerLabel = useIntl().formatMessage({ id: 'trainings.label.trainer' });
    const submitButtonText = useIntl().formatMessage({ id: 'trainings.button.update' });
    const addExerciseButtonText = useIntl().formatMessage({ id: 'trainings.button.addExercise' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });

    const getInitialValues = () => {
        if (training) {
            return {
                name: training.name,
                division_id: training.division.data.id,
            }
        }

        return {};
    }

    const handleAddExercise = (values) => {
        const exercise = exercises.find(exercise => exercise.id === values.exercise_id);
        const trainer = internalTrainers.find(trainer => trainer.id === values.trainer_id);

        if (trainer !== undefined) {
            exercise.internal_trainer = trainer.user.data.full_name;
        }

        dispatch(TRAININGS.EXERCISE.UPDATE.trigger({ training, exercise, trainer }));

        return true;
    }

    const handleDeleteExercise = exercise => {
        dispatch(TRAININGS.EXERCISE.DELETE.trigger({ training, exercise }));
    }

    const onSubmit = values => {
        dispatch(TRAININGS.UPDATE.trigger({
            id: params.trainingId,
            values
        }));

        return true
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    useEffect(() => {
        if (fetchedTraining === false) {
            dispatch(TRAININGS.SHOW.trigger(trainingId));
        }

        if (fetchedDivisions === false) {
            dispatch(DIVISIONS.FETCH.trigger());
        }

        if (fetchedExercises === false) {
            dispatch(EXERCISES.FETCH.trigger());
        }

        if (fetchedInternalTrainers === false) {
            dispatch(INTERNAL_TRAINERS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: TRAININGS.CLEAN });
            dispatch({ type: DIVISIONS.CLEAN });
            dispatch({ type: EXERCISES.CLEAN });
            dispatch({ type: INTERNAL_TRAINERS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetchedTraining && fetchedDivisions ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <TrainingForm
                                initialValues={ getInitialValues() }
                                nameLabel={ nameLabel }
                                divisionLabel={ divisionLabel }
                                divisions={ divisions }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                onSubmit={ onSubmit }
                                editMode
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <AddExerciseToTrainingForm
                                trainerLabel={ trainerLabel }
                                exerciseLabel={ exerciseLabel }
                                submitButtonText={ addExerciseButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                trainers={ internalTrainers }
                                exercises={ availableExercises }
                                selectedExercises={ trainingExercises }
                                onSubmit={ handleAddExercise }
                            />
                        </Paper>

                        <Box mt={ 2 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <Box mb={ 1.5 }>
                                    <Typography variant="h6" component="h1" gutterBottom>
                                        { exercisesLabel }
                                    </Typography>
                                </Box>

                                <ExerciseChips
                                    exercises={ trainingExercises }
                                    onDelete={ handleDeleteExercise }
                                />
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ isLoading } message={ ! isFetching ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default TrainingsEdit;
