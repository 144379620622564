import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import TeamEvaluationsTable from 'contents/tables/TeamEvaluationsTable';
import { TEAM } from 'store/constants';

const TeamEvaluations = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const teamId = parseInt(params.teamId);
    const evaluations = useSelector(state => state.teamEvaluation.evaluations);
    const reducerTeamId = useSelector(state => state.teamEvaluation.teamId);
    const error = useSelector(state => state.teamEvaluation.error);
    const fetched = teamId === reducerTeamId;

    useEffect(() => {
        if (! fetched) {
            dispatch(TEAM.EVALUATION.FETCH.trigger(teamId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetched, teamId]);

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    return (
        <ContentContainer title="Team: evaluaties" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <TeamEvaluationsTable
                            data={ evaluations }
                            teamId={ teamId }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default TeamEvaluations;