import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { sortCaseInsensitive } from 'utilities/sort';
import { EXERCISE_TAGS, EXERCISES, PROFILE } from 'store/constants';
import {
    defaultOptions,
    getMaterialTableTranslation
} from 'utilities/materialTable';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';
import LatestExercises from 'components/LatestExercises';

const ExerciseTags = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.exerciseTag.fetched);
    const exerciseTags = useSelector(state => state.exerciseTag.exerciseTags);
    const latestExercises = useSelector(state => state.exercise.latest);
    const loading = useSelector(state => state.exerciseTag.loading);
    const locale = useSelector(state => state.persist.language);
    const premiumFetched = useSelector(state => state.profile.fetched);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const isPremium = useSelector(state => state.profile.is_premium);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);
    const options = {
        ...defaultOptions,
        pageSize: 100,
    }

    const pageTitle = useIntl().formatMessage({ id: 'exerciseTags.title' });
    const latestTitle = useIntl().formatMessage({ id: 'exercises.latest' });
    const error = useSelector(state => state.exerciseTag.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'exerciseTags.tag' }),
            field: 'name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'exerciseTags.count' }),
            field: 'exercise_count',
            sorting: true,
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`exercise-tags/${rowData.id}`)
        },
    ];

    useEffect(() => {
        if (fetched === false) {
            dispatch(EXERCISE_TAGS.FETCH.trigger());
        }

        if (latestExercises.length === 0) {
            dispatch(EXERCISES.LATEST.trigger());
        }

        dispatch(PROFILE.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true && isPremium) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage, isPremium]);

    return (
        <ContentContainer title={ pageTitle }>
            <PremiumWall
                isProfileFetched={ premiumFetched }
                isPremium={ isPremium || hasCorrectPermission || isAdmin }
                premiumImage={ images.premium.exerciseTags.default }
            >
                { loading ? <LoadingIndicator /> : (
                    <Grid container spacing={ 3 }>
                        <Grid item xs={ 8 }>
                            <MaterialTable
                                title={ pageTitle }
                                localization={ localization }
                                columns={ columns }
                                data={ exerciseTags }
                                options={ options }
                                actions={ actions }
                            />
                        </Grid>

                        <Grid item xs={ 4 }>
                            <LatestExercises
                                title={ latestTitle }
                                items={ latestExercises }
                            />
                        </Grid>
                    </Grid>
                ) }
            </PremiumWall>
        </ContentContainer>
    );
};

export default ExerciseTags;
