import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { sortCaseInsensitive } from 'utilities/sort';
import { EXERCISES } from 'store/constants';
import {
    defaultOptions,
    getMaterialTableTranslation
} from 'utilities/materialTable';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';

const Exercises = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.exercise.fetched);
    const exercises = useSelector(state => state.exercise.exercises);
    const locale = useSelector(state => state.persist.language);
    const premiumFetched = useSelector(state => state.profile.fetched);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const isPremium = useSelector(state => state.profile.is_premium);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'exercises.title' });
    const error = useSelector(state => state.exercise.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.exercise.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const defaultColumns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'exercises.label.title' }),
            field: 'title',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'title'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.description' }),
            field: 'description',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'description'),
            defaultSort: 'asc',
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.difficulty' }),
            field: 'difficulty',
            defaultSort: 'asc',
            render: rowData => <Rating name="disabled" value={ parseInt(rowData.difficulty) } disabled />
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.tags' }),
            render: rowData => {
                const tags = rowData.tags.data.map(function (tag) {
                    return tag.name;
                });

                return tags.join(', ');
            },
            sorting: false,
        },
    ];

    const adminColumns = [
        {
            customSort: (a, b) => a?.labels?.length > b?.labels.length ? 1 : -1,
            title: 'Labels',
            render: rowData => {
                return rowData.labels.join(', ');
            },
        },
    ];

    const columns = isAdmin ? [
        ...defaultColumns,
        ...adminColumns,
    ] : defaultColumns;

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('exercises/create')
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`exercises/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`exercises/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(EXERCISES.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        dispatch(EXERCISES.FETCH.trigger());

        return function cleanup() {
            dispatch({ type: EXERCISES.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true && isPremium) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage, isPremium]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            <PremiumWall
                isProfileFetched={ premiumFetched }
                isPremium={ isPremium || hasCorrectPermission || isAdmin }
                premiumImage={ images.premium.exercise.default }
            >
                { ! fetched ? <LoadingIndicator /> : (
                    <Grid container spacing={3}>
                        <Grid item xs={ 12 }>
                            <MaterialTable
                                title={ pageTitle }
                                localization={ localization }
                                columns={ columns }
                                data={ exercises }
                                options={ defaultOptions }
                                actions={ actions }
                                editable={ { onRowDelete } }
                            />
                        </Grid>
                    </Grid>
                ) }
            </PremiumWall>
        </ContentContainer>
    );
};

export default Exercises;
