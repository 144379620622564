import { all } from 'redux-saga/effects';

import accountSaga from './AccountSaga';
import assessmentSaga from './AssessmentSaga';
import authSaga from './AuthSaga';
import classificationSaga from './ClassificationSaga';
import classificationTeamSaga from './ClassificationTeamSaga';
import clubSaga from './ClubSaga';
import clubAccessRequestSaga from './ClubAccessRequestSaga';
import clubExerciseSaga from './ClubExerciseSaga';
import clubPlayerSaga from './ClubPlayerSaga';
import clubPlayerAssessmentSaga from './ClubPlayerAssessmentSaga';
import clubTeamSaga from './ClubTeamSaga';
import clubTrainingSaga from './ClubTrainingSaga';
import clubUserSaga from './ClubUserSaga';
import criterionSaga from './CriterionSaga';
import divisionSaga from './DivisionSaga';
import evaluationSaga from './EvaluationSaga';
import eventTypeSaga from './EventTypeSaga';
import eventSaga from './EventSaga';
import exerciseSaga from './ExerciseSaga';
import exerciseTagSaga from './ExerciseTagSaga';
import feedbackSaga from './FeedbackSaga';
import internalTrainerSaga from './InternalTrainerSaga';
import matchSaga from './MatchSaga';
import newsSaga from './NewsSaga';
import occasionSaga from './OccasionSaga';
import orderSaga from './OrderSaga';
import periodSaga from './PeriodSaga';
import playerSaga from './PlayerSaga';
import playercardSaga from './PlayercardSaga';
import premiumSaga from './PremiumSaga';
import profileSaga from './ProfileSaga';
import surveyAnswerSaga from './SurveyAnswerSaga';
import surveyQuestionSaga from './SurveyQuestionSaga';
import surveySaga from './SurveySaga';
import surveyTopicSaga from './SurveyTopicSaga';
import tagSaga from './TagSaga';
import teamAdminSaga from './TeamAdminSaga';
import teamEvaluationSaga from './TeamEvaluationSaga';
import teamPlayerSaga from './TeamPlayerSaga';
import trainingSaga from './TrainingSaga';
import userSaga from './UserSaga';
import videoSaga from './VideoSaga';

export default function* rootSaga(getState) {
    yield all([
        accountSaga(),
        assessmentSaga(),
        authSaga(),
        classificationSaga(),
        classificationTeamSaga(),
        clubSaga(),
        clubAccessRequestSaga(),
        clubExerciseSaga(),
        clubPlayerSaga(),
        clubPlayerAssessmentSaga(),
        clubTeamSaga(),
        clubTrainingSaga(),
        clubUserSaga(),
        criterionSaga(),
        divisionSaga(),
        evaluationSaga(),
        eventTypeSaga(),
        eventSaga(),
        exerciseSaga(),
        exerciseTagSaga(),
        feedbackSaga(),
        internalTrainerSaga(),
        matchSaga(),
        newsSaga(),
        occasionSaga(),
        orderSaga(),
        periodSaga(),
        playerSaga(),
        playercardSaga(),
        premiumSaga(),
        profileSaga(),
        surveyAnswerSaga(),
        surveyQuestionSaga(),
        surveySaga(),
        surveyTopicSaga(),
        tagSaga(),
        teamAdminSaga(),
        teamEvaluationSaga(),
        teamPlayerSaga(),
        trainingSaga(),
        userSaga(),
        videoSaga(),
    ]);
}
