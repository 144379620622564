import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

import styles from './FullScreenBackground.styles';

const FullScreenBackground = props => {
    const classes = styles();

    const customClasses = makeStyles(() => ({
        backgroundImage: {
            backgroundImage: `url(${props.image})`,
        }
    }));

    return (
        <div className={ `${classes.root} ${customClasses().backgroundImage}` }>
            { props.children }
        </div>
    );
};

FullScreenBackground.propTypes = {
    image: PropTypes.string.isRequired,
};

export default FullScreenBackground;