import { PREMIUM } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    premium: [],
    active: null,
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const premiumReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PREMIUM.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case PREMIUM.FETCH.SUCCESS:
            return {
                ...state,
                premium: action.payload,
                fetched: true,
            };

        case PREMIUM.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case PREMIUM.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case PREMIUM.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                created: false,
            }

        case PREMIUM.CREATE.SUCCESS:
            return {
                ...state,
                premium: state.premium.concat(action.payload),
                created: action.payload.id,
            };

        case PREMIUM.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case PREMIUM.CREATE.FULFILL:
            return {
                ...state,
                loading: false,
                created: null,
            }

        case PREMIUM.SHOW.TRIGGER:
            return {
                ...state,
                error: false,
            }

        case PREMIUM.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case PREMIUM.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case PREMIUM.SHOW.FULFILL:
            return {
                ...state,
                error: false,
            }

        case PREMIUM.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                updated: false,
            }

        case PREMIUM.UPDATE.SUCCESS:
            return {
                ...state,
                premium: findIdAndUpdateObjectInArray([...state.premium], action.payload.id, action.payload),
                active: action.payload,
                updated: true,
            };

        case PREMIUM.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case PREMIUM.UPDATE.FULFILL:
            return {
                ...state,
                loading: false,
                updated: false,
            }

        case PREMIUM.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                deleted: false,
            }

        case PREMIUM.DELETE.SUCCESS:
            return {
                ...state,
                premium: deleteObjectWithIdFromArray([...state.premium], action.payload),
                deleted: true,
            };

        case PREMIUM.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case PREMIUM.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case PREMIUM.CLEAN:
            return {
                ...state,
                error: false,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        default:
            return state;
    }
};

export default premiumReducer;