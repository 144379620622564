import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { PLAYERCARD } from '../constants';
import { fetchRequest } from '../api';

export function* showFlow() {
    while (true) {
        const request = yield take(PLAYERCARD.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.playercard.show(id);
            const response = yield call(fetchRequest, endpoint);

            yield put(PLAYERCARD.SHOW.success(response.data.data));
        } catch (error) {
            yield put(PLAYERCARD.SHOW.failure());
        } finally {
            yield put(PLAYERCARD.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        showFlow(),
    ]);
}