import { useState } from 'react';
import PropTypes from 'prop-types';

import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';

import ConfirmModal from 'components/ConfirmModal';
import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import {downloadFile} from "../../../utilities/api";
import {useSelector} from "react-redux";

const EvaluationsTable = props => {
    const accessToken = useSelector(state => state.persist.accessToken);
    const { data, onDelete, onClear, onReminder, onSync } = props;
    const history = useHistory();
    const [clearActive, setClearActive] = useState(false);
    const [reminderActive, setReminderActive] = useState(false);
    const [syncActive, setSyncActive] = useState(false);
    const isClearOpen = !! clearActive;
    const isReminderOpen = !! reminderActive;
    const isSyncOpen = !! syncActive;

    const options = {
        ...defaultOptions,
        selection: true,
    }

    const columns = [
        {
            defaultSort: 'desc',
            field: 'date',
            render: rowData => reverseToReadableDate(rowData.date),
            title: 'Datum',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a.survey.data, b.survey.data, 'name'),
            field: 'survey.data.name',
            title: 'Vragenlijst',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a.team.data, b.team.data, 'name'),
            field: 'team.data.name',
            title: 'Team',
        },
        {
            field: 'number_of_assessments',
            title: 'Reacties',
            type: 'numeric',
        },
        {
            field: 'last_reminder_sent_at',
            render: rowData => reverseToReadableDate(rowData.last_reminder_sent_at),
            title: 'Laatste reminder',
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            position: 'row',
            onClick: (event, rowData) => history.push(`/evaluations/${rowData.id}`),
        },
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: () => history.push('/evaluations/create'),
        },
        {
            icon: 'download',
            tooltip: 'PDF',
            position: 'row',
            onClick: (event, rowData) => downloadEvaluation(rowData.id, `Evaluatie ${rowData.name} (${rowData.date})`),
        },
        {
            icon: 'sync',
            tooltip: 'Synchroniseer uitnodigingen',
            onClick: (event, data) => setSyncActive(data),
        },
        {
            icon: 'notifications_none_outlined',
            tooltip: 'Verstuur herinneringen',
            onClick: (event, data) => setReminderActive(data),
        },
        {
            icon: 'phonelink_erase',
            tooltip: 'Verwijder open uitnodigingen',
            onClick: (event, data) => setClearActive(data),
        },
    ];

    const editable = {
        onRowDelete: onDelete,
    };

    const closeModal = () => {
        setClearActive(null)
        setReminderActive(null)
        setSyncActive(null)
    };

    const onClearConfirm = () => {
        const evaluationIds = clearActive.map(evaluation => evaluation.id);

        onClear(evaluationIds);
        closeModal();
    }

    const onReminderConfirm = () => {
        const evaluationIds = reminderActive.map(evaluation => evaluation.id);

        onReminder(evaluationIds);
        closeModal();
    }

    const onSyncConfirm = () => {
        const evaluationIds = syncActive.map(evaluation => evaluation.id);

        onSync(evaluationIds);
        closeModal();
    }

    const downloadEvaluation = async (evaluationId, fileName) => {
        const server = process.env.REACT_APP_API_SERVER;
        const downloadUrl = `${server}/api/evaluations/${evaluationId}/pdf`;

        await downloadFile(accessToken, downloadUrl, fileName);
    }

    return (
        <>
            <MaterialTable
                title="Evaluaties"
                localization={ localization }
                columns={ columns }
                data={ data }
                options={ options }
                actions={ actions }
                editable={ editable }
            />

            <ConfirmModal
                confirmButtonText="Verwijder"
                content="Weet je zeker dat je de open uitnodigingen wilt verwijderen?"
                isOpen={ isClearOpen }
                onClose={ closeModal }
                onConfirm={ onClearConfirm }
                title="Open uitnodigingen verwijderen"
            />

            <ConfirmModal
                confirmButtonText="Verstuur"
                content="Weet je zeker dat je herinneringen wilt versturen?"
                isOpen={ isReminderOpen }
                onClose={ closeModal }
                onConfirm={ onReminderConfirm }
                title="Herinneringen versturen"
            />

            <ConfirmModal
                confirmButtonText="Synchroniseer"
                content="Weet je zeker dat je wilt synchroniseren?"
                isOpen={ isSyncOpen }
                onClose={ closeModal }
                onConfirm={ onSyncConfirm }
                title="Synchroniseren"
            />
        </>
    );
};

EvaluationsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onReminder: PropTypes.func.isRequired,
    onSync: PropTypes.func.isRequired,
};

export default EvaluationsTable;
