import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { reverseToReadableDate } from 'utilities/date';
import { sortCaseInsensitive } from 'utilities/sort';
import { SURVEYS } from 'store/constants';

const PlayersSurveys = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const playerId = parseInt(params.playerId);
    const surveys = useSelector(state => state.survey.player.surveys);
    const surveyPlayerId = useSelector(state => state.survey.player.profile?.id);
    const loading = useSelector(state => state.survey.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'surveys.title' });
    const error = useSelector(state => state.survey.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.survey.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'surveys.label.respondent' }),
            field: 'respondent.data.name',
            customSort: (a, b) => sortCaseInsensitive(a.respondent.data, b.respondent.data, 'name'),
        },
        {
            title: useIntl().formatMessage({ id: 'surveys.label.topic' }),
            field: 'topic.data.name',
            customSort: (a, b) => sortCaseInsensitive(a.topic.data, b.topic.data, 'name'),
        },
        {
            title: useIntl().formatMessage({ id: 'surveys.label.date' }),
            render: rowData => reverseToReadableDate(rowData.date),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'date'),
            defaultSort: 'desc',
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push(`/players/${playerId}/surveys/create`)
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/players/${playerId}/surveys/${rowData.id}`)
        },
    ];

    useEffect(() => {
        if (playerId !== surveyPlayerId) {
            dispatch(SURVEYS.PLAYER.FETCH.trigger(playerId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 9 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ surveys }
                            options={ defaultOptions }
                            actions={ actions }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default PlayersSurveys;
