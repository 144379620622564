import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    backButton: {
        marginRight: theme.spacing(1),
        color: '#fff',
    },

    title: {
        flexGrow: 1,
        color: '#ffffff'
    },
}));

export default styles;