import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles(theme => ({
    input: {
        backgroundColor: theme.palette.common.white,
    },

    forgotPassword: {
      textAlign: 'right',
    },

    button: {
        color: '#fff',
        flexGrow: 1,
    },
}));

export default styles;
