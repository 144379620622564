import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/TextInput';

const PlayerStatsForm = props => {
    const {
        initialValues,
        onSubmit,
    } = props;

    const invalidNumberMessage = 'Getal moet tussen de 0 en 99 liggen';
    const requiredFieldMessage = 'Verplicht veld';

    const validationSchemaObject = yup.object().shape({
        stat_1: yup
            .number(invalidNumberMessage)
            .min(0, invalidNumberMessage)
            .max(99, invalidNumberMessage)
            .required(requiredFieldMessage),
        stat_2: yup
            .number(invalidNumberMessage)
            .min(0, invalidNumberMessage)
            .max(99, invalidNumberMessage)
            .required(requiredFieldMessage),
        stat_3: yup
            .number(invalidNumberMessage)
            .min(0, invalidNumberMessage)
            .max(99, invalidNumberMessage)
            .required(requiredFieldMessage),
        stat_4: yup
            .number(invalidNumberMessage)
            .min(0, invalidNumberMessage)
            .max(99, invalidNumberMessage)
            .required(requiredFieldMessage),
        stat_5: yup
            .number(invalidNumberMessage)
            .min(0, invalidNumberMessage)
            .max(99, invalidNumberMessage)
            .required(requiredFieldMessage),
        stat_6: yup
            .number(invalidNumberMessage)
            .min(0, invalidNumberMessage)
            .max(99, invalidNumberMessage)
            .required(requiredFieldMessage),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
              }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'stat_1' }
                            label="Spelen van de bal (SVDB)"
                            component={ TextInput }
                            errorMessage={ errors['stat_1'] }
                            touched={ touched['stat_1'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['stat_1'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'stat_3' }
                            label="Lopen met de bal (LMDB)"
                            component={ TextInput }
                            errorMessage={ errors['stat_3'] }
                            touched={ touched['stat_3'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['stat_3'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'stat_5' }
                            label="Aannemen van de bal (AVDB)"
                            component={ TextInput }
                            errorMessage={ errors['stat_5'] }
                            touched={ touched['stat_5'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['stat_5'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'stat_2' }
                            label="Spelinzicht (SPEL)"
                            component={ TextInput }
                            errorMessage={ errors['stat_2'] }
                            touched={ touched['stat_2'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['stat_2'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'stat_4' }
                            label="Inzet (INZE)"
                            component={ TextInput }
                            errorMessage={ errors['stat_4'] }
                            touched={ touched['stat_4'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['stat_4'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'stat_6' }
                            label="Plezier (PLEZ)"
                            component={ TextInput }
                            errorMessage={ errors['stat_6'] }
                            touched={ touched['stat_6'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['stat_6'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            disabled={ isSubmitting }
                            type="submit"
                            variant="contained"
                        >
                            Pas stats aan
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

PlayerStatsForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
};

PlayerStatsForm.defaultProps = {
    initialValues: {
        stat_1: 0,
        stat_2: 0,
        stat_3: 0,
        stat_4: 0,
        stat_5: 0,
        stat_6: 0,
    },
}

export default PlayerStatsForm;
