import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { sortCaseInsensitive } from 'utilities/sort';
import { EXERCISE_TAGS, PROFILE } from 'store/constants';
import {
    defaultOptions,
    getMaterialTableTranslation
} from 'utilities/materialTable';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';
import Rating from '@material-ui/lab/Rating';

const ExerciseTagsShow = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const exerciseTagId = parseInt(params.exerciseTagId);
    const active = useSelector(state => state.exerciseTag.active);
    const activeLoaded = active ? active.id === exerciseTagId : false;
    const exercises = active ? active.exercises.data : [];
    const loading = useSelector(state => state.exerciseTag.loading);
    const locale = useSelector(state => state.persist.language);
    const premiumFetched = useSelector(state => state.profile.fetched);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const isPremium = useSelector(state => state.profile.is_premium);
    const permissions = useSelector(state => state.profile.permissions);
    const hasCorrectPermission = permissions.includes('workout');
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);
    const options = {
        ...defaultOptions,
        pageSize: 100,
    }

    const pageTitle = useIntl().formatMessage({ id: 'exerciseTags.title' });
    const error = useSelector(state => state.exerciseTag.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'exercises.label.title' }),
            field: 'title',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'title'),
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.description' }),
            field: 'description',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'description'),
        },
        {
            title: useIntl().formatMessage({ id: 'exercises.label.difficulty' }),
            field: 'difficulty',
            render: rowData => <Rating name="disabled" value={ parseInt(rowData.difficulty) } disabled />
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`/exercises/${rowData.id}`)
        },
    ];

    useEffect(() => {
        dispatch(EXERCISE_TAGS.SHOW.trigger(exerciseTagId));
        dispatch(PROFILE.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    return (
        <ContentContainer title={ pageTitle } backButton>
            <PremiumWall
                isProfileFetched={ premiumFetched }
                isPremium={ isPremium || hasCorrectPermission || isAdmin }
                premiumImage={ images.premium.exerciseTags.default }
            >
                { loading || ! activeLoaded ? <LoadingIndicator /> : (
                    <Grid container spacing={3}>
                        <Grid item xs={ 9 }>
                            <MaterialTable
                                title={ pageTitle }
                                localization={ localization }
                                columns={ columns }
                                data={ exercises }
                                options={ options }
                                actions={ actions }
                            />
                        </Grid>
                    </Grid>
                ) }
            </PremiumWall>
        </ContentContainer>
    );
};

export default ExerciseTagsShow;
