import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClassificationsTable from 'contents/tables/ClassificationsTable';
import { CLASSIFICATIONS } from 'store/constants';

const ClassificationOverview = () => {
    const dispatch = useDispatch();
    const classifications = useSelector(state => state.classification.classifications);
    const fetched = useSelector(state => state.classification.fetched);
    const error = useSelector(state => state.classification.error);
    const deleted = useSelector(state => state.classification.deleted);

    const onUpdate = data => {
        return new Promise((resolve, reject) => {
            const payload = {
                classificationId: data.id,
                values: {
                    closing_date: data.closing_date,
                }
            }
            dispatch(CLASSIFICATIONS.UPDATE.trigger(payload));

            resolve();
        });
    }

    const onDelete = data => {
        return new Promise((resolve, reject) => {
            dispatch(CLASSIFICATIONS.DELETE.trigger(data.id));

            resolve();
        });
    };

    useEffect(() => {
        dispatch(CLASSIFICATIONS.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (deleted) {
            toast.success('Teambeoordeling is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Teambeoordelingen">
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <ClassificationsTable
                            data={ classifications }
                            onDelete={ onDelete }
                            onUpdate={ onUpdate }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClassificationOverview;
