export const updateObjectInArray = (array, action) => {
    return array.map((item, index) => {
        if (index !== action.index) {
            return item
        }

        return {
            ...item,
            ...action.item
        }
    })
};

export const findIdAndUpdateObjectInArray = (array, id, newObject) => {
    const index = array.findIndex(object => {
        return object.id === id;
    });

    const action = {
        index: index,
        item: newObject,
    };

    return updateObjectInArray(array, action);
};

export const deleteObjectWithIdFromArray = (array, id) => {
    const index = array.findIndex(object => {
        return object.id === id;
    });

    return deleteObjectInArray(array, index);
};

export const deleteObjectInArray = (array, removeIndex) => {
    return array.filter(function(value, arrayIndex, arr) { return arrayIndex !== removeIndex })
};