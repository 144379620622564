import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import TrainerForm from 'components/TrainerForm';
import styles from './TrainersEdit.styles';
import { INTERNAL_TRAINERS } from 'store/constants';

const TrainersEdit = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const params = useParams();
    const isFetched = useSelector(state => state.internalTrainer.fetched);
    const isLoading = useSelector(state => state.internalTrainer.loading);

    const trainers = useSelector(state => state.internalTrainer.internalTrainers);
    const trainer = trainers.filter(object => object.id === parseInt(params.trainerId))[0];
    const rate = trainer ? trainer.rate : 0;
    const initialsValues = {
        rate
    };

    const error = useSelector(state => state.internalTrainer.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.internalTrainer.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'trainers.update.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'trainers.update.title' });
    const rateLabel = useIntl().formatMessage({ id: 'trainers.label.rate' });
    const submitButtonText = useIntl().formatMessage({ id: 'trainers.button.update' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidNumberMessage = useIntl().formatMessage({ id: 'validation.error.number' });

    const onSubmit = values => {
        dispatch(INTERNAL_TRAINERS.UPDATE.trigger({
            id: params.trainerId,
            values
        }));

        return true
    };

    useEffect(() => {
        if (isFetched === false) {
            dispatch(INTERNAL_TRAINERS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: INTERNAL_TRAINERS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    return (
        <ContentContainer title={ pageTitle } backButton>
            { isFetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <TrainerForm
                                rateLabel={ rateLabel }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidNumberMessage={ invalidNumberMessage }
                                onSubmit={ onSubmit }
                                initialValues={ initialsValues }
                                editable
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ isLoading } message={ isFetched ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default TrainersEdit;
