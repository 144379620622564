import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core";
import * as Sentry from '@sentry/browser';

import * as serviceWorker from './serviceWorker';
import { store, persistStorage } from './store';
import App from './App';
import LanguageWrapper from 'components/LanguageWrapper';
import './scss/default.scss';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F07D00',
            contrastText: '#FFFFFF'
        },
        background: {
            default: '#FAFAFA'
        }
    },
    typography: {
        allVariants: {
            color: '#3C3C3C',
        },
        fontFamily: [
            'Inter',
            'Arial',
            'sans-serif',
        ],
        body1: {
            fontSize: 13,
        },
    }
});

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_LARAVEL_DSN});
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistStorage}>
            <LanguageWrapper>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <App />
                </ThemeProvider>
            </LanguageWrapper>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
