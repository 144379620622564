import { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { EVENT, PLAYER, PLAYERCARD } from 'store/constants';
import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import PlayercardBlock from 'contents/blocks/PlayercardBlock';
import PlayerDetailsBlock from 'contents/blocks/PlayerDetailsBlock';
import PlayerEventsBlock from 'contents/blocks/PlayerEventsBlock';
import PlayerSettingsBlock from 'contents/blocks/PlayerSettingsBlock';
import TeamList from 'contents/lists/TeamList';
import UserList from 'contents/lists/UserList';

const PlayerShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const playerId = parseInt(params.playerId);
    const player = useSelector(state => state.player.active);
    const error = useSelector(state => state.player.error);
    const playercard = useSelector(state => state.playercard.active);
    const events = useSelector(state => state.event.events);
    const playerFetched = player?.id === playerId;
    const playercardFetched = playercard?.id === playerId;
    const eventsFetched = useSelector(state => state.event.fetched);

    const updatePlayer = values => {
        dispatch(PLAYER.UPDATE.trigger({
            playerId,
            values,
        }));
    };

    const updatePlayerSettings = values => {
        dispatch(PLAYER.SETTINGS.UPDATE.trigger({
            playerId,
            values,
        }));
    };

    const updatePlayercard = values => {
        dispatch(PLAYER.STATS.UPDATE.trigger({
            playerId,
            values
        }));
    }

    const addPlayerToEvent = values => {
        dispatch(PLAYER.EVENT.UPDATE.trigger({
            eventId: values.selected_id,
            playerId,
        }))
    }

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (! playerFetched) {
            dispatch(PLAYER.SHOW.trigger(playerId));
        }

        if (! playercardFetched) {
            dispatch(PLAYERCARD.SHOW.trigger(playerId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerId]);

    useEffect(() => {
        if (! playercardFetched) {
            dispatch(PLAYERCARD.SHOW.trigger(playerId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playercardFetched]);

    useEffect(() => {
        if (! eventsFetched) {
            dispatch(EVENT.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsFetched]);

    return (
        <ContentContainer title={ playerFetched ? player?.full_name : 'Speler bekijken' } backButton={ true }>
            { playerFetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <PlayerDetailsBlock
                            onSubmit={ updatePlayer }
                            player={ player }
                        />

                        <Box mt={ 2 }>
                            <PlayerSettingsBlock
                                onSubmit={ updatePlayerSettings }
                                player={ player }
                            />
                        </Box>

                        <Box mt={ 2 }>
                            <ContentBox>
                                <BoxHeading title="Gedeelde toegang" />

                                <UserList users={ player?.users?.data || [] } />
                            </ContentBox>
                        </Box>

                        <Box mt={ 2 }>
                            <ContentBox>
                                <BoxHeading title="Teams" />

                                <TeamList teams={ player?.teams?.data || [] } />
                            </ContentBox>
                        </Box>

                        <Box mt={ 2 }>
                            <PlayerEventsBlock
                                events={ events }
                                onSubmit={ addPlayerToEvent }
                                player={ player }
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={ 6 }>
                        { playercardFetched ? (
                            <PlayercardBlock
                                onSubmit={ updatePlayercard }
                                player={ player }
                                playercard={ playercard }
                            />
                        ) : (
                            <ContentBox>
                                <BoxHeading
                                    title="Playercard"
                                />

                                <LoadingIndicator />
                            </ContentBox>
                        )}

                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }


        </ContentContainer>
    );
};

export default PlayerShow;