import PropTypes from 'prop-types';

import * as yup from 'yup';
import { Field, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TextInput from '../TextInput';
import SelectInput from '../SelectInput';
import CheckboxInput from 'components/CheckboxInput';

const TrainingForm = props => {
    const {
        nameLabel,
        divisionLabel,
        divisions,
        submitButtonText,
        requiredFieldMessage,
        initialValues,
        onSubmit
    } = props;

    const validationSchemaObject = yup.object().shape({
        name: yup.string().max(255).required(requiredFieldMessage),
        division_id: yup.mixed().required(requiredFieldMessage),
    });

    const divisionOptions = divisions.map(division => {
        return {
            value: division.id,
            label: division.name,
        }
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name={ 'name' }
                            label={ nameLabel }
                            component={ TextInput }
                            errorMessage={ errors['name'] }
                            touched={ touched['name'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'division_id' }
                            label={ divisionLabel }
                            component={ SelectInput }
                            errorMessage={ errors['division_id'] }
                            touched={ touched['division_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            options={ divisionOptions }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name="hidden"
                            label="Verberg training"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['hidden'] }
                            touched={ touched['hidden'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>


                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

TrainingForm.propTypes = {
    nameLabel: PropTypes.string,
    divisionLabel: PropTypes.string,
    divisions: PropTypes.array,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    editMode: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

TrainingForm.defaultProps = {
    nameLabel: 'name',
    divisionLabel: 'division',
    divisions: [],
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    initialValues: {
        name: '',
        division_id: null,
        hidden: false,
        video: undefined
    }
}

export default TrainingForm;
