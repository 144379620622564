import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { NEWS } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patchFile, postFile } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.news.index);
}

export function* createRequest({token, payload}) {
    return yield postFile(token, Api.requests.news.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patchFile(token, Api.requests.news.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.news.delete(id));
}

export function* fetchFlow() {
    while (true) {
        yield take(NEWS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const news = response.data.data;

            yield put(NEWS.FETCH.success(news));
        } catch (error) {
            yield put(NEWS.FETCH.failure());
        } finally {
            yield put(NEWS.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(NEWS.CREATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.title);
            formData.append('message', request.payload.message);
            formData.append('image', request.payload.image);

            const response = yield call(createRequest, { token, payload: formData });

            yield put(NEWS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(NEWS.CREATE.failure());
        } finally {
            yield put(NEWS.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(NEWS.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = new FormData();
            formData.append('title', request.payload.values.title);
            formData.append('message', request.payload.values.message);

            if (request.payload.values.image !== undefined) {
                formData.append('image', request.payload.values.image);
            }

            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(NEWS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(NEWS.UPDATE.failure());
        } finally {
            yield put(NEWS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(NEWS.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(NEWS.DELETE.success(request.payload));
        } catch (error) {
            yield put(NEWS.DELETE.failure());
        } finally {
            yield put(NEWS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
    ]);
}