import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const ClubPlayerAssessmentsTable = props => {
    const { data } = props;
    const history = useHistory();

    const columns = [
        {
            defaultSort: 'desc',
            field: 'date',
            render: rowData => reverseToReadableDate(rowData.date),
            title: 'Datum',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a.survey.data, b.survey.data, 'name'),
            field: 'survey.data.name',
            title: 'Vragenlijst',
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/assessments/${rowData.id}`)
        },
    ];

    return (
        <MaterialTable
            title="Clubs"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
        />
    );
};

ClubPlayerAssessmentsTable.propTypes = {
    data: PropTypes.array.isRequired,
};

export default ClubPlayerAssessmentsTable;