import PropTypes from 'prop-types';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import { Field, Formik } from 'formik';
import TextInput from '../TextInput';
import FileInput from '../FileInput';
import Button from '@material-ui/core/Button';

const NewsForm = props => {
    const {
        imageLabel,
        messageLabel,
        titleLabel,
        submitButtonText,
        requiredFieldMessage,
        invalidFileSizeMessage,
        invalidFileTypeMessage,
        initialValues,
        editMode,
        onSubmit
    } = props;
    const FILE_SIZE = 2 * 1024 * 1024;
    const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];

    const validationSchema = editMode ? {
        title: yup.string().max(255).required(requiredFieldMessage),
        message: yup.string().nullable(),
        image: yup.mixed().notRequired()
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return SUPPORTED_FORMATS.includes(value.type)
                }
            ),
    } : {
        title: yup.string().max(255).required(requiredFieldMessage),
        description: yup.string(),
        image: yup.mixed().required(requiredFieldMessage)
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => value && value.size <= FILE_SIZE
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => value && SUPPORTED_FORMATS.includes(value.type)
            ),
    }
    const validationSchemaObject = yup.object().shape(validationSchema);

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name={ 'title' }
                            label={ titleLabel }
                            component={ TextInput }
                            errorMessage={ errors['title'] }
                            touched={ touched['title'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'message' }
                            label={ messageLabel }
                            lines={ 10 }
                            component={ TextInput }
                            errorMessage={ errors['message'] }
                            touched={ touched['message'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name={ 'image' }
                            component={ FileInput }
                            title={ imageLabel }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors["image"] ? errors["image"] : undefined }
                            touched={ touched["image"] }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};


NewsForm.propTypes = {
    imageLabel: PropTypes.string,
    messageLabel: PropTypes.string,
    titleLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    invalidFileSizeMessage: PropTypes.string,
    invalidFileTypeMessage: PropTypes.string,
    editMode: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

NewsForm.defaultProps = {
    imageLabel: 'image',
    messageLabel: 'message',
    titleLabel: 'title',
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    invalidFileSizeMessage: 'invalid file size',
    invalidFileTypeMessage: 'invalid file type',
    initialValues: {
        image: undefined,
        message: '',
        title: '',
    }
}

export default NewsForm;
