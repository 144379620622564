export const English = {
    body: {
        emptyDataSourceMessage: 'No records to display',
        addTooltip: 'Add',
        deleteTooltip: 'Delete',
        editTooltip: 'Edit',
        filterRow: {
            filterTooltip: 'Filter',
        },
        editRow: {
            deleteText: 'Are you sure delete this row?',
            cancelTooltip: 'Cancel',
            saveTooltip: 'Save',
        },
    },
    grouping: {
        placeholder: 'Drag headers ...',
    },
    header: {
        actions: 'Actions',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} of {count}',
        labelRowsSelect: 'rows',
        labelRowsPerPage: 'Rows per page:',
        firstAriaLabel: 'First Page',
        firstTooltip: 'First Page',
        previousAriaLabel: 'Previous Page',
        previousTooltip: 'Previous Page',
        nextAriaLabel: 'Next Page',
        nextTooltip: 'Next Page',
        lastAriaLabel: 'Last Page',
        lastTooltip: 'Last Page',
    },
    toolbar: {
        addRemoveColumns: 'Add or remove columns',
        nRowsSelected: '{0} row(s) selected',
        showColumnsTitle: 'Show Columns',
        showColumnsAriaLabel: 'Show Columns',
        exportTitle: 'Export',
        exportAriaLabel: 'Export',
        exportName: 'Export as CSV',
        searchTooltip: 'Search',
        searchPlaceholder: 'Search',
    },
};