import { createStore, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import rootReducer from './reducers';
import rootSaga from './sagas';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['persist'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    persistedReducer,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware),
    ),
);

const persistStorage = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistStorage };