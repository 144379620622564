import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { INTERNAL_TRAINERS } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patch, post } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.internalTrainers.index);
}

export function* createRequest({token, payload}) {
    return yield post(token, Api.requests.internalTrainers.store, payload);
}

export function* editRequest({token, id, payload, headers = {}}) {
    return yield patch(token, Api.requests.internalTrainers.update(id), payload, headers);
}

export function* deleteRequest(token, id) {
    return yield deletePost(token, Api.requests.internalTrainers.delete(id));
}

export function* fetchFlow() {
    while (true) {
        yield take(INTERNAL_TRAINERS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const internalTrainers = response.data.data;

            yield put(INTERNAL_TRAINERS.FETCH.success(internalTrainers));
        } catch (error) {
            yield put(INTERNAL_TRAINERS.FETCH.failure());
        } finally {
            yield put(INTERNAL_TRAINERS.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(INTERNAL_TRAINERS.CREATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = {
                rate: request.payload.rate,
                user_id: request.payload.user_id,
            };
            const response = yield call(createRequest, { token, payload: formData });

            yield put(INTERNAL_TRAINERS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(INTERNAL_TRAINERS.CREATE.failure());
        } finally {
            yield put(INTERNAL_TRAINERS.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(INTERNAL_TRAINERS.UPDATE.TRIGGER);

        try {
            const token = yield select(getToken);
            const formData = {
                rate: request.payload.values.rate,
            }
            const response = yield call(editRequest, {
                token,
                id: request.payload.id,
                payload: formData,
            });

            yield put(INTERNAL_TRAINERS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(INTERNAL_TRAINERS.UPDATE.failure());
        } finally {
            yield put(INTERNAL_TRAINERS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(INTERNAL_TRAINERS.DELETE.TRIGGER);

        try {
            const token = yield select(getToken);

            yield call(deleteRequest, token, request.payload);
            yield put(INTERNAL_TRAINERS.DELETE.success(request.payload));
        } catch (error) {
            yield put(INTERNAL_TRAINERS.DELETE.failure());
        } finally {
            yield put(INTERNAL_TRAINERS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
    ]);
}