import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import SurveyAnswers from './SurveyAnswers';
import SurveyOverview from './SurveyOverview';
import SurveyQuestions from './SurveyQuestions';
import SurveyTopics from './SurveyTopics';

const Surveys = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ path }
                component={ SurveyOverview }
            />

            <Route
                exact
                path={ `${path}/:surveyId/topics` }
                component={ SurveyTopics }
            />

            <Route
                exact
                path={ `${path}/:surveyId/topics/:topicId/questions` }
                component={ SurveyQuestions }
            />

            <Route
                exact
                path={ `${path}/:surveyId/topics/:topicId/questions/:questionId/answers` }
                component={ SurveyAnswers }
            />
        </Switch>
    );
};

export default Surveys;