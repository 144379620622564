import { all, call, put, take } from 'redux-saga/effects';

import { AUTH } from '../constants';
import { Api } from 'config/api';
import { accountUnverified, loginFailed, loginSuccessful } from '../actions/AuthActions';
import { guestPost } from 'utilities/api';

export function* authorize(payload) {
    return yield guestPost(Api.requests.login, {
        grant_type: Api.grantType,
        client_id: Api.clientId,
        client_secret: Api.clientSecret,
        username: payload.username,
        password: payload.password,
    });
}

export function* resetPasswordRequest(payload) {
    return yield guestPost(Api.requests.resetPassword, payload);
}

export function* loginFlow() {
    while (true) {
        const request = yield take(AUTH.LOGIN);

        try {
            const response = yield call(authorize, request.payload);
            const payload = {
                accessToken: response.data.access_token,
                isAdmin: response.data.is_admin,
                userId: response.data.user_id,
            }

            yield put(loginSuccessful(payload));
        } catch (error) {
            const statusCode = error?.response?.status || 500;

            if (statusCode === 403) {
                yield put(accountUnverified());
            } else {
                yield put(loginFailed());
            }
        }
    }
}

export function* resetPasswordFlow() {
    while (true) {
        const request = yield take(AUTH.PASSWORD.RESET);

        try {
            const payload = request.payload;

            yield(call(resetPasswordRequest, payload));
            yield put({ type: AUTH.PASSWORD.RESET_SUCCESS });
        } catch (error) {
            yield put({ type: AUTH.PASSWORD.RESET_FAIL });
        }
    }
}

export default function* rootSaga() {
    yield all([
        loginFlow(),
        resetPasswordFlow(),
    ]);
}