import PropTypes from 'prop-types';

import Header from '../Header';

import styles from './ContentContainer.styles';

const ContentContainer = props => {
    const { backButton, children, title } = props;
    const classes = styles();

    return (
        <div className={ classes.root }>
            <Header title={ title } backButton={ backButton } />

            <div className={ classes.content }>
                { children }
            </div>
        </div>
    );
};

ContentContainer.propTypes = {
    title: PropTypes.string,
    backButton: PropTypes.bool,
};

export default ContentContainer;
