import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { SURVEYS } from '../constants';
import {
    fetchRequest,
    storeRequest,
    updateRequest,
    deleteRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        yield take(SURVEYS.FETCH.TRIGGER);

        try {
            const endpoint = Api.requests.surveys.index;
            const response = yield call(fetchRequest, endpoint);
            const surveys = response.data.data;

            yield put(SURVEYS.FETCH.success(surveys));
        } catch (error) {
            yield put(SURVEYS.FETCH.failure());
        } finally {
            yield put(SURVEYS.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(SURVEYS.SHOW.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.surveys.show(id);
            const response = yield call(fetchRequest, endpoint);

            yield put(SURVEYS.SHOW.success(response.data.data));
        } catch (error) {
            yield put(SURVEYS.SHOW.failure());
        } finally {
            yield put(SURVEYS.SHOW.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(SURVEYS.CREATE.TRIGGER);

        try {
            const endpoint = Api.requests.surveys.store;
            const payload = request.payload;
            const response = yield call(storeRequest, endpoint, payload);

            yield put(SURVEYS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(SURVEYS.CREATE.failure());
        } finally {
            yield put(SURVEYS.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(SURVEYS.UPDATE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const endpoint = Api.requests.surveys.update(surveyId);
            const payload = request.payload.values;
            const response = yield call(updateRequest, endpoint, payload);

            yield put(SURVEYS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(SURVEYS.UPDATE.failure());
        } finally {
            yield put(SURVEYS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(SURVEYS.DELETE.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.surveys.delete(id);

            yield call(deleteRequest, endpoint);
            yield put(SURVEYS.DELETE.success(request.payload));
        } catch (error) {
            yield put(SURVEYS.DELETE.failure());
        } finally {
            yield put(SURVEYS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
        createFlow(),
        updateFlow(),
        deleteFlow(),
    ]);
}