import { AUTH, NEWS } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    news: [],
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const newsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEWS.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case NEWS.FETCH.SUCCESS:
            return {
                ...state,
                news: action.payload,
                fetched: true,
            };

        case NEWS.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case NEWS.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case NEWS.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                created: false,
            }

        case NEWS.CREATE.SUCCESS:
            return {
                ...state,
                news: state.news.concat(action.payload),
                created: action.payload.id,
            };

        case NEWS.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case NEWS.CREATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case NEWS.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
            }

        case NEWS.UPDATE.SUCCESS:
            return {
                ...state,
                news: findIdAndUpdateObjectInArray([...state.news], action.payload.id, action.payload),
                updated: true,
            };

        case NEWS.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case NEWS.UPDATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case NEWS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                deleted: false,
            }

        case NEWS.DELETE.SUCCESS:
            return {
                ...state,
                news: deleteObjectWithIdFromArray([...state.news], action.payload),
                deleted: true,
            };

        case NEWS.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case NEWS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case NEWS.CLEAN:
            return {
                ...state,
                error: null,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default newsReducer;