export const images = {
    background: {
        login: require('assets/images/background-login.jpg'),
    },
    clublogo: {
        victoria: require('assets/images/clublogo-victoria.png'),
        craeyenhout: require('assets/images/clublogo-craeyenhout.png'),
        feyenoord: require('assets/images/clublogo-feyenoord.png'),
        hcw: require('assets/images/clublogo-hcw.png'),
        hudito: require('assets/images/clublogo-hudito.png'),
        kieviten: require('assets/images/clublogo-kieviten.png'),
        nhc: require('assets/images/clublogo-nhc.png'),
        rotterdam: require('assets/images/clublogo-rotterdam.png'),
    },
    homepage: {
        stores: {
            appStore: require('assets/images/homepage-button-app-store.png'),
            googlePlay: require('assets/images/homepage-button-google-play.png'),
        },
        club: require('assets/images/homepage-support.jpg'),
        coach: {
            top: require('assets/images/homepage-coach-top.jpg'),
            bottom: require('assets/images/homepage-coach-bottom.jpg'),
        },
        player: require('assets/images/homepage-player.jpg'),
        playersApp: require('assets/images/homepage-players-app.png'),
        trainer: require('assets/images/homepage-trainer.png'),
        solutions: {
            platform: require('assets/images/homepage-online-platform.jpg'),
            playerApp: require('assets/images/homepage-player-app.jpg'),
            trainerApp: require('assets/images/homepage-team-app.jpg'),
        },
    },
    logo: {
        white: {
            png191x50: require('assets/images/logo-white-191-50.png'),
        },
        landscape208: require('assets/images/logo-landscape-208.jpg'),
        dark: require('assets/images/logo-dark-bg.png'),
    },
    premium: {
        drawingtool: require('assets/images/premium-drawingtool.jpg'),
        exercise: require('assets/images/premium-exercise.jpg'),
        exerciseTags: require('assets/images/premium-exercise-tags.jpg'),
        lineup: require('assets/images/premium-lineup.jpg'),
        tactic: require('assets/images/premium-tactic.jpg'),
        training: require('assets/images/premium-training.jpg'),
    },
    splash: {
        hero: {
            left: require('assets/images/splash-hero-left.svg'),
            right: require('assets/images/splash-hero-right.svg'),
        },
        login: {
            left: require('assets/images/splash-login-left.svg'),
            right: require('assets/images/splash-login-right.svg'),
        },
        player: {
            left: require('assets/images/splash-player-left.svg'),
            right: require('assets/images/splash-player-right.svg'),
        },
        playersApp: {
            right: require('assets/images/splash-playersapp-right.svg'),
        },
        register: {
            left: require('assets/images/splash-register-left.svg'),
            right: require('assets/images/splash-register-right.svg'),
        },
        testimonial: {
            left: require('assets/images/splash-testimonial-left.svg'),
        },
        trainer: {
            left: require('assets/images/splash-trainer-left.svg'),
            right: require('assets/images/splash-trainer-right.svg'),
        },
    },
    tool: {
        field: {
            indoor: {
                half: require('assets/images/tool-field-indoor-half.svg'),
                full: require('assets/images/tool-field-indoor-full.svg'),
            },
            outdoor: {
                half: require('assets/images/tool-field-outdoor-half.svg'),
                full: require('assets/images/tool-field-outdoor-full.svg'),
                quarter: require('assets/images/tool-field-outdoor-quarter.svg'),
            },
        },
        icon: {
            ball: require('assets/images/tool-icon-ball.svg'),
            ballGun: require('assets/images/tool-icon-ball-gun.svg'),
            balls: require('assets/images/tool-icon-balls.svg'),
            bounce: require('assets/images/tool-icon-bounce.svg'),
            circleBlue: require('assets/images/tool-icon-circle-blue.svg'),
            coneBlue: require('assets/images/tool-icon-cone-blue.svg'),
            coneGrey: require('assets/images/tool-icon-cone-grey.svg'),
            coneLowBlue: require('assets/images/tool-icon-cone-low-blue.svg'),
            coneLowGrey: require('assets/images/tool-icon-cone-low-grey.svg'),
            coneLowOrange: require('assets/images/tool-icon-cone-low-orange.svg'),
            coneLowRed: require('assets/images/tool-icon-cone-low-red.svg'),
            coneOrange: require('assets/images/tool-icon-cone-orange.svg'),
            coneRed: require('assets/images/tool-icon-cone-red.svg'),
            crossRed: require('assets/images/tool-icon-cross-red.svg'),
            goalBottom: require('assets/images/tool-icon-goal-bottom.svg'),
            goalLarge: require('assets/images/tool-icon-goal-large.svg'),
            goalSmall: require('assets/images/tool-icon-goal-small.svg'),
            goalUpper: require('assets/images/tool-icon-goal-upper.svg'),
            hoop: require('assets/images/tool-icon-hoop.svg'),
            hordeCones: require('assets/images/tool-icon-horde-cones.svg'),
            hordeHigh: require('assets/images/tool-icon-horde-high.svg'),
            hordeLow: require('assets/images/tool-icon-horde-low.svg'),
            keeper: require('assets/images/tool-icon-keeper.svg'),
            ladder: require('assets/images/tool-icon-ladder.svg'),
            shirtBlack: require('assets/images/tool-icon-shirt-black.svg'),
            shirtBlack01: require('assets/images/tool-icon-shirt-black-01.svg'),
            shirtBlack02: require('assets/images/tool-icon-shirt-black-02.svg'),
            shirtBlack03: require('assets/images/tool-icon-shirt-black-03.svg'),
            shirtBlack04: require('assets/images/tool-icon-shirt-black-04.svg'),
            shirtBlack05: require('assets/images/tool-icon-shirt-black-05.svg'),
            shirtBlack06: require('assets/images/tool-icon-shirt-black-06.svg'),
            shirtBlack07: require('assets/images/tool-icon-shirt-black-07.svg'),
            shirtBlack08: require('assets/images/tool-icon-shirt-black-08.svg'),
            shirtBlack09: require('assets/images/tool-icon-shirt-black-09.svg'),
            shirtBlack10: require('assets/images/tool-icon-shirt-black-10.svg'),
            shirtBlack11: require('assets/images/tool-icon-shirt-black-11.svg'),
            shirtBlack12: require('assets/images/tool-icon-shirt-black-12.svg'),
            shirtBlack13: require('assets/images/tool-icon-shirt-black-13.svg'),
            shirtBlack14: require('assets/images/tool-icon-shirt-black-14.svg'),
            shirtBlack15: require('assets/images/tool-icon-shirt-black-15.svg'),
            shirtBlack16: require('assets/images/tool-icon-shirt-black-16.svg'),
            shirtBlue: require('assets/images/tool-icon-shirt-blue.svg'),
            shirtBlue01: require('assets/images/tool-icon-shirt-blue-01.svg'),
            shirtBlue02: require('assets/images/tool-icon-shirt-blue-02.svg'),
            shirtBlue03: require('assets/images/tool-icon-shirt-blue-03.svg'),
            shirtBlue04: require('assets/images/tool-icon-shirt-blue-04.svg'),
            shirtBlue05: require('assets/images/tool-icon-shirt-blue-05.svg'),
            shirtBlue06: require('assets/images/tool-icon-shirt-blue-06.svg'),
            shirtBlue07: require('assets/images/tool-icon-shirt-blue-07.svg'),
            shirtBlue08: require('assets/images/tool-icon-shirt-blue-08.svg'),
            shirtBlue09: require('assets/images/tool-icon-shirt-blue-09.svg'),
            shirtBlue10: require('assets/images/tool-icon-shirt-blue-10.svg'),
            shirtBlue11: require('assets/images/tool-icon-shirt-blue-11.svg'),
            shirtBlue12: require('assets/images/tool-icon-shirt-blue-12.svg'),
            shirtBlue13: require('assets/images/tool-icon-shirt-blue-13.svg'),
            shirtBlue14: require('assets/images/tool-icon-shirt-blue-14.svg'),
            shirtBlue15: require('assets/images/tool-icon-shirt-blue-15.svg'),
            shirtBlue16: require('assets/images/tool-icon-shirt-blue-16.svg'),
            shirtOrange: require('assets/images/tool-icon-shirt-orange.svg'),
            shirtOrange01: require('assets/images/tool-icon-shirt-orange-01.svg'),
            shirtOrange02: require('assets/images/tool-icon-shirt-orange-02.svg'),
            shirtOrange03: require('assets/images/tool-icon-shirt-orange-03.svg'),
            shirtOrange04: require('assets/images/tool-icon-shirt-orange-04.svg'),
            shirtOrange05: require('assets/images/tool-icon-shirt-orange-05.svg'),
            shirtOrange06: require('assets/images/tool-icon-shirt-orange-06.svg'),
            shirtOrange07: require('assets/images/tool-icon-shirt-orange-07.svg'),
            shirtOrange08: require('assets/images/tool-icon-shirt-orange-08.svg'),
            shirtOrange09: require('assets/images/tool-icon-shirt-orange-09.svg'),
            shirtOrange10: require('assets/images/tool-icon-shirt-orange-10.svg'),
            shirtOrange11: require('assets/images/tool-icon-shirt-orange-11.svg'),
            shirtOrange12: require('assets/images/tool-icon-shirt-orange-12.svg'),
            shirtOrange13: require('assets/images/tool-icon-shirt-orange-13.svg'),
            shirtOrange14: require('assets/images/tool-icon-shirt-orange-14.svg'),
            shirtOrange15: require('assets/images/tool-icon-shirt-orange-15.svg'),
            shirtOrange16: require('assets/images/tool-icon-shirt-orange-16.svg'),
            tire: require('assets/images/tool-icon-tire.svg'),
            trainer: require('assets/images/tool-icon-trainer.svg'),
            yellowFlaps: require('assets/images/tool-icon-yellow-flaps.svg'),
        }
    },
};
