import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import EvaluationsTable from 'contents/tables/EvaluationsTable';
import { EVALUATIONS } from 'store/constants';

const EvaluationOverview = () => {
    const dispatch = useDispatch();
    const evaluations = useSelector(state => state.evaluation.evaluations);
    const fetched = useSelector(state => state.evaluation.fetched);
    const error = useSelector(state => state.evaluation.error);
    const deleted = useSelector(state => state.evaluation.deleted);
    const cleared = useSelector(state => state.evaluation.cleared);
    const reminded = useSelector(state => state.evaluation.reminded);
    const synced = useSelector(state => state.evaluation.synced);

    const onDelete = data => {
        return new Promise((resolve, reject) => {
            dispatch(EVALUATIONS.DELETE.trigger(data.id));

            resolve();
        });
    };

    const onClear = evaluationIds => {
        toast.info(`Bezig met verwijderen van open uitnodigingen`);

        dispatch(EVALUATIONS.CLEAR.trigger(evaluationIds));
    }

    const onReminder = evaluationIds => {
        toast.info(`Bezig met versturen van herinneringen`);

        dispatch(EVALUATIONS.REMIND.trigger(evaluationIds));
    }

    const onSync = evaluationIds => {
        toast.info(`Bezig met synchroniseren van uitnodigingen`);

        dispatch(EVALUATIONS.SYNC.trigger(evaluationIds));
    }

    useEffect(() => {
        dispatch(EVALUATIONS.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (deleted) {
            toast.success('Evaluatie is verwijderd');
        }
    }, [deleted]);

    useEffect(() => {
        if (cleared) {
            toast.success(`Open uitnodigingen zijn verwijderd`);
        }
    }, [cleared, evaluations]);

    useEffect(() => {
        if (reminded) {
            toast.success(`Herinneringen zijn verstuurd`);
        }
    }, [reminded, evaluations]);

    useEffect(() => {
        if (synced) {
            toast.success(`Evaluaties zijn gesynchroniseerd`);
        }
    }, [synced, evaluations]);

    return (
        <ContentContainer title="Evaluaties">
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <EvaluationsTable
                            data={ evaluations }
                            onDelete={ onDelete }
                            onClear={ onClear }
                            onReminder={ onReminder }
                            onSync={ onSync }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default EvaluationOverview;
