import PropTypes from 'prop-types';

import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';

import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const EvaluationAssessmentsTable = props => {
    const { data } = props;
    const history = useHistory();

    const options = {
        ...defaultOptions,
        showTitle: true,
    }

    const columns = [
        {
            defaultSort: 'asc',
            field: 'date',
            render: rowData => reverseToReadableDate(rowData.date),
            title: 'Datum',
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'player_name'),
            field: 'player_name',
            title: 'Speler',
        },
        {
            field: 'average_grade',
            title: 'Gemiddeld cijfer',
            type: 'numeric',
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/assessments/${rowData.id}`),
        }
    ];

    return (
        <>
            <MaterialTable
                title="Ingevulde evaluaties"
                localization={ localization }
                columns={ columns }
                data={ data }
                options={ options }
                actions={ actions }
            />
        </>
    );
};

EvaluationAssessmentsTable.propTypes = {
    data: PropTypes.array.isRequired,
};

export default EvaluationAssessmentsTable;
