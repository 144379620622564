import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[300],
    },

    input: {
        borderRadius: 4,
        backgroundColor: '#fff',
    },

    button: {
        backgroundColor: '#000',
        color: '#fff',
    }
}));

export default styles;