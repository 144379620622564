import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useState } from 'react';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import PlayerSettingsDetails from 'contents/details/PlayerSettingsDetails';
import PlayerSettingsForm from 'contents/forms/PlayerSettingsForm';

const PlayerDetailsBlock = props => {
    const { onSubmit, player } = props;
    const [editMode, setEditMode] = useState(false);
    const initialValues ={
        allow_evaluation: !! player?.settings?.data?.allow_evaluation,
        allow_photo: !! player?.settings?.data?.allow_photo,
        allow_video: !! player?.settings?.data?.allow_video,
    };

    const updateSettings = values => {
        setEditMode(false);
        onSubmit(values);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Privacy instellingen"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                {
                    editMode ? (
                        <PlayerSettingsForm
                            initialValues={ initialValues }
                            onSubmit={ updateSettings }
                        />
                    ) : (
                        <PlayerSettingsDetails player={ player } />
                    )
                }
            </Box>
        </ContentBox>
    );
};

PlayerDetailsBlock.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
}

export default PlayerDetailsBlock;