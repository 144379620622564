import { AUTH, EVENT } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    events: [],
    active: null,

    error: false,
    loading: false,
    fetched: false,

    created: null,
    updated: false,
    deleted: false,
};

const eventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVENT.FETCH.TRIGGER:
        case EVENT.CREATE.TRIGGER:
        case EVENT.SHOW.TRIGGER:
        case EVENT.UPDATE.TRIGGER:
        case EVENT.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case EVENT.FETCH.SUCCESS:
            return {
                ...state,
                events: action.payload,
                fetched: true,
            };

        case EVENT.CREATE.SUCCESS:
            return {
                ...state,
                events: state.events.concat(action.payload),
                created: action.payload.id,
            };

        case EVENT.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case EVENT.UPDATE.SUCCESS:
            return {
                ...state,
                active: action.payload,
                events: findIdAndUpdateObjectInArray([...state.events], action.payload.id, action.payload),
                updated: true,
            };

        case EVENT.DELETE.SUCCESS:
            return {
                ...state,
                events: deleteObjectWithIdFromArray([...state.events], action.payload),
                deleted: true,
            };

        case EVENT.DATE.UPDATE.SUCCESS:
        case EVENT.TRAINER.UPDATE.SUCCESS:
        case EVENT.TRAINER.DELETE.SUCCESS:
        case EVENT.TRAINING.UPDATE.SUCCESS:
        case EVENT.TRAINING.DELETE.SUCCESS:
        case EVENT.PLAYER.DELETE.SUCCESS:
            return {
                ...state,
                updated: true,
            };
        case EVENT.DATE.DELETE.SUCCESS:
            return {
                ...state,
                updated: true,
            };

        case EVENT.FETCH.FAILURE:
        case EVENT.CREATE.FAILURE:
        case EVENT.SHOW.FAILURE:
        case EVENT.UPDATE.FAILURE:
        case EVENT.DELETE.FAILURE:
        case EVENT.DATE.UPDATE.FAILURE:
        case EVENT.DATE.DELETE.FAILURE:
        case EVENT.TRAINER.UPDATE.FAILURE:
        case EVENT.TRAINER.DELETE.FAILURE:
        case EVENT.TRAINING.UPDATE.FAILURE:
        case EVENT.TRAINING.DELETE.FAILURE:
        case EVENT.PLAYER.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case EVENT.FETCH.FULFILL:
        case EVENT.CREATE.FULFILL:
        case EVENT.SHOW.FULFILL:
        case EVENT.UPDATE.FULFILL:
        case EVENT.DATE.UPDATE.FULFILL:
        case EVENT.DATE.DELETE.FULFILL:
        case EVENT.TRAINER.UPDATE.FULFILL:
        case EVENT.TRAINER.DELETE.FULFILL:
        case EVENT.TRAINING.UPDATE.FULFILL:
        case EVENT.TRAINING.DELETE.FULFILL:
        case EVENT.PLAYER.DELETE.FULFILL:
        case EVENT.DELETE.FULFILL:
            return {
                ...state,
                error: false,
                loading: false,
                created: null,
                updated: false,
                deleted: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default eventReducer;