import {
    AUTH,
    LANGUAGE, VIEW_MODE,
} from '../constants';

const INITIAL_STATE = {
    accessToken: '',
    isAdmin: false,
    userId: null,
    language: 'nl',
    viewMode: 'default',
};

const persistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                isAdmin: action.payload.isAdmin,
                userId: action.payload.userId,
            };

        case AUTH.LOGOUT:
            return {
                ...state,
                accessToken: '',
                isAdmin: false,
                userId: null,
            };

        case LANGUAGE.SET:
            return {
                ...state,
                language: action.language,
            };

        case VIEW_MODE.TOGGLE:
            return {
                ...state,
                viewMode: action.payload,
            };

        default:
            return state;
    }
};

export default persistReducer;