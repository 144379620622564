import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const UserSearchResultTable = props => {
    const { data, query } = props;

    const history = useHistory();

    const columns = [
        {
            title: 'Voornaam',
            field: 'first_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'first_name'),
            defaultSort: 'asc',
        },
        {
            title: 'Achternaam',
            field: 'last_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'last_name'),
        },
        {
            title: 'E-mailadres',
            field: 'email',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'email'),
        },
        {
            title: 'Activatiedatum',
            render: rowData => rowData.email_verified_at ? reverseToReadableDate(rowData.email_verified_at) : '',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'email_verified_at'),
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`/users/${rowData.id}`)
        },
    ];

    const tableOptions = {
        ...defaultOptions,
        showTitle: true,
    };

    return (
        <MaterialTable
            title={ `Resultaat voor '${query}'` }
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ tableOptions }
            actions={ actions }
        />
    );
};

UserSearchResultTable.propTypes = {
    data: PropTypes.array.isRequired,
    query: PropTypes.string.isRequired,
};

export default UserSearchResultTable;