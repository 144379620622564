import {
    LANGUAGE,
} from '../constants';
import Dutch from 'languages/nl';

const INITIAL_STATE = {
    messages: Dutch,
};

const languageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANGUAGE.MESSAGES.SET:
            return {
                ...state,
                messages: action.messages,
            };

        default:
            return state;
    }
};

export default languageReducer;