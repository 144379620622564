import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { EVENT } from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import OrdersTable from 'contents/tables/OrdersTable';

const EventsOrders = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const eventId = parseInt(params.eventId);
    const event = useSelector(state => state.event.active);
    const error = useSelector(state => state.event.error);
    const fetched = event?.id === eventId;
    const orders = event?.orders?.data || [];
    const pageTitle = fetched ? `${event?.name} aanmeldingen` : 'Event aanmeldingen';

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        dispatch(EVENT.SHOW.trigger(eventId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer
            title={ pageTitle }
            backButton
        >
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <OrdersTable data={ orders } />
                    </Grid>
                </Grid>
            ) : <LoadingIndicator /> }
        </ContentContainer>
    );
};

export default EventsOrders;
