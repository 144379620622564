import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { IconButton } from '@material-ui/core';

import styles from './ManualButton.styles';

const ManualButton = () => {
    const classes = styles();

    return (
        <IconButton
            onClick={ () => { window.open('/pdf/instruction-manual.pdf', '_blank') } }
            className={ classes.icon }
        >
            <LiveHelpIcon />
        </IconButton>
    );
};

export default ManualButton;
