import PropTypes from 'prop-types';

import { Line } from "react-konva";

const Draw = props => {
    const { coords, draggable, index, onDoubleClick, onUpdate } = props;

    const handleDoubleClick = () => onDoubleClick(index);

    return (
        <Line
            draggable={ draggable }
            fill="black"
            onDblClick={ handleDoubleClick }
            points={ [...coords] }
            stroke="black"
            strokeWidth={ 3 }
            onDragEnd={e => {
                const newCoords = e.target.points().map((coord, key) => {
                    const difference = key % 2 === 0 ? e.target.x() : e.target.y();

                    return coord + difference;
                });

                onUpdate(index, {
                    coords: newCoords,
                });
            } }
        />
    )
};

Draw.propTypes = {
    coords: PropTypes.array.isRequired,
    draggable: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default Draw;