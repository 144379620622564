import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CLUB } from 'store/constants';
import { removeEmptyKeys } from 'utilities/object';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ClubUsersTable from 'contents/tables/ClubUsersTable';

const ClubUsers = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const clubId = parseInt(params.clubId);
    const users = useSelector(state => state.clubUser.users);
    const reducerClubId = useSelector(state => state.clubUser.clubId);
    const error = useSelector(state => state.clubUser.error);
    const created = useSelector(state => state.clubUser.created);
    const updated = useSelector(state => state.clubUser.updated);
    const deleted = useSelector(state => state.clubUser.deleted);
    const fetched = clubId === reducerClubId;

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = removeEmptyKeys(data);

            dispatch(CLUB.USER.CREATE.trigger({
                clubId,
                values,
            }))

            resolve();
        })
    }

    const onUpdate = (newData, oldData) => {
        return new Promise((resolve) => {
            let values = {};

            if (oldData.director !== newData.director) {
                values = {
                    ...values,
                    director: newData.director,
                }
            }

            if (oldData.clubmanager !== newData.clubmanager) {
                values = {
                    ...values,
                    clubmanager: newData.clubmanager,
                }
            }

            dispatch(CLUB.USER.UPDATE.trigger({
                clubId,
                userId: newData.id,
                values,
            }))

            resolve();
        })
    }

    const onDelete = data => {
        return new Promise((resolve) => {
            dispatch(CLUB.USER.DELETE.trigger({
                clubId,
                userId: data.id,
            }));

            resolve();
        })
    };

    useEffect(() => {
        dispatch(CLUB.USER.FETCH.trigger(clubId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Gebruiker is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (updated) {
            toast.success('Gebruiker is aangepast');
        }
    }, [updated]);

    useEffect(() => {
        if (deleted) {
            toast.success('Gebruiker is verwijderd');
        }
    }, [deleted]);

    return (
        <ContentContainer title="Club: gebruikers" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <ClubUsersTable
                            data={ users }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                            onUpdate={ onUpdate }
                        />
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClubUsers;
