import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import PeriodForm from 'components/PeriodForm';
import styles from './PeriodsEdit.styles';
import { PERIODS, TRAININGS } from 'store/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const PeriodsEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetchedPeriods = useSelector(state => state.period.fetched);
    const fetchedTrainings = useSelector(state => state.training.fetched);
    const loadingPeriod = useSelector(state => state.period.loading);
    const loadingTraining = useSelector(state => state.training.loading);
    const classes = styles();

    const error = useSelector(state => state.period.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.period.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'periods.update.loading' });

    const periods = useSelector(state => state.period.periods);
    const period = periods.filter(object => object.id === parseInt(params.periodId))[0];
    const trainings = useSelector(state => state.training.trainings);

    const pageTitle = useIntl().formatMessage({ id: 'periods.update.title' });
    const startDateLabel = useIntl().formatMessage({ id: 'periods.label.startDate' });
    const endDateLabel = useIntl().formatMessage({ id: 'periods.label.endDate' });
    const internalLabel = useIntl().formatMessage({ id: 'periods.label.internal' });
    const trainingsLabel = useIntl().formatMessage({ id: 'exercises.label.trainings' });
    const submitButtonText = useIntl().formatMessage({ id: 'periods.button.update' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const getInitialValues = () => {
        if (period) {
            return {
                start_date: period.start_date,
                end_date: period.end_date,
                internal: period.internal,
            }
        }

        return {};
    }

    const getSelectedTrainings = () => {
        return period ? period.trainings.data : [];
    }

    const handleAddTraining = training => {
        dispatch(PERIODS.TRAINING.UPDATE.trigger({ period, training }));
    }

    const handleDeleteTraining = training => {
        dispatch(PERIODS.TRAINING.DELETE.trigger({ period, training }));
    }

    const onTrainingChange = (event, newTraining, action, training) => {
        if (action === 'remove-option') {
            handleDeleteTraining(training.option);
        } else if (action === 'select-option') {
            handleAddTraining(training.option);
        }
    }

    const onSubmit = values => {
        dispatch(PERIODS.UPDATE.trigger({
            id: params.periodId,
            values
        }));

        return true
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    useEffect(() => {
        if (fetchedPeriods === false) {
            dispatch(PERIODS.FETCH.trigger());
        }

        if (fetchedTrainings === false) {
            dispatch(TRAININGS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: PERIODS.CLEAN });
            dispatch({ type: TRAININGS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetchedPeriods && fetchedTrainings ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <PeriodForm
                                initialValues={ getInitialValues() }
                                startDateLabel={ startDateLabel }
                                endDateLabel={ endDateLabel }
                                internalLabel={ internalLabel }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidFileSizeMessage={ invalidFileSizeMessage }
                                invalidFileTypeMessage={ invalidFileTypeMessage}
                                onSubmit={ onSubmit }
                                editMode
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <Autocomplete
                                options={ trainings }
                                getOptionLabel={ tag => tag.name }
                                onChange={ onTrainingChange }
                                multiple={ true }
                                value={ getSelectedTrainings() }
                                getOptionSelected={ (a, b) => a.id === b.id }
                                renderInput={ (params) => <TextField {...params} label={ trainingsLabel } variant="outlined" /> }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ loadingPeriod || loadingTraining } message={ fetchedPeriods && fetchedTrainings ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default PeriodsEdit;
