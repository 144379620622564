import { Fragment } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import { AUTH } from 'store/constants';
import { images } from 'config/images';
import LoginForm from 'components/LoginForm';
import StaticHeader from 'components/StaticHeader';
import { Link } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch();

    const unverified = useSelector(state => state.auth.unverified);
    const error = useSelector(state => state.auth.errorMessage);
    const errorMessage = useIntl().formatMessage({ id: 'login.error'});
    const pageTitle = useIntl().formatMessage({ id: 'login.title'});
    const usernameLabel = useIntl().formatMessage({ id: 'login.label.username'});
    const passwordLabel = useIntl().formatMessage({ id: 'login.label.password'});
    const submitButtonText = useIntl().formatMessage({ id: 'login.label.loginButton'});
    const passwordRecoveryLabel = useIntl().formatMessage({ id: 'login.label.passwordRecovery'})
    const registerLabel = useIntl().formatMessage({ id: 'login.label.register'})
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const emailFieldMessage = useIntl().formatMessage({ id: 'validation.error.email' });
    const passwordRecoveryTarget = '/password-recovery';

    const handleSubmit = values => {
        dispatch({
            type: AUTH.LOGIN,
            payload: values,
        });

        return true;
    }

    return (
        <Fragment>
            <StaticHeader />

            <main role="main" className="pages">
                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.login.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.login.right.default } alt="Splash" />
                    </div>
                </figure>

                <section className="log-in">
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xm-8 col-xs-8 mx-auto no-gutters">
                                <article className="info">
                                    <h1>{ pageTitle }</h1>

                                    { error && (
                                        <Box mb={ 4 }>
                                            <Alert severity="error">{ errorMessage }</Alert>
                                        </Box>
                                    ) }

                                    { unverified && (
                                        <Box mb={ 4 }>
                                            <Alert severity="warning">
                                                De verificatiemail is opnieuw naar u verstuurd.<br />
                                                Verifier het account om in te kunnen loggen.
                                            </Alert>
                                        </Box>
                                    ) }
                                </article>

                                <LoginForm
                                    usernameLabel={ usernameLabel }
                                    passwordLabel={ passwordLabel }
                                    passwordRecoveryLabel={ passwordRecoveryLabel }
                                    emailFieldMessage={ emailFieldMessage }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    submitButtonText={ submitButtonText }
                                    passwordRecoveryTarget={ passwordRecoveryTarget }
                                    onSubmit={ handleSubmit }
                                />

                                <Box mt={ 3 }>
                                    <Link to={ '/register' }>{ registerLabel }</Link>
                                </Box>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default Login;
