import { createRoutine } from 'redux-saga-routines';

export const ACCOUNTS = {
    FETCH: createRoutine('ACCOUNTS_FETCH'),
    CREATE: createRoutine('ACCOUNTS_CREATE'),
    DELETE: createRoutine('ACCOUNTS_DELETE'),
};

export const ASSESSMENTS = {
    SHOW: createRoutine('ASSESSMENTS_SHOW'),
};

export const AUTH = {
    LOGIN: 'AUTH_LOGIN',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAIL: 'AUTH_LOGIN_FAIL',
    UNVERIFIED: 'AUTH_UNVERIFIED',
    ERROR_RESET: 'LOGIN_ERROR_RESET',
    LOGOUT: 'AUTH_LOGOUT',
    PASSWORD: {
        RESET: 'PASSWORD_RESET',
        RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
        RESET_FAIL: 'PASSWORD_RESET_FAIL',
    }
};

export const CLASSIFICATIONS = {
    FETCH: createRoutine('CLASSIFICATIONS_FETCH'),
    CREATE: createRoutine('CLASSIFICATIONS_CREATE'),
    SHOW: createRoutine('CLASSIFICATIONS_SHOW'),
    UPDATE: createRoutine('CLASSIFICATIONS_UPDATE'),
    DELETE: createRoutine('CLASSIFICATIONS_DELETE'),
    TEAMS: {
        SHOW: createRoutine('CLASSIFICATIONS_TEAMS_SHOW'),
    }
};

export const CLUB = {
    FETCH: createRoutine('CLUB_FETCH'),
    CREATE: createRoutine('CLUB_CREATE'),
    UPDATE: createRoutine('CLUB_UPDATE'),
    DELETE: createRoutine('CLUB_DELETE'),
    ACCESS_REQUEST: {
        FETCH: createRoutine('CLUB_ACCESS_REQUEST_FETCH'),
        UPDATE: createRoutine('CLUB_ACCESS_REQUEST_UPDATE'),
        DELETE: createRoutine('CLUB_ACCESS_REQUEST_DELETE'),
    },
    PLAYER: {
        FETCH: createRoutine('CLUB_PLAYER_FETCH'),
        CREATE: createRoutine('CLUB_PLAYER_CREATE'),
        UPDATE: createRoutine('CLUB_PLAYER_UPDATE'),
        DELETE: createRoutine('CLUB_PLAYER_DELETE'),
        IMPORT: createRoutine('CLUB_PLAYER_IMPORT'),
        ASSESSMENT: {
            FETCH: createRoutine('CLUB_PLAYER_ASSESSMENT_FETCH'),
        },
    },
    TEAM: {
        FETCH: createRoutine('CLUB_TEAM_FETCH'),
        CREATE: createRoutine('CLUB_TEAM_CREATE'),
        UPDATE: createRoutine('CLUB_TEAM_UPDATE'),
        DELETE: createRoutine('CLUB_TEAM_DELETE'),
        PLAYER: {
            UPDATE: createRoutine('CLUB_TEAM_PLAYER_UPDATE'),
        },
    },
    USER: {
        FETCH: createRoutine('CLUB_USER_FETCH'),
        CREATE: createRoutine('CLUB_USER_CREATE'),
        UPDATE: createRoutine('CLUB_USER_UPDATE'),
        DELETE: createRoutine('CLUB_USER_DELETE'),
    },
};

export const CLUB_EXERCISES = {
    FETCH: createRoutine('CLUB_EXERCISES_FETCH'),
    CREATE: createRoutine('CLUB_EXERCISES_CREATE'),
    UPDATE: createRoutine('CLUB_EXERCISES_UPDATE'),
    DELETE: createRoutine('CLUB_EXERCISES_DELETE'),
    TAG: {
        UPDATE: createRoutine('CLUB_EXERCISES_TAG_UPDATE'),
        DELETE: createRoutine('CLUB_EXERCISES_TAG_DELETE'),
    },
};

export const CLUB_TRAININGS = {
    FETCH: createRoutine('CLUB_TRAININGS_FETCH'),
    CREATE: createRoutine('CLUB_TRAININGS_CREATE'),
    UPDATE: createRoutine('CLUB_TRAININGS_UPDATE'),
    DELETE: createRoutine('CLUB_TRAININGS_DELETE'),
    EXERCISE: {
        UPDATE: createRoutine('CLUB_TRAININGS_TAG_UPDATE'),
        DELETE: createRoutine('CLUB_TRAININGS_TAG_DELETE'),
    },
};

export const CRITERION = {
    FETCH: createRoutine('CRITERION_FETCH'),
};

export const DIVISIONS = {
    FETCH: createRoutine('DIVISIONS_FETCH'),
    CLEAN: 'DIVISIONS_CLEAN',
};

export const EVENT_TYPES = {
    FETCH: createRoutine('EVENT_TYPES_FETCH'),
    CLEAN: 'EVENT_TYPES_CLEAN',
};

export const EVALUATIONS = {
    FETCH: createRoutine('EVALUATIONS_FETCH'),
    SHOW: createRoutine('EVALUATIONS_SHOW'),
    CREATE: createRoutine('EVALUATIONS_CREATE'),
    DELETE: createRoutine('EVALUATIONS_DELETE'),
    REMIND: createRoutine('EVALUATIONS_REMIND'),
    CLEAR: createRoutine('EVALUATIONS_CLEAR'),
    SYNC: createRoutine('EVALUATIONS_SYNC'),
};

export const EVENT = {
    FETCH: createRoutine('EVENT_FETCH'),
    CREATE: createRoutine('EVENT_CREATE'),
    SHOW: createRoutine('EVENT_SHOW'),
    UPDATE: createRoutine('EVENT_UPDATE'),
    DELETE: createRoutine('EVENT_DELETE'),
    DATE: {
        UPDATE: createRoutine('EVENT_DATE_UPDATE'),
        DELETE: createRoutine('EVENT_DATE_DELETE'),
    },
    TRAINER: {
        UPDATE: createRoutine('EVENT_TRAINER_UPDATE'),
        DELETE: createRoutine('EVENT_TRAINER_DELETE'),
    },
    TRAINING: {
        UPDATE: createRoutine('EVENT_TRAINING_UPDATE'),
        DELETE: createRoutine('EVENT_TRAINING_DELETE'),
    },
    PLAYER: {
        DELETE: createRoutine('EVENT_PLAYER_DELETE'),
    },
    CLEAN: 'EVENT_CLEAN',
};

export const EXERCISES = {
    FETCH: createRoutine('EXERCISES_FETCH'),
    SHOW: createRoutine('EXERCISES_SHOW'),
    CREATE: createRoutine('EXERCISES_CREATE'),
    UPDATE: createRoutine('EXERCISES_UPDATE'),
    DELETE: createRoutine('EXERCISES_DELETE'),
    LATEST: createRoutine('EXERCISES_LATEST'),
    EDUCATION_PLAN: createRoutine('EXERCISES_EDUCATION_PLAN'),
    LABEL: {
        UPDATE: createRoutine('EXERCISES_LABEL_UPDATE'),
        DELETE: createRoutine('EXERCISES_LABEL_DELETE'),
    },
    TAG: {
        UPDATE: createRoutine('EXERCISES_TAG_UPDATE'),
        DELETE: createRoutine('EXERCISES_TAG_DELETE'),
    },
    CLEAN: 'EXERCISES_CLEAN',
};

export const EXERCISE_TAGS = {
    FETCH: createRoutine('EXERCISE_TAGS_FETCH'),
    SHOW: createRoutine('EXERCISE_TAGS_SHOW'),
};

export const FEEDBACK = {
    FETCH: createRoutine('FEEDBACK_FETCH'),
    CREATE: createRoutine('FEEDBACK_CREATE'),
    UPDATE: createRoutine('FEEDBACK_UPDATE'),
    DELETE: createRoutine('FEEDBACK_DELETE'),
    PLAYER: {
        FETCH: createRoutine('FEEDBACK_PLAYER_FETCH'),
    },
    CLEAN: 'FEEDBACK_CLEAN',
};

export const INTERNAL_TRAINERS = {
    FETCH: createRoutine('INTERNAL_TRAINERS_FETCH'),
    CREATE: createRoutine('INTERNAL_TRAINERS_CREATE'),
    UPDATE: createRoutine('INTERNAL_TRAINERS_UPDATE'),
    DELETE: createRoutine('INTERNAL_TRAINERS_DELETE'),
    CLEAN: 'INTERNAL_TRAINERS_CLEAN',
};

export const LANGUAGE = {
    SET: 'LANGUAGE_SET',
    MESSAGES: {
        SET: 'LANGUAGE_MESSAGES_SET',
    }
};

export const MATCHES = {
    FETCH: createRoutine('MATCHES_FETCH'),
    CREATE: createRoutine('MATCHES_CREATE'),
    SHOW: createRoutine('MATCHES_SHOW'),
    UPDATE: createRoutine('MATCHES_UPDATE'),
    DELETE: createRoutine('MATCHES_DELETE'),
    LINEUP: {
        CREATE: createRoutine('MATCHES_LINEUP_CREATE'),
    },
    TACTIC: {
        CREATE: createRoutine('MATCHES_TACTIC_CREATE'),
    },
    HIGHLIGHT: {
        CREATE: createRoutine('MATCHES_HIGHLIGHT_CREATE'),
        DELETE: createRoutine('MATCHES_HIGHLIGHT_DELETE'),
    },
    REPORT: {
        CREATE: createRoutine('MATCHES_REPORT_CREATE'),
    },
    PHOTO: {
        CREATE: createRoutine('MATCHES_PHOTO_CREATE'),
        DELETE: createRoutine('MATCH_ESPHOTO_DELETE'),
    },
    RATING: {
        CREATE: createRoutine('MATCHES_RATING_CREATE'),
    },
    CLEAN: 'MATCHES_CLEAN',
};

export const PROFILE = {
    FETCH: createRoutine('PROFILE_FETCH'),
};

export const NEWS = {
    FETCH: createRoutine('NEWS_FETCH'),
    CREATE: createRoutine('NEWS_CREATE'),
    UPDATE: createRoutine('NEWS_UPDATE'),
    DELETE: createRoutine('NEWS_DELETE'),
    CLEAN: 'NEWS_CLEAN',
};

export const OCCASIONS = {
    FETCH: createRoutine('OCCASIONS_FETCH'),
    SHOW: createRoutine('OCCASIONS_SHOW'),
    CREATE: createRoutine('OCCASIONS_CREATE'),
    UPDATE: createRoutine('OCCASIONS_UPDATE'),
    DELETE: createRoutine('OCCASIONS_DELETE'),
};

export const ORDERS = {
    FETCH: createRoutine('ORDERS_FETCH'),
    SHOW: createRoutine('ORDERS_SHOW'),
};

export const PERIODS = {
    FETCH: createRoutine('PERIODS_FETCH'),
    CREATE: createRoutine('PERIODS_CREATE'),
    UPDATE: createRoutine('PERIODS_UPDATE'),
    DELETE: createRoutine('PERIODS_DELETE'),
    TRAINING: {
        UPDATE: createRoutine('PERIODS_TRAINING_UPDATE'),
        DELETE: createRoutine('PERIODS_TRAINING_DELETE'),
    },
    CLEAN: 'PERIODS_CLEAN',
};

export const PLAYER = {
    CREATE: createRoutine('PLAYER_CREATE'),
    SHOW: createRoutine('PLAYER_SHOW'),
    UPDATE: createRoutine('PLAYER_UPDATE'),
    EVENT: {
        UPDATE: createRoutine('PLAYER_EVENT_UPDATE'),
    },
    SETTINGS: {
        UPDATE: createRoutine('PLAYER_SETTINGS_UPDATE'),
    },
    STATS: {
        UPDATE: createRoutine('PLAYER_STATS_UPDATE'),
    },
};

export const PLAYERCARD = {
    SHOW: createRoutine('PLAYERCARD_SHOW'),
};

export const PREMIUM = {
    FETCH: createRoutine('PREMIUM_FETCH'),
    CREATE: createRoutine('PREMIUM_CREATE'),
    SHOW: createRoutine('PREMIUM_SHOW'),
    UPDATE: createRoutine('PREMIUM_UPDATE'),
    DELETE: createRoutine('PREMIUM_DELETE'),
};

export const SURVEYS = {
    FETCH: createRoutine('SURVEY_FETCH'),
    SHOW: createRoutine('SURVEY_SHOW'),
    CREATE: createRoutine('SURVEY_CREATE'),
    UPDATE: createRoutine('SURVEY_UPDATE'),
    DELETE: createRoutine('SURVEY_DELETE'),
    ANSWERS: {
        CREATE: createRoutine('SURVEY_ANSWERS_CREATE'),
        UPDATE: createRoutine('SURVEY_ANSWERS_UPDATE'),
        DELETE: createRoutine('SURVEY_ANSWERS_DELETE'),
    },
    QUESTIONS: {
        CREATE: createRoutine('SURVEY_QUESTIONS_CREATE'),
        UPDATE: createRoutine('SURVEY_QUESTIONS_UPDATE'),
        DELETE: createRoutine('SURVEY_QUESTIONS_DELETE'),
    },
    TOPICS: {
        CREATE: createRoutine('SURVEY_TOPICS_CREATE'),
        UPDATE: createRoutine('SURVEY_TOPICS_UPDATE'),
        DELETE: createRoutine('SURVEY_TOPICS_DELETE'),
    },
};

export const TAGS = {
    FETCH: createRoutine('TAGS_FETCH'),
    CLEAN: 'TAGS_CLEAN',
}

export const TEAM = {
    ADMIN: {
        FETCH: createRoutine('TEAM_ADMIN_FETCH'),
        CREATE: createRoutine('TEAM_ADMIN_CREATE'),
        DELETE: createRoutine('TEAM_ADMIN_DELETE'),
    },
    EVALUATION: {
        FETCH: createRoutine('TEAM_EVALUATION_FETCH'),
        SHOW: createRoutine('TEAM_EVALUATION_SHOW'),
    },
    PLAYER: {
        FETCH: createRoutine('TEAM_PLAYER_FETCH'),
        DELETE: createRoutine('TEAM_PLAYER_DELETE'),
    }
};

export const TRAININGS = {
    FETCH: createRoutine('TRAININGS_FETCH'),
    SHOW: createRoutine('TRAININGS_SHOW'),
    CREATE: createRoutine('TRAININGS_CREATE'),
    UPDATE: createRoutine('TRAININGS_UPDATE'),
    DELETE: createRoutine('TRAININGS_DELETE'),
    EXERCISE: {
        UPDATE: createRoutine('TRAININGS_TAG_UPDATE'),
        DELETE: createRoutine('TRAININGS_TAG_DELETE'),
    },
    CLEAN: 'TRAININGS_CLEAN',
};

export const USER = {
    SHOW: createRoutine('USER_SHOW'),
    UPDATE: createRoutine('USER_UPDATE'),
    SEARCH: createRoutine('USER_SEARCH'),
};

export const VIDEOS = {
    FETCH: createRoutine('VIDEOS_FETCH'),
    CREATE: createRoutine('VIDEOS_CREATE'),
    UPDATE: createRoutine('VIDEOS_UPDATE'),
    DELETE: createRoutine('VIDEOS_DELETE'),
    TAG: {
        UPDATE: createRoutine('VIDEOS_TAG_UPDATE'),
        DELETE: createRoutine('VIDEOS_TAG_DELETE'),
    },
    CLEAN: 'VIDEOS_CLEAN',
};

export const VIEW_MODE = {
    TOGGLE: 'VIEW_MODE_TOGGLE',
};
