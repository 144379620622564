import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import CheckboxInput from 'components/CheckboxInput';

const PlayerSettingsForm = props => {
    const {
        initialValues,
        onSubmit,
    } = props;

    const validationSchemaObject = yup.object().shape({
        allow_evaluation: yup
            .bool(),
        allow_photo: yup
            .bool(),
        allow_video: yup
            .bool(),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name={ 'allow_evaluation' }
                            label="Evaluaties toegestaan"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['allow_evaluation'] }
                            touched={ touched['allow_evaluation'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['allow_evaluation'] }
                        />
                    </Box>

                    <Box>
                        <Field
                            name={ 'allow_photo' }
                            label="Foto's toegestaan"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['allow_photo'] }
                            touched={ touched['allow_photo'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['allow_photo'] }
                        />
                    </Box>

                    <Box>
                        <Field
                            name={ 'allow_video' }
                            label="Video's toegestaan"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['allow_video'] }
                            touched={ touched['allow_video'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['allow_video'] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            Pas instellingen aan
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

PlayerSettingsForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
};

PlayerSettingsForm.defaultProps = {
    initialValues: {
        allow_evaluation: false,
        allow_photo: false,
        allow_video: false,
    },
}

export default PlayerSettingsForm;
