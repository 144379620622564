import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import CheckboxGroupInput from 'components/CheckboxGroupInput';
import CheckboxInput from 'components/CheckboxInput';
import SelectInput from 'components/SelectInput';
import styles from './EvaluationForm.styles';

const EvaluationForm = props => {
    const classes = styles();
    const {
        onSubmit,
        surveys,
        teams,
    } = props;
    const initialValues = {
        teams: teams.map(team => `${team.value}`),
    };

    const validationSchemaObject = yup.object().shape({
        invite_goalkeepers: yup.boolean(),
        invite_players: yup.boolean(),
        is_private: yup.boolean(),
        survey_id: yup.mixed().required('Verplicht veld'),
        teams: yup
            .array()
            .min(1, 'Minimaal 1 team verplicht')
            .required('Verplicht veld')
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                  dirty,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isValid,
                  isSubmitting,
                  setFieldValue,
                  touched,
              }) => (
                <form onSubmit={ handleSubmit }>
                    <Box className={classes.selectInput}>
                        <Field
                            name="survey_id"
                            label="Vragenlijst"
                            component={ SelectInput }
                            errorMessage={ errors['survey_id'] }
                            touched={ touched['survey_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['survey_id'] }
                            options={ surveys }
                        />
                    </Box>

                    <Box mt={ 3 }>
                        <Field
                            component={ CheckboxGroupInput }
                            errorMessage={ errors['teams'] }
                            heading="Teams"
                            name="teams"
                            options={ teams }
                            setFieldValue={ setFieldValue }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Typography variant="h6" align="left">
                            Overige opties
                        </Typography>
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="invite_players"
                            label="Spelers uitnodigen"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['invite_players'] }
                            touched={ touched['invite_players'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box>
                        <Field
                            name="invite_goalkeepers"
                            label="Keepers uitnodigen"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['invite_goalkeepers'] }
                            touched={ touched['invite_goalkeepers'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box>
                        <Field
                            name="is_private"
                            label="Afschermen voor spelers"
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['is_private'] }
                            touched={ touched['is_private'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            disabled={ isSubmitting || ! isValid || ! dirty }
                            type="submit"
                            variant="contained"
                        >
                            Verstuur verzoeken
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

EvaluationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    surveys: PropTypes.array.isRequired,
    teams: PropTypes.array.isRequired,
};

export default EvaluationForm;
