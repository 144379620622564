import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import DatePickerInput from 'components/DatePickerInput';

const EventDateForm = props => {
    const {
        onSubmit,
    } = props;
    const initialValues = {};
    const validationSchemaObject = yup.object().shape({
        date: yup
            .string()
            .required('Verplicht veld'),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  touched,
              }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name="date"
                            label="Datum"
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['date'] }
                            touched={ touched['date'] }
                            onBlur={ handleBlur['date'] }
                            minDate={ new Date() }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            Voeg datum toe
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

EventDateForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default EventDateForm;
