import { Component } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Main from './containers/Main';
import Auth from './containers/Auth';

toast.configure({
    autoClose: 7000,
    draggable: false,
    position: toast.POSITION.BOTTOM_RIGHT,
});

class App extends Component {
  render() {

    return (
        <Router>
          <div className="App">
            { this.props.accessToken ? <Main /> : <Auth /> }
          </div>
        </Router>
    );
  }
}

const mapStateToProps = store => {
  return {
    accessToken: store.persist.accessToken
  };
};

export default connect(mapStateToProps, null)(App);
