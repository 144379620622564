import PropTypes from 'prop-types';

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import styles from './RatingBlock.styles';

const RatingBlock = props => {
    const { label, value } = props;
    const classes = styles();

    const stars = () => {
        if (value > 5) {
            return 5;
        }

        if (value < 0) {
            return 0
        }

        return value;
    }

    return (
        <Box mb={ 2 }>
            <Typography variant="body1" gutterBottom className={ classes.label }>
                { label }
            </Typography>

            <Rating name="disabled" value={ stars() } disabled />
        </Box>
    );
};

RatingBlock.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number
};

export default RatingBlock;
