import { useCallback, useEffect, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import nl2br from 'react-nl2br';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Player } from 'video-react';

import LoadingIndicator from 'components/LoadingIndicator';
import StaticHeader from 'components/StaticHeader';
import { Api } from 'config/api';
import { images } from 'config/images';
import { logout } from 'store/actions/AuthActions';
import { guestGet } from 'utilities/api';
import styles from './PromoExercise.styles';

const PromoExercise = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [exercise, setExercise] = useState(null);
    const [error, setError] = useState(false);
    const classes = styles();

    const fetchExercise = useCallback(async () => {
        try {
            const endpoint = Api.requests.promo.exercises.index(params.slug);

            const response = await guestGet(endpoint);
            setExercise(response.data.data);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [params])

    useEffect(() => {
        fetchExercise();
    }, [fetchExercise])

    useEffect(() => {
        dispatch(logout());
    });

    return (
        <>
            <StaticHeader />

            <main role="main" className="pages no-padding">
                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.login.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.login.right.default } alt="Splash" />
                    </div>
                </figure>

                { loading && (
                    <LoadingIndicator message="Bezig met ophalen van oefening" />
                ) }

                { error && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 mx-auto">
                                <Alert severity="error">De oefening kon niet worden geladen</Alert>
                            </div>
                        </div>
                    </div>
                ) }

                { ! loading && ! error && (
                    <section className="log-in">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mx-auto">
                                    <Box mb={ 2 }>
                                        <Typography variant="h3" component="h1">
                                            { exercise.title }
                                        </Typography>

                                        <Typography variant="subtitle1">
                                            { exercise.description }
                                        </Typography>
                                    </Box>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-xs-12 mx-auto">
                                    <img src={ exercise.image } alt={ exercise.description } className="responsive-image" />
                                </div>
                                <div className="col-lg-6 col-xs-12 mx-auto">
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Uitleg</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                { nl2br(exercise.explanation) }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Instructie</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                { nl2br(exercise.instruction) }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Uitbouw</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                { nl2br(exercise.extension) }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    { exercise.video.length > 0 && (
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography className={classes.heading}>Video</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Player>
                                                    <source src={ exercise.video } />
                                                </Player>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) }

                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={ classes.heading }>Over Go4Hockey</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={ classes.details }>
                                            <Typography>
                                                Ben je op zoek naar oefeningen? In onze database staan er meer dan 500.
                                                Je vindt er ook complete trainingen en een leerlijn. Go4Hockey is het
                                                platform voor het optimaal begeleiden van je team.
                                            </Typography>

                                            <Box mt={ 2 }>
                                                <Link to="/"  className="btn btn-fill primary">Meer informatie</Link>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                ) }
            </main>
        </>
    );
};

export default PromoExercise;
