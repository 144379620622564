import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import PlayerStatsForm from 'contents/forms/PlayerStatsForm';

const PlayercardBlock = props => {
    const { onSubmit, player, playercard } = props;
    const [editMode, setEditMode] = useState(false);
    const initialValues ={
        stat_1: player?.stats?.data?.stat_1,
        stat_2: player?.stats?.data?.stat_2,
        stat_3: player?.stats?.data?.stat_3,
        stat_4: player?.stats?.data?.stat_4,
        stat_5: player?.stats?.data?.stat_5,
        stat_6: player?.stats?.data?.stat_6,
    };

    const updatePlayercard = values => {
        setEditMode(false);
        onSubmit(values);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Playercard"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                {
                    editMode ? (
                        <PlayerStatsForm
                            initialValues={ initialValues }
                            onSubmit={ updatePlayercard }
                        />
                    ) : (
                        <img src={ playercard.base64 } alt="Playercard"  className="responsive-image" />
                    )
                }
            </Box>
        </ContentBox>
    );
};

PlayercardBlock.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
    playercard: PropTypes.object.isRequired,
}

export default PlayercardBlock;