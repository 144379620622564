import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const ClubTeamsTable = props => {
    const { data, onCreate, onDelete, onUpdate } = props;
    const history = useHistory();

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Team',
            validate: rowData => !! rowData?.name,
        },
        {
            align: 'left',
            editable: 'never',
            field: 'player_count',
            title: 'Aantal spelers',
            type: 'numeric',
        },
    ];

    const actions = [
        {
            icon: 'sports',
            tooltip: 'Admins',
            onClick: (event, rowData) => history.push(`/teams/${rowData.id}/admins`)
        },
        {
            icon: 'people_outline',
            tooltip: 'Spelers',
            onClick: (event, rowData) => history.push(`/teams/${rowData.id}/players`)
        },
        {
            icon: 'list_alt',
            tooltip: 'Evaluaties',
            onClick: (event, rowData) => history.push(`/teams/${rowData.id}/evaluations`)
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowUpdate: onUpdate,
        onRowDelete: onDelete,
    };

    return (
        <MaterialTable
            title="Teams"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

ClubTeamsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ClubTeamsTable;