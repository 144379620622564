import { AUTH, MATCHES } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    matches: [],
    active: null,
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const matchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MATCHES.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case MATCHES.FETCH.SUCCESS:
            return {
                ...state,
                matches: action.payload,
                fetched: true,
            };

        case MATCHES.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case MATCHES.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case MATCHES.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                created: false,
            }

        case MATCHES.CREATE.SUCCESS:
            return {
                ...state,
                matches: state.matches.concat(action.payload),
                created: action.payload.id,
            };

        case MATCHES.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case MATCHES.CREATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case MATCHES.SHOW.TRIGGER:
            return {
                ...state,
                error: false,
            }

        case MATCHES.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case MATCHES.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case MATCHES.SHOW.FULFILL:
            return {
                ...state,
                error: false,
            }

        case MATCHES.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                updated: false,
            }

        case MATCHES.UPDATE.SUCCESS:
            return {
                ...state,
                matches: findIdAndUpdateObjectInArray([...state.matches], action.payload.id, action.payload),
                updated: true,
            };

        case MATCHES.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case MATCHES.UPDATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case MATCHES.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
                deleted: false,
            }

        case MATCHES.DELETE.SUCCESS:
            return {
                ...state,
                matches: deleteObjectWithIdFromArray([...state.matches], action.payload),
                deleted: true,
            };

        case MATCHES.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case MATCHES.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                deleted: false,
            }

        case MATCHES.TACTIC.CREATE.TRIGGER:
        case MATCHES.REPORT.CREATE.TRIGGER:
        case MATCHES.HIGHLIGHT.CREATE.TRIGGER:
        case MATCHES.RATING.CREATE.TRIGGER:
        case MATCHES.LINEUP.CREATE.TRIGGER:
        case MATCHES.PHOTO.CREATE.TRIGGER:
            return {
                ...state,
                updated: false,
                error: false,
                loading: true,
            }

        case MATCHES.TACTIC.CREATE.SUCCESS:
        case MATCHES.REPORT.CREATE.SUCCESS:
        case MATCHES.HIGHLIGHT.CREATE.SUCCESS:
        case MATCHES.RATING.CREATE.SUCCESS:
        case MATCHES.PHOTO.CREATE.SUCCESS:
        case MATCHES.LINEUP.CREATE.SUCCESS:
        case MATCHES.PHOTO.DELETE.SUCCESS:
            return {
                ...state,
                updated: true,
                active: action.payload,
            }

        case MATCHES.TACTIC.CREATE.FAILURE:
        case MATCHES.REPORT.CREATE.FAILURE:
        case MATCHES.HIGHLIGHT.CREATE.FAILURE:
        case MATCHES.RATING.CREATE.FAILURE:
        case MATCHES.LINEUP.CREATE.FAILURE:
        case MATCHES.PHOTO.CREATE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case MATCHES.TACTIC.CREATE.FULFILL:
        case MATCHES.REPORT.CREATE.FULFILL:
        case MATCHES.HIGHLIGHT.CREATE.FULFILL:
        case MATCHES.RATING.CREATE.FULFILL:
        case MATCHES.LINEUP.CREATE.FULFILL:
        case MATCHES.PHOTO.CREATE.FULFILL:
            return {
                ...state,
                updated: false,
                error: false,
                loading: false,
            }

        case MATCHES.HIGHLIGHT.DELETE.TRIGGER:
            return {
                ...state,
                deleted: false,
                error: false,
                loading: true,
            }

        case MATCHES.HIGHLIGHT.DELETE.SUCCESS:
            return {
                ...state,
                deleted: true,
                active: action.payload,
            }

        case MATCHES.HIGHLIGHT.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case MATCHES.HIGHLIGHT.DELETE.FULFILL:
            return {
                ...state,
                deleted: false,
                error: false,
                loading: false,
            }

        case MATCHES.CLEAN:
            return {
                ...state,
                error: false,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default matchReducer;