import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Player } from 'video-react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './VideosShow.styles';
import ValueBlock from 'components/ValueBlock';
import { VIDEOS } from 'store/constants';
import { toast } from 'react-toastify';

const VideosShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetched = useSelector(state => state.video.fetched);
    const loading = useSelector(state => state.video.loading);
    const classes = styles();

    const videos = useSelector(state => state.video.videos);
    const video = videos.filter(object => object.id === parseInt(params.videoId))[0];
    const title = video ? video.title : '';
    const titleLabel = useIntl().formatMessage({ id: 'videos.label.title' });
    const description = video ? video.description : '';
    const descriptionLabel = useIntl().formatMessage({ id: 'videos.label.description' });
    const tags = video ? video.tags.data.map(tag => tag.name).join(', ') : '';
    const tagsLabel = useIntl().formatMessage({ id: 'videos.label.tags' });
    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(VIDEOS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: VIDEOS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ title } backButton={ true }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ titleLabel } value={ title } />
                            <ValueBlock label={ descriptionLabel } value={ description } />
                            <ValueBlock label={ tagsLabel } value={ tags } />
                        </Paper>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <Player>
                                { video ? <source src={ video.video } /> : null }
                            </Player>
                        </Paper>
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default VideosShow;
