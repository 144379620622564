import PropTypes from 'prop-types';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import { Field, Formik } from 'formik';
import TextInput from '../TextInput';
import FileInput from '../FileInput';
import Button from '@material-ui/core/Button';
import CheckboxInput from '../CheckboxInput';

const FeedbackForm = props => {
    const {
        feedbackLabel,
        publishedLabel,
        imageLabel,
        submitButtonText,
        requiredFieldMessage,
        invalidFileSizeMessage,
        invalidFileTypeMessage,
        onSubmit,
        initialValues,
    } = props;
    const FILE_SIZE = 2 * 1024 * 1024;
    const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];

    const validationSchema = {
        feedback: yup.string().max(255).required(requiredFieldMessage),
        published: yup.boolean(),
        image: yup.mixed().notRequired()
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return SUPPORTED_FORMATS.includes(value.type)
                }
            ),
    }
    const validationSchemaObject = yup.object().shape(validationSchema);

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name={ 'feedback' }
                            label={ feedbackLabel }
                            component={ TextInput }
                            errorMessage={ errors['feedback'] }
                            touched={ touched['feedback'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            lines={ 20 }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name={ 'image' }
                            component={ FileInput }
                            title={ imageLabel }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors["image"] ? errors["image"] : undefined }
                            touched={ touched["image"] }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    { ! initialValues.published ? (
                        <Box mt={ 2 }>
                            <Field
                                name={ 'published' }
                                label={ publishedLabel }
                                component={ CheckboxInput }
                                setFieldValue={ setFieldValue }
                                errorMessage={ errors['published'] }
                                touched={ touched['published'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                            />
                        </Box>
                    ) : null }

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};


FeedbackForm.propTypes = {
    feedbackLabel: PropTypes.string,
    publishedLabel: PropTypes.string,
    imageLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    invalidFileSizeMessage: PropTypes.string,
    invalidFileTypeMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

FeedbackForm.defaultProps = {
    feedbackLabel: 'feedback',
    publishedLabel: 'published at',
    imageLabel: 'photo',
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    invalidFileSizeMessage: 'invalid file size',
    invalidFileTypeMessage: 'invalid file type',
    initialValues: {
        feedback: '',
        published: false,
        image: undefined,
    }
}

export default FeedbackForm;
