import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TextInput from 'components/TextInput';
import styles from './ForgotPasswordForm.styles';

const ForgotPasswordForm = props => {
    const {
        emailLabel,
        emailFieldMessage,
        requiredFieldMessage,
        submitButtonText,
        onSubmit,
    } = props;

    const classes = styles();

    const initialValues = {
        email: '',
    };

    const validationSchemaObject = yup.object().shape({
        email: yup.string().email(emailFieldMessage).required(requiredFieldMessage),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'email' }
                            label={ emailLabel }
                            component={ TextInput }
                            errorMessage={ errors['email'] }
                            touched={ touched['email'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            variant="outlined"
                            className={ classes.input }
                        />
                    </Box>

                    <Box mt={ 3 }>
                        <Button
                            type="submit"
                            size="large"
                            disabled={ isSubmitting }
                            color={ 'primary' }
                            variant={ 'contained' }
                            className={ classes.button }
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

ForgotPasswordForm.propTypes = {
    emailLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    emailFieldMessage: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
    emailLabel: 'E-mail',
    submitButtonText: 'Submit',
    emailFieldMessage: 'Invalid e-mail',
    requiredFieldMessage: 'Required',
};

export default ForgotPasswordForm;
