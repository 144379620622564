import { ACCOUNTS, AUTH } from '../constants';
import { deleteObjectWithIdFromArray } from 'utilities/array';

const INITIAL_STATE = {
    accounts: [],
    fetched: false,
    error: false,
    loading: false,
    created: null,
    deleted: false,
};

const accountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCOUNTS.FETCH.TRIGGER:
        case ACCOUNTS.CREATE.TRIGGER:
        case ACCOUNTS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: false,
            }

        case ACCOUNTS.FETCH.SUCCESS:
            return {
                ...state,
                accounts: action.payload,
                fetched: true,
            };

        case ACCOUNTS.CREATE.SUCCESS:
            return {
                ...state,
                accounts: state.accounts.concat(action.payload),
                created: action.payload.id,
            };

        case ACCOUNTS.DELETE.SUCCESS:
            return {
                ...state,
                accounts: deleteObjectWithIdFromArray([...state.accounts], action.payload),
                deleted: true,
            };

        case ACCOUNTS.FETCH.FAILURE:
        case ACCOUNTS.CREATE.FAILURE:
        case ACCOUNTS.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case ACCOUNTS.FETCH.FULFILL:
        case ACCOUNTS.CREATE.FULFILL:
        case ACCOUNTS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                created: null,
                updated: false,
                deleted: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default accountReducer;