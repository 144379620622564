import { createRef, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import FileStatusAvatar from 'components/FileStatusAvatar';
import styles from './FileInput.styles';


const FileInput = props => {
    const classes = styles();
    const fileUpload = createRef();

    const [file, setFile] = useState(undefined);

    const { errorMessage, title, touched } = props;
    const { name, onBlur } = props.field;

    const showFileUpload = () => {
        if (fileUpload) {
            fileUpload.current.click();
        }
    }

    const handleImageChange = e => {
        e.preventDefault();

        let newFile = e.target.files[0];

        if (newFile) {
            setFile(newFile);

            props.setFieldValue(props.field.name, newFile);
        }
    }

    const renderIcon = () => {
        const { errorMessage } = props;

        if ((file || touched) && errorMessage) {
            return <FileStatusAvatar status={ 'error' } onClick={ showFileUpload } />
        } else if (file) {
            return <FileStatusAvatar status={ 'selected' } onClick={ showFileUpload } />
        }

        return <FileStatusAvatar status={ 'open' } onClick={ showFileUpload } />
    };

    return (
        <div className={classes.container}>
            <input
                className={ classes.hidden }
                id={ name }
                name={ name }
                type="file"
                onChange={ handleImageChange }
                ref={ fileUpload }
                onBlur={ onBlur }
            />

            { renderIcon() }

            <div className={ classes.infoBox }>
                <Typography variant="body1" align={ 'left' }>
                    { file ? `${title}: ${file.name} ` : title }
                </Typography>

                { touched && errorMessage ? (
                    <Typography variant="caption" color="error" align={ 'left' }>
                        { errorMessage }
                    </Typography>
                ) : null}
            </div>
        </div>
    );
};

FileInput.propTypes = {
    field: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    title: PropTypes.string,
};

export default FileInput;
