import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './CheckboxInput.styles';

const CheckboxInput = ({ field, ...props }) => {
    const { label, errorMessage, touched } = props;
    const { name, value, onChange, onBlur } = field;
    const classes = styles();

    return (
        <FormControl required error={ errorMessage && touched } component="fieldset">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={ !! value }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        name={ name }
                        color="primary"
                    />

                }
                label={ label }
            />

            { errorMessage && touched ? <FormHelperText className={ classes.helperText }>{ errorMessage }</FormHelperText> : null }
        </FormControl>
    );
};

export default CheckboxInput;
