import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export default styles;