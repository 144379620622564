import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Player } from 'video-react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { FEEDBACK } from 'store/constants';
import { reverseToReadableDate } from 'utilities/date';
import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import ValueBlock from 'components/ValueBlock';
import styles from './FeedbackShow.styles';

const FeedbackShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const playerId = parseInt(params.playerId);
    const feedback = useSelector(state => state.feedback.player.feedback);
    const feedbackPlayerId = useSelector(state => state.feedback.player.profile?.id);
    const loading = useSelector(state => state.feedback.loading);
    const classes = styles();

    const item = feedback.filter(object => object.id === parseInt(params.feedbackId))[0];

    const unpublishedText = useIntl().formatMessage({ id: 'feedback.label.unpublished' });
    const pageTitle = useIntl().formatMessage({ id: 'feedback.title' });
    const feedbackText = item ? item.feedback : '';
    const feedbackLabel = useIntl().formatMessage({ id: 'feedback.label.feedback' });
    const published = item && item.published_at ? reverseToReadableDate(item.published_at) : unpublishedText;
    const publishedLabel = useIntl().formatMessage({ id: 'feedback.label.published' });
    const player = item ? item.player.data.full_name : '';
    const playerLabel = useIntl().formatMessage({ id: 'feedback.label.player' });
    const image = item ? item.image : '';
    const imageLabel = useIntl().formatMessage({ id: 'feedback.label.image' });
    const video = item ? item.video : '';
    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (playerId !== feedbackPlayerId) {
            dispatch(FEEDBACK.PLAYER.FETCH.trigger(playerId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton={ true }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ playerLabel } value={ player } />
                            <ValueBlock label={ publishedLabel } value={ published } />
                            <ValueBlock label={ feedbackLabel } value={ feedbackText } />
                        </Paper>
                    </Grid>

                    <Grid item xs={ 6 }>
                        { video.length ? (
                            <Paper elevation={ 2 } className={ classes.block }>
                                <Player>
                                    <source src={ video } />
                                </Player>
                            </Paper>
                        ) : null }

                        { image.length ? (
                            <Box mt={ video.length ? 2 : 0 }>
                                <Paper elevation={ 2 } className={ classes.block }>
                                    <img src={ image } alt={ imageLabel } className="responsive-image" />
                                </Paper>
                            </Box>
                        ) : null }
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default FeedbackShow;
