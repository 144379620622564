import {
    AUTH,
    ASSESSMENTS
} from '../constants';

const INITIAL_STATE = {
    active: null,

    error: false,
    loading: false,
};

const assessmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ASSESSMENTS.SHOW.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case ASSESSMENTS.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case ASSESSMENTS.SHOW.FAILURE:
            return {
                ...state,
                error: true,
            }

        case ASSESSMENTS.SHOW.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default assessmentReducer;