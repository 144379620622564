import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styles from './LatestItem.styles';

const LatestItem = props => {
    const { title, subtitle, onClick } = props;
    const classes = styles();

    return (
        <Box mt={ 2 }>
            <Paper elevation={ 2 } className={ classes.outerContainer } onClick={ onClick }>
                <div className={ classes.textContainer }>
                    <Typography variant="subtitle1">
                        { title }
                    </Typography>
                    <Typography variant="subtitle2">
                        { subtitle }
                    </Typography>
                </div>

                <ArrowForwardIosIcon />
            </Paper>
        </Box>
    );
};

LatestItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

LatestItem.defaultProps = {
    subtitle: '',
}

export default LatestItem;
