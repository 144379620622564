import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TextInput from '../TextInput';
import styles from './InquiryForm.styles';

const InquiryForm = props => {
    const { onSubmit } = props;

    const classes = styles();

    const initialValues = {
        club: '',
        contact: '',
        email: '',
        phone: '',
    };

    const requiredFieldMessage = 'Verplicht veld';
    const emailFieldMessage = 'Ongeldig e-mailadres';
    const minimumLengthMessage = 'Ongeldig telefoonnummer';

    const validationSchemaObject = yup.object().shape({
        club: yup.string().required(requiredFieldMessage),
        contact: yup.string().required(requiredFieldMessage),
        email: yup.string().email(emailFieldMessage).required(requiredFieldMessage),
        phone: yup.string()
            .test('len', minimumLengthMessage, val => {
                if (val) {
                    return val.length >= 8;
                }

                return false;
            }),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  dirty,
              }) => (
                <form onSubmit={ handleSubmit }>
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 6 }>
                            <Field
                                name="club"
                                label="Club"
                                component={ TextInput }
                                errorMessage={ errors['club'] }
                                touched={ touched['club'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Field
                                name="contact"
                                label="Contactpersoon"
                                component={ TextInput }
                                errorMessage={ errors['contact'] }
                                touched={ touched['contact'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Field
                                name="email"
                                label="E-mailadres"
                                component={ TextInput }
                                errorMessage={ errors['email'] }
                                touched={ touched['email'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Field
                                name="phone"
                                label="Telefoonnummer"
                                component={ TextInput }
                                errorMessage={ errors['phone'] }
                                touched={ touched['phone'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                variant="outlined"
                                className={ classes.input }
                            />
                        </Grid>

                        <Grid item xs={ 6 }>
                            <Box mt={ 2 }>
                                <Button
                                    type="submit"
                                    size="large"
                                    disabled={ ! dirty || ! isValid }
                                    color="primary"
                                    variant="contained"
                                    className={ classes.button }
                                >
                                    Vraag aan
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

InquiryForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default InquiryForm;
