import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import ExerciseForm from 'components/ExerciseForm';
import styles from './ClubExercisesCreate.styles';
import { CLUB_EXERCISES, PROFILE } from 'store/constants';
import { images } from 'config/images';
import PremiumWall from 'components/PremiumWall';

const ClubExercisesCreate = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const history = useHistory();
    const loading = useSelector(state => state.clubExercise.loading);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const profileFetched = useSelector(state => state.profile.fetched);
    const roles = useSelector(state => state.profile.roles);
    const isClubmanager = roles.includes('clubmanager');

    const error = useSelector(state => state.clubExercise.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.clubExercise.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'videos.create.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'exercises.create.title' });
    const descriptionLabel = useIntl().formatMessage({ id: 'exercises.label.description' });
    const difficultyLabel = useIntl().formatMessage({ id: 'exercises.label.difficulty' });
    const explanationLabel = useIntl().formatMessage({ id: 'exercises.label.explanation' });
    const extensionLabel = useIntl().formatMessage({ id: 'exercises.label.extension' });
    const imageLabel = useIntl().formatMessage({ id: 'exercises.label.image' });
    const instructionLabel = useIntl().formatMessage({ id: 'exercises.label.instruction' });;
    const titleLabel = useIntl().formatMessage({ id: 'exercises.label.title' });
    const videoLabel = useIntl().formatMessage({ id: 'exercises.label.video' });
    const submitButtonText = useIntl().formatMessage({ id: 'exercises.button.save' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const onSubmit = values => {
        const payload = {
            ...values,
            clubOnly: 1,
        }

        dispatch(CLUB_EXERCISES.CREATE.trigger(payload));

        return true;
    };

    useEffect(() => {
        dispatch(PROFILE.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/club/exercises/${created}/edit`)
        }
    }, [created, createdMessage, history])

    return (
        <ContentContainer title={ pageTitle } backButton>
            <PremiumWall
                isProfileFetched={ profileFetched }
                isPremium={ isClubmanager || isAdmin }
                premiumImage={ images.premium.exercise.default }
            >
                <LoadingBackdrop open={ loading } message={ loadingMessage } />

                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ExerciseForm
                                descriptionLabel={ descriptionLabel }
                                difficultyLabel={ difficultyLabel }
                                explanationLabel={ explanationLabel }
                                extensionLabel={ extensionLabel }
                                imageLabel={ imageLabel }
                                instructionLabel={ instructionLabel }
                                titleLabel={ titleLabel }
                                videoLabel={ videoLabel }
                                submitButtonText={ submitButtonText }
                                showVideoField={ isAdmin }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidFileSizeMessage={ invalidFileSizeMessage }
                                invalidFileTypeMessage={ invalidFileTypeMessage }
                                onSubmit={ onSubmit }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </PremiumWall>
        </ContentContainer>
    );
};

export default ClubExercisesCreate;
