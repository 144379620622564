import { combineReducers } from 'redux';

import accountReducer from './AccountReducer';
import assessmentReducer from './AssessmentReducer';
import authReducer from './AuthReducer';
import classificationReducer from './ClassificationReducer';
import classificationTeamReducer from './ClassificationTeamReducer';
import clubReducer from './ClubReducer';
import clubAccessRequestReducer from './ClubAccessRequestReducer';
import clubExerciseReducer from './ClubExerciseReducer';
import clubPlayerReducer from './ClubPlayerReducer';
import clubPlayerAssessmentReducer from './ClubPlayerAssessmentReducer';
import clubTeamReducer from './ClubTeamReducer';
import clubTrainingReducer from './ClubTrainingReducer';
import clubUserReducer from './ClubUserReducer';
import criterionReducer from './CriterionReducer';
import divisionReducer from './DivisionReducer';
import evaluationReducer from './EvaluationReducer';
import eventTypeReducer from './EventTypeReducer';
import eventReducer from './EventReducer';
import exerciseReducer from './ExerciseReducer';
import exerciseLabelReducer from './ExerciseLabelReducer';
import exerciseTagReducer from './ExerciseTagReducer';
import feedbackReducer from './FeedbackReducer';
import internalTrainerReducer from './InternalTrainerReducer';
import languageReducer from './LanguageReducer';
import matchReducer from './MatchReducer';
import newsReducer from './NewsReducer';
import occasionReducer from './OccasionReducer';
import orderReducer from './OrderReducer';
import periodReducer from './PeriodReducer';
import persistReducer from './PersistReducer';
import playerReducer from './PlayerReducer';
import playercardReducer from './PlayercardReducer';
import premiumReducer from './PremiumReducer';
import profileReducer from './ProfileReducer';
import surveyAnswerReducer from './SurveyAnswerReducer';
import surveyQuestionReducer from './SurveyQuestionReducer';
import surveyReducer from './SurveyReducer';
import surveyTopicReducer from './SurveyTopicReducer';
import tagReducer from './TagReducer';
import teamAdminReducer from './TeamAdminReducer';
import teamEvaluationReducer from './TeamEvaluationReducer';
import teamPlayerReducer from './TeamPlayerReducer';
import trainingReducer from './TrainingReducer';
import userReducer from './UserReducer';
import videoReducer from './VideoReducer';

const rootReducer = combineReducers({
    account: accountReducer,
    assessment: assessmentReducer,
    auth: authReducer,
    classification: classificationReducer,
    classificationTeam: classificationTeamReducer,
    club: clubReducer,
    clubAccessRequest: clubAccessRequestReducer,
    clubExercise: clubExerciseReducer,
    clubPlayer: clubPlayerReducer,
    clubPlayerAssessment: clubPlayerAssessmentReducer,
    clubTeam: clubTeamReducer,
    clubTraining: clubTrainingReducer,
    clubUser: clubUserReducer,
    criterion: criterionReducer,
    division: divisionReducer,
    evaluation: evaluationReducer,
    eventType: eventTypeReducer,
    event: eventReducer,
    exercise: exerciseReducer,
    exerciseLabel: exerciseLabelReducer,
    exerciseTag: exerciseTagReducer,
    feedback: feedbackReducer,
    internalTrainer: internalTrainerReducer,
    language: languageReducer,
    match: matchReducer,
    news: newsReducer,
    occasion: occasionReducer,
    order: orderReducer,
    period: periodReducer,
    persist: persistReducer,
    player: playerReducer,
    playercard: playercardReducer,
    premium: premiumReducer,
    profile: profileReducer,
    survey: surveyReducer,
    surveyAnswer: surveyAnswerReducer,
    surveyQuestion: surveyQuestionReducer,
    surveyTopic: surveyTopicReducer,
    tag: tagReducer,
    teamAdmin: teamAdminReducer,
    teamEvaluation: teamEvaluationReducer,
    teamPlayer: teamPlayerReducer,
    training: trainingReducer,
    user: userReducer,
    video: videoReducer,
});

export default rootReducer;
