import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import VideoForm from 'components/VideoForm';
import styles from './VideosEdit.styles';
import { VIDEOS, TAGS } from 'store/constants';

const VideosEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetchedVideos = useSelector(state => state.video.fetched);
    const fetchedTags = useSelector(state => state.tag.fetched);
    const loadingVideos = useSelector(state => state.video.loading);
    const loadingTags = useSelector(state => state.tag.loading);
    const classes = styles();

    const error = useSelector(state => state.video.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.video.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'videos.update.loading' });

    const tags = useSelector(state => state.tag.tags);
    const videos = useSelector(state => state.video.videos);
    const video = videos.filter(object => object.id === parseInt(params.videoId))[0];

    const title = video ? video.title : '';
    const titleLabel = useIntl().formatMessage({ id: 'videos.label.title' });
    const descriptionLabel = useIntl().formatMessage({ id: 'videos.label.description' });
    const videoLabel = useIntl().formatMessage({ id: 'videos.label.video' });
    const tagsLabel = useIntl().formatMessage({ id: 'videos.label.tags' });
    const submitButtonText = useIntl().formatMessage({ id: 'videos.button.update' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const getSelectedTags = () => {
        return video ? video.tags.data : [];
    }

    const getInitialValues = () => {
        if (video) {
            return {
                title: video.title,
                description: video.description,
            }
        }

        return {};
    }

    const handleAddTag = tag => {
        dispatch(VIDEOS.TAG.UPDATE.trigger({ video, tag }));
    }

    const handleDeleteTag = tag => {
        dispatch(VIDEOS.TAG.DELETE.trigger({ video, tag }));
    }

    const onTagChange = (event, newTag, action, tag) => {
        if (action === 'remove-option') {
            handleDeleteTag(tag.option);
        } else if (action === 'select-option') {
            handleAddTag(tag.option);
        }
    }

    const onSubmit = values => {
        dispatch(VIDEOS.UPDATE.trigger({
            id: params.videoId,
            values
        }));

        return true
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    useEffect(() => {
        if (fetchedVideos === false) {
            dispatch(VIDEOS.FETCH.trigger());
        }

        if (fetchedTags === false) {
            dispatch(TAGS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: VIDEOS.CLEAN });
            dispatch({ type: TAGS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ title } backButton>
            { fetchedVideos && fetchedTags ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <VideoForm
                                initialValues={ getInitialValues() }
                                titleLabel={ titleLabel }
                                descriptionLabel={ descriptionLabel }
                                videoLabel={ videoLabel }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidFileSizeMessage={ invalidFileSizeMessage }
                                invalidFileTypeMessage={ invalidFileTypeMessage}
                                onSubmit={ onSubmit }
                                editMode
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <Autocomplete
                                options={ tags }
                                getOptionLabel={ tag => tag.name }
                                onChange={ onTagChange }
                                multiple={ true }
                                value={ getSelectedTags() }
                                getOptionSelected={ (a, b) => a.id === b.id }
                                renderInput={ (params) => <TextField {...params} label={ tagsLabel } variant="outlined" /> }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ loadingVideos || loadingTags } message={ fetchedVideos && fetchedTags ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default VideosEdit;
