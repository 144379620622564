import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import ActionList from 'components/ActionList';
import BoxHeading from 'components/BoxHeading';
import ChipCollection from 'components/ChipCollection';
import ContentBox from 'components/ContentBox';

const EventPlayersBlock = props => {
    const { onDelete, players } = props;
    const [editMode, setEditMode] = useState(false);
    const chips = players.map(player => {
        return {
            id: player.id,
            label: player.full_name,
        }
    });
    const activePlayers = players.map(player => (
        {
            target: `/players/${player.id}`,
            label: `${player.first_name} ${player.last_name}`,
        }
    )).sort();

    const deletePlayer = playerId => {
        onDelete(playerId);
    }

    return (
        <ContentBox>
            <BoxHeading
                title="Spelers"
                icon={ editMode ? 'clear' : 'edit' }
                onClick={ () => setEditMode(! editMode) }
            />

            <Box mt={ 2 }>
                { editMode ? (
                    <ChipCollection
                        items={ chips }
                        onDelete={ deletePlayer }
                    />
                ) : (
                    <ActionList
                        values={ activePlayers }
                    />
                ) }
            </Box>
        </ContentBox>
    );
};

EventPlayersBlock.propTypes = {
    onDelete: PropTypes.func.isRequired,
    players: PropTypes.array.isRequired,
}

export default EventPlayersBlock;