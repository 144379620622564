import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CLUB } from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import FormDialog from 'components/FormDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import ClubPlayerImportForm from 'contents/forms/ClubPlayerImportForm';
import SelectForm from 'contents/forms/SelectForm';
import ClubPlayersTable from 'contents/tables/ClubPlayersTable';

const ClubPlayers = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const clubId = parseInt(params.clubId);
    const isAdmin = useSelector(state => state.persist.isAdmin);
    const players = useSelector(state => state.clubPlayer.players);
    const teams = useSelector(state => state.clubTeam.teams);
    const reducerClubId = useSelector(state => state.clubPlayer.clubId);
    const error = useSelector(state => state.clubPlayer.error);
    const created = useSelector(state => state.clubPlayer.created);
    const updated = useSelector(state => state.clubPlayer.updated);
    const deleted = useSelector(state => state.clubPlayer.deleted);
    const errorImport = useSelector(state => state.clubPlayer.errorImport);
    const isImporting = useSelector(state => state.clubPlayer.isImporting);
    const importSuccess = useSelector(state => state.clubPlayer.imported)
    const fetched = clubId === reducerClubId;
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [teamDialogOpen, setTeamDialogOpen] = useState(false);
    const [activePlayer, setActivePlayer] = useState({});

    const teamOptions = teams.map(team => {
        return {
            label: team?.name,
            value: team?.id,
        }
    });

    const onCreate = data => {
        return new Promise((resolve) => {
            const values = {
                uuid: data.uuid,
                name: data.name,
                goalkeeper: !! data?.goalkeeper,
            };

            dispatch(CLUB.PLAYER.CREATE.trigger({
                clubId,
                values,
            }))

            resolve();
        })
    }

    const onUpdate = data => {
        return new Promise((resolve) => {
            const playerId = data.id;
            const values = {
                goalkeeper: data.goalkeeper,
                name: data.name,
                uuid: data.uuid,
            }

            dispatch(CLUB.PLAYER.UPDATE.trigger({
                clubId,
                playerId,
                values,
            }))

            resolve();
        })
    };

    const onDelete = data => {
        return new Promise((resolve) => {
            dispatch(CLUB.PLAYER.DELETE.trigger({
                clubId,
                playerId: data.id,
            }));

            resolve();
        })
    };

    const openTeamDialog = player => {
        setActivePlayer(player);
        setTeamDialogOpen(true);
    }

    const closeTeamDialog = () => {
        setTeamDialogOpen(false);
        setActivePlayer({});
    }

    const onPlayerImport = values => {
        setImportDialogOpen(false);

        dispatch(CLUB.PLAYER.IMPORT.trigger({
            clubId,
            values,
        }));
    }

    const addPlayerToTeam = values => {
        const payload = {
            clubId,
            playerId: activePlayer.id,
            teamId: values.selected_id,
        }

        dispatch(CLUB.TEAM.PLAYER.UPDATE.trigger(payload));
        closeTeamDialog();
    }

    useEffect(() => {
        if (! fetched) {
            dispatch(CLUB.PLAYER.FETCH.trigger(clubId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId]);

    useEffect(() => {
        if (! fetched) {
            dispatch(CLUB.TEAM.FETCH.trigger(clubId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            toast.success('Speler is aangemaakt');
        }
    }, [created]);

    useEffect(() => {
        if (updated) {
            toast.success('Speler is aangepast');
        }
    }, [updated]);

    useEffect(() => {
        if (deleted) {
            toast.success('Speler is verwijderd');
        }
    }, [deleted]);

    useEffect(() => {
        if (errorImport) {
            toast.error('Import mislukt: er zit een fout in het bestand');
        }
    }, [errorImport]);

    useEffect(() => {
        if (importSuccess) {
            toast.success('Importeren voltooid');
        }
    }, [importSuccess]);

    return (
        <ContentContainer title="Club: spelers" backButton>
            { fetched ? (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        { isImporting && (
                            <Box mb={ 3 }>
                                <LoadingIndicator message="Bezig met verwerken van CSV-bestand" />
                            </Box>
                        )}

                        <ClubPlayersTable
                            data={ players }
                            isAdmin={ isAdmin }
                            onAddToTeamButtonClick={ openTeamDialog }
                            onCreate={ onCreate }
                            onDelete={ onDelete }
                            onImportButtonClick={ () => setImportDialogOpen(true) }
                            onUpdate={ onUpdate }
                        />

                        <FormDialog
                            open={ importDialogOpen }
                            onClose={ () => setImportDialogOpen(false) }
                            title="Spelers importeren"
                        >
                            <ClubPlayerImportForm
                                onSubmit={ onPlayerImport }
                            />
                        </FormDialog>

                        <FormDialog
                            open={ teamDialogOpen }
                            onClose={ closeTeamDialog }
                            title={ activePlayer?.name || 'Speler' }
                        >
                            <SelectForm
                                label="Team"
                                onSubmit={ addPlayerToTeam }
                                options={ teamOptions }
                                submitButtonText="Voeg toe"
                            />
                        </FormDialog>
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            ) }
        </ContentContainer>
    );
};

export default ClubPlayers;