import { useState } from 'react';

import Alert from '@material-ui/lab/Alert';

import InquiryForm from 'components/InquiryForm';
import StaticHeader from 'components/StaticHeader';
import { Api } from 'config/api';
import { images } from 'config/images';
import { guestPost } from 'utilities/api';

const Inquiry = () => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async values => {
        setError(null);
        setSuccess(null);

        try {
            await guestPost(Api.requests.inquiries.index, values);

            setSuccess(true);

            window.lintrk('track', { conversion_id: 6287585 });
        } catch (error) {
            setError(true);
        }

        return true;
    }

    return (
        <>
            <StaticHeader />

            <main role="main" className="sign-up">

                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.register.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.register.right.default } alt="Splash" />
                    </div>
                </figure>

                <section className="sign-up gap-both">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-9 col-xs-12 mx-auto no-gutters">
                                { success ? (
                                    <Alert severity="success">
                                        De aanraag voor een try-out is verstuurd.<br />
                                        Wij nemen zo spoedig mogelijk contact met u op.
                                    </Alert>
                                ) : (
                                    <>
                                        <article className="info">
                                            <h1>Gratis try-out</h1>

                                            <p>
                                                Wil je ervaren wat het Go4Hockey platform voor jouw hockeyclub kan betekenen?
                                                Via het onderstaande formulier kan jouw club een gratis try-out voor
                                                het huidige seizoen aanvragen.
                                            </p>

                                            { error && (
                                                <Alert severity="error">
                                                    Oeps... er is een fout opgetreden.<br />
                                                    Zijn alle velden correct ingevuld?
                                                </Alert>
                                            ) }
                                        </article>

                                        <InquiryForm
                                            onSubmit={ handleSubmit }
                                        />
                                    </>
                                ) }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Inquiry;
