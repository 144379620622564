import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/TextInput';
import FileInput from 'components/FileInput';
import SelectInput from 'components/SelectInput';

const EventForm = props => {
    const {
        clubs,
        editMode,
        eventTypes,
        initialValues,
        onSubmit,
        submitButtonText,
    } = props;
    const FILE_SIZE = 2 * 1024 * 1024;
    const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];
    const requiredFieldMessage = 'Verplicht veld';
    const invalidNumberMessage = 'Ongeldige waarde';
    const invalidFileSizeMessage = 'De afbeelding is te groot';
    const invalidFileTypeMessage = 'De afbeelding heeft een ongeldig bestandsformaat';
    const clubOptions = clubs.map(club => {
        return { value: club.id, label: club.name }
    });
    const eventTypeOptions = eventTypes.map(eventType => {
        return { value: eventType.id, label: eventType.name }
    });


    const defaultValidationSchema = {
        name: yup.string().max(255).required(requiredFieldMessage),
        club_id: yup.mixed().required(requiredFieldMessage),
        event_type_id: yup.mixed().required(requiredFieldMessage),
        description: yup.string().nullable(),
        explanation: yup.string().nullable(),
        price: yup.number(invalidNumberMessage).min(0).max(500).required(requiredFieldMessage),
        places: yup.number(invalidNumberMessage).min(0).max(500).required(requiredFieldMessage),
    };

    const validationSchema = editMode ? {
        ...defaultValidationSchema,
        image: yup.mixed().notRequired()
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return SUPPORTED_FORMATS.includes(value.type)
                }
            ),
    } : {
        ...defaultValidationSchema,
        image: yup.mixed().required(requiredFieldMessage)
            .test(
                'fileSize',
                invalidFileSizeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return value.size <= FILE_SIZE;
                }
            )
            .test(
                'fileFormat',
                invalidFileTypeMessage,
                value => {
                    if (! value) {
                        return true;
                    }

                    return SUPPORTED_FORMATS.includes(value.type)
                }
            ),
    }
    const validationSchemaObject = yup.object().shape(validationSchema);

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name="name"
                            label="Event"
                            component={ TextInput }
                            errorMessage={ errors['name'] }
                            touched={ touched['name'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['name'] }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="event_type_id"
                            label="Type"
                            component={ SelectInput }
                            errorMessage={ errors['event_type_id'] }
                            touched={ touched['event_type_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['event_type_id'] }
                            options={ eventTypeOptions }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="club_id"
                            label="Club"
                            component={ SelectInput }
                            errorMessage={ errors['club_id'] }
                            touched={ touched['club_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['club_id'] }
                            options={ clubOptions }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="places"
                            label="Beschikbare plaatsen"
                            component={ TextInput }
                            errorMessage={ errors['places'] }
                            touched={ touched['places'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['places'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="price"
                            label="Prijs"
                            component={ TextInput }
                            errorMessage={ errors['price'] }
                            touched={ touched['price'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['price'] }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="description"
                            label="Omschrijving"
                            component={ TextInput }
                            errorMessage={ errors['description'] }
                            touched={ touched['description'] }
                            onChange={ handleChange['description'] }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name="explanation"
                            label="Uitleg"
                            component={ TextInput }
                            errorMessage={ errors['explanation'] }
                            touched={ touched['explanation'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['explanation'] }
                            lines={ 12 }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Field
                            name="image"
                            component={ FileInput }
                            title="Afbeelding"
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors["image"] ? errors["image"] : undefined }
                            touched={ touched["image"] }
                            onBlur={ handleBlur["image"] }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            disabled={ isSubmitting }
                            type="submit"
                            variant="contained"
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

EventForm.propTypes = {
    clubs: PropTypes.array.isRequired,
    editMode: PropTypes.bool,
    eventTypes: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
};

EventForm.defaultProps = {
    initialValues: {
        name: '',
        club_id: null,
        event_type_id: null,
        description: '',
        explanation: '',
        price: '',
        places: '',
        image: undefined,
    },
    submitButtonText: 'Maak event aan',
}

export default EventForm;
