import { Fragment, useState } from 'react';

import { useIntl } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

import { images } from 'config/images';
import { guestPost } from 'utilities/api';
import { Api } from 'config/api';
import RegistrationForm from 'components/RegistrationForm';
import StaticHeader from 'components/StaticHeader';

const Register = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const pageTitle = useIntl().formatMessage({ id: 'register.title' });
    const emailLabel = useIntl().formatMessage({ id: 'register.label.email' });
    const firstNameLabel = useIntl().formatMessage({ id: 'register.label.firstName' });
    const infixLabel = useIntl().formatMessage({ id: 'register.label.infix' });
    const lastNameLabel = useIntl().formatMessage({ id: 'register.label.lastName' });
    const loginLabel = useIntl().formatMessage({ id: 'register.label.login' });
    const passwordLabel = useIntl().formatMessage({ id: 'register.label.password' });
    const passwordConfirmationLabel = useIntl().formatMessage({ id: 'register.label.passwordConfirmation' });
    const termsAndConditionsLabelText = useIntl().formatMessage({ id: 'register.label.termsAndConditions' });
    const termsAndConditionsLabel = <Link to={ '/pdf/terms-and-conditions.pdf' } target="_blank">{ termsAndConditionsLabelText }</Link>;
    const privacyPolicyLabelText = useIntl().formatMessage({ id: 'register.label.privacyPolicy' });
    const privacyPolicyLabel = <Link to={ '/pdf/privacy-policy.pdf' } target="_blank">{ privacyPolicyLabelText }</Link>;
    const submitButtonText = useIntl().formatMessage({ id: 'register.button.submit' });
    const emailFieldMessage = useIntl().formatMessage({ id: 'validation.error.email' });
    const equalFieldMessage = useIntl().formatMessage({ id: 'validation.error.equal' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const minimumLengthMessage = useIntl().formatMessage({ id: 'validation.error.minPasswordLength' });
    const accountExistsMessage = useIntl().formatMessage({ id: 'register.error.existingEmail' });
    const errorMessage = useIntl().formatMessage({ id: 'register.error.error' });
    const accountCreatedMessage = useIntl().formatMessage({ id: 'register.success' });

    const handleSubmit = async values => {
        setError(null);
        setSuccess(null);

        try {
            await guestPost(Api.requests.register, values);

            setSuccess(accountCreatedMessage);
        } catch (error) {
            const status = error.response.status ?? null;

            if (status === 409) {
                setError(accountExistsMessage);

                return true;
            }

            setError(errorMessage);

            return true;
        }
    }

    return (
        <Fragment>
            <StaticHeader />

            <main role="main" className="sign-up">

                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.register.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.register.right.default } alt="Splash" />
                    </div>
                </figure>

                <section className="sign-up gap-both">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-9 col-xs-12 mx-auto no-gutters">
                                { success ? (
                                    <Alert severity="success">{ success }</Alert>
                                ) : (
                                    <div>
                                        <article className="info">
                                            <h1>{ pageTitle }</h1>

                                            { error ? <Alert severity="error">{ error }</Alert> : null }
                                        </article>

                                        <RegistrationForm
                                            emailLabel={ emailLabel }
                                            firstNameLabel={ firstNameLabel }
                                            infixLabel={ infixLabel }
                                            lastNameLabel={ lastNameLabel }
                                            passwordLabel={ passwordLabel }
                                            passwordConfirmationLabel={ passwordConfirmationLabel }
                                            privacyPolicyLabel={ privacyPolicyLabel }
                                            submitButtonText={ submitButtonText }
                                            termsAndConditionsLabel={ termsAndConditionsLabel }
                                            minimumLengthMessage={ minimumLengthMessage }
                                            emailFieldMessage={ emailFieldMessage }
                                            equalFieldMessage={ equalFieldMessage }
                                            requiredFieldMessage={ requiredFieldMessage }
                                            onSubmit={ handleSubmit }
                                        />

                                        <Box mt={ 3 }>
                                            <Link to={ '/login' }>{ loginLabel }</Link>
                                        </Box>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default Register;
