import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './ToolbarItem.styles';

const ToolbarItem = props => {
    const { active, color, icon, onClick, tooltip } = props;
    const classes = styles();

    return (
        <Tooltip
            arrow
            className={ `${ classes.root } ${ active ? classes.active : false }` }
            onClick={ onClick }
            placement={ 'right' }
            title={ tooltip }
        >
            <IconButton aria-label="delete" className={ classes.iconButton }>
                <Icon style={ { color: color } }>{ icon }</Icon>
            </IconButton>
        </Tooltip>
    );
};

ToolbarItem.propTypes = {
    active: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
    color: PropTypes.string,
};

ToolbarItem.defaultProps = {
    color: 'black'
};

export default ToolbarItem;