import { AUTH } from '../constants';

const INITIAL_STATE = {
    loading: false,
    errorMessage: false,
    passwordSuccess: false,
    passwordError: false,
    unverified: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH.LOGIN:
            return {
                ...INITIAL_STATE,
                loading: true,
                errorMessage: false,
                unverified: false,
            };

        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case AUTH.LOGIN_FAIL:
            return {
                ...INITIAL_STATE,
                errorMessage: true,
            };

        case AUTH.UNVERIFIED:
            return {
                ...INITIAL_STATE,
                unverified: true,
            };

        case AUTH.ERROR_RESET:
            return {
                ...INITIAL_STATE,
                errorMessage: false,
            };

        case AUTH.PASSWORD.RESET:
            return {
                ...state,
                passwordSuccess: false,
                passwordError: false,
            };

        case AUTH.PASSWORD.RESET_SUCCESS:
            return {
                ...state,
                passwordSuccess: true,
            };

        case AUTH.PASSWORD.RESET_FAIL:
            return {
                ...state,
                passwordError: true,
            };

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default authReducer;