import { AUTH, TEAM } from '../constants';
import { deleteObjectWithIdFromArray } from 'utilities/array';

const INITIAL_STATE = {
    teamId: null,
    admins: [],
    
    error: false,
    unknownUser: false,
    loading: false,
    
    created: null,
    deleted: false,
};

const teamAdminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEAM.ADMIN.FETCH.TRIGGER:
        case TEAM.ADMIN.CREATE.TRIGGER:
        case TEAM.ADMIN.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }
            
        case TEAM.ADMIN.FETCH.SUCCESS:
            return {
                ...state,
                teamId: action.payload.teamId,
                admins: action.payload.admins,
            };

        case TEAM.ADMIN.CREATE.SUCCESS:
            return {
                ...state,
                admins: state.admins.concat(action.payload),
                created: action.payload.id,
            };

        case TEAM.ADMIN.DELETE.SUCCESS:
            return {
                ...state,
                admins: deleteObjectWithIdFromArray([...state.admins], action.payload),
                deleted: true,
            };

        case TEAM.ADMIN.FETCH.FAILURE:
        case TEAM.ADMIN.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case TEAM.ADMIN.CREATE.FAILURE:
            return {
                ...state,
                unknownUser: true,
            }

        case TEAM.ADMIN.FETCH.FULFILL:
        case TEAM.ADMIN.CREATE.FULFILL:
        case TEAM.ADMIN.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                unknownUser: false,
                created: false,
                deleted: false,
            }
            
        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default teamAdminReducer;