import { useRef, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text as KonvaText, Transformer } from "react-konva";

const Text = props => {
    const { draggable, fontSize, index, onDoubleClick, onSelect, onUpdate, rotation, selected, text, x, y} = props;
    const shapeRef = useRef();
    const transformRef = useRef();

    const handleDoubleClick = () => onDoubleClick(index);

    const handleOnClick = () => {
        onSelect(index);
    };

    useEffect(() => {
        if (selected) {
            transformRef.current.setNode(shapeRef.current);
            transformRef.current.getLayer().batchDraw();
        }
    }, [selected]);

    return (
        <Fragment>
            <KonvaText
                draggable={ draggable }
                fontSize={ fontSize }
                onClick={ handleOnClick }
                onDblClick={ handleDoubleClick }
                onDragEnd={ (e) => {
                    onUpdate(index, {
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                } }
                onTransformEnd={ (e) => {
                    const newFontSize = fontSize * e.target.scaleX();

                    onUpdate(index, {
                        fontSize: newFontSize,
                        rotation: e.target.rotation(),
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                } }
                ref={ shapeRef }
                rotation={ rotation }
                text={ text }
                x={ x }
                y={ y }
            />

            { selected && (
                <Transformer
                    ref={ transformRef }
                    boundBoxFunc={ (oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }

                        return newBox;
                    } }
                />
            ) }
        </Fragment>
    );
};

Text.propTypes = {
    draggable: PropTypes.bool,
    fontSize: PropTypes.number,
    index: PropTypes.number.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    rotation: PropTypes.number,
    selected: PropTypes.bool,
    text: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
};

Text.defaultProps = {
    fontSize: 18,
    rotation: 0,
};

export default Text;