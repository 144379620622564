import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AssessmentQuestion from 'components/AssessmentQuestion';
import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationArrows from 'components/PaginationArrows';
import { TEAM } from 'store/constants';
import { downloadFile } from 'utilities/api';
import styles from './TeamEvaluationShow.styles';

const TeamEvaluationShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const classes = styles();
    const evaluationId = parseInt(params.evaluationId);
    const teamId = parseInt(params.teamId);
    const evaluation = useSelector(state => state.teamEvaluation.active);
    const error = useSelector(state => state.teamEvaluation.error);
    const accessToken = useSelector(state => state.persist.accessToken);
    const fetched = evaluation?.id === evaluationId;
    const [isLoading, setLoading] = useState(false);
    const structure = evaluation?.survey?.data?.structure?.data || [];
    const questions = structure
        .map(topic => topic?.questions?.data)
        .reduce((accumulator, item) => [...accumulator, ...item], []);
    const assessments = evaluation?.assessments?.data || [];
    const [questionKey, setQuestionKey] = useState(0);
    const question = questions[questionKey];

    const toPreviousQuestion = () => {
        if (questionKey > 0) {
            setQuestionKey(key => key - 1);
        }
    }

    const toNextQuestion = () => {
        if (questionKey < questions.length - 1) {
            setQuestionKey(key => key + 1);
        }
    }

    const downloadEvaluation = async () => {
        const server = process.env.REACT_APP_API_SERVER;
        const downloadUrl = `${server}/api/teams/${evaluation.team.data.id}/evaluations/${evaluation.id}/pdf`;
        const fileName = `Evaluatie ${evaluation?.team?.data?.name}.pdf`;

        setLoading(true);

        await downloadFile(accessToken, downloadUrl, fileName);

        setLoading(false);
    }

    const renderAnswers = () => {
        return assessments.map((assessment, key) => {
            const answers = assessment.answers.data.filter(answer => answer.survey_question_id === question.id);

            return (
                <Box
                    key={ `player-answer-${key}`}
                    mb={ 2 }
                >
                    <Paper
                        className={ classes.block }
                        elevation={ 2 }
                    >
                        <AssessmentQuestion
                            answers={ answers }
                            heading={ assessment.player_name }
                        />
                    </Paper>
                </Box>
            )
        });
    }

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (! fetched) {
            dispatch(TEAM.EVALUATION.SHOW.trigger({
                teamId,
                evaluationId,
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetched, evaluationId, teamId]);

    return (
        <ContentContainer title="Evaluatie bekijken (team mode)" backButton>
            { fetched ? (
                <>
                    <PaginationArrows
                        previous={ questionKey > 0 ? toPreviousQuestion : null }
                        next={ questionKey < questions.length - 1 ? toNextQuestion : null }
                    />

                    <Box mb={ 2 }>
                        <Paper
                            className={ classes.block }
                            elevation={ 2 }
                        >
                            <Typography variant="h5" color="primary">
                                { question.question }
                            </Typography>
                        </Paper>
                    </Box>

                    { renderAnswers() }

                    <PaginationArrows
                        previous={ questionKey > 0 ? toPreviousQuestion : null }
                        next={ questionKey < questions.length - 1 ? toNextQuestion : null }
                    >
                        <Button
                            color="primary"
                            onClick={ downloadEvaluation }
                            variant="contained"
                        >
                            Download team evaluatie
                        </Button>
                    </PaginationArrows>
                </>
            ) : (
                <LoadingIndicator />
            ) }

            <LoadingBackdrop
                open={ isLoading }
                message="Bezig met ophalen van PDF"
            />
        </ContentContainer>
    );
};

export default TeamEvaluationShow;