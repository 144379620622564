import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { SURVEYS } from '../constants';
import {
    storeRequest,
    updateRequest,
    deleteRequest
} from '../api';

export function* createFlow() {
    while (true) {
        const request = yield take(SURVEYS.TOPICS.CREATE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const payload = request.payload.values;
            const endpoint = Api.requests.surveys.topics.store(surveyId);

            yield call(storeRequest, endpoint, payload);
            yield put(SURVEYS.SHOW.trigger(surveyId));
            yield put(SURVEYS.TOPICS.CREATE.success());
        } catch (error) {
            yield put(SURVEYS.TOPICS.CREATE.failure());
        } finally {
            yield put(SURVEYS.TOPICS.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(SURVEYS.TOPICS.UPDATE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const topicId = request.payload.topicId;
            const payload = request.payload.values;
            const endpoint = Api.requests.surveys.topics.update(surveyId, topicId);

            yield call(updateRequest, endpoint, payload);
            yield put(SURVEYS.SHOW.trigger(surveyId));
            yield put(SURVEYS.TOPICS.UPDATE.success());
        } catch (error) {
            yield put(SURVEYS.TOPICS.UPDATE.failure());
        } finally {
            yield put(SURVEYS.TOPICS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(SURVEYS.TOPICS.DELETE.TRIGGER);

        try {
            const surveyId = request.payload.surveyId;
            const topicId = request.payload.topicId;
            const endpoint = Api.requests.surveys.topics.delete(surveyId, topicId);

            yield call(deleteRequest, endpoint);
            yield put(SURVEYS.SHOW.trigger(surveyId));
            yield put(SURVEYS.TOPICS.DELETE.success());
        } catch (error) {
            yield put(SURVEYS.TOPICS.DELETE.failure());
        } finally {
            yield put(SURVEYS.TOPICS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        createFlow(),
        updateFlow(),
        deleteFlow(),
    ]);
}