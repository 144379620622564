import { AUTH, CLUB } from '../constants';
import { deleteObjectWithIdFromArray } from 'utilities/array';

const INITIAL_STATE = {
    accessRequests: [],
    clubId: null,

    fetched: false,
    error: false,
    loading: false,
    
    updated: false,
    deleted: false,
};

const ClubAccessRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB.ACCESS_REQUEST.FETCH.TRIGGER:
        case CLUB.ACCESS_REQUEST.UPDATE.TRIGGER:
        case CLUB.ACCESS_REQUEST.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLUB.ACCESS_REQUEST.FETCH.SUCCESS:
            return {
                ...state,
                accessRequests: action.payload.accessRequests,
                clubId: action.payload.clubId,
                fetched: true,
            };

        case CLUB.ACCESS_REQUEST.UPDATE.SUCCESS:
            return {
                ...state,
                accessRequests: deleteObjectWithIdFromArray([...state.accessRequests], action.payload),
                updated: true,
            };

        case CLUB.ACCESS_REQUEST.DELETE.SUCCESS:
            return {
                ...state,
                accessRequests: deleteObjectWithIdFromArray([...state.accessRequests], action.payload),
                deleted: true,
            };

        case CLUB.ACCESS_REQUEST.FETCH.FAILURE:
        case CLUB.ACCESS_REQUEST.UPDATE.FAILURE:
        case CLUB.ACCESS_REQUEST.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLUB.ACCESS_REQUEST.FETCH.FULFILL:
        case CLUB.ACCESS_REQUEST.UPDATE.FULFILL:
        case CLUB.ACCESS_REQUEST.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                updated: false,
                deleted: false,
            }
            
        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default ClubAccessRequestReducer;