import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    label: {
        fontWeight: 'bold',
        marginBottom: 0,
    },

    list: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: theme.spacing(2),
    },

    listItem: {
        marginLeft: theme.spacing(1),
    },
}));

export default styles;