import PropTypes from 'prop-types';

import { Arrow as KonvaArrow } from "react-konva";

const Arrow = props => {
    const { color, dash, draggable, endX, endY, index, onDoubleClick, onUpdate, startX, startY } = props;
    const points = [startX, startY, endX, endY];

    const handleDoubleClick = () => onDoubleClick(index);

    const getDashType = () => {
        if (dash === 'dashed') {
            return [3, 5];
        }
        else if (dash === 'broken') {
            return [12, 8, 4, 8];
        }

        return [];
    };

    const getColor = () => {
        if (color === 'blue') {
            return 'blue';
        }
        else if (color === 'orange') {
            return '#F07D00';
        }

        return 'black';
    };

    return (
        <KonvaArrow
            dash={ getDashType() }
            draggable={ draggable }
            fill={ getColor() }
            onDragEnd={ (e) => {
                onUpdate(index, {
                    endX: endX + e.target.x(),
                    endY: endY + e.target.y(),
                    startX: startX + e.target.x(),
                    startY: startY + e.target.y(),
                });
            } }
            onDblClick={ handleDoubleClick }
            points={ points }
            stroke={ getColor() }
            strokeWidth={ 3 }
        />
    )
};

Arrow.propTypes = {
    color: PropTypes.oneOf(['black', 'blue', 'orange']),
    draggable: PropTypes.bool,
    dash: PropTypes.oneOf(['dashed', 'broken', 'solid']),
    endX: PropTypes.number.isRequired,
    endY: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    startX: PropTypes.number.isRequired,
    startY: PropTypes.number.isRequired,
};

Arrow.defaultPropTypes = {
    color: 'orange',
    dash: 'solid',
};

export default Arrow;