import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import OrderOverview from './OrderOverview';
import OrderShow from './OrderShow';

const Orders = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ path }
                component={ OrderOverview }
            />
            <Route
                exact
                path={ `${path}/:orderId` }
                component={ OrderShow }
            />
        </Switch>
    );
};

export default Orders;