import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import NewsForm from 'components/NewsForm';
import styles from './NewsCreate.styles';
import { NEWS } from 'store/constants';

const NewsCreate = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const history = useHistory();
    const loading = useSelector(state => state.news.loading);

    const error = useSelector(state => state.news.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.news.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'news.create.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'news.create.title' });
    const titleLabel = useIntl().formatMessage({ id: 'news.label.title' });
    const messageLabel = useIntl().formatMessage({ id: 'news.label.message' });
    const imageLabel = useIntl().formatMessage({ id: 'news.label.image' });
    const submitButtonText = useIntl().formatMessage({ id: 'news.button.save' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const onSubmit = values => {
        dispatch(NEWS.CREATE.trigger(values));

        return true
    };

    useEffect(() => {
        return function cleanup() {
            dispatch({ type: NEWS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/news/${created}/edit`)
        }
    }, [created, createdMessage, history])

    return (
        <ContentContainer title={ pageTitle } backButton>
            <LoadingBackdrop open={ loading } message={ loadingMessage } />

            <Grid container spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <Paper elevation={ 2 } className={ classes.block }>
                        <NewsForm
                            titleLabel={ titleLabel }
                            messageLabel={ messageLabel }
                            imageLabel={ imageLabel }
                            submitButtonText={ submitButtonText }
                            requiredFieldMessage={ requiredFieldMessage }
                            invalidFileSizeMessage={ invalidFileSizeMessage }
                            invalidFileTypeMessage={ invalidFileTypeMessage}
                            onSubmit={ onSubmit }
                        />
                    </Paper>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

export default NewsCreate;
