import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import DatePickerInput from '../DatePickerInput';
import CheckboxInput from '../CheckboxInput';

const ClubForm = props => {
    const {
        startDateLabel,
        endDateLabel,
        internalLabel,
        submitButtonText,
        requiredFieldMessage,
        initialValues,
        onSubmit
    } = props;

    const validationSchemaObject = yup.object().shape({
        start_date: yup.string().max(255).required(requiredFieldMessage),
        end_date: yup.string().nullable().required(requiredFieldMessage),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <form onSubmit={ handleSubmit }>
                    <Box>
                        <Field
                            name={ 'start_date' }
                            label={ startDateLabel }
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['start_date'] }
                            touched={ touched['start_date'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'end_date' }
                            label={ endDateLabel }
                            component={ DatePickerInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['end_date'] }
                            touched={ touched['end_date'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Field
                            name={ 'internal' }
                            label={ internalLabel }
                            component={ CheckboxInput }
                            setFieldValue={ setFieldValue }
                            errorMessage={ errors['internal'] }
                            touched={ touched['internal'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                        />
                    </Box>

                    <Box mt={ 1 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};


ClubForm.propTypes = {
    startDateLabel: PropTypes.string,
    endDateLabel: PropTypes.string,
    internalLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    invalidFileSizeMessage: PropTypes.string,
    invalidFileTypeMessage: PropTypes.string,
    editMode: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

ClubForm.defaultProps = {
    startDateLabel: 'start date',
    endDateLabel: 'end date',
    internalLabel: 'internal',
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    invalidFileSizeMessage: 'invalid file size',
    invalidFileTypeMessage: 'invalid file type',
    initialValues: {
        start_date: '',
        end_date: '',
        internal: false,
    }
}

export default ClubForm;
