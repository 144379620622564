import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import AppsCatchAll from './AppsCatchAll';

const Apps = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={ `${path}/:club` }
                component={ AppsCatchAll }
            />

            <Redirect from={ path } to={ '/' } />
        </Switch>
    );
};

export default Apps;