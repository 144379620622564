import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutocompleteInput = ({ field, ...props }) => {
    const { label, errorMessage, touched, options, setFieldValue, key } = props;
    const { name, onBlur } = field;

    const handleChange = (event, option, action) => {
        const newValue = option ? option.value : '';

        setFieldValue(name, newValue);
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
                key={ key }
                fullWidth
                options={ options }
                getOptionLabel={ option => option.label }
                getOptionSelected={ (option, value) => option.value === value.value }
                onChange={ handleChange }
                onBlur={ onBlur }
                renderInput={(params) => <TextField {...params} label={ label } />}
            />

            { touched && errorMessage ? (
                <FormHelperText error={ true }>{ errorMessage  }</FormHelperText>
            ) : undefined }
        </FormControl>
    );
};

AutocompleteInput.propTypes = {
    field: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    touched: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    setFieldValue: PropTypes.func.isRequired,
};

AutocompleteInput.defaultProps = {
    options: [],
}

export default AutocompleteInput;
