import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    avatar: {
        width: 40,
        height: 40,
        cursor: 'pointer'
    },

    openAvatar: {
        backgroundColor: theme.palette.primary.main
    },

    selectedAvatar: {
        backgroundColor: theme.palette.success.main
    },

    errorAvatar: {
        backgroundColor: theme.palette.error.main
    },
}));

export default styles;