import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AssessmentQuestion from 'components/AssessmentQuestion';
import styles from './AssessmentTopic.styles';

const AssessmentTopic = props => {
    const { answers, questions, topic } = props;
    const classes = styles();

    const renderQuestions = () => {
        return questions.map((question, i) => {
            const answersToQuestion = answers.filter(answer => answer.survey_question_id === question.id);
            const isLastQuestion = questions.length - 1 === i;

            if (answersToQuestion.length === 0) {
                return null;
            }

            return (
                <React.Fragment
                    key={ `assessment-question-${question.id}`}
                >
                    <AssessmentQuestion
                        answers={ answersToQuestion }
                        heading={ question.question }
                    />

                    { ! isLastQuestion && (
                        <Box mt={ 2 } mb={ 1 }>
                            <Divider />
                        </Box>
                    ) }
                </React.Fragment>
            )
        });
    }

    return (
        <Paper elevation={ 2 } className={ classes.block }>
            <Box mb={ 2 }>
                <Typography variant="h5" color="primary">
                    { topic }
                </Typography>
            </Box>

            { renderQuestions() }
        </Paper>
    );
};

AssessmentTopic.propTypes = {
    answers: PropTypes.array.isRequired,
    questions: PropTypes.array.isRequired,
    topic: PropTypes.string.isRequired,
};

export default AssessmentTopic;
