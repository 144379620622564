import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const CheckboxGroupInput = props => {
    const { field, heading, lg, md, options, setFieldValue, sm, xl, xs } = props;
    const { name, value } = field;
    const [active, setActive] = useState(value || []);

    const handleToggle = event => {
        const eventValue = event?.target?.value;
        const exists = active.includes(eventValue);
        let newActive = [];

        if (exists) {
            newActive = active.filter(item => item !== eventValue);
        }
        else {
            newActive = [
                ...active,
                eventValue
            ];
        }

        setActive(() => newActive);
        setFieldValue(name, newActive)
    }

    const renderOptions = () => {
        let i = 0;

        return options.map(option => {
            i++;

            return (
                <Grid
                    item
                    key={ `checkbox-${i}` }
                    lg={ lg }
                    md={ md }
                    sm={ sm }
                    xs={ xs }
                    xl={ xl }
                >
                    <Checkbox
                        checked={ active.includes(`${option.value}`) }
                        onChange={ handleToggle }
                        name={ `${name}[${i}]` }
                        color="primary"
                        value={ option.value }
                    />

                    { option.label }
                </Grid>
            )
        })
    }

    return (
        <>
            { heading && (
                <Box mb={ 1 }>
                    <Typography variant="h6" align="left">
                        { heading }
                    </Typography>
                </Box>
            )}

            <Grid
                container
                direction="row"
            >
                { renderOptions() }
            </Grid>
        </>
    );
};

CheckboxGroupInput.propTypes = {
    field: PropTypes.object.isRequired,
    heading: PropTypes.string,
    lg: PropTypes.number,
    md: PropTypes.number,
    options: PropTypes.array.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    sm: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
}

CheckboxGroupInput.defaultProps = {
    lg: 4,
    md: 6,
    sm: 12,
    xs: 12,
    xl: 3,
}

export default CheckboxGroupInput;