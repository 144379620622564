import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { FEEDBACK } from '../constants';
import { Api } from 'config/api';
import { deletePost, get, patchFile, postFile } from 'utilities/api';
import { fetchRequest } from '../api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest() {
    const token = yield select(getToken);

    return yield get(token, Api.requests.feedback.index);
}

export function* createRequest(playerId, payload) {
    const token = yield select(getToken);

    return yield postFile(token, Api.requests.feedback.store(playerId), payload);
}

export function* editRequest(playerId, feedbackId, payload, headers = {}) {
    const token = yield select(getToken);

    return yield patchFile(token, Api.requests.feedback.update(playerId, feedbackId), payload, headers);
}

export function* deleteRequest(playerId, feedbackId) {
    const token = yield select(getToken);

    return yield deletePost(token, Api.requests.feedback.delete(playerId, feedbackId));
}

export function* fetchFlow() {
    while (true) {
        yield take(FEEDBACK.FETCH.TRIGGER);

        try {
            const response = yield call(fetchAllRequest);
            const feedback = response.data.data;

            yield put(FEEDBACK.FETCH.success(feedback));
        } catch (error) {
            yield put(FEEDBACK.FETCH.failure());
        } finally {
            yield put(FEEDBACK.FETCH.fulfill());
        }
    }
}

export function* addFlow() {
    while (true) {
        const request = yield take(FEEDBACK.CREATE.TRIGGER);

        try {
            const formData = new FormData();
            formData.append('feedback', request.payload.values.feedback);
            formData.append('published', request.payload.values.published ? '1' : '0');

            if (request.payload.values.image) {
                formData.append('image', request.payload.values.image);
            }
            const response = yield call(createRequest, request.payload.playerId, formData);

            yield put(FEEDBACK.CREATE.success(response.data.data));
        } catch (error) {
            yield put(FEEDBACK.CREATE.failure());
        } finally {
            yield put(FEEDBACK.CREATE.fulfill());
        }
    }
}

export function* editFlow() {
    while (true) {
        const request = yield take(FEEDBACK.UPDATE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const feedbackId = request.payload.feedbackId;
            const formData = new FormData();
            formData.append('feedback', request.payload.values.feedback);
            formData.append('published', request.payload.values.published ? '1' : '0');

            if (request.payload.values.image) {
                formData.append('image', request.payload.values.image);
            }

            const response = yield call(editRequest, playerId, feedbackId, formData);

            yield put(FEEDBACK.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(FEEDBACK.UPDATE.failure());
        } finally {
            yield put(FEEDBACK.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(FEEDBACK.DELETE.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const feedbackId = request.payload.feedbackId;

            yield call(deleteRequest, playerId, feedbackId);
            yield put(FEEDBACK.DELETE.success(feedbackId));
        } catch (error) {
            yield put(FEEDBACK.DELETE.failure());
        } finally {
            yield put(FEEDBACK.DELETE.fulfill());
        }
    }
}

export function* fetchPlayerFlow() {
    while (true) {
        const request = yield take(FEEDBACK.PLAYER.FETCH.TRIGGER);

        try {
            const playerId = request.payload;
            const endpoint = Api.requests.feedback.player.index(playerId);
            const response = yield call(fetchRequest, endpoint);
            const feedback = response.data.data;

            yield put(FEEDBACK.PLAYER.FETCH.success({
                playerId,
                feedback,
            }));
        } catch (error) {
            yield put(FEEDBACK.PLAYER.FETCH.failure());
        } finally {
            yield put(FEEDBACK.PLAYER.FETCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        addFlow(),
        editFlow(),
        deleteFlow(),
        fetchPlayerFlow(),
    ]);
}