import { useRef, useState, Fragment } from 'react';

import useImage from 'use-image';
import { Image, Layer, Stage } from 'react-konva';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';

import styles from './MapDrawing.styles';
import { images } from 'config/images';
import Draw from './Draw';
import Circle from './Circle';
import Arrow from './Arrow';
import UrlImage from './UrlImage';
import Text from './Text';
import Toolbar from './Toolbar';
import TextPanel from './TextPanel';
import ImagePanel from './ImagePanel';
import { useIntl } from 'react-intl';

const MapDrawing = () => {
    const classes = styles();
    const stageRef = useRef();
    const backgroundRef = useRef();
    const imageRef = useRef();
    const [actionType, setActionType] = useState('element');
    const [activeElement, setActiveElement] = useState({});
    const [elements, setElements] = useState([]);
    const [draggable, setDraggable] = useState(true);
    const [activeBackground, setActiveBackground] = useState(images.tool.field.outdoor.full);
    const [selectedElement, setSelectedElement] = useState(null);
    const [background] = useImage(activeBackground.default);

    const generateIdentifier = () => {
        const timestamp = Date.now();
        const randomInt = Math.floor(Math.random() * 1000);

        return `${timestamp}-${randomInt}`;
    };

    const handleAddText = text => {
        const newElement = {
            draggable,
            identifier: generateIdentifier(),
            text: text,
            type: 'text',
            x: 50,
            y: 50,
        };

        setActionType('element');
        setDraggable(true);
        setElements([
            ...elements,
            newElement,
        ]);
    };

    const handleDrop = e => {
        stageRef.current.setPointersPositions(e);
        const positions = stageRef.current.getPointerPosition();

        const newElement = {
            draggable,
            height: 45,
            identifier: generateIdentifier(),
            image: imageRef.current,
            rotation: 0,
            type: 'image',
            width: 45,
            x: positions.x,
            y: positions.y,
        };

        setActionType('element');
        setDraggable(true);
        setElements([
            ...elements,
            newElement,
        ]);
    };

    const handleMouseDown = e => {
        handleDeselect(e);

        const { x, y } = e.target.getStage().getPointerPosition();

        if (Object.keys(activeElement).length === 0) {
            if (actionType === 'draw') {
                const coords = [x, y];

                setDraggable(false);
                setActiveElement({
                    coords,
                    identifier: generateIdentifier(),
                    type: actionType,

                });
            }
            else if (actionType === 'circle') {
                setDraggable(false);
                setActiveElement({
                    endX: x,
                    endY: y,
                    identifier: generateIdentifier(),
                    startX: x,
                    startY: y,
                    type: actionType,
                });
            }
            else if (actionType.substr(0, 5) === 'arrow') {
                const dash = actionType.substr(6).length > 0 ? actionType.substr(6) : 'solid';
                let color = 'black';

                if (dash !== 'solid') {
                    color = dash === 'dashed' ? 'blue' : 'orange';
                }

                setDraggable(false);
                setActiveElement({
                    color,
                    dash,
                    endX: x,
                    endY: y,
                    identifier: generateIdentifier(),
                    startX: x,
                    startY: y,
                    type: actionType,
                });
            }
        }
    };

    const handleMouseMove = e => {
        const { x, y } = e.target.getStage().getPointerPosition();

        if (Object.keys(activeElement).length > 0) {
            if (actionType === 'draw') {
                setActiveElement({
                    ...activeElement,
                    coords: [
                        ...activeElement.coords,
                        x,
                        y,
                    ],
                });
            }
            else if (
                actionType === 'circle' ||
                actionType.substr(0, 5) === 'arrow'
            ) {
                setActiveElement({
                    ...activeElement,
                    endX: x,
                    endY: y,
                });
            }
        }
    };

    const handleMouseUp = e => {
        const { x, y } = e.target.getStage().getPointerPosition();

        if (Object.keys(activeElement).length > 0) {
            if (actionType === 'draw') {
                const newElement = {
                    ...activeElement,
                    coords: [
                        ...activeElement.coords,
                        x,
                        y,
                    ],
                };

                setDraggable(true);
                setActiveElement({});
                setElements([
                    ...elements,
                    newElement,
                ]);
            } else if (
                actionType === 'circle' ||
                actionType.substr(0, 5) === 'arrow'
            ) {
                const newElement = {
                    ...activeElement,
                    endX: x,
                    endY: y,
                };

                setDraggable(true);
                setActiveElement({});
                setElements([
                    ...elements,
                    newElement,
                ]);
            }
        }
    };

    const handleDelete = key => {
        if (actionType === 'element') {
            const newElements = [...elements];
            newElements.splice(key, 1);

            setElements(newElements);
            setSelectedElement(null);
        }
    };

    const handleUpdate = (key, updatedProps) => {
        const updatedElement = {
            ...elements[key],
            ...updatedProps,
            identifier: generateIdentifier(),
        };

        const newElements = [...elements];
        newElements[key] = updatedElement;

        setElements(newElements);
    };

    const handleSelect = identifier => {
        setSelectedElement(identifier);
    };

    const handleDeselect = element => {
        const clickedOnEmpty = element.target === backgroundRef.current;

        if (clickedOnEmpty) {
            setSelectedElement(null);
        }
    };

    const handleReset = () => {
        setElements([]);
    };

    const downloadMap = () => {
        const canvasImage = stageRef.current.toDataURL({ pixelRatio: 3, mimeType: 'image/png', quality: 1 });

        forceDownload(canvasImage, 'go4hockey-map');
    };

    const forceDownload = (uri, name) => {
        const link = document.createElement('a');
        link.download = name;
        link.href = uri;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const renderElements = () => {
        let increment = 0;

        const renderElements = [...elements];

        if (Object.keys(activeElement).length > 0) {
            renderElements.push(activeElement);
        }

        return renderElements.map(element => {
            increment++;

            if (element.type === 'draw') {
                return <Draw
                    coords={ element.coords }
                    draggable={ draggable }
                    index={ increment - 1 }
                    key={ element.identifier }
                    onDoubleClick={ handleDelete }
                    onUpdate={ handleUpdate }
                />
            }
            else if (element.type === 'circle') {
                return <Circle
                    draggable={ draggable }
                    endX={ element.endX }
                    endY={ element.endY }
                    index={ increment - 1 }
                    key={ element.identifier }
                    onDoubleClick={ handleDelete }
                    onUpdate={ handleUpdate }
                    startX={ element.startX }
                    startY={ element.startY }
                />
            }
            else if (element.type.substr(0, 5) === 'arrow') {
                return <Arrow
                    color={ element.color }
                    dash={ element.dash }
                    draggable={ draggable }
                    endX={ element.endX }
                    endY={ element.endY }
                    index={ increment - 1 }
                    key={ element.identifier }
                    onDoubleClick={ handleDelete }
                    onUpdate={ handleUpdate }
                    startX={ element.startX }
                    startY={ element.startY }
                />
            }
            else if (element.type === 'image') {
                return <UrlImage
                    draggable={ draggable }
                    height={ element.height }
                    index={ increment - 1 }
                    image={ element.image }
                    key={ element.identifier }
                    onSelect={ handleSelect }
                    onDoubleClick={ handleDelete }
                    onUpdate={ handleUpdate }
                    rotation={ element.rotation }
                    selected={increment - 1 === selectedElement}
                    width={ element.width }
                    x={ element.x - element.width / 2 }
                    y={ element.y - element.height / 2 }
                />
            }
            else if (element.type === 'text') {
                return <Text
                    draggable={ draggable }
                    fontSize={ element.fontSize }
                    index={ increment - 1 }
                    key={ element.identifier }
                    onDoubleClick={ handleDelete }
                    onSelect={ handleSelect }
                    onUpdate={ handleUpdate }
                    rotation={ element.rotation }
                    selected={increment - 1 === selectedElement}
                    text={ element.text }
                    x={ element.x }
                    y={ element.y }
                />
            }

            return null;
        });
    };

    return (
        <Fragment>
            <Grid container spacing={ 2 }>
                <Grid item>
                    <Toolbar actionType={ actionType } setActionType={ setActionType } />
                </Grid>

                <Grid
                    item
                    onDragOver={e => e.preventDefault()}
                    onDrop = { handleDrop }
                >
                    <Stage
                        width={ 800 }
                        height={ 600 }
                        onMouseDown={ handleMouseDown }
                        onMouseUp={ handleMouseUp }
                        onMouseMove={ handleMouseMove }
                        ref={ stageRef }
                    >
                        <Layer>
                            <Image
                                image={ background }
                                ref={ backgroundRef }
                                width={ 800 }
                                height={ 600 }
                            />

                            { renderElements() }
                        </Layer>
                    </Stage>

                    <Typography variant="caption">
                        { useIntl().formatMessage({ id: 'mapDrawing.explanation'}) }
                    </Typography>

                    <Grid item className={ classes.fieldPanel }>
                        <ButtonGroup fullWidth>
                            <Button
                                variant="contained"
                                color={ activeBackground === images.tool.field.outdoor.full ? 'primary' : 'default' }
                                onClick={ () => setActiveBackground(images.tool.field.outdoor.full) }
                            >
                                Outdoor heel
                            </Button>

                            <Button
                                variant="contained"
                                color={ activeBackground === images.tool.field.outdoor.half ? 'primary' : 'default' }
                                onClick={ () => setActiveBackground(images.tool.field.outdoor.half) }
                            >
                                Outdoor half
                            </Button>

                            <Button
                                variant="contained"
                                color={ activeBackground === images.tool.field.outdoor.quarter ? 'primary' : 'default' }
                                onClick={ () => setActiveBackground(images.tool.field.outdoor.quarter) }
                            >
                                Outdoor circel
                            </Button>

                            <Button
                                variant="contained"
                                color={ activeBackground === images.tool.field.indoor.full ? 'primary' : 'default' }
                                onClick={ () => setActiveBackground(images.tool.field.indoor.full) }
                            >
                                Indoor heel
                            </Button>

                            <Button
                                variant="contained"
                                color={ activeBackground === images.tool.field.indoor.half ? 'primary' : 'default' }
                                onClick={ () => setActiveBackground(images.tool.field.indoor.half) }
                            >
                                Indoor half
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                <Grid item className={ classes.panels }>
                    <Grid className={ classes.textPanel }>
                        <TextPanel addText={ handleAddText } />
                    </Grid>

                    <div className={ classes.imagePanel }>
                        <ImagePanel setDraggable={ setDraggable } setRef={ ref => imageRef.current = ref } />
                    </div>

                    <Grid className={ classes.buttonPanel }>
                        <Button
                            className={ classes.resetButton }
                            variant="contained"
                            fullWidth
                            onClick={ () => handleReset() }
                        >
                            { useIntl().formatMessage({ id: 'mapDrawing.resetButton'}) }
                        </Button>

                        <Button
                            className={ classes.downloadButton }
                            variant="contained"
                            color="primary"
                            startIcon={ <CloudDownloadIcon /> }
                            size={ 'large' }
                            fullWidth
                            onClick={ () => downloadMap() }
                        >
                            { useIntl().formatMessage({ id: 'mapDrawing.downloadButton'}) }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default MapDrawing;
