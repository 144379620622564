import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import TrainingForm from 'components/TrainingForm';
import styles from './TrainingsCreate.styles';
import { TRAININGS, DIVISIONS } from 'store/constants';

const TrainingsCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const fetched = useSelector(state => state.division.fetched);
    const loading = useSelector(state => state.division.loading);
    const classes = styles();

    const pageTitle = useIntl().formatMessage({ id: 'trainings.create.title' });
    const error = useSelector(state => state.training.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.training.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'trainings.create.loading' });

    const divisions = useSelector(state => state.division.divisions);

    const nameLabel = useIntl().formatMessage({ id: 'trainings.label.name' });
    const divisionLabel = useIntl().formatMessage({ id: 'trainings.label.division' });
    const submitButtonText = useIntl().formatMessage({ id: 'trainings.button.create' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });

    const onSubmit = values => {
        dispatch(TRAININGS.CREATE.trigger(values));

        return true;
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/trainings/${created}/edit`)
        }
    }, [created, createdMessage, history])

    useEffect(() => {
        if (fetched === false) {
            dispatch(DIVISIONS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: DIVISIONS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <TrainingForm
                                nameLabel={ nameLabel }
                                divisionLabel={ divisionLabel }
                                divisions={ divisions }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                onSubmit={ onSubmit }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ loading } message={ ! fetched ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default TrainingsCreate;
