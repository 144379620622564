import { AUTH, SURVEYS } from '../constants';

const INITIAL_STATE = {
    error: false,
    loading: false,
    
    created: false,
    updated: false,
    deleted: false,
};

const surveyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SURVEYS.QUESTIONS.CREATE.TRIGGER:
        case SURVEYS.QUESTIONS.UPDATE.TRIGGER:
        case SURVEYS.QUESTIONS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case SURVEYS.QUESTIONS.CREATE.SUCCESS:
            return {
                ...state,
                created: true,
            };

        case SURVEYS.QUESTIONS.UPDATE.SUCCESS:
            return {
                ...state,
                updated: true,
            };

        case SURVEYS.QUESTIONS.DELETE.SUCCESS:
            return {
                ...state,
                deleted: true,
            };

        case SURVEYS.QUESTIONS.CREATE.FAILURE:
        case SURVEYS.QUESTIONS.UPDATE.FAILURE:
        case SURVEYS.QUESTIONS.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case SURVEYS.QUESTIONS.CREATE.FULFILL:
        case SURVEYS.QUESTIONS.UPDATE.FULFILL:
        case SURVEYS.QUESTIONS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                created: false,
                updated: false,
                deleted: false,
            }
            
        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default surveyReducer;