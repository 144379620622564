import { AUTH, } from '../constants';

const INITIAL_STATE = {
    labels: [
        { id: 1, name:'Balbezit' },
        { id: 2, name:'Niet-balbezit' },
        { id: 3, name:'Omschakeling' },
        { id: 4, name:'Fun/F Jeugd' },
        { id: 5, name:'E Jeugd' },
        { id: 6, name:'D/C Jeugd' },
        { id: 7, name:'A/B Jeugd' },
        { id: 8, name:'Senioren' },
    ],
};

const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default Reducer;