import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';
import { PERIODS } from 'store/constants';
import { reverseToReadableDate } from 'utilities/date';

const Periods = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.period.fetched);
    const periods = useSelector(state => state.period.periods);
    const loading = useSelector(state => state.period.loading);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'periods.title' });
    const error = useSelector(state => state.period.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.period.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const isInternalText = useIntl().formatMessage({ id: 'string.yes' });
    const isNotInternalText = useIntl().formatMessage({ id: 'string.no' });

    const columns = [
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'periods.label.startDate' }),
            render: rowData => reverseToReadableDate(rowData.start_date),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'start_date'),
            defaultSort: 'asc',
            customFilterAndSearch: (query, rowData) => reverseToReadableDate(rowData.start_date).includes(query),
        },
        {
            title: useIntl().formatMessage({ id: 'periods.label.endDate' }),
            render: rowData => reverseToReadableDate(rowData.end_date),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'end_date'),
            defaultSort: 'asc',
            customFilterAndSearch: (query, rowData) => reverseToReadableDate(rowData.end_date).includes(query),
        },
        {
            title: useIntl().formatMessage({ id: 'periods.label.internal' }),
            render: rowData => rowData.internal ? isInternalText : isNotInternalText,
            sorting: false,
            customFilterAndSearch: (query, rowData) => {
                const fieldData = rowData.internal ? isInternalText : isNotInternalText;

                return fieldData.includes(query);
            },
        },
        {
            title: useIntl().formatMessage({ id: 'periods.label.numberOfTrainings' }),
            render: rowData => rowData.trainings.data.length,
            customSort: (a, b) => a.trainings.data.length < b.trainings.data.length ? -1 : 1,
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('periods/create')
        },
        {
            icon: 'search',
            tooltip: useIntl().formatMessage({ id: 'table.actions.view' }),
            onClick: (event, rowData) => history.push(`periods/${rowData.id}`)
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`periods/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(PERIODS.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        if (fetched === false) {
            dispatch(PERIODS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: PERIODS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ periods }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default Periods;
