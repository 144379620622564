import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';
import { timestampToDateTimeString } from 'utilities/date';

const OrderDetails = props => {
    const { order } = props;

    return (
        <>
            <ValueBlock label="Mollie Payment ID" value={ order?.mollie_payment_id } />
            <ValueBlock label="Event" value={ order?.event?.data?.name } />
            <ValueBlock label="Betaald" value={ order?.amount_paid } />
            <ValueBlock label="Status" value={ order?.status } />
            <ValueBlock label="Tijdstip" value={ timestampToDateTimeString(order?.timestamp) } />
        </>
    );
};

OrderDetails.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderDetails;