import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import PeriodForm from 'components/PeriodForm';
import styles from './PeriodsCreate.styles';
import { PERIODS } from 'store/constants';

const PeriodsCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const fetched = useSelector(state => state.period.fetched);
    const loading = useSelector(state => state.period.loading);
    const classes = styles();

    const error = useSelector(state => state.period.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.period.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'periods.update.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'periods.create.title' });
    const startDateLabel = useIntl().formatMessage({ id: 'periods.label.startDate' });
    const endDateLabel = useIntl().formatMessage({ id: 'periods.label.endDate' });
    const internalLabel = useIntl().formatMessage({ id: 'periods.label.internal' });
    const submitButtonText = useIntl().formatMessage({ id: 'periods.button.create' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const onSubmit = values => {
        dispatch(PERIODS.CREATE.trigger(values));

        return true
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/periods/${created}/edit`)
        }
    }, [created, createdMessage, history])

    useEffect(() => {
        if (fetched === false) {
            dispatch(PERIODS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: PERIODS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <PeriodForm
                                startDateLabel={ startDateLabel }
                                endDateLabel={ endDateLabel }
                                internalLabel={ internalLabel }
                                submitButtonText={ submitButtonText }
                                requiredFieldMessage={ requiredFieldMessage }
                                invalidFileSizeMessage={ invalidFileSizeMessage }
                                invalidFileTypeMessage={ invalidFileTypeMessage}
                                onSubmit={ onSubmit }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : null }

            <LoadingBackdrop open={ loading } message={ fetched ? loadingMessage : null } />
        </ContentContainer>
    );
};

export default PeriodsCreate;
