import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

import BoxHeading from 'components/BoxHeading';
import ContentBox from 'components/ContentBox';
import EvaluationAssessmentsTable from 'contents/tables/EvaluationAssessmentsTable';

const EvaluationAssessmentsBlock = props => {
    const { evaluation } = props;
    const history = useHistory();
    const assessments = evaluation?.assessments?.data || [];

    const goToTeamEvaluation = () => history.push(`/teams/${evaluation.team.data.id}/evaluations/${evaluation.id}`);

    return assessments.length ? (
        <>
            <EvaluationAssessmentsTable
                data={assessments}
            />

            { evaluation.team && (
                <Box>
                    <Button
                        color="primary"
                        onClick={ goToTeamEvaluation }
                    >
                        Bekijk team overzicht
                    </Button>
                </Box>
            )}
        </>
    ) : (
        <ContentBox>
            <BoxHeading
                title="Ingevulde evaluaties"
            />

            <Box mt={ 2 }>
                <Typography variant="body1">
                    Er zijn geen ingevulde evaluaties.
                </Typography>
            </Box>
        </ContentBox>
    )
};

EvaluationAssessmentsBlock.propTypes = {
    evaluation: PropTypes.object.isRequired,
}

export default EvaluationAssessmentsBlock;
