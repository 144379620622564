import { AUTH, TEAM } from '../constants';
import { deleteObjectWithIdFromArray } from 'utilities/array';

const INITIAL_STATE = {
    teamId: null,
    players: [],
    
    error: false,
    loading: false,
    
    deleted: false,
};

const teamPlayerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEAM.PLAYER.FETCH.TRIGGER:
        case TEAM.PLAYER.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case TEAM.PLAYER.FETCH.SUCCESS:
            return {
                ...state,
                teamId: action.payload.teamId,
                players: action.payload.players,
                fetched: true,
            };
            
        case TEAM.PLAYER.DELETE.SUCCESS:
            return {
                ...state,
                players: deleteObjectWithIdFromArray([...state.players], action.payload),
                deleted: true,
            };

        case TEAM.PLAYER.FETCH.FAILURE:
        case TEAM.PLAYER.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case TEAM.PLAYER.FETCH.FULFILL:
        case TEAM.PLAYER.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                deleted: false,
            }
            
        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default teamPlayerReducer;