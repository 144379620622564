import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { CRITERION } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(playerId, topicId) {
    const token = yield select(getToken);

    return yield get(token, Api.requests.criteria.show(playerId, topicId));
}

export function* fetchFlow() {
    while (true) {
        const request = yield take(CRITERION.FETCH.TRIGGER);

        try {
            const playerId = request.payload.playerId;
            const topicId = request.payload.topicId;
            const response = yield call(fetchAllRequest, playerId, topicId);
            const criteria = response.data.data;

            yield put(CRITERION.FETCH.success({
                criteria,
                playerId,
                topicId,
            }));
        } catch (error) {
            yield put(CRITERION.FETCH.failure());
        } finally {
            yield put(CRITERION.FETCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
    ]);
}
