import { useState } from 'react';
import PropTypes from 'prop-types';

import * as yup from 'yup';
import { Field, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import AutoCompleteInput from '../AutocompleteInput';

const AddExerciseToTrainingForm = props => {
    const {
        trainerLabel,
        exerciseLabel,
        exercises,
        submitButtonText,
        requiredFieldMessage,
        initialValues,
        onSubmit,
    } = props;
    const trainers = props?.trainers ? props.trainers : [];
    const [autocompleteKey, setAutocompleteKey] = useState(Date.now());
    const validationSchemaObject = yup.object().shape({
        exercise_id: yup.mixed().required(requiredFieldMessage),
        trainer_id: yup.mixed().nullable(),
    });
    const exerciseOptions = exercises.map(exercise => {
        return {
            value: exercise.id,
            label: exercise.title,
        }
    })
    const trainerOptions = trainers.map(trainer => {
        return {
            value: trainer.id,
            label: trainer.user.data.full_name,
        }
    });

    const handleSubmit = (values, { resetForm }) => {
        onSubmit(values);
        resetForm(initialValues);
        setAutocompleteKey(Date.now());
    }

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ handleSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            key={ autocompleteKey }
                            options={ exerciseOptions }
                            name={ 'exercise_id' }
                            label={ exerciseLabel }
                            component={ AutoCompleteInput }
                            errorMessage={ errors['exercise_id'] }
                            touched={ touched['exercise_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            setFieldValue={ setFieldValue }
                            value={ values.exercise_id || '' }
                        />
                    </Box>

                    { trainers.length > 0 && (
                        <Box>
                            <Field
                                key={ autocompleteKey }
                                options={ trainerOptions }
                                name={ 'trainer_id' }
                                label={ trainerLabel }
                                component={ AutoCompleteInput }
                                errorMessage={ errors['trainer_id'] }
                                touched={ touched['trainer_id'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                setFieldValue={ setFieldValue }
                                value={ values.trainer_id || '' }
                            />
                        </Box>
                    )}

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' } >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};


AddExerciseToTrainingForm.propTypes = {
    trainerLabel: PropTypes.string,
    exerciseLabel: PropTypes.string,
    trainers: PropTypes.array,
    exercises: PropTypes.array,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

AddExerciseToTrainingForm.defaultProps = {
    trainerLabel: 'trainer',
    exerciseLabel: 'exercise',
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    initialValues: {
        exercise_id: null,
        trainer_id: null,
    }
}

export default AddExerciseToTrainingForm;
