import { AUTH, PLAYER } from '../constants';

const INITIAL_STATE = {
    active: null,
    error: false,
    loading: false,
    created: null,
    updated: false,
};

const playerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLAYER.CREATE.TRIGGER:
        case PLAYER.SHOW.TRIGGER:
        case PLAYER.UPDATE.TRIGGER:
        case PLAYER.SETTINGS.UPDATE.TRIGGER:
        case PLAYER.STATS.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case PLAYER.CREATE.SUCCESS:
            return {
                ...state,
                created: action.payload.id,
            };

        case PLAYER.SHOW.SUCCESS:
        case PLAYER.UPDATE.SUCCESS:
        case PLAYER.SETTINGS.UPDATE.SUCCESS:
            return {
                ...state,
                active: action.payload,
                updated: true,
            };

        case PLAYER.STATS.UPDATE.SUCCESS:
            return {
                ...state,
                active: {
                    ...state.active,
                    stats: {
                        data: action.payload,
                    },
                },
                updated: true,
            }

        case PLAYER.CREATE.FAILURE:
        case PLAYER.SHOW.FAILURE:
        case PLAYER.UPDATE.FAILURE:
        case PLAYER.SETTINGS.UPDATE.FAILURE:
        case PLAYER.STATS.UPDATE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case PLAYER.CREATE.FULFILL:
        case PLAYER.SHOW.FULFILL:
        case PLAYER.UPDATE.FULFILL:
        case PLAYER.SETTINGS.UPDATE.FULFILL:
        case PLAYER.STATS.UPDATE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                created: null,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default playerReducer;