import PropTypes from 'prop-types';

import ActionList from 'components/ActionList';

const EventList = props => {
    const { events } = props;
    const listItems = events.map(event => (
        {
            target: `/events/${event.id}`,
            label: event.name,
        }
    )).sort()

    return (
        <ActionList
            values={ listItems }
        />
    );
};

EventList.propTypes = {
    events: PropTypes.array.isRequired,
};

export default EventList;