import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';

import styles from './FileStatusAvatar.styles';

const FileStatusAvatar = props => {
    const { status, onClick } = props;
    const [colorClass, setColorClass] = useState(null);
    const [icon, setIcon] = useState(null);
    const classes = styles();

    useEffect(() => {
        if (status === 'selected') {
            setColorClass(classes.selectedAvatar);
            setIcon('done');
        } else if (status === 'error') {
            setColorClass(classes.errorAvatar);
            setIcon('error_outline');
        } else {
            setColorClass(classes.openAvatar);
            setIcon('folder');
        }
    }, [status, classes]);

    return (
        <Avatar
            className={ colorClass ? `${classes.avatar} ${colorClass }` : classes.avatar }
            onClick={ onClick }
        >
            <Icon>{ icon }</Icon>
        </Avatar>
    );
};

FileStatusAvatar.propTypes = {
    status: PropTypes.oneOf(['open', 'selected', 'error']).isRequired,
    onClick: PropTypes.func
};

export default FileStatusAvatar;
