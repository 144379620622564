import { AUTH, EVALUATIONS } from '../constants';
import { deleteObjectWithIdFromArray } from 'utilities/array';

const INITIAL_STATE = {
    evaluations: [],
    active: null,

    fetched: false,
    error: false,
    loading: false,

    created: null,
    deleted: false,
    cleared: false,
    reminded: false,
    synced: false,
};

const evaluationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVALUATIONS.FETCH.TRIGGER:
        case EVALUATIONS.SHOW.TRIGGER:
        case EVALUATIONS.CREATE.TRIGGER:
        case EVALUATIONS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case EVALUATIONS.FETCH.SUCCESS:
            return {
                ...state,
                evaluations: action.payload,
                fetched: true,
            };

        case EVALUATIONS.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case EVALUATIONS.CREATE.SUCCESS:
            return {
                ...state,
                created: true,
            };

        case EVALUATIONS.DELETE.SUCCESS:
            return {
                ...state,
                evaluations: deleteObjectWithIdFromArray([...state.evaluations], action.payload),
                deleted: true,
            };

        case EVALUATIONS.CLEAR.SUCCESS:
            return {
                ...state,
                cleared: true,
            };

        case EVALUATIONS.REMIND.SUCCESS:
            return {
                ...state,
                reminded: true,
            };

        case EVALUATIONS.SYNC.SUCCESS:
            return {
                ...state,
                synced: true,
            };

        case EVALUATIONS.FETCH.FAILURE:
        case EVALUATIONS.SHOW.FAILURE:
        case EVALUATIONS.CREATE.FAILURE:
        case EVALUATIONS.DELETE.FAILURE:
        case EVALUATIONS.CLEAR.FAILURE:
        case EVALUATIONS.REMIND.FAILURE:
        case EVALUATIONS.SYNC.FAILURE:
            return {
                ...state,
                error: true,
            }

        case EVALUATIONS.FETCH.FULFILL:
        case EVALUATIONS.SHOW.FULFILL:
        case EVALUATIONS.CREATE.FULFILL:
        case EVALUATIONS.DELETE.FULFILL:
        case EVALUATIONS.CLEAR.FULFILL:
        case EVALUATIONS.REMIND.FULFILL:
        case EVALUATIONS.SYNC.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                created: null,
                deleted: false,
                cleared: false,
                reminded: false,
                synced: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default evaluationReducer;
