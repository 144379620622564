import { Fragment, useEffect, useState } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import { images } from 'config/images';
import { guestGet } from 'utilities/api';
import { Api } from 'config/api';
import { logout } from 'store/actions/AuthActions';
import StaticHeader from 'components/StaticHeader';

const Verify = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const [status, setStatus] = useState('verifying');
    const pageTitle = useIntl().formatMessage({ id: 'verify.title' });
    const loginLabel = useIntl().formatMessage({ id: 'verify.label.login' });
    const verifyingMessage = useIntl().formatMessage({ id: 'verify.message.verifying' });
    const alreadyVerifiedMessage = useIntl().formatMessage({ id: 'verify.message.alreadyVerified' });
    const successMessage = useIntl().formatMessage({ id: 'verify.message.success' });
    const errorMessage = useIntl().formatMessage({ id: 'verify.message.error' });

    const handleVerification = async () => {
        const userId = params.userId;
        const query = location.search;

        try {
            await guestGet(Api.requests.verify(userId, query));

            setStatus('success');
        } catch (error) {
            const status = error.response.status ?? null;

            if (status === 422) {
                setStatus('verified');

                return;
            }

            setStatus('failed')
        }
    }

    useEffect(() => {
        dispatch(logout());
        handleVerification();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <StaticHeader />

            <main role="main" className="pages">
                <figure className="img-box">
                    <div className="splash splash-left">
                        <img className="img-fluid" src={ images.splash.login.left.default } alt="Splash" />
                    </div>
                    <div className="splash splash-right">
                        <img className="img-fluid right" src={ images.splash.login.right.default } alt="Splash" />
                    </div>
                </figure>

                <section className="log-in">
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xm-8 col-xs-8 mx-auto no-gutters">
                                <article className="info">
                                    <h1>{ pageTitle }</h1>

                                    { status === 'verifying' && <Alert severity="info">{ verifyingMessage }</Alert> }
                                    { status === 'success' && <Alert severity="success">{ successMessage }</Alert> }
                                    { status === 'verified' && <Alert severity="success">{ alreadyVerifiedMessage }</Alert> }
                                    { status === 'failed' && <Alert severity="error">{ errorMessage }</Alert> }
                                </article>

                                <Box mt={ 3 }>
                                    <Link to={ '/login' }>{ loginLabel }</Link>
                                </Box>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default Verify;
