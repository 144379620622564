import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { get } from 'utilities/api';
import { Api } from 'config/api';
import { CLUB, PREMIUM } from 'store/constants';
import SearchBox from 'components/SearchBox';
import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import PremiumForm from 'components/PremiumForm';
import ValueBlock from 'components/ValueBlock';
import styles from './PremiumCreate.styles';

const PremiumCreate = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const history = useHistory();
    const loading = useSelector(state => state.premium.loading);
    const clubs = useSelector(state => state.club.clubs);
    const clubsFetched = useSelector(state => state.club.fetched);
    const accessToken = useSelector(state => state.persist.accessToken);
    const [user, setUser] = useState(null);
    const [isSearchingForUser, setIsSearchingForUser] = useState(false);

    const error = useSelector(state => state.premium.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const created = useSelector(state => state.premium.created);
    const createdMessage = useIntl().formatMessage({ id: 'toast.success.created' });
    const loadingMessage = useIntl().formatMessage({ id: 'premium.create.loading' });

    const pageTitle = useIntl().formatMessage({ id: 'premium.create.title' });
    const nameLabel = useIntl().formatMessage({ id: 'premium.label.name' });
    const startDateLabel = useIntl().formatMessage({ id: 'premium.label.start_date' });
    const endDateLabel = useIntl().formatMessage({ id: 'premium.label.end_date' });
    const emailLabel = useIntl().formatMessage({ id: 'premium.label.email' });
    const clubLabel = useIntl().formatMessage({ id: 'premium.label.club' });
    const searchButtonText = useIntl().formatMessage({ id: 'premium.create.search_button_text' });
    const submitButtonText = useIntl().formatMessage({ id: 'premium.create.submit_button_text' });
    const userNotFoundMessage = useIntl().formatMessage({ id: 'premium.create.user_not_found' });
    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });

    const searchForUser =  async (email) => {
        setIsSearchingForUser(true);

        try {
            const result = await get(accessToken, Api.requests.users.search(email));

            setUser(result.data.data);
        } catch (error) {
            toast.error(userNotFoundMessage);
        }

        setIsSearchingForUser(false);
    }

    const handleSubmit = values => {
        dispatch(PREMIUM.CREATE.trigger({
            ...values,
            user_id: user.id,
        }));

        return true;
    }

    useEffect(() => {
        if (clubsFetched === false) {
            dispatch(CLUB.FETCH.trigger());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (created) {
            toast.success(createdMessage);

            history.push(`/premium/${created}/edit`)
        }
    }, [created, createdMessage, history])

    return (
        <ContentContainer title={ pageTitle } backButton>
            <Grid container spacing={ 2 }>
                { user ? (
                    <Grid item xs={ 4 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock
                                label={ nameLabel }
                                value={ user.full_name }
                            />

                            <ValueBlock
                                label={ emailLabel }
                                value={ user.email }
                            />
                        </Paper>

                        <Box mt={ 2 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <PremiumForm
                                    startDateLabel={ startDateLabel }
                                    endDateLabel={ endDateLabel }
                                    clubLabel={ clubLabel }
                                    submitButtonText={ submitButtonText }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    clubs={ clubs }
                                    onSubmit={ handleSubmit }
                                />
                            </Paper>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={ 4 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <SearchBox
                                onSubmit={ searchForUser }
                                submitDisabled={ isSearchingForUser }
                                fieldLabel={ emailLabel }
                                buttonLabel={ searchButtonText }
                                submitLabel
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>

            <LoadingBackdrop open={ loading } message={ loadingMessage } />
        </ContentContainer>
    );
};

export default PremiumCreate;
