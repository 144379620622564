import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import ImageButton from '../../ImageButton';

const ImageItem = props => {
    const { onDragStart, tooltip, src } = props;

    return (
        <Grid item>
            <Tooltip
                arrow
                placement={ 'bottom' }
                title={ tooltip }
            >
                <div>
                    <ImageButton
                        src={ src }
                        alt={ tooltip }
                        onDragStart={ onDragStart }
                    />
                </div>
            </Tooltip>
        </Grid>
    );
};

ImageItem.propTypes = {
    onDragStart: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
};

export default ImageItem;