import PropTypes from 'prop-types';

import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TextInput from '../TextInput';
import AutoCompleteInput from '../AutocompleteInput/AutocompleteInput';

const TrainerForm = props => {
    const {
        emailLabel,
        rateLabel,
        users,
        submitButtonText,
        requiredFieldMessage,
        invalidNumberMessage,
        initialValues,
        onSubmit,
        editable,
    } = props;

    const validationSchema = editable ? {
        rate: yup.number(invalidNumberMessage).required(requiredFieldMessage),
    } : {
        user_id: yup.mixed().required(requiredFieldMessage),
        rate: yup.number(invalidNumberMessage).required(requiredFieldMessage),
    };

    const validationSchemaObject = yup.object().shape(validationSchema);

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur={ true }
        >
            {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  values,
            }) => (
                <form onSubmit={handleSubmit}>
                    { ! editable ? (
                        <Box>
                            <Field
                                options={ users }
                                name={ 'user_id' }
                                label={ emailLabel }
                                component={ AutoCompleteInput }
                                errorMessage={ errors['user_id'] }
                                touched={ touched['user_id'] }
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                setFieldValue={ setFieldValue }
                                value={ values.user_id || '' }
                            />
                        </Box>
                    ) : null }

                    <Box mt={ editable ? 0 : 1 }>
                        <Field
                            name={ 'rate' }
                            label={ rateLabel }
                            component={ TextInput }
                            errorMessage={ errors['rate'] }
                            touched={ touched['rate'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur }
                            type={ 'number' }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button type="submit" disabled={ isSubmitting } color={ 'primary' } variant={ 'contained' }>
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};


TrainerForm.propTypes = {
    emailLabel: PropTypes.string,
    rateLabel: PropTypes.string,
    submitButtonText: PropTypes.string,
    requiredFieldMessage: PropTypes.string,
    invalidNumberMessage: PropTypes.string,
    editMode: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    editable: PropTypes.bool,
};

TrainerForm.defaultProps = {
    users: [],
    emailLabel: 'email',
    rateLabel: 'rate',
    submitButtonText: 'submit',
    requiredFieldMessage: 'required',
    invalidNumberMessage: 'invalid number',
    initialValues: {
        user_id: undefined,
        rate: 0,
    }
}

export default TrainerForm;
