import { useEffect, useMemo } from 'react';

import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { reverseToReadableDate } from 'utilities/date';
import { defaultOptions, localization } from 'utilities/materialTable';
import { sortCaseInsensitive } from 'utilities/sort';

const EventsTable = props => {
    const { data, clubs, eventTypes, onCreate, onDelete } = props;
    const history = useHistory();

    const convertObjectToLookupArray = source => {
        let list = {};

        source.forEach(row => {
            list[row.id] = row.name;
        });

        return list;
    }

    const clubLookup = useMemo(() => convertObjectToLookupArray(clubs), [clubs]);
    const eventTypeLookup = useMemo(() => convertObjectToLookupArray(eventTypes), [eventTypes]);

    const columns = [
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'name'),
            defaultSort: 'asc',
            field: 'name',
            title: 'Event',
            validate: rowData => !! rowData?.name,
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'event_type_id'),
            field: 'event_type_id',
            render: rowData => rowData.type,
            title: 'Type',
            lookup: eventTypeLookup,
            validate: rowData => !! rowData?.event_type_id,
        },
        {
            customSort: (a, b) => sortCaseInsensitive(a, b, 'club'),
            field: 'club_id',
            render: rowData => rowData.club,
            title: 'Club',
            lookup: clubLookup,
            validate: rowData => !! rowData?.club_id,
        },
        {
            editable: 'never',
            field: 'player_count',
            render: rowData => `${rowData.player_count} / ${rowData.places}`,
            sorting: false,
            title: 'Aantal spelers',
        },
        {
            customFilterAndSearch: (value, rowData) => {
                const dates  = rowData.dates.data.map(date => {
                    return reverseToReadableDate(date.date);
                }).join(' / ');

                return dates.includes(value);
            },
            editable: 'never',
            field: 'dates.data',
            render: rowData => {
                const dates  = rowData.dates.data.map(date => {
                    return reverseToReadableDate(date.date);
                });

                return dates.join(' / ');
            },
            sorting: false,
            title: 'Data',
        },
    ];

    const actions = [
        {
            icon: 'search',
            tooltip: 'Bekijk',
            onClick: (event, rowData) => history.push(`events/${rowData.id}`)
        },
        {
            icon: 'payment',
            tooltip: 'Aanmeldingen',
            onClick: (event, rowData) => history.push(`events/${rowData.id}/orders`)
        },
    ];

    const editable = {
        onRowAdd: onCreate,
        onRowDelete: onDelete,
    };

    // useEffect(() => {
    //     eventTypes.forEach(type => {
    //         eventTypeLookup[type.id] = type.name;
    //     });
    // }, [eventTypes, eventTypeLookup]);

    useEffect(() => {
        clubs.forEach(club => {
            clubLookup[club.id] = club.name;
        });
    }, [clubs, clubLookup]);

    return (
        <MaterialTable
            title="Events"
            localization={ localization }
            columns={ columns }
            data={ data }
            options={ defaultOptions }
            actions={ actions }
            editable={ editable }
        />
    );
};

EventsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default EventsTable;