import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { TAGS } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.tags.index);
}

export function* fetchFlow() {
    while (true) {
        yield take(TAGS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const tags = response.data.data;

            yield put(TAGS.FETCH.success(tags));
        } catch (error) {
            yield put(TAGS.FETCH.failure());
        } finally {
            yield put(TAGS.FETCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
    ]);
}