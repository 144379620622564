import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';
import { reverseToReadableDate } from 'utilities/date';

const ClassificationDetails = props => {
    const { classification } = props;

    return (
        <>
            <ValueBlock label="Sluitingsdatum" value={ reverseToReadableDate(classification.closing_date) } />
            <ValueBlock label="Aantal teams" value={ classification.team_count } />
            <ValueBlock label="Aantal beoordelingen" value={ classification.review_count } />
        </>
    );
};

ClassificationDetails.propTypes = {
    classification: PropTypes.object.isRequired,
};

export default ClassificationDetails;