import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.white,
        flexDirection: 'column',
    },

    message: {
        color: theme.palette.common.white,
    }
}));

export default styles;