import PropTypes from 'prop-types';

import ValueBlock from 'components/ValueBlock';
import { reverseToReadableDate } from 'utilities/date';

const OrderPlayerDetails = props => {
    const { order } = props;

    return (
        <>
            <ValueBlock label="Naam" value={ order?.player?.data?.full_name } />
            <ValueBlock label="Geboortedatum" value={ reverseToReadableDate(order?.birthday) } />
            <ValueBlock label="Club" value={ order?.club } />
            <ValueBlock label="Team" value={ order?.team } />
            <ValueBlock label="Dieet" value={ order?.diet } />
            <ValueBlock label="Shirtmaat" value={ order?.shirt_size } />
            <ValueBlock label="In de groep met" value={ order?.group_with } />
        </>
    );
};

OrderPlayerDetails.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderPlayerDetails;