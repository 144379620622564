import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { INTERNAL_TRAINERS, PLAYER, USER } from 'store/constants';
import BoxHeading from 'components/BoxHeading';
import ContentBox from 'components/ContentBox';
import ContentContainer from 'components/ContentContainer';
import FormDialog from 'components/FormDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import PlayerForm from 'contents/forms/PlayerForm';
import UserDetails from 'contents/details/UserDetails';
import UserForm from 'contents/forms/UserForm';
import PlayerList from 'contents/lists/PlayerList';
import Button from '@material-ui/core/Button';

const UserShow = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const userId = parseInt(params.userId);
    const user = useSelector(state => state.user.active);
    const error = useSelector(state => state.user.error);
    const updated = useSelector(state => state.user.updated);
    const created = useSelector(state => state.player.created);
    const internalTrainerCreated = useSelector(state => state.internalTrainer.created);
    const isLoading = userId !== user?.id;
    const userFormValues = isLoading ? {} : {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email_verified_at: user?.email_verified_at,
    };
    const [userEditMode, setUserEditMode] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const createPlayer = values => {
        dispatch(PLAYER.CREATE.trigger({
            userId,
            values,
        }));
    }

    const updateUser = values => {
        dispatch(USER.UPDATE.trigger({
            userId,
            values,
        }))
    }

    const createInternalTrainerFromUser = () => {
        dispatch(INTERNAL_TRAINERS.CREATE.trigger({
            user_id: userId,
            rate: 0,
        }));
    }

    useEffect(() => {
        if (error) {
            toast.error('Er is een fout opgetreden...');
        }
    }, [error]);

    useEffect(() => {
        if (created) {
            dispatch(USER.SHOW.trigger(userId));
            setModalOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [created, userId]);

    useEffect(() => {
        if (updated) {
            setUserEditMode(false);
        }
    }, [updated]);

    useEffect(() => {
        if (isLoading) {
            dispatch(USER.SHOW.trigger(userId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
        if (internalTrainerCreated) {
            toast.success(`${user.first_name} is aangemaakt als trainer`);

            history.push(`/trainers`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalTrainerCreated]);

    return (
        <ContentContainer title={ isLoading ? 'Gebruiker bekijken' : user?.full_name } backButton>
            { isLoading ? (
                <LoadingIndicator />
            ) : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <ContentBox>
                            <BoxHeading
                                title="Gebruiker details"
                                icon={ userEditMode ? 'clear' : 'edit' }
                                onClick={ () => setUserEditMode(! userEditMode) }
                            />

                            <Box mt={ 2 }>
                                { userEditMode ? (
                                    <UserForm
                                        initialValues={ userFormValues }
                                        onSubmit={ updateUser }
                                    />
                                ) : (
                                    <UserDetails user={ user } />
                                )}
                            </Box>

                            { ! user?.is_internal_trainer && (
                                <Box mt={ 2 }>
                                    <Button
                                        color="primary"
                                        onClick={ createInternalTrainerFromUser }
                                        variant="contained"
                                    >
                                        Upgrade naar trainer
                                    </Button>
                                </Box>
                            ) }
                        </ContentBox>


                    </Grid>

                    <Grid item xs={ 6 }>
                        <ContentBox>
                            <BoxHeading
                                title="Spelers"
                                icon="add"
                                onClick={ () => setModalOpen(true) }
                            />

                            <Box mt={ 2 }>
                                { isLoading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <PlayerList players={ user?.players?.data || []} />
                                )}
                            </Box>
                        </ContentBox>
                    </Grid>
                </Grid>
            )}

            <FormDialog
                open={ isModalOpen }
                onClose={ () => setModalOpen(false) }
                title="Speler aanmaken"
            >
                <PlayerForm onSubmit={ createPlayer } />
            </FormDialog>
        </ContentContainer>
    );
};

export default UserShow;