import { AUTH, CRITERION } from '../constants';

const INITIAL_STATE = {
    criteria: [],
    playerId: null,
    topicId: null,
    error: false,
};

const criterionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CRITERION.FETCH.TRIGGER:
            return {
                ...state,
                error: false,
            }

        case CRITERION.FETCH.SUCCESS:
            return {
                ...state,
                criteria: action.payload.criteria,
                playerId: action.payload.playerId,
                topicId: action.payload.topicId,
            };

        case CRITERION.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case CRITERION.FETCH.FULFILL:
            return {
                ...state,
                error: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default criterionReducer;
