import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import SelectInput from 'components/SelectInput';

const SelectForm = props => {
    const {
        initialValues,
        label,
        options,
        onSubmit,
        submitButtonText,
    } = props;

    const validationSchemaObject = yup.object().shape({
        selected_id: yup
            .mixed()
            .required('Verplicht veld'),
    });

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ validationSchemaObject }
            validateOnBlur
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            name="selected_id"
                            label={ label }
                            component={ SelectInput }
                            errorMessage={ errors['selected_id'] }
                            touched={ touched['selected_id'] }
                            onChange={ handleChange }
                            onBlur={ handleBlur['selected_id'] }
                            options={ options }
                        />
                    </Box>

                    <Box mt={ 2 }>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >
                            { submitButtonText }
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

SelectForm.propTypes = {
    initialValues: PropTypes.object,
    label: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    submitButtonText: PropTypes.string,
};

SelectForm.defaultProps = {
    initialValues: {
        selected_id: null,
    },
    label: 'Label',
    submitButtonText: 'Selecteer',
}

export default SelectForm;
