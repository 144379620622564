import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    message: {
        marginLeft: theme.spacing(2),
    }
}));

export default styles;