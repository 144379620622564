import PropTypes from 'prop-types';

import ActionList from 'components/ActionList';

const UserList = props => {
    const { users } = props;
    const listItems = users.map(user => (
        {
            target: `/users/${user.id}`,
            label: user.name,
        }
    )).sort()

    return (
        <ActionList
            values={ listItems }
        />
    );
};

UserList.propTypes = {
    users: PropTypes.array.isRequired,
};

export default UserList;