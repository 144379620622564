import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { CLUB } from '../constants';
import {
    fetchRequest,
    updateRequest,
    deleteRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(CLUB.ACCESS_REQUEST.FETCH.TRIGGER);

        try {
            const clubId = request.payload;
            const endpoint = Api.requests.clubs.accessRequests.index(clubId);
            const response = yield call(fetchRequest, endpoint);
            const accessRequests = response.data.data;

            yield put(CLUB.ACCESS_REQUEST.FETCH.success({
                clubId,
                accessRequests,
            }));
        } catch (error) {
            yield put(CLUB.ACCESS_REQUEST.FETCH.failure());
        } finally {
            yield put(CLUB.ACCESS_REQUEST.FETCH.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(CLUB.ACCESS_REQUEST.UPDATE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const accessRequestId = request.payload.accessRequestId;
            const endpoint = Api.requests.clubs.accessRequests.update(clubId, accessRequestId);
            const payload = request.payload.values;

            yield call(updateRequest, endpoint, payload);
            yield put(CLUB.ACCESS_REQUEST.UPDATE.success(accessRequestId));
        } catch (error) {
            yield put(CLUB.ACCESS_REQUEST.UPDATE.failure());
        } finally {
            yield put(CLUB.ACCESS_REQUEST.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(CLUB.ACCESS_REQUEST.DELETE.TRIGGER);

        try {
            const clubId = request.payload.clubId;
            const accessRequestId = request.payload.accessRequestId;
            const endpoint = Api.requests.clubs.accessRequests.delete(clubId, accessRequestId);

            yield call(deleteRequest, endpoint);
            yield put(CLUB.ACCESS_REQUEST.DELETE.success(accessRequestId));
        } catch (error) {
            yield put(CLUB.ACCESS_REQUEST.DELETE.failure());
        } finally {
            yield put(CLUB.ACCESS_REQUEST.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        updateFlow(),
        deleteFlow(),
    ]);
}