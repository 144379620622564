import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { images } from 'config/images';

const StaticHeader = props => {
    const { promo } = props;

    return (
        <header className="navbar navbar-expand-xl fixed-top">
            <Link to={ '/' } className="logo">
                <img src={ images.logo.dark.default } className="img-fluid" alt="Go4Hockey logo" />
            </Link>

            <nav className="navbar-go4h">
                { promo ? (
                    <Link to={ '/inquiry' } className="btn btn-fill primary">Aanmelden</Link>
                ) : (
                    <>
                        <Link to={ '/login' } className="btn btn-stroke white">Inloggen</Link>
                        <Link to={ '/inquiry' } className="btn btn-fill primary header">Gratis try-out</Link>
                    </>
                ) }
            </nav>
        </header>
    );
};

StaticHeader.propTypes = {
    promo: PropTypes.bool,
}

export default StaticHeader;
