import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { Player } from 'video-react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

import { FEEDBACK } from 'store/constants';
import ContentContainer from 'components/ContentContainer';
import LoadingBackdrop from 'components/LoadingBackdrop';
import FeedbackForm from 'components/FeedbackForm';
import ValueBlock from 'components/ValueBlock';
import styles from './FeedbackEdit.styles';

const FeedbackEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetched = useSelector(state => state.feedback.fetched);
    const loading = useSelector(state => state.feedback.loading);
    const classes = styles();

    const error = useSelector(state => state.feedback.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const updated = useSelector(state => state.feedback.updated);
    const updatedMessage = useIntl().formatMessage({ id: 'toast.success.updated' });
    const loadingMessage = useIntl().formatMessage({ id: 'feedback.update.loading' });

    const feedback = useSelector(state => state.feedback.feedback);
    const item = feedback.filter(object => object.id === parseInt(params.feedbackId))[0];

    const pageTitle = useIntl().formatMessage({ id: 'feedback.update.title' });
    const feedbackText = item ? item.feedback : '';
    const feedbackLabel = useIntl().formatMessage({ id: 'feedback.label.feedback' });
    const publishedLabel = useIntl().formatMessage({ id: 'feedback.label.publish' });
    const player = item ? item.player.data.full_name : '';
    const playerLabel = useIntl().formatMessage({ id: 'feedback.label.player' });
    const image = item ? item.image : undefined;
    const imageLabel = useIntl().formatMessage({ id: 'feedback.label.image' });
    const video = item ? item.video : '';
    const submitButtonText = useIntl().formatMessage({ id: 'feedback.button.update' });

    const requiredFieldMessage = useIntl().formatMessage({ id: 'validation.error.required' });
    const invalidFileSizeMessage = useIntl().formatMessage({ id: 'validation.error.fileSize' });
    const invalidFileTypeMessage = useIntl().formatMessage({ id: 'validation.error.fileType' });

    const getInitialValues = () => {
        if (feedback) {
            return {
                image: undefined,
                feedback: feedbackText,
                published: !! item.published_at,
            }
        }

        return {};
    }

    const onSubmit = values => {
        dispatch(FEEDBACK.UPDATE.trigger({
            values,
            playerId: item.player.data.id,
            feedbackId: params.feedbackId
        }));

        return true;
    };

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (updated) {
            toast.success(updatedMessage);
        }
    }, [updated, updatedMessage])

    useEffect(() => {
        if (fetched === false) {
            dispatch(FEEDBACK.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: FEEDBACK.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton>
            { fetched ? (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ `${classes.block} ${classes.smallPaddingBottom}` }>
                            <ValueBlock label={ playerLabel } value={ player } />
                        </Paper>

                        <Box mt={ 2 }>
                            <Paper elevation={ 2 } className={ classes.block }>
                                <FeedbackForm
                                    feedbackLabel={ feedbackLabel }
                                    publishedLabel={ publishedLabel }
                                    imageLabel={ imageLabel }
                                    submitButtonText={ submitButtonText }
                                    requiredFieldMessage={ requiredFieldMessage }
                                    invalidFileSizeMessage={ invalidFileSizeMessage }
                                    invalidFileTypeMessage={ invalidFileTypeMessage }
                                    onSubmit={ onSubmit }
                                    initialValues={ getInitialValues() }
                                />
                            </Paper>
                        </Box>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ playerLabel } value={ player } />

                            { video.length ? (
                                <Player>
                                    <source src={ video } />
                                </Player>
                            ) : null }
                        </Paper>

                        { image.length ? (
                            <Box mt={ video.length ? 2 : 0 }>
                                <Paper elevation={ 2 } className={ classes.block }>
                                    <img src={ image } alt={ imageLabel } className="responsive-image" />
                                </Paper>
                            </Box>
                        ) : null }
                    </Grid>
                </Grid>
            ) : null}

            <LoadingBackdrop
                open={ loading }
                message={ fetched ? loadingMessage : null }
            />
        </ContentContainer>
    );
};

export default FeedbackEdit;
