import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import BoxHeading from 'components/BoxHeading/BoxHeading';
import ContentBox from 'components/ContentBox';
import ClassificationDetails from 'contents/details/ClassificationDetails';

const ClassificationDetailBlock = props => {
    const { classification } = props;

    return (
        <ContentBox>
            <BoxHeading
                title="Teambeoordeling details"
            />

            <Box mt={ 2 }>
                <ClassificationDetails classification={ classification } />
            </Box>
        </ContentBox>
    );
};

ClassificationDetailBlock.propTypes = {
    classification: PropTypes.object.isRequired,
}

export default ClassificationDetailBlock;