import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: 0 - theme.spacing(0.5),

        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default styles;