import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

import styles from './FormDialog.styles';

const FormDialog = props => {
    const { children, onClose, open, title } = props;
    const classes = styles();

    return (
        <Dialog onClose={ onClose } open={ open }>
            { title && (
                <DialogTitle className={ classes.title }>
                    { title }
                </DialogTitle>
            )}

            <DialogContent>
                <Box mb={ 2 }>
                    { children }
                </Box>
            </DialogContent>
        </Dialog>
    );
};

FormDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
};

export default FormDialog;