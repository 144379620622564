import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    helperText: {
        marginTop: -1,
        marginLeft: 32,
    },
}));

export default styles;
