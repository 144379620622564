import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import styles from './PremiumPromo.styles';
import ImageDialog from '../ImageDialog';

const PremiumPromo = props => {
    const { image } = props;
    const classes = styles();

    const [open, setOpen] = useState(false);

    const title = useIntl().formatMessage({ id: 'premium.promo.title' });
    const explanation = useIntl().formatMessage({ id: 'premium.promo.description' });
    const buttonText = useIntl().formatMessage({ id: 'premium.promo.button_text' });

    return (
        <Fragment>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <Paper elevation={ 2 } className={ classes.block }>
                        <img src={ image } alt={ 'Premium' } className="responsive-image" onClick={ () => setOpen(true) } />
                    </Paper>
                </Grid>

                <Grid item xs={ 6 }>
                    <Paper elevation={ 2 } className={ classes.block }>
                        <Typography variant="h5" component="h1" gutterBottom>
                            { title }
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                            { explanation }
                        </Typography>

                        <Box mt={ 2 }>
                            <Link to={ '/subscription' } className={ classes.link }>
                                <Button color={ 'primary' } variant={ 'contained' }>
                                    { buttonText }
                                </Button>
                            </Link>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <ImageDialog
                open={ open }
                handleClose={ () => setOpen(false) }
                image={ image }
            />
        </Fragment>
    );
};

PremiumPromo.propTypes = {
    image: PropTypes.string.isRequired,
};

export default PremiumPromo;
