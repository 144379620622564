import { all, call, put, take } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { DIVISIONS } from '../constants';
import { Api } from 'config/api';
import { get } from 'utilities/api';

export const getToken = (state) => state.persist.accessToken;

export function* fetchAllRequest(token) {
    return yield get(token, Api.requests.divisions.index);
}

export function* fetchFlow() {
    while (true) {
        yield take(DIVISIONS.FETCH.TRIGGER);

        try {
            const token = yield select(getToken);
            const response = yield call(fetchAllRequest, token);
            const divisions = response.data.data;

            yield put(DIVISIONS.FETCH.success(divisions));
        } catch (error) {
            yield put(DIVISIONS.FETCH.failure());
        } finally {
            yield put(DIVISIONS.FETCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
    ]);
}