import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import BoxHeading from 'components/BoxHeading';
import ContentBox from 'components/ContentBox';
import styles from './SurveyStructure.styles';

const SurveyStructure = props => {
    const { structure, topicId, questionId } = props;

    const classes = styles();

    const renderTopics = () => {
        return structure.map(topic => (
            <ul
                key={ `topic-${topic.id}`}
                className={ classes.list }
            >
                <li className={ classes.listItem }>
                    <span className={ topic.id === topicId ? classes.active : null }>
                        { topic.name }
                    </span>

                    { renderQuestions(topic?.questions?.data ?? []) }
                </li>
            </ul>
        ));
    }

    const renderQuestions = questions => {
        return questions.map(question => (
            <ul
                key={ `question-${question.id}`}
                className={ classes.list }
            >
                <li className={ classes.listItem }>
                    <span className={ question.id === questionId ? classes.active : null }>
                        { question.question }
                    </span>

                    { renderAnswers(question?.answers?.data ?? []) }
                </li>
            </ul>
        ));
    }

    const renderAnswers = answers => {
        return answers.map(answer => (
            <ul
                key={ `answer-${answer.id}`}
                className={ classes.list }
            >
                <li className={ classes.listItem }>
                    { answer.answer }
                </li>
            </ul>
        ));
    }
    
    return (
        <ContentBox>
            <BoxHeading
                title="Structuur"
            />

            <Box mt={ 2 }>
                { renderTopics() }
            </Box>
        </ContentBox>
    );
};

SurveyStructure.propTypes = {
    questionId: PropTypes.number,
    topicId: PropTypes.number,
    structure: PropTypes.array.isRequired,
}

export default SurveyStructure;