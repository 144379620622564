import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ContentContainer from 'components/ContentContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './PeriodsShow.styles';
import ValueBlock from 'components/ValueBlock';
import { PERIODS } from 'store/constants';
import { reverseToReadableDate } from 'utilities/date';
import ValueList from 'components/ValueList';

const PeriodsShow = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const fetched = useSelector(state => state.period.fetched);
    const loading = useSelector(state => state.period.loading);
    const classes = styles();

    const periods = useSelector(state => state.period.periods);
    const period = periods.filter(object => object.id === parseInt(params.periodId))[0];

    const pageTitle = useIntl().formatMessage({ id: 'periods.show.title' });
    const startDate = period ? reverseToReadableDate(period.start_date) : '';
    const startDateLabel = useIntl().formatMessage({ id: 'periods.label.startDate' });
    const endDate = period ? reverseToReadableDate(period.end_date) : '';
    const endDateLabel = useIntl().formatMessage({ id: 'periods.label.endDate' });
    const internal = period ? period.internal : false;
    const internalLabel = useIntl().formatMessage({ id: 'periods.label.internal' });
    const trainings = period ? period.trainings.data.map(training => training.name) : [];
    const trainingsLabel = useIntl().formatMessage({ id: 'periods.label.trainings' });
    const numberOfTrainingsLabel = useIntl().formatMessage({ id: 'periods.label.numberOfTrainings' });
    const isInternalText = useIntl().formatMessage({ id: 'string.yes' });
    const isNotInternalText = useIntl().formatMessage({ id: 'string.no' });
    const error = useSelector(state => state.period.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (fetched === false) {
            dispatch(PERIODS.FETCH.trigger());
        }

        return function cleanup() {
            dispatch({ type: PERIODS.CLEAN });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentContainer title={ pageTitle } backButton={ true }>
            { loading ? <LoadingIndicator /> : (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Paper elevation={ 2 } className={ classes.block }>
                            <ValueBlock label={ startDateLabel } value={ startDate } />
                            <ValueBlock label={ endDateLabel } value={ endDate } />
                            <ValueBlock label={ internalLabel } value={ internal ? isInternalText : isNotInternalText } />
                            <ValueBlock label={ numberOfTrainingsLabel } value={ trainings.length } />

                            <ValueList
                                label={ trainingsLabel }
                                values={ trainings }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) }
        </ContentContainer>
    );
};

export default PeriodsShow;
