import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './LoadingIndicator.styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const LoadingIndicator = props => {
    const classes = styles();
    const { message } = props;

    return (
        <div className={ classes.root }>
            <CircularProgress />

            <Typography variant="body2" className={ classes.message }>
                { message }
            </Typography>
        </div>
    );
};

LoadingIndicator.propTypes = {
    message: PropTypes.string,
};

export default LoadingIndicator;
