import { AUTH, CLASSIFICATIONS } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    classifications: [],
    active: null,

    fetched: false,
    error: false,
    loading: false,

    created: null,
    updated: false,
    deleted: false,
};

const classificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLASSIFICATIONS.FETCH.TRIGGER:
        case CLASSIFICATIONS.SHOW.TRIGGER:
        case CLASSIFICATIONS.CREATE.TRIGGER:
        case CLASSIFICATIONS.UPDATE.TRIGGER:
        case CLASSIFICATIONS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
            }

        case CLASSIFICATIONS.FETCH.SUCCESS:
            return {
                ...state,
                classifications: action.payload,
                fetched: true,
            };

        case CLASSIFICATIONS.SHOW.SUCCESS:
            return {
                ...state,
                active: action.payload,
            };

        case CLASSIFICATIONS.CREATE.SUCCESS:
            return {
                ...state,
                classifications: state.classifications.concat(action.payload),
                created: action.payload.id,
            };

        case CLASSIFICATIONS.UPDATE.SUCCESS:
            return {
                ...state,
                classifications: findIdAndUpdateObjectInArray([...state.classifications], action.payload.id, action.payload),
                updated: true,
            };

        case CLASSIFICATIONS.DELETE.SUCCESS:
            return {
                ...state,
                classifications: deleteObjectWithIdFromArray([...state.classifications], action.payload),
                deleted: true,
            };

        case CLASSIFICATIONS.FETCH.FAILURE:
        case CLASSIFICATIONS.SHOW.FAILURE:
        case CLASSIFICATIONS.CREATE.FAILURE:
        case CLASSIFICATIONS.UPDATE.FAILURE:
        case CLASSIFICATIONS.DELETE.FAILURE:
            return {
                ...state,
                error: true,
            }

        case CLASSIFICATIONS.FETCH.FULFILL:
        case CLASSIFICATIONS.SHOW.FULFILL:
        case CLASSIFICATIONS.CREATE.FULFILL:
        case CLASSIFICATIONS.UPDATE.FULFILL:
        case CLASSIFICATIONS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
                error: false,
                created: null,
                updated: false,
                deleted: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default classificationReducer;