import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { CLASSIFICATIONS } from '../constants';
import {
    fetchRequest,
    storeRequest,
    updateRequest,
    deleteRequest
} from '../api';

export function* fetchFlow() {
    while (true) {
        yield take(CLASSIFICATIONS.FETCH.TRIGGER);

        try {
            const endpoint = Api.requests.classifications.index;
            const response = yield call(fetchRequest, endpoint);
            const classifications = response.data.data;

            yield put(CLASSIFICATIONS.FETCH.success(classifications));
        } catch (error) {
            yield put(CLASSIFICATIONS.FETCH.failure());
        } finally {
            yield put(CLASSIFICATIONS.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(CLASSIFICATIONS.SHOW.TRIGGER);

        try {
            const classificationId = request.payload;

            const classificationEndpoint = Api.requests.classifications.show(classificationId);
            const classificationResponse = yield call(fetchRequest, classificationEndpoint);
            const classification = classificationResponse.data.data;

            yield put(CLASSIFICATIONS.SHOW.success(classification));
        } catch (error) {
            yield put(CLASSIFICATIONS.SHOW.failure());
        } finally {
            yield put(CLASSIFICATIONS.SHOW.fulfill());
        }
    }
}

export function* createFlow() {
    while (true) {
        const request = yield take(CLASSIFICATIONS.CREATE.TRIGGER);

        try {
            const endpoint = Api.requests.classifications.store;
            const payload = request.payload;
            const response = yield call(storeRequest, endpoint, payload);

            yield put(CLASSIFICATIONS.CREATE.success(response.data.data));
        } catch (error) {
            yield put(CLASSIFICATIONS.CREATE.failure());
        } finally {
            yield put(CLASSIFICATIONS.CREATE.fulfill());
        }
    }
}

export function* updateFlow() {
    while (true) {
        const request = yield take(CLASSIFICATIONS.UPDATE.TRIGGER);

        try {
            const classificationId = request.payload.classificationId;
            const endpoint = Api.requests.classifications.update(classificationId);
            const payload = request.payload.values;
            const response = yield call(updateRequest, endpoint, payload);

            yield put(CLASSIFICATIONS.UPDATE.success(response.data.data));
        } catch (error) {
            yield put(CLASSIFICATIONS.UPDATE.failure());
        } finally {
            yield put(CLASSIFICATIONS.UPDATE.fulfill());
        }
    }
}

export function* deleteFlow() {
    while (true) {
        const request = yield take(CLASSIFICATIONS.DELETE.TRIGGER);

        try {
            const id = request.payload;
            const endpoint = Api.requests.classifications.delete(id);

            yield call(deleteRequest, endpoint);
            yield put(CLASSIFICATIONS.DELETE.success(request.payload));
        } catch (error) {
            yield put(CLASSIFICATIONS.DELETE.failure());
        } finally {
            yield put(CLASSIFICATIONS.DELETE.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
        createFlow(),
        updateFlow(),
        deleteFlow(),
    ]);
}