import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { TEAM } from '../constants';
import { fetchRequest } from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(TEAM.EVALUATION.FETCH.TRIGGER);

        try {
            const teamId = request.payload;
            const endpoint = Api.requests.teams.evaluations.index(teamId);
            const response = yield call(fetchRequest, endpoint);
            const evaluations = response.data.data;

            yield put(TEAM.EVALUATION.FETCH.success({
                teamId,
                evaluations,
            }));
        } catch (error) {
            yield put(TEAM.EVALUATION.FETCH.failure());
        } finally {
            yield put(TEAM.EVALUATION.FETCH.fulfill());
        }
    }
}

export function* showFlow() {
    while (true) {
        const request = yield take(TEAM.EVALUATION.SHOW.TRIGGER);

        try {
            const teamId = request.payload.teamId;
            const evaluationId = request.payload.evaluationId;
            const endpoint = Api.requests.teams.evaluations.show(teamId, evaluationId);
            const response = yield call(fetchRequest, endpoint);

            yield put(TEAM.EVALUATION.SHOW.success(response.data.data));
        } catch (error) {
            yield put(TEAM.EVALUATION.SHOW.failure());
        } finally {
            yield put(TEAM.EVALUATION.SHOW.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
        showFlow(),
    ]);
}