import { Fragment } from 'react';

import StaticHeader from 'components/StaticHeader';
import StaticFooter from 'components/StaticFooter/StaticFooter';

const Faq = () => {
    return (
        <Fragment>
            <StaticHeader />

            <main role="main">
                <section className="faq gap-both">
                    <div className="container-fluid">
                        <div className="row mb-md-5 mb-sm-4 mb-3">
                            <div className="col-12">
                                <h1>FAQ</h1>
                                <p>
                                    Veelgestelde vragen proberen wij alvast voor je te beantwoorden. Mocht je vraag hier niet tussen
                                    staan, neem dan contact met ons op. Wij staan voor je klaar!
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="go4h-accordion" id="go4h-faq">
                                    <div className="card">
                                        <div className="card-header collapsed" id="headingFour" data-toggle="collapse"
                                             data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">

                                            <h5 className="mb-0">
                                                Wat zijn de kosten voor een club?
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                                             data-parent="#go4h-faq">
                                            <p className="card-body">
                                                Wij bieden verschillende pakketten aan waaronder trainer, coach, speler-volgsysteem, extra
                                                ondersteuning en clubuitstraling. Wij maken het graag op maat voor uw club. Mail gerust
                                                naar <a href="mailto:info@go4hockey.nl">info@go4hockey.nl</a>
                                            </p>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="card">
                                        <div className="card-header collapsed" id="headingFive" data-toggle="collapse"
                                             data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">

                                            <h5 className="mb-0">
                                                Hoe kan ik meer informatie krijgen voor mijn club?
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                                             data-parent="#go4h-faq">
                                            <p className="card-body">
                                                Wij sturen extra informatie op, maar wij maken graag vrijblijvend een afspraak om het
                                                platform te laten zien en de mogelijkheden te bespreken. Mail gerust naar <a href="mailto:info@go4hockey.nl">info@go4hockey.nl</a>
                                            </p>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="card">
                                        <div className="card-header collapsed" id="headingSix" data-toggle="collapse"
                                             data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">

                                            <h5 className="mb-0">
                                                Ik heb een vraag over het platform?
                                            </h5>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                                             data-parent="#go4h-faq">
                                            <p className="card-body last">
                                                Wij helpen je graag verder! Heb je een vraag over het systeem? Neem dan contact
                                                met ons op:
                                            </p>

                                            <ul>
                                                <li><a href="mailto:info@go4hockey.nl">info@go4hockey.nl</a></li>
                                                <li> WhatsApp: <a href="tel:0630633351">06 30 63 33 51</a>
                                                    (Lars Verboom)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <StaticFooter />
        </Fragment>
    );
};

export default Faq;
