import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { PREMIUM } from 'store/constants';
import { sortCaseInsensitive } from 'utilities/sort';
import { reverseToReadableDate, timestampToDateTimeString } from 'utilities/date';
import { defaultOptions, getMaterialTableTranslation } from 'utilities/materialTable';
import LoadingIndicator from 'components/LoadingIndicator';
import ContentContainer from 'components/ContentContainer';

const Premium = () => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.premium.fetched);
    const orders = useSelector(state => state.premium.premium);
    const locale = useSelector(state => state.persist.language);
    const history = useHistory();
    const localization = getMaterialTableTranslation(locale);

    const pageTitle = useIntl().formatMessage({ id: 'premium.title' });
    const error = useSelector(state => state.premium.error);
    const errorMessage = useIntl().formatMessage({ id: 'toast.error.main' });
    const deleted = useSelector(state => state.premium.deleted);
    const deletedMessage = useIntl().formatMessage({ id: 'toast.success.delete' });

    const columns = [
        {
            title: useIntl().formatMessage({ id: 'premium.label.timestamp' }),
            render: rowData => timestampToDateTimeString(rowData.timestamp),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'timestamp'),
        },
        {
            title: useIntl().formatMessage({ id: 'premium.label.name' }),
            field: 'user.data.full_name',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'user.data.full_name'),
        },
        {
            width: 250,
            title: useIntl().formatMessage({ id: 'premium.label.email' }),
            field: 'user.data.email',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'user.data.email'),
        },
        {
            title: useIntl().formatMessage({ id: 'premium.label.start_date' }),
            render: rowData => reverseToReadableDate(rowData.start_date),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'start_date'),
        },
        {
            title: useIntl().formatMessage({ id: 'premium.label.end_date' }),
            render: rowData => reverseToReadableDate(rowData.end_date),
            customSort: (a, b) => sortCaseInsensitive(a, b, 'end_date'),
        },
        {
            title: useIntl().formatMessage({ id: 'premium.label.club' }),
            field: 'club',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'club'),
        },
        {
            title: useIntl().formatMessage({ id: 'premium.label.status' }),
            field: 'status',
            customSort: (a, b) => sortCaseInsensitive(a, b, 'status'),
        },
    ];

    const actions = [
        {
            icon: 'add_box',
            tooltip: localization.body.addTooltip,
            isFreeAction: true,
            onClick: (event) => history.push('premium/create')
        },
        {
            icon: 'edit',
            tooltip: localization.body.editTooltip,
            onClick: (event, rowData) => history.push(`premium/${rowData.id}/edit`)
        },
    ];

    const onRowDelete = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(PREMIUM.DELETE.trigger(data.id))

            resolve();
        });
    };

    useEffect(() => {
        dispatch(PREMIUM.FETCH.trigger());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error === true) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    useEffect(() => {
        if (deleted === true) {
            toast.success(deletedMessage);
        }
    }, [deleted, deletedMessage]);

    return (
        <ContentContainer title={ pageTitle }>
            { fetched ? (
                <Grid container spacing={3}>
                    <Grid item xs={ 12 }>
                        <MaterialTable
                            title={ pageTitle }
                            localization={ localization }
                            columns={ columns }
                            data={ orders }
                            options={ defaultOptions }
                            actions={ actions }
                            editable={ { onRowDelete } }
                        />
                    </Grid>
                </Grid>
            ) : <LoadingIndicator /> }
        </ContentContainer>
    );
};

export default Premium;
