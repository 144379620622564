import { AUTH, INTERNAL_TRAINERS } from '../constants';
import {
    deleteObjectWithIdFromArray,
    findIdAndUpdateObjectInArray,
} from 'utilities/array';

const INITIAL_STATE = {
    internalTrainers: [],
    fetched: false,
    error: false,
    loading: false,
    deleted: false,
    created: null,
    updated: false,
};

const internalTrainerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INTERNAL_TRAINERS.FETCH.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case INTERNAL_TRAINERS.FETCH.SUCCESS:
            return {
                ...state,
                internalTrainers: action.payload,
                fetched: true,
            };

        case INTERNAL_TRAINERS.FETCH.FAILURE:
            return {
                ...state,
                error: true
            }

        case INTERNAL_TRAINERS.FETCH.FULFILL:
            return {
                ...state,
                loading: false
            }

        case INTERNAL_TRAINERS.CREATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                created: false,
            }

        case INTERNAL_TRAINERS.CREATE.SUCCESS:
            return {
                ...state,
                internalTrainers: state.internalTrainers.concat(action.payload),
                created: action.payload.id,
            };

        case INTERNAL_TRAINERS.CREATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case INTERNAL_TRAINERS.CREATE.FULFILL:
            return {
                ...state,
                loading: false,
                created: null,
            }

        case INTERNAL_TRAINERS.UPDATE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
            }

        case INTERNAL_TRAINERS.UPDATE.SUCCESS:
            return {
                ...state,
                internalTrainers: findIdAndUpdateObjectInArray([...state.internalTrainers], action.payload.id, action.payload),
                updated: true,
            };

        case INTERNAL_TRAINERS.UPDATE.FAILURE:
            return {
                ...state,
                error: true
            }

        case INTERNAL_TRAINERS.UPDATE.FULFILL:
            return {
                ...state,
                loading: false
            }

        case INTERNAL_TRAINERS.DELETE.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null,
                deleted: false,
            }

        case INTERNAL_TRAINERS.DELETE.SUCCESS:
            return {
                ...state,
                internalTrainers: deleteObjectWithIdFromArray([...state.internalTrainers], action.payload),
                deleted: true,
            };

        case INTERNAL_TRAINERS.DELETE.FAILURE:
            return {
                ...state,
                error: true
            }

        case INTERNAL_TRAINERS.DELETE.FULFILL:
            return {
                ...state,
                loading: false,
            }

        case INTERNAL_TRAINERS.CLEAN:
            return {
                ...state,
                error: null,
                loading: false,
                deleted: false,
                created: false,
                updated: false,
            }

        case AUTH.LOGOUT:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default internalTrainerReducer;