import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import styles from './ValueList.styles';

const ValueList = props => {
    const { label, values } = props;
    const classes = styles();

    const renderList = () => {
        let increment = 0;

        return values.map(value => {
            increment++;

            return (
                <li
                    key={ `value-list-item-${increment}`}
                    className={ classes.listItem }
                >
                    <Typography variant="body1" gutterBottom>
                        { value }
                    </Typography>
                </li>
            )
        })
    }

    return (
        <Box mb={ 2 }>
            { label && (
                <Typography variant="body1" gutterBottom className={ classes.label }>
                    { label }
                </Typography>
            )}

            <ul className={ classes.list }>
                { renderList() }
            </ul>
        </Box>
    );
};

ValueList.propTypes = {
    label: PropTypes.string,
    values: PropTypes.array.isRequired,
};

export default ValueList;
