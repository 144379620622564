import { all, call, put, take } from 'redux-saga/effects';

import { Api } from 'config/api';
import { CLUB } from '../constants';
import { fetchRequest } from '../api';

export function* fetchFlow() {
    while (true) {
        const request = yield take(CLUB.PLAYER.ASSESSMENT.FETCH.TRIGGER);

        try {
            const clubPlayerId = request.payload;
            const endpoint = Api.requests.clubs.players.assessments.index(clubPlayerId);
            const response = yield call(fetchRequest, endpoint);
            const assessments = response.data.data;

            yield put(CLUB.PLAYER.ASSESSMENT.FETCH.success({
                clubPlayerId,
                assessments,
            }));
        } catch (error) {
            yield put(CLUB.PLAYER.ASSESSMENT.FETCH.failure());
        } finally {
            yield put(CLUB.PLAYER.ASSESSMENT.FETCH.fulfill());
        }
    }
}

export default function* rootSaga() {
    yield all([
        fetchFlow(),
    ]);
}