const server = process.env.REACT_APP_API_SERVER;

export const Api = {
    url: server,
    grantType: 'password',
    clientId: process.env.REACT_APP_API_CLIENT_ID,
    clientSecret: process.env.REACT_APP_API_SECRET,

    requests: {
        accounts: {
            index: `${server}/api/clubmanager/users`, // GET
            store: `${server}/api/clubmanager/users`, // POST
            destroy: id => `${server}/api/clubmanager/users/${id}`, // DELETE
        },
        assessments: {
            show: assessmentId => `${server}/api/assessments/${assessmentId}`, // GET
        },
        classifications: {
            index: `${server}/api/classifications`, // GET
            store: `${server}/api/classifications`, // POST
            show: classificationId =>`${server}/api/classifications/${classificationId}?include=teams`, // GET
            update: classificationId => `${server}/api/classifications/${classificationId}`, // PATCH
            delete: classificationId => `${server}/api/classifications/${classificationId}`, // DELETE
            teams: {
                show: (classificationId, teamId) =>`${server}/api/classifications/${classificationId}?team=${teamId}&include=reviews`, // GET
            }
        },
        clubExercises: {
            index: `${server}/api/club/exercises?show_hidden=1`, // GET
        },
        clubs: {
            index: `${server}/api/clubs`, // GET
            store: `${server}/api/clubs`, // POST
            update: id => `${server}/api/clubs/${id}`, // PATCH
            delete: id => `${server}/api/clubs/${id}`, // DELETE
            accessRequests: {
                index: clubId =>`${server}/api/clubs/${clubId}/access-requests`, // GET
                update: (clubId, accessRequestId) => `${server}/api/clubs/${clubId}/access-requests/${accessRequestId}`, // PATCH
                delete: (clubId, accessRequestId) => `${server}/api/clubs/${clubId}/access-requests/${accessRequestId}`, // DELETE
            },
            players: {
                index: clubId =>`${server}/api/clubs/${clubId}/players`, // GET
                store: clubId =>`${server}/api/clubs/${clubId}/players`, // POST
                update: (clubId, playerId) => `${server}/api/clubs/${clubId}/players/${playerId}`, // PATCH
                delete: (clubId, playerId) => `${server}/api/clubs/${clubId}/players/${playerId}`, // DELETE
                import: clubId => `${server}/api/clubs/${clubId}/players/import`, // POST
                assessments: {
                    index: clubPlayerId =>`${server}/api/club-players/${clubPlayerId}/assessments`, // GET
                },
            },
            teams: {
                index: clubId =>`${server}/api/clubs/${clubId}/teams`, // GET
                store: clubId =>`${server}/api/clubs/${clubId}/teams`, // POST
                update: (clubId, teamId) => `${server}/api/clubs/${clubId}/teams/${teamId}`, // PATCH
                delete: (clubId, teamId) => `${server}/api/clubs/${clubId}/teams/${teamId}`, // DELETE
            },
            users: {
                index: clubId =>`${server}/api/clubs/${clubId}/users`, // GET
                store: clubId =>`${server}/api/clubs/${clubId}/users`, // POST
                update: (clubId, userId) =>`${server}/api/clubs/${clubId}/users/${userId}`, // PATCH
                delete: (clubId, userId) => `${server}/api/clubs/${clubId}/users/${userId}`, // DELETE
            },
        },
        clubTrainings: {
            index: `${server}/api/club/trainings?include=exercises&show_hidden=1`, // GET
        },
        criteria: {
            show: (playerId, topicId) => `${server}/api/users/${playerId}/assessment-topics/${topicId}`, // GET
        },
        divisions: {
            index: `${server}/api/divisions`, // GET
        },
        events: {
            index: `${server}/api/events`, // GET
            store: `${server}/api/events`, // POST
            show: eventId =>`${server}/api/events/${eventId}`, // GET
            update: id => `${server}/api/events/${id}`, // PATCH
            delete: id => `${server}/api/events/${id}`, // DELETE
            dates: {
                store: (eventId) => `${server}/api/events/${eventId}/dates`, // POST
                delete: (eventId, dateId) => `${server}/api/events/${eventId}/dates/${dateId}`, // DELETE
            },
            trainers: {
                update: (eventId, trainerId) => `${server}/api/events/${eventId}/trainers/${trainerId}`, // PATCH
                delete: (eventId, trainerId) => `${server}/api/events/${eventId}/trainers/${trainerId}`, // DELETE
            },
            trainings: {
                update: (eventId, trainingId) => `${server}/api/events/${eventId}/trainings/${trainingId}`, // PATCH
                delete: (eventId, trainingId) => `${server}/api/events/${eventId}/trainings/${trainingId}`, // DELETE
            },
            players: {
                update: (eventId, playerId) => `${server}/api/events/${eventId}/players/${playerId}`, // PATCH
                delete: (eventId, playerId) => `${server}/api/events/${eventId}/players/${playerId}`, // DELETE
            },
        },
        evaluations: {
            index: `${ server }/api/evaluations`, // GET
            store: `${ server }/api/evaluations`, // POST
            show: evaluationId => `${ server }/api/evaluations/${ evaluationId }`, // GET
            delete: evaluationId => `${ server }/api/evaluations/${ evaluationId }`, // DELETE
            clear: `${ server }/api/evaluations/clear-invitations`, // PATCH
            remind: `${ server }/api/evaluations/send-reminders`, // PATCH
            sync: `${ server }/api/evaluations/sync-invitations`, // PATCH
        },
        eventTypes: {
            index: `${server}/api/event-types`, // GET
        },
        exercises: {
            index: `${server}/api/exercises`, // GET
            show: id => `${server}/api/exercises/${id}`, // GET
            store: `${server}/api/exercises`, // POST
            update: id => `${server}/api/exercises/${id}`, // PATCH
            delete: id => `${server}/api/exercises/${id}`, // DELETE
            latest: `${server}/api/exercises/latest`, // GET
            educationPlan: `${server}/api/exercises/education-plan`, // GET
            label: {
                update: (exerciseId, labelId) => `${server}/api/exercises/${exerciseId}/labels/${labelId}`, // PATCH
                delete: (exerciseId, labelId) => `${server}/api/exercises/${exerciseId}/labels/${labelId}`, // DELETE
            },
            tag: {
                update: (exerciseId, tagId) => `${server}/api/exercises/${exerciseId}/tags/${tagId}`, // PATCH
                delete: (exerciseId, tagId) => `${server}/api/exercises/${exerciseId}/tags/${tagId}`, // DELETE
            },
        },
        exerciseTags: {
            index: `${server}/api/exercise-tags`,
            show: id => `${server}/api/exercise-tags/${id}`,
        },
        feedback: {
            index: `${server}/api/feedback?include=player`, // GET
            store: playerId => `${server}/api/users/${playerId}/feedback`, // POST
            update: (playerId, feedbackId) => `${server}/api/users/${playerId}/feedback/${feedbackId}`, // PATCH
            delete: (playerId, feedbackId) => `${server}/api/users/${playerId}/feedback/${feedbackId}`, // DELETE
            player: {
                index: playerId => `${server}/api/users/${playerId}/feedback`, // GET
            },
        },
        highlights: {
            store: matchId => `${server}/api/matches/${matchId}/highlights`, // POST
            delete: (matchId, highlightId) => `${server}/api/matches/${matchId}/highlights/${highlightId}`, // DELETE
        },
        internalTrainers: {
            index: `${server}/api/internal-trainers`, // GET
            store: `${server}/api/internal-trainers`, // POST
            update: id => `${server}/api/internal-trainers/${id}`, // PATCH
            delete: id => `${server}/api/internal-trainers/${id}`, // DELETE
        },
        inquiries: {
            index: `${server}/api/inquiries`, // POST
        },
        lineup: {
            store: matchId => `${server}/api/matches/${matchId}/lineups`, // POST
        },
        login: `${server}/api/oauth/token`,
        matches: {
            index: teamId => `${server}/api/teams/${teamId}/matches`, // GET
            store: teamId => `${server}/api/teams/${teamId}/matches`, // POST
            show: (teamId, matchId) => `${server}/api/teams/${teamId}/matches/${matchId}`, // GET
            update: (teamId, matchId) => `${server}/api/teams/${teamId}/matches/${matchId}`, // PATCH
            delete: (teamId, matchId) => `${server}/api/teams/${teamId}/matches/${matchId}`, // DELETE
        },
        news: {
            index: `${server}/api/news`, // GET
            store: `${server}/api/news`, // POST
            update: id => `${server}/api/news/${id}`, // PATCH
            delete: id => `${server}/api/news/${id}`, // DELETE
        },
        occasions: {
            index: `${server}/api/occasions`, // GET
        },
        orders: {
            index: `${server}/api/orders`, // GET
            show: orderId => `${server}/api/orders/${orderId}`, // GET
        },
        periods: {
            index: `${server}/api/periods`, // GET
            store: `${server}/api/periods`, // POST
            update: id => `${server}/api/periods/${id}`, // PATCH
            delete: id => `${server}/api/periods/${id}`, // DELETE
            training: {
                update: (periodId, trainingId) => `${server}/api/periods/${periodId}/trainings/${trainingId}`, // PATCH
                delete: (periodId, trainingId) => `${server}/api/periods/${periodId}/trainings/${trainingId}`, // DELETE
            },
        },
        photos: {
            store: matchId => `${server}/api/matches/${matchId}/photos`, // POST
            delete: (matchId, photoId) => `${server}/api/matches/${matchId}/photos/${photoId}`, // DELETE
        },
        players: {
            store: userId => `${server}/api/users/${userId}/players`, // POST
            show: playerId => `${server}/api/players/${playerId}`, // GET
            update: playerId => `${server}/api/players/${playerId}`, // POST
            stats: {
                store: playerId => `${server}/api/players/${playerId}/stats`, // POST
            },
        },
        playercard: {
            show: playerId => `${server}/api/playercard/${playerId}`, // GET
        },
        premium: {
            index: `${server}/api/premiums`, // GET
            show: id => `${server}/api/premiums/${id}`, // GET
            store: `${server}/api/premiums`, // POST
            update: id => `${server}/api/premiums/${id}`, // PATCH
            delete: id => `${server}/api/premiums/${id}`, // DELETE
        },
        promo: {
            exercises: {
                index: slug => `${server}/api/promo/exercises?slug=${slug}`, // GET
            },
            instructions: {
                index: slug => `${server}/api/promo/instructions?slug=${slug}`, // GET
            },
        },
        ratings: {
            store: matchId => `${server}/api/matches/${matchId}/ratings`, // POST
        },
        register: `${server}/api/registration`, // POST
        report: {
            store: matchId => `${server}/api/matches/${matchId}/report`, // POST
        },
        requestPasswordReset: `${server}/api/password/send-reset-link`, // POST
        resetPassword: `${server}/api/password/reset`, // POST
        subscriptions: {
            store: `${server}/api/subscriptions`, // POST
        },
        surveys: {
            index: `${server}/api/surveys`, // GET
            store: `${server}/api/surveys`, // POST
            show: surveyId => `${server}/api/surveys/${surveyId}`, // GET
            update: surveyId => `${server}/api/surveys/${surveyId}`, // PATCH
            delete: surveyId => `${server}/api/surveys/${surveyId}`, // DELETE
            answers: {
                store: questionId => `${server}/api/survey-questions/${questionId}/answers`, // GET
                update: (questionId, answerId) => `${server}/api/survey-questions/${questionId}/answers/${answerId}`, // PATCH
                delete: (questionId, answerId) => `${server}/api/survey-questions/${questionId}/answers/${answerId}`, // DELETE
            },
            questions: {
                store: topicId => `${server}/api/survey-topics/${topicId}/questions`, // GET
                update: (topicId, questionId) => `${server}/api/survey-topics/${topicId}/questions/${questionId}`, // PATCH
                delete: (topicId, questionId) => `${server}/api/survey-topics/${topicId}/questions/${questionId}`, // DELETE
            },
            topics: {
                store: surveyId => `${server}/api/surveys/${surveyId}/topics`, // POST
                update: (surveyId, topicId) => `${server}/api/surveys/${surveyId}/topics/${topicId}`, // PATCH
                delete: (surveyId, topicId) => `${server}/api/surveys/${surveyId}/topics/${topicId}`, // DELETE
            },
        },
        tactic: {
            store: matchId => `${server}/api/matches/${matchId}/tactic`, // POST
        },
        tags: {
            index: `${server}/api/tags`, // GET
        },
        teams: {
            show: teamId => `${server}/api/teams/${teamId}`, // GET
            admins: {
                store: teamId => `${server}/api/teams/${teamId}/admins`, // POST
                delete: (teamId, adminId) => `${server}/api/teams/${teamId}/admins/${adminId}`, // DELETE
            },
            clubPlayers: {
                update: (playerId, teamId) => `${server}/api/teams/${teamId}/club-players/${playerId}`, // POST
            },
            evaluations: {
                index: teamId => `${server}/api/teams/${teamId}/evaluations`, // GET
                show: (teamId, evaluationId) => `${server}/api/teams/${teamId}/evaluations/${evaluationId}`, // GET
            },
            players: {
                index: teamId => `${server}/api/teams/${teamId}/players`, // GET
                delete: (teamId, playerId) => `${server}/api/teams/${teamId}/players/${playerId}`, // DELETE
            },
        },
        trainings: {
            index: `${server}/api/trainings`, // GET
            show: trainingId => `${server}/api/trainings/${trainingId}`, // GET
            store: `${server}/api/trainings`, // POST
            update: id => `${server}/api/trainings/${id}`, // PATCH
            delete: id => `${server}/api/trainings/${id}`, // DELETE
            exercise: {
                update: (trainingId, exerciseId) => `${server}/api/trainings/${trainingId}/exercises/${exerciseId}`, // PATCH
                delete: (trainingId, exerciseId) => `${server}/api/trainings/${trainingId}/exercises/${exerciseId}`, // DELETE
            },
        },
        users: {
            show: userId => `${server}/api/users/${userId}`, // GET
            update: userId => `${server}/api/users/${userId}`, // POST
            search: `${server}/api/users/search`, // POST
        },
        verify: (userId, query) => `${server}/api/verify/${userId}${query}`, // GET
        videos: {
            index: `${server}/api/videos`, // GET
            store: `${server}/api/videos`, // POST
            update: id => `${server}/api/videos/${id}`, // PATCH
            delete: id => `${server}/api/videos/${id}`, // DELETE
            tag: {
                update: (videoId, tagId) => `${server}/api/videos/${videoId}/tags/${tagId}`, // PATCH
                delete: (videoId, tagId) => `${server}/api/videos/${videoId}/tags/${tagId}`, // DELETE
            },
        },
    },
};
