import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        boxSizing: 'border-box',
        padding: theme.spacing(3),
    }
}));

export default styles;